import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import img1 from "../../assets/images/svg/Telecom-banner4.svg";
import TowerIcon from "../../assets/images/svg/network-tower.png";
import Banner3img from "../../assets/images/brivasfinanceimage.png";

const Banner3 = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section>
      <div className="mx-md-5 px-3 px-md-5 bg-light">
        <div class="row">
          <div class="col-md-6 col-sm-12 order-2 order-md-1">
            <h1 className="mb-3 fw-bold w-75 ">
              Driving Finance with <span> Location-Based </span> Technology
            </h1>
            <p
              className="display-6 fw-normal w-75 pt-3 lh-base text-sm-center text-md-start mt-md-5 mb-5"
              style={{ fontSize: "15px" }}
            >
              By putting in innovative location-based technology, we are
              changing the way you use financial services. Our platform combines
              the power of geographic information with the world of finance to
              improve your experience, give you more security, and give you
              access to new financial goods.
            </p>
          </div>

          <div className="d-flex gap-2 my-2">
            <button
              color="success"
              className="btn rounded-pill text-danger btn-light border-danger"
              onClick={() => "/register"}
            >
              <span className="text-danger">Talk to an expert</span>
            </button>
          </div>

          <div class="col-md-6 col-sm-12 my-5 d-flex align-items-center order-1">
            <img src={Banner3img} alt="" className="user-img w-75" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner3;
