import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';

//SimpleBar
import SimpleBar from "simplebar-react";
import { listMyApps } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';

const MyApps = () => {

    const { slug } = useParams();
    const dispatch = useDispatch();

    const { mApps, appCreated } = useSelector(state => ({
        mApps: state.App.mApps,
        appCreated: state.App.appCreated,
    }));

    useEffect(() => {
        dispatch(listMyApps(slug));
    }, [dispatch, slug, appCreated]);

    // Copy Key
    const handleCopy = (key) => {
        navigator.clipboard.writeText(key);

        document.getElementById("copyClipBoard").style.display = "block";
        setTimeout(() => {
            document.getElementById("copyClipBoard").style.display = "none";
        }, 2000);
    };

    return (
        <React.Fragment>
            <Row>
                <Col lg={8}>
                    <Row>
                        {mApps?.map((item, key) => <Col lg={6} key={key}>
                            <Card className='shadow-none'>
                                <CardBody>
                                    <h5 className="fs-15 fw-semibold">{item?.name}</h5>
                                    <div className='d-flex'>
                                        <div className="flex-grow-1 text-truncate text-muted">
                                            {item?.app_id}
                                        </div>
                                        <div className="flex-shrink-0">
                                            <Link className="text-uppercase fs-11 badge fw-medium badge-soft-secondary"
                                                onClick={() => handleCopy(item?.app_id)}><b>Copy Key</b></Link>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>)}
                    </Row>
                </Col>

                <Col lg={4}>
                    <Card className='shadow-none'>
                        <CardBody>
                            <h5 className="card-title mb-4">Other Apps</h5>
                            <div className="d-flex flex-wrap gap-2 fs-16">
                                <div className="badge fw-medium badge-soft-secondary">SMS</div>
                                <div className="badge fw-medium badge-soft-secondary">Bulk SMS</div>
                                <div className="badge fw-medium badge-soft-secondary">IVR</div>
                                <div className="badge fw-medium badge-soft-secondary">Audio Streaming</div>
                                <div className="badge fw-medium badge-soft-secondary">Video Streaming</div>
                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
            <div
                className="alert alert-warning alert-dismissible copyclipboard-alert px-4 fade show "
                id="copyClipBoard"
                role="alert"
            >
                Copied
            </div>
        </React.Fragment>
    );
};

export default MyApps;