// import React, { useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import {
//   Card,
//   Col,
//   Carousel,
//   Container,
//   Form,
//   Input,
//   Row,
//   UncontrolledTooltip,
// } from "reactstrap";

// // import JobProfile2 from "../../assets/images/job-profile2.png";
// import Dolapo from "../../assets/images/svg/brimailhome-min.png";

// import { listProductGroup } from "../../store/actions";
// import { useDispatch, useSelector } from "react-redux";

// const Homenew = () => {
//   const history = useNavigate();
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(listProductGroup());
//   }, [dispatch]);

//   const { productGroups } = useSelector((state) => ({
//     productGroups: state.Landing.productGroups,
//   }));

//   return (
//     <React.Fragment>
//       <section
//         className="section pb-0"
//         style={{
//           backgroundImage: `url(${Dolapo})`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           height: "635px",
//         }}
//       >
//         <div className=" bg-home-overlay-pattern2"></div>
//         <div className="px-4">
//           <Row className="justify-content-between p-5 align-items-center">
//             <Col lg={6}>
//               <div>
//                 <h1 className="display-6 fw-large mb-3 w-75 mt-3  fw-bold text-light fs-50  lh-base">
//                   Introducing Brimail: the <br></br>
//                   pinnacle of emailing services
//                 </h1>
//                 <p className="lead lh-base mb-5 mt- text-light mx-auto "></p>

//                 <div className="d-flex gap-2 mt-2 text-primary">
//                   <button
//                     color="light "
//                     className="btn rounded-pill btn-light text-primary"
//                     onClick={() => history("/register")}
//                   >
//                     Start for free
//                     <i className=" align-middle text-primary ms-1 padding-right-30px  rounded-pill"></i>
//                   </button>

//                   <button
//                     color="success"
//                     className="btn rounded-pill btn-light margin-left-20px"
//                     onClick={() => history("/register")}
//                   >
//                     Talk to an expert
//                     <i className=" align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
//                   </button>
//                 </div>
//               </div>
//             </Col>
//             <Col lg={4}>
//               <div className="position-relative home-img text-center mt-3 mt-lg-0">
//                 {/* <img src={JobProfile2} alt="" className="user-img" /> */}

//                 {/* <img
//                   src={HomeImg}
//                   alt=""
//                   className="landing-home-img"
//                   height={560.2}
//                 /> */}
//               </div>
//             </Col>
//           </Row>
//         </div>
//       </section>
//     </React.Fragment>
//   );
// };

// export default Homenew;


import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Carousel,
  Container,
  Form,
  Input,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

// import JobProfile2 from "../../assets/images/job-profile2.png";
import Dolapo from "../../assets/images/svg/brimailhome-min.png";

import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const Homenew = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <section
        className="section  bg-secondary pb-0"
        id="hero"
        style={{
          backgroundImage: `url(${Dolapo})`,
          backgroundSize: "cover",
          backgroundPosition: "contain",
         
        }}
      >
     
        <div className="my-3 py-5">
          <Row className="">
            <Col md={12} sm={12}>
              <div className="mx-5 d-flex w-lg-75 flex-column">
                <h1 className="display-5 fw-medium mb-3 ft-bold text-light fs-45  lh-base">
                Introducing Brimail: the pinnacle of emailing services<br></br>
                  Messaging Solutions
                </h1>
                <p className="lead lh-base mb-5 mt-5 text-light ">
                  Want stronger customer relationships? Our communication APIs
                  are complete.Fast transactional and promotional messaging
                  API.
                </p>

                <div className=" d-flex gap-2 mt-2">
                  <button
                    color="danger "
                    className="btn rounded-pill btn-danger"
                    onClick={() => history("/register")}
                  >
                    Start for free
                   
                  </button>

                  <button
                    color="success"
                    className="btn rounded-pill btn-light margin-left-20px"
                    onClick={() => history("/register")}
                  >
                    Talk to an expert
                   
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Homenew;

