import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  getBulkSMSBalance,
  postScheduleBulkSMS,
  postSendBulkSMS,
} from "../../helpers/backend_helper";
import { BULK_SMS_BALANCE, BULK_SMS_SCHEDULE, SEND_BULK_SMS } from "./actonType";
import { bulkSMSError, bulkSMSSuccess } from "./action";

function* bulkSMSBalSaga() {
  try {
    const resp = yield call(getBulkSMSBalance);
    if (resp.status === "success") {
      yield put(bulkSMSSuccess(BULK_SMS_BALANCE, resp.data));
    }
  } catch (error) {
    //
  }
}

function* sendBulkSMSSaga({ payload: { data } }) {
  try {
    const resp = yield call(postSendBulkSMS, data);
    if (resp.status === "success") {
      yield put(bulkSMSSuccess(SEND_BULK_SMS, resp));
      toast.success(resp?.msg ?? 'Message sent', { autoClose: 3000 });
    } else {
      toast.error(resp?.msg ?? 'An error occured', { autoClose: 3000 });
      yield put(bulkSMSError(SEND_BULK_SMS, resp?.msg ?? 'An error occured'));
    }
  } catch (error) {
    yield put(bulkSMSError(SEND_BULK_SMS, error?.message ?? 'Failed'));
    toast.error(error?.response?.data?.msg ?? 'Failed', { autoClose: 3000 });
  }
}

function* scheduleBulkSMSSaga({ payload: { data } }) {
  try {
    const resp = yield call(postScheduleBulkSMS, data);
    if (resp.status === "success") {
      yield put(bulkSMSSuccess(BULK_SMS_SCHEDULE, resp));
      toast.success(resp?.msg ?? 'Message scheduled', { autoClose: 3000 });
    } else {
      toast.error(resp?.msg ?? 'An error occured', { autoClose: 3000 });
      yield put(bulkSMSError(BULK_SMS_SCHEDULE, resp?.msg ?? 'An error occured'));
    }
  } catch (error) {
    yield put(bulkSMSError(BULK_SMS_SCHEDULE, error?.message ?? 'Something went wrong'));
    toast.error(error?.response?.data?.msg ?? 'Something went wrong', { autoClose: 3000 });
  }
}

function* bulkSMSListContactSaga({ payload: { data } }) {
  try {
    const resp = yield call(postSendBulkSMS, data);
    if (resp.status === "success") {
      yield put(bulkSMSSuccess(SEND_BULK_SMS, resp));
      toast.success(resp?.msg ?? 'Message sent', { autoClose: 3000 });
    } else {
      toast.error(resp?.msg ?? 'An error occured', { autoClose: 3000 });
      yield put(bulkSMSError(SEND_BULK_SMS, resp?.msg ?? 'An error occured'));
    }
  } catch (error) {
    yield put(bulkSMSError(SEND_BULK_SMS, error?.message ?? 'Failed'));
    toast.error(error?.response?.data?.msg ?? 'Failed', { autoClose: 3000 });
  }
}

export function* watchBulkSMSBal() {
  yield takeEvery(BULK_SMS_BALANCE, bulkSMSBalSaga);
}
export function* watchSendBulkSMS() {
  yield takeEvery(SEND_BULK_SMS, sendBulkSMSSaga);
}
export function* watchScheduleBulkSMS() {
  yield takeEvery(BULK_SMS_SCHEDULE, scheduleBulkSMSSaga);
}

function* bulkSMSSaga() {
  yield all([fork(watchBulkSMSBal)]);
  yield all([fork(watchSendBulkSMS)]);
  yield all([fork(watchScheduleBulkSMS)]);
}

export default bulkSMSSaga;
