import {
    LIST_PRODUCT_GROUP,
    LIST_PRODUCT_GROUP_SUCCESS,
    LIST_PRODUCT_GROUP_ERROR,
    GET_PRODUCT,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_ERROR,
} from "./actionType";

export const listProductGroup = () => ({
    type: LIST_PRODUCT_GROUP,
});

export const listProductGroupSuccess = (actionType, data) => ({
    type: LIST_PRODUCT_GROUP_SUCCESS,
    payload: { actionType, data },
});

export const listProductGroupError = (actionType, error) => ({
    type: LIST_PRODUCT_GROUP_ERROR,
    payload: { actionType, error },
});

export const getProduct = (slug) => ({
    type: GET_PRODUCT,
    payload: slug,
});

export const getProductSuccess = (actionType, data) => ({
    type: GET_PRODUCT_SUCCESS,
    payload: { actionType, data },
});

export const getProductError = (actionType, error) => ({
    type: GET_PRODUCT_ERROR,
    payload: { actionType, error },
});
