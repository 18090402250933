import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import icon1 from "../../assets/images/svg/Telecom-icon1.svg";
import icon2 from "../../assets/images/svg/Telecom-icon2.svg";
import icon3 from "../../assets/images/svg/Telecom-icon3.svg";
import icon4 from "../../assets/images/svg/Telecom-icon4.svg";
import icon5 from "../../assets/images/svg/Telecom-icon5.svg";


const Bottom = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section>
      <div className="" style={{marginBottom:'120px'}}>
        <div>
          <div className="" style={{marginBottom:'120px'}}>
            <h2 className=" text-center fw-bold text-dark">
              Protecting and preserving network connections
            </h2>
          </div>

          <div className="d-flex justify-content-around gap-2">
            <div style={{marginLeft:'80px'}}>  
              <Card
                className=""
                style={{
                  borderRadius: "20px",
                  boxShadow: "10px 10px",
                  width: "110%",
                  height: "90%",
                }}
              >
                <img
                  className="pt-2"
                  src={icon1}
                  alt="Card cap"
                  width="40px"
                  height="40px"
                />
                <CardBody style={{ width: "211px" }}>
                  <h4 className="card-title ft-bold mb-3 mt-4 fw-bolder fs-24">
                    OTP
                  </h4>
                  <p className="card-text text-muted" style={{width:'21ch'}}>
                    Increase the level of security using one-of-a-kind codes
                    that are sent via phone conversations.
                  </p>
                  <div className="text-end bottom  mt-5">
                    <Link to="#" className="soft-primary">
                      Learn more <i class="pl-2 ri-arrow-right-circle-fill text-danger"></i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div>
              <Card
                style={{
                  borderRadius: "20px",
                  boxShadow: "10px 10px",
                  width: "110%",
                  height: "90%",
                }}
              >
                <img
                  className="card-img-top pt-3"
                  style={{ width: 40, height: 40 }}
                  src={icon2}
                  alt="Card cap"
                />
                <CardBody style={{ width: "211px" }}>
                  <h4 className="card-title ft-bold mb-3 mt-4 fw-bolder fs-24">
                    USSD
                  </h4>
                  <p className="card-text text-muted" style={{width:'21ch'}}>
                    Create an offline menu system for users without internet
                    access to facilitate interactive interactions.
                  </p>
                  <div className="text-end bottom">
                    <Link to="#" className="soft-primary w-50 mx-auto">
                      Learn more
                      <i class="pl-2 ri-arrow-right-circle-fill text-danger"></i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div>
              <Card
                style={{
                  borderRadius: "20px",
                  boxShadow: "10px 10px",
                  width: "110%",
                  height: "90%",
                }}
              >
                <img
                  className="card-img-top pt-3"
                  style={{ width: 40, height: 40 }}
                  src={icon3}
                  alt="Card cap"
                />
                <CardBody style={{ width: "211px" }}>
                  <h4 className="card-title ft-bold mb-3 mt-4  fw-bolder fs-24">
                    Flash Call
                  </h4>
                  <p className="card-text text-muted" style={{width:'21ch'}}>
                    Identify users in an instant by dialing their phone numbers
                    using an automated system.
                  </p>
                  <div className="text-end">
                    <Link to="#" className="soft-primary w-50 mx-auto">
                      Learn more
                      <i class="pl-2 ri-arrow-right-circle-fill text-danger"></i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div>
              <Card
                style={{
                  borderRadius: "20px",
                  boxShadow: "10px 10px",
                  width: "110%",
                  height: "90%",
                }}
              >
                <img
                  className="card-img-top pt-3"
                  style={{ width: 40, height: 40 }}
                  src={icon4}
                  alt="Card cap"
                />
                <CardBody style={{ width: "211px", height:'84px' }}>
                  <h4 className="card-title ft-bold mb-3 mt-4 fw-bolder fs-24">
                    Voice Call
                  </h4>
                  <p className="card-text text-muted" style={{width:'21ch'}}>
                    Voice interactions should facilitate understandable and
                    trustworthy communication.
                  </p>
                  <div className="text-end">
                    <Link to="#" className="soft-primary mx-auto">
                      Learn more
                      <i class="pl-2 ri-arrow-right-circle-fill text-danger"></i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="flex-wrap" style={{marginRight:'80px'}}>
              <Card
                style={{
                  borderRadius: "20px",
                  boxShadow: "10px 10px",
                  width: "110%",
                  height: "90%",
                }}
              >
                <img
                  className="card-img-top pt-3"
                  style={{ width: 40, height: 40 }}
                  src={icon4}
                  alt="Card cap"
                />
                <CardBody >
                  <h4 className="card-title ft-bold mb-3 mt-4 fw-bolder fs-24 ">
                    SMS
                  </h4>
                  <p className="card-text text-muted " style={{width:'21ch'}}>
                    Effectively communicate with customers via text and Rich
                    Communication Services.
                  </p>
                  <div className="text-end">
                    <Link to="#" className="soft-primary mx-auto">
                      Learn more
                      <i class="pl-2 ri-arrow-right-circle-fill text-danger"></i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bottom;
