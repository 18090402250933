import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  NavLink,
  TabContent,
  Input,
  Button,
  Alert,
  ModalHeader,
  ModalBody,
  Modal,
  Accordion,
  AccordionItem,
  Collapse,
  Badge,
  Col,
  Container,
  Form,
  Label,
  Nav,
  NavItem,
  Row,
  ListGroup,
  ListGroupItem,
  TabPane,
  ButtonGroup,
  UncontrolledTooltip,
  UncontrolledAlert,
  CardText,
} from "reactstrap";
import classnames from "classnames";
import { Multiselect } from "multiselect-react-dropdown";
import ReactFlagsSelect from "react-flags-select";
// import ListGroup from "react-bootstrap/ListGroup";

import img1 from "../../assets/images/voicecallapilogo.png";
import img2 from "../../assets/images/videoapilogo.png";
import img3 from "../../assets/images/recordapilogo.png";
import img4 from "../../assets/images/nigeriaflag.png";
import img5 from "../../assets/images/frenchflag.png";

import SimpleBar from "simplebar-react";
import {
  BasicRaterExample,
  BasicRaterExamplefornode,
  BasicRaterExampleforjava,
  BasicRaterExampleforpython,
  BasicRaterExampleforphp,
} from "../../Components/UiRatingsCode";
import { TestSMSCurl } from "../../Components/UiRatingsCode";
import { Textarea } from "@mui/joy";

const MakeAVoicecall = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [codeTab, setCodeTab] = useState("cURL"); // Define isTypeTab variable
  const [modal_grid, setmodal_grid] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const [selected, setSelected] = useState("");

  const data = [
    { Country: "India", id: 1 },
    { Country: "America", id: 2 },
    { Country: "United States", id: 1 },
    { Country: "France", id: 1 },
    { Country: "Bulgaria", id: 1 },
    { Country: "Hungary", id: 1 },
  ];
  const [options] = useState(data);

  const appId = "1d9069bd-80d6-45fd-9b5f-9709ae12d2cb";
  const sessionId =
    "2_MX4xZDkwNjliZC04MGQ2LTQ1ZmQtOWI1Zi05NzA5YWUxMmQyY2J-fjE3MDU5NDMxOTkxNjh-REhwRzgyN1pTSndIY05UcE5KM2NzTkpKfn5-";
  const token =
    "eyJhbGciOiJSUzI1NiJ9.eyJhcHBsaWNhdGlvbl9pZCI6IjFkOTA2OWJkLTgwZDYtNDVmZC05YjVmLTk3MDlhZTEyZDJjYiIsImlhdCI6MTcwNTk0MzE5OSwianRpIjoiMWQ5MDY5YmQtODBkNi00NWZkLTliNWYtOTcwOWFlMTJkMmNiIiwic2NvcGUiOiJzZXNzaW9uLmNvbm5lY3QiLCJzZXNzaW9uX2lkIjoiMl9NWDR4WkRrd05qbGlaQzA0TUdRMkxUUTFabVF0T1dJMVppMDVOekE1WVdVeE1tUXlZMkotZmpFM01EVTVORE14T1RreE5qaC1SRWh3UnpneU4xcFRTbmRJWTA1VWNFNUtNMk56VGtwS2ZuNS0iLCJyb2xlIjoicHVibGlzaGVyIiwiaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdCI6IiJ9.FVlk0AsQNb3z_NIrwj2dnnwTkBS1lEXM-xLQBWoV_kOx6kYeAaakSQiKgVw9zsF_ZVWV3zeJCmlslfCMB8uQ5BvkfeLNcZA7Drvtni78DjGDOjHGb8Khb6V6WjcQsfADaB41LkkLo94c5OnSMAZ7M8X-gMae7f11SppnMNthRuHOnKi8N7qfemMqdM_OvFirtdvG7EI4vuWOYGRmMzRPwtnrr64ss66v4C34O4poUmtP7Ae4tR9wG91aW6heG13jOVBlwiFHde4_XkMTK8jP5xsfO9Mb9JtPdqcxTgLN9QFZITRNa968Db6cG97QpguetvtgvabLwstKs60hTYhMxQ";

  function tog_grid() {
    setmodal_grid(!modal_grid);
  }

  
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <Card className="shadow-none">
      <CardHeader className="">
        <h1 className=" mb-4 fs-120 align-items-center fw-bold">
          Voice Calling
        </h1>
        <p className="card-title mb-0 w-50 align-items-center text-muted mt-2 fs-13">
          Voice and Video
        </p>
      </CardHeader>

      <div className="text-center mt-lg-5 pt-5">
        <h1 className="display-6 fw-semibold mb-3 lh-base">
          {" "}
          <span className="text-primary">BRIVAS </span>voice & video API
        </h1>
        <p className="lead w-sm-50 mb-5 text-muted lh-base">
          Getting started with our voice and video API.
        </p>
      </div>

      <CardHeader>
        <div>
          <Nav
            className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
            role="tablist"
          >
            <NavItem>
              <NavLink
                href="#"
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTab("1");
                }}
              >
                Voice API <img src={img1} alt="" className="img-fluid" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggleTab("2");
                }}
              >
                Video API <img src={img2} alt="" className="img-fluid" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggleTab("3");
                }}
              >
                Recording <img src={img3} alt="" className="img-fluid" />
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </CardHeader>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Card
            className="rounded-3 m-4  text-dark"
            // style={{ backgroundColor: "#D3D3D3" }}
          >
            <CardBody>
              <div className="d-flex position-relative">
                <Col xl={6}>
                  <br />

                  <h5 className="mb-4">
                    <strong>
                      <p>Voice Calling</p>
                    </strong>
                  </h5>

                  <p className="mb-3 mt-3 text-muted">Test Voice API</p>

                  <div className="mb-5 mt-3 border-5 col-md-10">
                    <Label
                      className="form-label fw-normal i-copy"
                      htmlFor="gen-info-password-input"
                    >
                      From
                    </Label>
                    <ReactFlagsSelect
                      selected={selected}
                      onSelect={(code) => setSelected(code)}
                      style={{ maxWidth: "80%" }}
                    />
                  </div>

                  <div className="mb-5 mt-3 border-5 col-md-10">
                    <Label
                      className="form-label text-muted fw-normal i-copy"
                      htmlFor="gen-info-password-input"
                    >
                      To
                    </Label>
                    <Multiselect
                      options={options}
                      displayValue="Country"
                      className=" bg-white"
                      style={{ border: " solid #ced4da", maxWidth: "80%" }}
                      placeholder="Select Country"
                      searchable
                      searchPlaceholder="Search Numbers"
                    />
                  </div>

                  <div className="mt-4 mr-5 ml-3">
                    <div className="mb-5">
                      <Label
                        htmlFor="exampleFormControlTextarea5"
                        className="form-label "
                      >
                        Message
                      </Label>
                      <textarea
                        className="form-control rounded-4 "
                        id="exampleFormControlTextarea5"
                        rows="3"
                        placeholder="Your verification code is “ code “ Verified by Brivas"
                        style={{ maxWidth: "80%" }}
                      ></textarea>
                    </div>
                  </div>

                  <div className="mb-3 col-md-10">
                    <Label
                      className="form-label text-muted fw-normal i-copy"
                      htmlFor="gen-info-password-input"
                    >
                      language
                    </Label>
                    <select
                      className="form-select rounded-4  mb-3"
                      aria-label="Default select example"
                    >
                      <option>Select language</option>
                      <option defaultValue="1"> English, US </option>
                      <option defaultValue="2"> English, UK</option>
                      <option defaultValue="3">Francias</option>
                      <option defaultValue="3">German</option>
                      <option defaultValue="3">Dutch</option>
                    </select>
                  </div>

                  <div className="align-items-start gap-3 mt-2 mb-2">
                    <Button color="primary rounded-pill">Place a Call</Button>
                  </div>
                </Col>

                <Col xl={6}>
                  {/* <div className="mb-3 border-5 col-md-10">
                    <Label
                      className="form-label text-muted fw-normal i-copy"
                      htmlFor="gen-info-password-input"
                    >
                      Registered phone number
                    </Label>
                    <Input
                      type="password"
                      className="form-control"
                      id="gen-info-password-input"
                      placeholder="BRIVAS"
                    />
                  </div> */}
                  {/* <div className="mb-3 border-5 col-md-10">
                    <Label
                      className="form-label text-muted fw-normal i-copy"
                      htmlFor="gen-info-password-input"
                    >
                      API Secret
                    </Label>
                    <Input
                      type="password"
                      className="form-control"
                      id="gen-info-password-input"
                      placeholder="BRIVAS "
                    />
                  </div> */}

                  <div className="email-content">
                    <div className="p-4 pb-0">
                      <div className="border-bottom border-bottom-dashed">
                        <Row className="mt-n2 mb-3 mb-sm-0">
                          <Col className="col-sm-auto order-1 d-block d-lg-none">
                            <button
                              type="button"
                              className="btn btn-soft-success btn-icon btn-sm fs-16 email-menu-btn"
                            >
                              <i className="ri-menu-2-fill align-bottom"></i>
                            </button>
                          </Col>
                          <div className="col-sm order-3 order-sm-2">
                            <div className="hstack gap-sm-1 align-items-center flex-wrap email-topbar-link">
                              <div className="form-check fs-14 m-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="checkall"
                                  onChange={(e) => {
                                    // checkedAll(e.target.value);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="checkall"
                                ></label>
                              </div>

                              <div
                                id="email-topbar-actions"
                                style={{ display: "none" }}
                              >
                                <div className="hstack gap-sm-1 align-items-center flex-wrap">
                                  <button
                                    type="button"
                                    className="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                                    id="Tooltip1"
                                  >
                                    <i className="ri-inbox-archive-fill align-bottom"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                                    id="Tooltip2"
                                  >
                                    <i className="ri-error-warning-fill align-bottom"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                                    id="Tooltip3"
                                    onClick={() => {
                                      // setDeleteModal(true);
                                    }}
                                  >
                                    <i className="ri-delete-bin-5-fill align-bottom"></i>
                                  </button>
                                </div>
                              </div>

                              <div className="vr align-self-center mx-2"></div>

                              <Alert
                                color="warning"
                                style={{ display: "none" }}
                                className="unreadConversations-alert px-4 fade"
                                id="unreadConversations"
                              >
                                No Unread Conversations
                              </Alert>

                              <UncontrolledTooltip
                                placement="top"
                                target="Tooltip1"
                              >
                                {" "}
                                Archive{" "}
                              </UncontrolledTooltip>
                              <UncontrolledTooltip
                                placement="top"
                                target="Tooltip2"
                              >
                                {" "}
                                Report Spam{" "}
                              </UncontrolledTooltip>
                              <UncontrolledTooltip
                                placement="top"
                                target="Tooltip3"
                              >
                                {" "}
                                Trash{" "}
                              </UncontrolledTooltip>
                            </div>
                          </div>
                          <div className="col-auto order-2 order-sm-3">
                            <div className="d-flex gap-sm-1 email-topbar-link">
                              <button
                                type="button"
                                className="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                              >
                                <i className="ri-refresh-line align-bottom"></i>
                              </button>
                            </div>
                          </div>
                        </Row>

                        <Row className="row align-items-end mt-3">
                          <Col>
                            <div id="mail-filter-navlist m-5">
                              <Nav
                                className="nav nav-tabs nav-tabs-custom nav-success gap-1 text-center border-bottom-0"
                                role="tablist"
                              >
                                <NavItem>
                                  <NavLink
                                    className={classnames(
                                      { active: codeTab === "cURL" },
                                      "fw-semibold"
                                    )}
                                    onClick={() => setCodeTab("cURL")}
                                    href="#"
                                  >
                                    <span className="ms-1 d-none d-sm-inline-block">
                                      cURL
                                    </span>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    className={classnames(
                                      { active: codeTab === "Node" },
                                      "fw-semibold"
                                    )}
                                    onClick={() => setCodeTab("Node")}
                                    href="#"
                                  >
                                    <span className="ms-1 d-none d-sm-inline-block">
                                      Node
                                    </span>
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <SimpleBar className="message-list-content mx-n4 px-4 message-list-scroll">
                        {/* {isLoader ? (
                          <div id="elmLoader">

                          </div>
                        ) : ( */}
                        <div>
                          {codeTab === "cURL" && (
                            <div>
                              <h6 className=" mt-2">Install the Library</h6>

                              <pre>
                                <code className="language-markup">
                                  <BasicRaterExample />
                                </code>
                              </pre>

                              <h6 className=" mt-2">
                                Check the request with a code
                              </h6>

                              <pre>
                                <code className="language-markup">
                                  <BasicRaterExample />
                                </code>
                              </pre>
                              <h6 className=" mt-2">Cancel The Request</h6>
                              <pre>
                                <code className="language-markup">
                                  <BasicRaterExample />
                                </code>
                              </pre>
                            </div>
                          )}

                          {codeTab === "Node" && <div>This is for Node</div>}
                          {codeTab === "Java" && <div>This is for Java</div>}
                        </div>
                      </SimpleBar>
                    </div>
                  </div>
                </Col>
              </div>
            </CardBody>
          </Card>
        </TabPane>

        <TabPane tabId="2">
          <Card className="shadow-none p-5">
            <div>
              <h1 className=" mb-0 fs-120 fw-bold">Video calling API</h1>
              <p className="card-title mb-0 mb-5 w-50 text-muted mt-1 fs-13">
                Launch a fully featured Video application in 5 minutes or less
              </p>
            </div>
            <CardBody>
              <div className="flex-grow-1">
                <h4 className="card-title fw-bold text-muted mb-3">Test Video API</h4>
              </div>
              <Card className="mt-2" style={{ backgroundColor: " #4C4452" }}>
                <CardBody>
                  <CardText className="text-light">
                    <pre>{JSON.stringify({ appId }, null, 2)}</pre>
                    <pre>{JSON.stringify({ sessionId }, null, 2)}</pre>
                    <pre>{JSON.stringify({ token }, null, 2)}</pre>
                  </CardText>
                </CardBody>
              </Card>
            </CardBody>
            <h5 className="text-muted fw-bold">Usage Guideline</h5>

            <div>
              <li>
                <p>
                  When establishing a connection to a Brivas Video API session,
                  you need to provide a working API key, session ID, and token.
                  Normally, your server generates these credentials, but this
                  time, we generated them for you.
                </p>
              </li>
              <li>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Eget in volutpat vitae
                  vitae ut. Amet etiam turpis tortor rutrum duis habitant. Amet
                  vitae nam congue et egestas semper vulputate. Scelerisque
                  pulvinar eget augue at. Adipiscing posuere ut.
                </p>
              </li>
              <li>
                <p>
                  Egestas amet sollicitudin mauris proin mi augue justo. Sodales
                  enim urna mattis rhoncus. Nunc morbi luctus eget risus
                  ultricies congue tempus dolor semper.
                </p>
              </li>
            </div>

            <h5 className="text-muted mt-3 mb-4 fw-bold">Resources</h5>

            <Row>
              <Col xl={4}>
                <h6>Primary Alert</h6>
                <UncontrolledAlert
                  color="secondary"
                  className="alert-label-icon label-arrow "
                >
                  <i className="ri-file-copy-2-line label-icon"></i>
                  <strong>Documentation</strong> - Label icon arrow alert <i class="ri-links-line"></i>
                </UncontrolledAlert>
              </Col>

              <Col xl={4}>
                <h6>Primary Alert</h6>
                <UncontrolledAlert
                  color="secondary"
                  className="alert-label-icon label-arrow "
                >
                  <i className="ri-smartphone-line label-icon"></i>
                  <strong>Related Apps</strong> - Label icon arrow alert <i class="ri-links-line"></i>
                </UncontrolledAlert>
              </Col>

              <Col xl={4}>
                <h6>Primary Alert</h6>
                <UncontrolledAlert
                  color="secondary"
                  className="alert-label-icon label-arrow "
                >
                  <i className="ri-currency-line label-icon"></i>
                  <strong>Pricing</strong> - Label icon arrow alert <i class="ri-links-line"></i>
                </UncontrolledAlert>
              </Col>
            </Row>
          </Card>
        </TabPane>

        <TabPane tabId="3">Tab Three</TabPane>
      </TabContent>
    </Card>
  );
};

export default MakeAVoicecall;
