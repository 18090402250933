import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import verifiyimage from "../../assets/images/svg/Verification-banner2-image.png";
import verifiyimage2 from "../../assets/images/svg/Verification-banner2-image2.png";
import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import ListIcon from "../../assets/images/svg/voiceservicelogo.svg";

const Banner2 = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <section
        className="section pb-0"
        id="hero"
        style={{ backgroundColor: "#FFF5FF" }}
      >
        <div className="">
          <div className="mx-md-5 px-3 px-md-5 my-5">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <h1 className="display-6 fw-bold fs-48 lh-base text-sm-center text-dark ">
                  Focus on juicy deals; let’s add the cherry on the top
                </h1>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12 order-2 order-md-1 my-3">
                <p className="mb-5">
                  Dive into our all-encompassing solution, reshaping identity
                  confirmation, making it remarkably easy, effective, and
                  reliable. Embrace cost effectiveness, heightened security, and
                  unparalleled user engagement – all on Brivas.
                </p>
                <div className="media d-flex mb-4">
                  <img
                    className="align-self-start mx-2"
                    src={ListIcon}
                    alt="Generic placeholder image"
                  />
                  <div className="media-body">
                    <h5 className="mt-0 text-dark">Versatile Integration</h5>
                    <p>
                      Incorporate SMS, Flash Calls, Phone Calls, Flash Messages,
                      and Verification API via Brivas.
                    </p>
                  </div>
                </div>
                <div className="media d-flex mb-4">
                  <img
                    className="align-self-start mx-2"
                    src={ListIcon}
                    alt="Generic placeholder image"
                  />
                  <div className="media-body">
                    <h5 className="mt-0 text-dark">Elevated Security Measures</h5>
                    <p>
                      Strengthened safeguards to protect sensitive information
                      and data.
                    </p>
                  </div>
                </div>
                <div className="media d-flex mb-4">
                  <img
                    className="align-self-start mx-2"
                    src={ListIcon}
                    alt="Generic placeholder image"
                  />
                  <div className="media-body">
                    <h5 className="mt-0 text-dark">Exceptional User Experiences</h5>
                    <p>
                      Deliver outstanding interactions that leave users
                      satisfied and engaged.
                    </p>
                  </div>
                </div>

                <div className="d-flex gap-2 my-5">
                  <button
                    color="primary "
                    className="btn rounded-pill btn-primary"
                    onClick={() => "/register"}
                  >
                    Start for free
                    <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                  </button>
                  <button
                    color="success"
                    className="btn rounded-pill btn-light border-danger"
                    onClick={() => history("/register")}
                  >
                    <span className="text-danger">Talk to an expert</span>
                  </button>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 voip order-1">
                <img
                  src={verifiyimage}
                  alt=""
                  className="user-img w-50 my-4 mx-md-5"
                />
              </div>
            </div>
          </div>

          <div className="mx-md-5 px-3 px-md-5 my-5">
            <div class="row">
              <div class="col-md-6 col-sm-12"></div>
              <div class="col-md-6 col-sm-12">
                <h1 className="display-6 fw-bold fs-48 lh-base text-sm-center  text-dark">
                  Enjoy from the wealth of{" "}
                  <span className="text-primary">passwordless</span>{" "}
                  authentication
                </h1>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12 voip order-1">
                <img
                  src={verifiyimage2}
                  alt=""
                  className="user-img w-50 my-4"
                />
              </div>
              <div class="col-md-6 col-sm-12 order-2 order-md-1 my-3 text-justify">
                <p className="mb-5">
                  Seamlessly integrate QR Codes into your solutions. Unlock a
                  world of convenience with QR codes. Say goodbye to manual data
                  entry or lengthy URLs. Bridging the gap between the physical
                  and digital has never been easier. Scan, Discover, and Connect
                  with Ease!
                </p>
                <div className="media d-flex mb-4">
                  <img
                    className="align-self-start mx-2"
                    src={ListIcon}
                    alt="Generic placeholder image"
                  />
                  <div className="media-body">
                    <h5 className="mt-0 text-dark">Interactive Experiences</h5>
                    <p>
                      Engage customers with videos, surveys, and interactive
                      content.
                    </p>
                  </div>
                </div>
                <div className="media d-flex mb-4">
                  <img
                    className="align-self-start mx-2"
                    src={ListIcon}
                    alt="Generic placeholder image"
                  />
                  <div className="media-body">
                    <h5 className="mt-0 text-dark">Instant Access</h5>
                    <p>
                      Scan QR Codes to swiftly access websites, promotions, and
                      exclusive content.
                    </p>
                  </div>
                </div>
                <div className="media d-flex mb-4">
                  <img
                    className="align-self-start mx-2"
                    src={ListIcon}
                    alt="Generic placeholder image"
                  />
                  <div className="media-body">
                    <h5 className="mt-0 text-dark">Seamless Users Experience</h5>
                    <p>
                      Revolutionize experiences with your services with QR Codes
                      - the key to a seamless, connected world!
                    </p>
                  </div>
                </div>

                <div className="d-flex gap-2 my-5">
                  <button
                    color="primary "
                    className="btn rounded-pill btn-primary"
                    onClick={() => "/register"}
                  >
                    Start for free
                    <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                  </button>
                  <button
                    color="success"
                    className="btn rounded-pill btn-light border-danger"
                    onClick={() => history("/register")}
                  >
                    <span className="text-danger">Talk to an expert</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Banner2;
