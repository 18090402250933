import React, { useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import img1 from "../../assets/images/svg/wificartoon.svg";

const Scalable = () => {
    const [plan, setPlan] = useState(true);
    const toggle = () => setPlan(!plan);
    const history = useNavigate();
    
  return (
    <section>
    <div>
        <Row className="align-items-center p-4 text-light bg-primary gy-4">
                        <Col lg={6} className="order-2 order-lg-1">
                            <div className="text-bold  fs-40 pt-5 py-5">
                                <h1 className="mb-3 text-light">On a mission to build
                                <br/>
                                sustainable future (IOT)
                                <br/>
                                 automation</h1>
                                <p className="mb-4 ff-secondary">
                                With a focus on providing a smooth healthcare journey, our 
                                platform offers a seamless experience where you can 
                                effortlessly schedule appointments and enjoy reliable streaming for virtual 
                                consultations and accessing medical content. </p>

                                <Row>
                                    <Col sm={5}>
                                        <div className="vstack gap-2 text-light">
                                            <div className="d-flex align-items-center ">
                                                <div className="flex-shrink-0 me-2">
                                                    <div className="avatar-xs icon-effect">
                                                        <div
                                                            className="avatar-title bg-transparent text-success rounded-circle h2">
                                                            <i className="ri-check-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="fs-14 mb-0">Schedule appointment</h5>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 me-2">
                                                    <div className="avatar-xs icon-effect">
                                                        <div
                                                            className="avatar-title bg-transparent text-success rounded-circle h2">
                                                            <i className="ri-check-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="fs-14 mb-0">Fast and reliable</h5>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 me-2">
                                                    <div className="avatar-xs icon-effect">
                                                        <div
                                                            className="avatar-title bg-transparent text-success rounded-circle h2">
                                                            <i className="ri-check-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="fs-14 mb-0">Streaming</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    
                                </Row>

                                <div className="d-flex pt-5 gap-2">
                                        <button
                                            color="danger "
                                            className="btn rounded-pill btn-light"
                                            onClick={() => history("/register")}
                                        >
                                            Start for free
                                            <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                                        </button>

                                        <button
                                            color="success"
                                            className="btn rounded-pill text-danger btn-danger text-light  margin-left-20px"
                                            onClick={() => history("/register")}
                                        >
                                            Talk to an expert
                                            <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
                                        </button>
                                </div>
                            </div>
                        </Col>

                        <Col lg={6} sm={7} className="col-10 ms-auto order-1 order-lg-2">
                            <div>
                                <img src={img1} alt="" className="img-fluid" />
                            </div>
                        </Col>


                    </Row>
    </div>
    </section>
  )
}

export default Scalable;