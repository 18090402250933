import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row, Container, Button, Card, CardBody } from "reactstrap";
import Img1 from "../../assets/images/svg/partnerimage-min.png";
import Img2 from "../../assets/images/svg/exploreimage.svg";
import Image1 from "../../assets/images/svg/tipsreport.svg";
import Image2 from "../../assets/images/svg/tipsreport2.svg";
import Image3 from "../../assets/images/svg/tipsguide.svg";
import Image4 from "../../assets/images/fbimage.png";
import SMSTips from "../../Components/SMSTips";
import Whatsapp from "../../assets/images/whatsappicon.png";
import FBmsg from "../../assets/images/fbmessangericon.png";
import DLCfoods from "../../assets/images/dlcfoodsimage.png";
import Telegramlogo from "../../assets/images/telegramicon.png";

import IMG1 from "../../assets/images/msgboticon1.png";
import IMG2 from "../../assets/images/msgboticon2.png";
import IMG3 from "../../assets/images/msgboticon3.png";
import Man from "../../assets/images/manmsg.png";
import Woman from "../../assets/images/womanmsg.png";
import Brivassupport from "../../assets/images/brivassupport.png";

import SKYPE from "../../assets/images/iconforskype.png";
import SKYPELOGO from "../../assets/images/imageforskype.png";
import SLACK from "../../assets/images/imageforslack.png";
import SLACKLOGO from "../../assets/images/iconforslack.png";
import INSTAIMAGE from "../../assets/images/imageforinstagram.png";
import INSTAICON from "../../assets/images/iconforinstagram.png";

import TELEGRAMIMAGE from "../../assets/images/telegramimage.png";
import ImgFB from "../../assets/images/fbimage.png";

const CHATGPT = () => (
  <div>
    <img className="card-img-top W-75 img-fluid" src={Woman} alt="Card cap" />
  </div>
);

const CHATbotsimage = () => (
  <div>
    <img
      className="card-img-top w-75 mb-4 pt-0 img-fluid"
      src={Brivassupport}
      alt="Card cap"
    />
  </div>
);

const EMAIL = () => (
  <div>
    <img className="card-img-top img-fluid" src={Image4} alt="Card cap" />
  </div>
);

const UNDERCHATGPT = () => (
  <div className="vstack gap-2 mt-4">
    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG1}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">24/7 Support</h5>
        <p className="w-75">
          Attend to customers and get rewarding satisfaction from them as a
          result.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG2}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">No Codes Required</h5>
        <p className="w-75">
          Brivas chatbot requires no code. Write attention-grabbing messages
          using our templates.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG3}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Omnichannel</h5>
        <p className="w-75">
          Integrate chatbots into websites, mobile apps, social media, and
          messaging apps seamlessly. Assist consumers wherever..
        </p>
      </div>
    </div>

    <div></div>
    <div className="d-flex gap-2">
      <button
        color="danger "
        className="btn rounded-pill btn-primary"
        onClick={() => ""}
      >
        Start for free
        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
      </button>

      <button
        color="success"
        className="btn rounded-pill btn-light text-primary btn-border border-primary margin-left-20px"
        onClick={() => ""}
      >
        Talk to an expert
        <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
      </button>
    </div>
  </div>
);

const UNDERSMSCGW = () => (
  <div className="vstack gap-2 mt-4">
    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG1}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Message Routing</h5>
        <p className="w-75">
          Brivas SMS Gateway will handle the complexities of message routing,
          ensuring your messages reach the intended recipients quickly.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG2}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Storage</h5>
        <p className="w-75">
          We securely store messages and transmit them when your recipients
          return online, providing timely communication.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG3}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Delivery Confirmation</h5>
        <p className="w-75">
          Track your messages. SMS Gateway delivers real-time delivery reports
          on successful deliveries and difficulties.
        </p>
      </div>
    </div>

    <div></div>
    <div className="d-flex gap-2">
      <button
        color="danger "
        className="btn rounded-pill btn-primary"
        onClick={() => ""}
      >
        Start for free
        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
      </button>

      <button
        color="success"
        className="btn rounded-pill btn-light text-primary btn-border border-primary margin-left-20px"
        onClick={() => ""}
      >
        Talk to an expert
        <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
      </button>
    </div>
  </div>
);

const UNDERCHATBOT = () => (
  <div className="vstack gap-2 mt-4">
    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG1}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">24/7 Support</h5>
        <p className="w-75">
          Attend to customers and get rewarding satisfaction from them as a
          result.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG2}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">No Codes Required</h5>
        <p className="w-75">
          Brivas chatbot requires no code. Write attention-grabbing messages
          using our templates.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG3}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Omnichannel</h5>
        <p className="w-75">
          Integrate chatbots into websites, mobile apps, social media, and
          messaging apps seamlessly. Assist consumers wherever..
        </p>
      </div>
    </div>

    <div></div>
    <div className="d-flex gap-2">
      <button
        color="danger "
        className="btn rounded-pill btn-primary"
        onClick={() => ""}
      >
        Start for free
        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
      </button>

      <button
        color="success"
        className="btn rounded-pill btn-light text-primary btn-border border-primary margin-left-20px"
        onClick={() => ""}
      >
        Talk to an expert
        <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
      </button>
    </div>
  </div>
);

const SKYPEPAGE = () => (
  <div>
    <img
      className="card-img-top w-75 img-fluid"
      src={SKYPELOGO}
      alt="Card cap"
    />
  </div>
);

const TELEGRAM = () => (
  <div>
    <img className="card-img-top w-75 img-fluid" src={Man} alt="Card cap" />
  </div>
);

const UNDERRCS = () => (
  <div className="vstack gap-2 mt-4">
    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG1}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold"> 24/7 Support</h5>
        <p className="w-75">
          Attend to customers and get rewarding satisfaction from them as a
          result.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG2}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold"> No Codes Required</h5>
        <p className="w-75">
          Brivas chatbot requires no code. Write attention-grabbing messages
          using our templates.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG3}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Omnichannel</h5>
        <p className="w-75">
          Integrate chatbots into websites, mobile apps, social media, and
          messaging apps seamlessly. Assist consumers wherever.
        </p>
      </div>
    </div>

    <div></div>
    <div className="d-flex gap-2">
      <button
        color="danger "
        className="btn rounded-pill btn-primary"
        onClick={() => ""}
      >
        Start for free
        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
      </button>

      <button
        color="success"
        className="btn rounded-pill btn-light text-primary btn-border border-primary margin-left-20px"
        onClick={() => ""}
      >
        Talk to an expert
        <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
      </button>
    </div>
  </div>
);

const UNDERSKYPE = () => (
  <div className="vstack gap-2 mt-4">
    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={SKYPE}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Global Calling Made Easy</h5>
        <p className="w-75">
          You can easily connect with coworkers and customers located all over
          the world using Skype.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={SKYPE}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Instant Messaging at Your Fingertips</h5>
        <p className="w-75">
          Communicate with your team, share files, and work together on
          projects.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={SKYPE}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Unified Communication Hub</h5>
        <p className="w-75">
          Bring together all your contacts, messages, and calls in one place,
          ensuring connections don't end.
        </p>
      </div>
    </div>

    <div></div>
    <div className="d-flex gap-2">
      <button
        color="danger "
        className="btn rounded-pill btn-primary"
        onClick={() => ""}
      >
        Start for free
        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
      </button>

      <button
        color="success"
        className="btn rounded-pill btn-light text-primary btn-border border-primary margin-left-20px"
        onClick={() => ""}
      >
        Talk to an expert
        <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
      </button>
    </div>
  </div>
);

const tabs = [
  {
    title: "Chatbots",
    header: "Respond to Customer Inquiries Without Batting",
    desc: " Elevate your customer engagement and support with intelligent chatbots. Answer problems, assist customers, and unlock around-the-clock instant assistance. Embrace the power of chatbots and transform the way you interact with your customers!",
    link: "#telecom",
    component: <CHATbotsimage />,
    component2: <UNDERCHATBOT />,
  },
  {
    title: "ChatGPT",
    header: "Respond to Customer Inquiries Without Batting",
    desc: " Elevate your customer engagement and support with intelligent chatbots. Answer problems, assist customers, and unlock around-the-clock instant assistance. Embrace the power of chatbots and transform the way you interact with your customers!",
    link: "#education",
    component: <CHATGPT />,
    component2: <UNDERCHATGPT />,
  },
  {
    title: "SMSC",
    header: "Enable SMS communication between different networks.",
    desc: " Users can send and receive text messages from different mobile operators and services. Connect, engage, and communicate easily across networks for trustworthy messaging. Brivas SMS Gateway transforms your communication today! ",
    link: "#healthcare",
    component: <TELEGRAM />,
    component2: <UNDERSMSCGW />,
  },

  {
    title: "USSD",
    header: "Embrace the Wonders of USSD Gateway (USSD GW).",
    desc: " Connect mobile networks and apps/services. Discover how USSD-based services can alter information flow and empower consumers in real time.",
    link: "#telecom",
    component: <SKYPEPAGE />,
    component2: <UNDERSKYPE />,
  },

  // {
  //   title: "Instagram",
  //   header: "Harness Instagram's API and transform your success strategy.",
  //   desc: "Reach and inspire customers on Instagram, one of the world's most popular social media platforms. Brivas Instagram API simplifies sending end users promotional, transactional, and conversational messages.",
  //   link: "#education",
  //   component: <INSTAGRAM />,
  //   component2: <UNDERINSTAGRAM />,
  // },
  // {
  //   title: "Slack",
  //   header: "Boost Collaboration with Slack",
  //   desc: " Say hello to Slack-boosted productivity. Chat, exchange files, work on projects, and integrate with your favourite tools in one spot. Embrace a united workspace to avoid dispersed communication. Improve teamwork today. ",
  //   link: "#healthcare",
  //   component: <SLACKPAGE />,
  //   component2: <UNDERSLACK />,
  // },
];

const Homenew = () => {
  const history = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <React.Fragment>
      <section
        className="section pb-0"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: "#FFF5FF",
        }}
      >
        <Container className="">
          <Row className="justify-content-between  align-items-center">
            <div className=" ">
              <h5 className="mb-2">
                <div className="d-flex  gap-2 ">
                  {tabs.map((i, x) => (
                    <button
                      key={`tab-${x}`}
                      color="danger "
                      className={`btn ${
                        i?.title == tabs[selectedTab]?.title ? "" : "btn-light"
                      } active rounded-pill btn-primary`}
                      onClick={() => setSelectedTab(x)}
                    >
                      {i.title}
                      <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                    </button>
                  ))}
                </div>
              </h5>
            </div>
          </Row>

          <div className="py-5">
            <Row>
              <Col lg={6}>
                <div style={{}}>
                  <h6 className="display-6 fw-bold mt-2 mb-3 text-dark fs-21 lh-base">
                    {tabs[selectedTab]?.header}
                  </h6>
                  <p className="mb-4">{tabs[selectedTab]?.desc}</p>
                  <a className="mt-4" href={tabs[selectedTab]?.link}></a>
                </div>
                {tabs[selectedTab].component2}
              </Col>
              <Col lg={6}>{tabs[selectedTab].component}</Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Homenew;
