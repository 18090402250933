import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

import Banner2img from "../../assets/images/educationbanner2.png";
import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const Banner2 = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <Row className="mx-md-5 px-3 px-md-5 my-5">
        <Col sm={12} md={6}>
          <div class="">
            <div class="row g-0 card-body px-0 py-0">
              <div class="col-md-9 col-sm-12">
                <div class="d-flex justify-content-center flex-column py-3 px-4">
                  <img
                    className="align-self-start mx-2"
                    src={Banner2img}
                    alt="Ge
                    `````````````````````````````````````neric placeholder image"
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12} md={6} className="mx=md-5">
          <div className="text-bold fs-25 pt-5 py-5 w-100 " style={{}}>
            <h1 className="mb-3 fw-bold ">
              Acquiring the resources necessary for a better education
            </h1>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Banner2;
