import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { Grid, _ } from "gridjs-react";
import Modal from "react-bootstrap/Modal";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import avtarImage1 from "../../assets/images/documentcac.png";
import avtarImage2 from "../../assets/images/downloadlogo.png";
import avtarImage3 from "../../assets/images/downloadlogo.png";
import avtarImage4 from "../../assets/images/sugreenlogo.png";
import avtarImage5 from "../../assets/images/suredlogo.png";
import avtarImage6 from "../../assets/images/sugreenlogo.png";

const Pendingusers = () => {
  const [showLiveKey, setShowLiveKey] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { account } = useSelector((state) => ({
    account: state.Account.account,
  }));

  const data2 = [
    [
      "Jhonnyboy201",
      " Jonathan  ",
      "Senior Implementation Architect",
      " jonathan@example.com",
      "...",
    ],
    [
      "Harold",
      " Metz Inc ",
      "Forward Creative Coordinator",
      " harold@example.com ",
      "Iran",
    ],
    [
      "Shannon",
      "shannon@example.com",
      "Legacy Functionality Associate",
      "Zemlak Group",
      "South Georgia",
    ],
    [
      "Robert",
      "robert@example.com",
      "Product Accounts Technician",
      "Hoeger",
      "San Marino",
    ],
    [
      "Noel",
      "noel@example.com",
      "Customer Data Director",
      "Howell - Rippin",
      "Germany",
    ],
    [
      "Traci",
      "traci@example.com",
      "Corporate Identity Director",
      "Koelpin - Goldner",
      "Vanuatu",
    ],
    [
      "Kerry",
      "kerry@example.com",
      "Lead Applications Associate",
      "Feeney, Langworth and Tremblay",
      "Niger",
    ],
    [
      "Patsy",
      "patsy@example.com",
      "Dynamic Assurance Director",
      "Streich Group",
      "Niue",
    ],
    [
      "Cathy",
      "cathy@example.com",
      "Customer Data Director",
      "Ebert, Schamberger and Johnston",
      "Mexico",
    ],
    [
      "Tyrone",
      "tyrone@example.com",
      "Senior Response Liaison",
      "Raynor, Rolfson and Daugherty",
      "Qatar",
    ],
  ];

  useEffect(() => {}, []);

  document.title = "BRIVAS | Dashboard";

  return (
    <React.Fragment>
      <div className="shadow-none">
        <Row className="mb-3 p-3 pb-1">
          <Col xs={12}>
            <div className="d-flex p-4 align-items-lg-center flex-lg-row flex-column">
              <div className="flex-grow-1">
                <h1 className=" fw-bold text-dark mb-1">
                  Sender Identification
                </h1>
                <p className="text-muted mb-0">
                  Getting started with our voice and video API
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <div className="table-responsive">
          <Table className="align-middle table-nowrap mb-0">
            <thead className="table-light">
              <tr>
                <th scope="col" style={{ width: "42px" }}>
                  <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="responsivetableCheck"
                    />
                    <Label
                      className="form-check-label"
                      for="responsivetableCheck"
                    ></Label>
                  </div>
                </th>
                {/* <th scope="col">ID</th>
                <th scope="col">Date</th>
                <th scope="col">Status</th> */}
                <th scope="col">SN</th>
                <th scope="col">Sender ID</th>
                <th scope="col">Status</th>
                <th scope="col">Company</th>
                <th scope="col">Products</th>
                <th scope="col"> Documents</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="responsivetableCheck01"
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="responsivetableCheck01"
                    ></Label>
                  </div>
                </th>
                <td>
                  <div className="d-flex gap-2 align-items-center">
                    <div className="flex-grow-1"> 1 </div>
                  </div>
                </td>
                <td>
                  <a
                    href="#"
                    className="d-flex justify-content-start mt-1"
                    variant="primary"
                    onClick={handleShow}
                  >
                    <span className="text-dark mx-1 fs-12">Sample ID</span>
                  </a>
                </td>
                <td className="fw-bold" style={{ color: "#FBD42C" }}>
                  Pending
                </td>
                <td>Company I</td>
                <td>SMS </td>
                <td className="ri-download-fill "> <i className="text-success"></i> CAC Company I</td>
              </tr>
              <tr>
                <th scope="row">
                  <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="responsivetableCheck02"
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="responsivetableCheck02"
                    ></Label>
                  </div>
                </th>
                <td>
                  <div className="d-flex gap-2 align-items-center">
                    <div className="flex-grow-1">2</div>
                  </div>
                </td>
                <td>Sample ID II</td>
                <td className="fw-bold" style={{ color: "#FBD42C" }}>
                  Pending
                </td>
                <td>Company II</td>
                <td>VOICE</td>
                <td className="ri-download-fill"> <i className="text-success"></i> Company Document</td>
              </tr>
            </tbody>
          </Table>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>New SID Request </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex">
              <Col xxl={12}>
                <Card>
                  <CardBody>
                    <div className="live-preview mt-2">
                      <form action="#">
                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="nameInput"
                              className="form-label fw-bold"
                            >
                              SID: <span className="text-danger"></span>
                            </Label>
                          </Col>
                          <Col lg={9}>
                            <Input
                              type="text"
                              className="form-control"
                              id="nameInput"
                              placeholder="sample"
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="websiteUrl"
                              className="form-label fw-bold"
                            >
                              Products: <span className="text-danger"></span>
                            </Label>
                          </Col>
                          <Col lg={9}>
                            <Input
                              type="url"
                              className="form-control "
                              id="websiteUrl"
                              placeholder="SMS"
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="leaveemails"
                              className="form-label fw-bold"
                            >
                              Company: <span className="text-danger"></span>
                            </Label>
                          </Col>
                          <Col lg={9}>
                            <Input
                              type="email"
                              className="form-control"
                              id="leaveemails"
                              placeholder="Sample LTD"
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="contactNumber"
                              className="form-label fw-bold"
                            >
                              User ID:{" "}
                            </Label>
                          </Col>
                          <Col lg={9}>
                            <Input
                              type="number"
                              className="form-control"
                              id="contactNumber"
                              placeholder="de803iboduvwjoiewgpibegap "
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="contactNumber"
                              className="form-label fw-bold"
                            >
                              CAC Doc:{" "}
                            </Label>
                          </Col>
                          <Col lg={9}>
                            <Button className=" w-100 btn-light">
                              <span className="text-start" >
                                <i className="ri-download-fill text-success"></i>{" "}
                                PDF Document
                              </span>
                            </Button>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="contactNumber"
                              className="form-label fw-bold"
                            >
                              License:{" "}
                            </Label>
                          </Col>
                          <Col lg={9}>
                            <Button className=" w-100 btn-light">
                              <span className="text-start">
                                <i className="ri-download-fill text-success"></i>{" "}
                                PDF Document
                              </span>
                            </Button>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col lg={3}>
                            <Label
                              htmlFor="contactNumber"
                              className="form-label fw-bold"
                            >
                              Other Docs:{" "}
                            </Label>
                          </Col>
                          <Col lg={9}>
                            <Button className=" w-100 btn-light">
                              <span className="text-start">
                                <i className="ri-download-fill text-success"></i>{" "}
                                PDF Document
                              </span>
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className=" btn-danger" onClick={handleClose}>
              Deny Request
            </Button>
            <Button className="btn-success" onClick={handleClose}>
              Approve
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Pendingusers;
