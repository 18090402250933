import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import img1 from "../../assets/images/svg/number-connect-banner-image-min.png";
import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const Banner = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(listProductGroup());
  // }, [dispatch]);

  // const { productGroups } = useSelector((state) => ({
  //   productGroups: state.Landing.productGroups,
  // }));

  return (
    <React.Fragment>
      <section
        className="section  bg-secondary pb-0"
        id="hero"
        style={{
          height: "648px",
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className=" bg-home-overlay-pattern2"></div>
        <div className="px-4">
          <Row className="j">
            <Col lg={6}>
              <div className="margin-left-50px"style={{marginLeft:'83px'}}>
                <h1 className="display-6 text-light fw-medium mb-5 fs-4  lh-base">
                  Fax-API
                </h1>
                <h1 className="display-6 fw-medium mb-3 text-light fs-40  lh-base">
                  Experience the <br />
                  convenience of a <br />
                  digital fax
                </h1>

                <div className="d-flex gap-2">
                  {""}

                  <button
                    color="success"
                    className="btn rounded-pill btn-light mt-5"
                    onClick={() => history("/register")}
                  >
                    <span className="text-primary text-bolder">
                      Start for Free
                    </span>
                    <i className="align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
                  </button>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div></div>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Banner;
