import {
  ACCOUNT_SUCCESS,
  ACCOUNT_REGISTER,
  ACCOUNT_LOGIN,
  ACCOUNT_ERROR,
  ACCOUNT_PROFILE,
  ACCOUNT_FORGOT_PASSWORD,
  ACCOUNT_RESET_PASSWORD
} from "./actionTypes";

const initialState = {
  account: null,
  isResolving: true,
  isPending: false,
  accountError: null,
  loading: false,
  error: null,
  forgetError: null,
  forgetSuccessMsg: null,
  resetError: null,
  resetSuccessMsg: null,
};

const Account = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_SUCCESS:
      switch (action.payload.actionType) {
        case ACCOUNT_REGISTER:
          return { ...state, loading: false, isPending: true };
        case ACCOUNT_LOGIN:
          return { ...state, account: action.payload.data, loading: false };
        case ACCOUNT_FORGOT_PASSWORD:
          return { ...state, forgetSuccessMsg: action.payload.msg, loading: false };
        case ACCOUNT_RESET_PASSWORD:
          return { ...state, resetSuccessMsg: action.payload.msg, loading: false };
        case ACCOUNT_PROFILE:
          return { ...state, account: action.payload.data, isResolving: false };
        default:
          return { ...state };
      }
    case ACCOUNT_ERROR:
      switch (action.payload.actionType) {
        case ACCOUNT_REGISTER:
          return { ...state, ...action.payload?.data, loading: false };
        case ACCOUNT_LOGIN:
          return { ...state, ...action.payload?.data, loading: false };
        case ACCOUNT_FORGOT_PASSWORD:
          return { ...state, forgetError: action.payload.msg, loading: false };
        case ACCOUNT_RESET_PASSWORD:
          return { ...state, resetError: action.payload.msg, loading: false };
        case ACCOUNT_PROFILE:
          return { ...state, ...action.payload?.data, accountError: action.payload.error, account: null, isResolving: false };
        default:
          return { ...state };
      }
    case ACCOUNT_REGISTER:
      return { ...state, loading: true };
    case ACCOUNT_LOGIN:
      return { ...state, loading: true };
    case ACCOUNT_FORGOT_PASSWORD:
      return { ...state, loading: true };
    case ACCOUNT_RESET_PASSWORD:
      return { ...state, loading: true };
    default:
      return state;
  }
};

export default Account;
