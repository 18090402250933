import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Row,
  TabPane,
  Table,
} from "reactstrap";

const SMSStatus = () => {

  document.title = "BRIVAS | SMS Status";

  return (
      <React.Fragment>
      <div className="shadow-none">
        {/* <h2 className="fw-bold text-dark">Ahoy Melvin, welcome to BRIVAS!</h2> */}
        <Row className="mt-4 px-sm-1 px-lg-5">
          <Col xl={12}>
            <Card
              className="rounded-4 text-light"
  
            >
              <TabPane tabId="2" id="pill-justified-profile-1" >
                <div>
                  <Table className="align-middle table-nowrap mb-0 px-3">
                    <thead>
                      <tr>
                        <th scope="col">Status</th>
                        <th scope="col">Type</th>
                        <th scope="col">Error</th>
                        <th scope="col">Reason</th>
                        <th scope="col">From</th>
                        <th scope="col">To</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          <Link to="#" className=" text-success fw-medium">
                            Accepted
                          </Link>
                        </th>
                        <td className="text-muted">Outbound</td>
                        <td className="text-muted">None</td>
                        <td className="text-muted">Network_Recognized</td>
                        <td>
                          <Link to="#" className="link-success">
                            +234 8056342345 <p>Feb 12, 14:00:59</p>
                          </Link>
                        </td>
                        <td>
                          <Link to="#" className="link-success">
                            +234 8056342345 <p>Feb 12, 14:00:59</p>
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <Link to="#" className=" text-danger fw-medium">
                            Declined
                          </Link>
                        </th>
                        <td className="text-muted">Inbound</td>
                        <td className="text-muted">All</td>
                        <td className="text-muted">Network_Unrecognized</td>
                        <td>
                          <Link to="#" className="link-success">
                            +234 9057469410 <p>Feb 12, 14:00:59</p>
                          </Link>
                        </td>
                        <td>
                          <Link to="#" className="link-success">
                            +234 8056342345 <p>Feb 12, 14:00:59</p>
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </TabPane>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default SMSStatus;
