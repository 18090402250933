import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import img1 from "../../assets/images/svg/Enterprise-Banner2.svg";

const Banner = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section>
      <div
        className="card text-dark my-4 mx-5 px-5 py-4"
        style={{
          backgroundColor: "#FFE1FF",
          borderRadius:'24px'
       
        }}
      >
        <div class="row">
          <div class="col-md-6 col-sm-12 d-flex align-items-center order-2 order-md-1">
            <div className='' style={{ width: "100%" }}>
              <h1 className="mb-3 fs-47">
                Enterprise Messaging
                <br />
                Platform <span className="text-primary">Integration</span>
              </h1>
              <p class="card-text mb-4 ff-secondary">
                Choose communication mediums that support your goals. Common
                solutions include Slack, Microsoft Team, WhatsApp Business,
                Facebook Messenger, etc. Allows you to choose a messaging
                platform and set integration objectives. Improved internal team
                communication, customer service, or process automation are
                examples.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 my-5 d-flex align-items-center order-1">
            <img src={img1} alt="" className="img-fluid w-75" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
