import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import img1 from "../../assets/images/intotechwoman.png";

const Home = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <React.Fragment>
      <section className="section  fs-50" id="plans">
        <div className="bg-overlay bg-light opacity-100"></div>
        <div className="w-75 mx-auto">
          <Row className="align-items-center mt-5 pt-lg-5 gy-4">
            <Col lg={6} sm={7} className="col-10 mx-auto">
              <div className="order-2">
                <img src={img1} alt="" className="img-fluid" />
              </div>
            </Col>
            <Col lg={6}>
              <div className=" ps-lg-5">
                <h5 className="fs-16 text-uppercase text-dark">
                  
                </h5>
                <h1 className=" fs-30 mb-3 order-1">
                Information and Technology: Joined in Brimail's Unity.
                </h1>

                <div className="d-flex gap-2">
                  <button
                    color="danger "
                    className="btn rounded-pill btn-primary"
                    onClick={() => history("/register")}
                  >
                    Start for free
                    <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Home;
