import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  CardText,
  Container,
  Form,
  Input,
  Label,
  Button,
  ButtonGroup,
  Alert,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import SimpleBar from "simplebar-react";
import { BasicRaterExample } from "../../Components/UiRatingsCode";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { setAuthorization } from "../../helpers/api_helper";
import { testFlashCall, verifyFlashCall } from "../../store/actions";

const Flashcall = () => {
  const [codeTab, setCodeTab] = useState("cURL"); // Define isTypeTab variable
  const [activeTab, setactiveTab] = useState(1);
  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();

  const { account, loading } = useSelector((state) => ({
    account: state.Account.account,
    loading: state.FlashCall.loading,
  }));
  console.log(loading);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      sender: "",
      recipients: "",
    },
    validationSchema: Yup.object({
      // recipients: Yup.string().required("Please enter your email address"),
      code: Yup.string().required("Please enter code"),
    }),
    onSubmit: (values) => {
      // alert("Sent to the backend");
      setAuthorization(account?.test_secret_key);
      dispatch(
        console.log(values),
        alert('test'),
        testFlashCall({
          from: values.sender,
          to: values.recipients,
        })
      );
    },
  });

  const verification = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      // recipients: Yup.string().required("Please enter your email address"),
      code: Yup.string().required("Please enter code"),
    }),
    onSubmit: (values) => {
      // alert("Enter Verification");
      setAuthorization(account?.test_secret_key);
      dispatch(
        verifyFlashCall({
          code: values.code,
        })
      );
    },
  });

  return (
    <Container fluid>
      <Card>
        <CardHeader>
          <h1 className=" mb-0 fs-120 fw-bold">Check out the Verify API </h1>
          <p className="card-title mb-0 text-muted mt-2 fs-13">
            The Verify API lets you send a PIN to a user's phone and validate
            that they received it.{" "}
            <span className="text-primary">Learn more </span>
          </p>
        </CardHeader>

        <CardBody>
          <Alert
            color=" text-dark"
            style={{ backgroundColor: "#F0F0F0" }}
            className="mt-3"
          >
            <strong>
              Verify <span className="text-primary"> V2 API </span> is now
              available
            </strong>
            <br />
            The developer documentation for the new API can be found here and
            includes some new features such as
            <br />
            - Sending verifications over new channels such as WhatsApp and Email
            <br />
            - Receiving callbacks with added insights into your request
            <br />
          </Alert>

          <Row>
            <Col xl={6}>
              <Form
                action="#"
                className="form-steps"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div>
                  <p>
                    <strong>Try it out with UI</strong>
                  </p>
                </div>
                <p>Step 1</p>
                <div className="mb-3 col-md-10">
                  <Label
                    className="form-label  fw-normal fs-12"
                    htmlFor="gen-info-email-input"
                  >
                    Brand name
                  </Label>
                  <Input
                    type="text"
                    className="form-control rounded-3"
                    placeholder="BRIVAS"
                    name="sender"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.sender || ""}
                    invalid={
                      validation.touched.sender && validation.errors.sender
                        ? true
                        : false
                    }
                  />
                </div>
                <div className="mb-5 mt-3 col-md-10">
                  <Label
                    className="form-label fw-normal"
                    htmlFor="gen-info-password-input"
                  >
                    Registered phone number
                  </Label>
                  <Input
                    className="form-control rounded-3"
                    id="gen-info-password-input"
                    placeholder="2348165661377"
                    name="recipients"
                    type="number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.recipients || ""}
                    invalid={
                      validation.touched.recipients &&
                      validation.errors.recipients
                        ? true
                        : false
                    }
                  />

                  <Label
                    className="form-label text-muted fw-normal"
                    htmlFor="gen-info-password-input"
                  >
                    You can only use the registered number to receive messages
                    in this example.
                  </Label>
                </div>

                <div className=" align-items-start gap-3 my-5">
                  <Button
                    type="submit"
                    color="primary"
                    className="btn-load rounded-pill px-5 py-2"
                    disabled={loading}
                  >
                    <span className="d-flex align-items-center">
                      {loading && (
                        <Spinner
                          size="sm"
                          type="grow"
                          className="flex-shrink-0"
                        />
                      )}
                      <span className="flex-grow-1 ms-1">Make a Call</span>
                    </span>
                  </Button>
                </div>
              </Form>
              <Form
                action="#"
                className="form-steps"
                onSubmit={(e) => {
                  e.preventDefault();
                  verification.handleSubmit();
                  return false;
                }}
              >
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={1}>
                    <p>Step 2</p>
                    <div className="mb-5 mt-3 col-md-10">
                      <Label
                        className="form-label fw-normal"
                        htmlFor="gen-info-password-input"
                      >
                        Enter code
                      </Label>
                      <Input
                        className="form-control rounded-3"
                        id="gen-info-password-input"
                        placeholder="2348165661377"
                        name="code"
                        type="number"
                        onChange={verification.handleChange}
                        onBlur={verification.handleBlur}
                        value={verification.values.code || ""}
                        invalid={
                          verification.touched.code && verification.errors.code
                            ? true
                            : false
                        }
                      />

                      <Label
                        className="form-label text-muted fw-normal"
                        htmlFor="gen-info-password-input"
                      >
                        Enter the last 5 didgits of the Number
                      </Label>

                      <div className=" align-items-start gap-3 my-5">
                        <Button
                          type="submit"
                          color="primary"
                          className="btn-load rounded-pill px-5 py-2"
                          disabled={loading}
                        >
                          <span className="d-flex align-items-center">
                            {loading && (
                              <Spinner
                                size="sm"
                                type="grow"
                                className="flex-shrink-0"
                              />
                            )}
                            <span className="flex-grow-1 ms-1">
                              Verify call
                            </span>
                          </span>
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </Form>
            </Col>
            <Col xl={6}>
              <div className="email-content">
                <div className="p-4 pb-0">
                  <div className="border-bottom border-bottom-dashed">
                    <Row className="row align-items-end mt-3">
                      <Col>
                        <div id="mail-filter-navlist">
                          <Nav
                            className="nav nav-tabs nav-tabs-custom nav-success gap-5 text-center border-bottom-0"
                            role="tablist"
                          >
                            <NavItem>
                              <NavLink
                                className={classnames(
                                  { active: codeTab === "cURL" },
                                  "fw-semibold"
                                )}
                                onClick={() => setCodeTab("cURL")}
                                href="#"
                              >
                                <span className="ms-1 d-none d-sm-inline-block">
                                  cURL
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames(
                                  { active: codeTab === "Node" },
                                  "fw-semibold"
                                )}
                                onClick={() => setCodeTab("Node")}
                                href="#"
                              >
                                <span className="ms-1 d-none d-sm-inline-block">
                                  Node
                                </span>
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <SimpleBar className="message-list-content mx-n4 px-4 message-list-scroll">
                    {isLoader ? (
                      <div id="elmLoader">
                        <div
                          className="spinner-border text-primary avatar-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {codeTab === "cURL" && (
                          <Card className="shadow-none">
                            <h6 className=" mt-3 mb-5">Install the Library</h6>

                            <pre>
                              <code className="language-markup">
                                <BasicRaterExample />
                              </code>
                            </pre>

                            <h6 className=" mt-2">
                              Check the request with a code
                            </h6>

                            <pre>
                              <code className="language-markup">
                                <BasicRaterExample />
                              </code>
                            </pre>
                          </Card>
                        )}

                        {codeTab === "Node" && (
                          <Card className="shadow-none">
                            <h6 className=" mt-3 mb-5">
                              This is a place holder for content
                            </h6>

                            <pre>
                              <code className="language-markup">
                                <BasicRaterExample />
                              </code>
                            </pre>

                            <h6 className=" mt-2">
                              Check the request with a code
                            </h6>

                            <pre>
                              <code className="language-markup">
                                <BasicRaterExample />
                              </code>
                            </pre>
                          </Card>
                        )}
                        {codeTab === "Java" && <div>This is for Java</div>}
                      </div>
                    )}
                  </SimpleBar>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default Flashcall;
