import {
  RESOLVE_EMAIL,
  RESOLVE_EMAIL_SUCCESS,
  RESOLVE_EMAIL_FAILED,
} from "./actionTypes";

export const resolveEmail = () => {

  return {
    type: RESOLVE_EMAIL
  };
};

export const resolveEmailSuccess = data => {
  return {
    type: RESOLVE_EMAIL_SUCCESS,
    payload: data,
  };
};

export const resolveEmailError = error => {
  return {
    type: RESOLVE_EMAIL_FAILED,
    payload: error,
  };
};
