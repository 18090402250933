import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

import Banner2img from "../../assets/images/fianancialimg2.png";
import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const Banner2 = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <Row className="mx-md-5 px-3 px-md-5 my-5 bg-light">
        <Col sm={12} md={6}>
          <div class="">
            <div class="row g-0 card-body px-0 py-0">
              <div class="col-md-9 col-sm-12">
                <div class="d-flex justify-content-center flex-column py-3 px-4">
                  <img
                    className="align-self-start mx-auto"
                    src={Banner2img}
                    alt="Generic placeholder image"
                    height={"250px"}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12} md={6} className="mx=md-5">
          <div className="text-bold fs-25 pt-5 py-5 w-100 " style={{}}>
            <h1 className="mb-3 fw-bold ">
              Improving<span className="text-danger"> Financial Services</span>{" "}
              Connections with Their Customers
            </h1>
            <p>
              Our solution combines streaming, unified communication, and USSD
              technology to optimize customer connections in the financial
              industry. Stream engaging financial content, facilitate seamless
              communication, and provide convenient banking services. Strengthen
              customer relationships, drive loyalty, and deliver exceptional
              experiences.
            </p>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Banner2;
