import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { RESOLVE_EMAIL } from "./actionTypes";
import { resolveEmailSuccess, resolveEmailError } from "./actions";

import { getPendingEmail } from "../../../helpers/backend_helper";

function* resolveEmailAddress() {
  try {
    const json = yield call(getPendingEmail);
    if (json.status === "success") {
      yield put(resolveEmailSuccess(json));
    } else {
      yield put(resolveEmailError(json));
    }
  } catch (err) {
    yield put(resolveEmailError(err?.message ?? 'An error occured'));
  }
}

function* resolveEmailAddressSaga() {
  yield takeEvery(RESOLVE_EMAIL, resolveEmailAddress);
}

export default resolveEmailAddressSaga;
