import React from "react";
import { Card, CardBody, Col, Row, Container, Button } from "reactstrap";
import Header from "./Header";
import { Link } from "react-router-dom";
import Umb from "../../assets/images/umbrella.png";

const SentMessages = () => {
  document.title = "BRIVAS | Sent Messages";

  return (
    <Container fluid>
      <Card className="shadow-none">
        <Row>
          <Col xl={12}>
            <div className="rounded-3 text-dark">
              <Header>
                <div className="">
                  <div className="">
                    <h1 className="fw-bold text-dark mb-2 d-flex gap-2 m-0">
                      <i class="ri-send-plane-fill"></i> SMS Sender ID
                    </h1>
                    <p className="w-50 mx-5">
                      Get all of the sender identification for your
                      communications in one easy-to-access place for smooth
                      monitoring.
                    </p>
                  </div>
                </div>
              </Header>
              <CardBody>
                <div className=" align-items-center text-center">   
                <img
                      src={Umb}
                      className=" img-fluid"
                      alt="..."
                    /> 
                  <h2 className="fw-bold mb-2 mt-5">Place a request for any sender ID</h2>
                  <h6 className="mb-5">All of your sender ID will appear here</h6>
                  <Link
                    to="/bulk-sms/create-sms"
                    className="bg-primary rounded-pill  text-light px-5 fw-bold text-center py-2"
                  >
                    Request SID
                  </Link>
                </div>
              </CardBody>
            </div>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default SentMessages;
