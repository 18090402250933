import { BULK_SMS_BALANCE, BULK_SMS_ERROR, BULK_SMS_SCHEDULE, BULK_SMS_SUCCESS, SEND_BULK_SMS, TEST_BULK_SMS } from "./actonType";

export const bulkSMSSuccess = (actionType, data) => {
  return {
    type: BULK_SMS_SUCCESS,
    payload: { actionType, data },
  };
};

export const bulkSMSError = (actionType, error) => {
  return {
    type: BULK_SMS_ERROR,
    payload: { actionType, error },
  };
};

export const sendBulkSMS = (data) => ({
  type: SEND_BULK_SMS,
  payload: { data },
});

export const getBulkSMSBal = (data) => ({
  type: BULK_SMS_BALANCE,
});

export const scheduleBulkSMS = (data) => ({
  type: BULK_SMS_SCHEDULE,
  payload: { data },
});

export const listBulkSMSContact = (data) => ({
  type: SEND_BULK_SMS,
  payload: { data },
});