import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

// import JobProfile2 from "../../assets/images/job-profile2.png";
// import Cardimage1 from "../../assets/images/svg/Num-connect-bottom-card-img1.svg";
import Cardimage1 from "../../assets/images/numbers-and-connectivity.png";
import Cardimage2 from "../../assets/images/svg/Num-connect-bottom-card-img3.svg";

import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardText } from "@mui/joy";

const Bottom = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(listProductGroup());
  // }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <section>
        <div
          className=""
          style={{
            backgroundColor: "#FFE1FF",
            marginTop: "0px",
            paddingTop: "90px",
            paddingBottom: "120px",
          }}
        >
          <div>
            <h1 className="display-6 fw-medium mb-5 text-dark fs-40  lh-base text-center">
              Toll-free and Non-toll-free numbers
            </h1>
          </div>
          <div>
            <Row>
              <Col sm={12} md={6}>
                <div class="card mx-4">
                  <div class="row g-0 card-body px-0 py-0">
                    <div
                      class="col-md-3 col-sm-12"
                    >
                       <div
                        style={{
                          height: "290px",
                          width: "auto",
                          backgroundImage:`url(${Cardimage1})`,
                          backgroundPosition:'center',
                          backgroundSize:'cover',
                        }}
                      ></div>
                    </div>
                    <div class="col-md-9 col-sm-12">
                      <div
                        class="d-flex justify-content-center flex-column py-3 px-4"
                      >
                        <h5 class="card-title fs-24">
                          Inspire trust with a toll-free number
                        </h5>
                        <p class="card-text fs-16 fw-400">
                          Make connecting with your customers a breeze with a
                          toll-free number from Brivas. Break down communication
                          barriers, build trust, and provide a free and
                          convenient way for your audience to reach you.
                        </p>

                        <div>
                          <button
                            color="danger"
                            className="btn rounded-pill btn-primary"
                            onClick={() => history("/register")}
                          >
                            Start for free
                          </button>
                          <button
                            color="success"
                            className="btn rounded-pill btn-light border-danger mx-3"
                            onClick={() => history("/register")}
                          >
                            <span className="text-danger">
                              Talk to an expert
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6}>
              <div class="card mx-4">
                  <div class="row g-0 card-body px-0 py-0">
                    <div
                      class="col-md-3 col-sm-12"
                    >
                      <div
                        style={{
                          height: "290px",
                          width: "auto",
                          backgroundImage:`url(${Cardimage2})`,
                          backgroundPosition:'center',
                          backgroundSize:'cover',
                        }}
                      ></div>
                    </div>
                    <div class="col-md-9 col-sm-12">
                      <div
                        class="d-flex justify-content-center flex-column py-3 px-4"
                      >
                        <h5 class="card-title fs-24 fw-500">
                          A great idea for improving customer service
                        </h5>
                        <p class="card-text fs-16 fw-400">
                          Make people feel safe. Use this trusted channel to
                          have two-way conversations with customers. Never miss
                          a chance to get straight feedback and make connections
                          that matter.
                        </p>

                        <div>
                          <button
                            color="danger"
                            className="btn rounded-pill btn-primary"
                            onClick={() => history("/register")}
                          >
                            Start for free
                          </button>
                          <button
                            color="success"
                            className="btn rounded-pill btn-light border-danger mx-3"
                            onClick={() => history("/register")}
                          >
                            <span className="text-danger">
                              Talk to an expert
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Bottom;
