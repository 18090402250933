import {
  LIST_APPS,
  LIST_APPS_SUCCESS,
  LIST_APPS_ERROR
} from "./actionType";

export const listApps = () => ({
  type: LIST_APPS
});

export const listAppsSuccess = data => {
  return {
    type: LIST_APPS_SUCCESS,
    payload: data,
  };
};

export const listAppsError = error => {
  return {
    type: LIST_APPS_ERROR,
    payload: error,
  };
};