import {
  ACCOUNT_SUCCESS,
  ACCOUNT_REGISTER,
  ACCOUNT_LOGIN,
  ACCOUNT_RESET_FLAG,
  ACCOUNT_PROFILE,
  ACCOUNT_ERROR,
  ACCOUNT_LOGOUT,
  ACCOUNT_FORGOT_PASSWORD,
  ACCOUNT_RESET_PASSWORD
} from "./actionTypes"

export const accountSuccess = (actionType, data) => {
  return {
    type: ACCOUNT_SUCCESS,
    payload: { actionType, data },
  }
}

export const accountError = (actionType, error) => {
  return {
    type: ACCOUNT_ERROR,
    payload: { actionType, error },
  }
}

export const initCreateAccount = (data, history) => {
  return {
    type: ACCOUNT_REGISTER,
    payload: { data, history },
  }
}

export const accountLogin = (data, history) => {
  return {
    type: ACCOUNT_LOGIN,
    payload: { data, history },
  }
}

export const accountProfile = history => ({
  type: ACCOUNT_PROFILE,
  payload: history,
});

export const accountForgotPassword = (data, history) => ({
  type: ACCOUNT_FORGOT_PASSWORD,
  payload: { data, history },
});

export const accountResetPassword = (data, history) => ({
  type: ACCOUNT_RESET_PASSWORD,
  payload: { data, history },
});

export const accountLogout = (history) => ({
  type: ACCOUNT_LOGOUT,
  payload: { history },
});

export const accountResetFlag = () => {
  return {
    type: ACCOUNT_RESET_FLAG,
  }
}

