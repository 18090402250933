import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";

import img1 from "../../assets/images/fairwomanmessage.png";
import icon1 from "../../assets/images/svg/Telecom-icon1.svg";
import icon2 from "../../assets/images/svg/Telecom-icon2.svg";
import icon3 from "../../assets/images/svg/Telecom-icon3.svg";
import icon4 from "../../assets/images/svg/Telecom-icon4.svg";
import icon5 from "../../assets/images/svg/Telecom-icon5.svg";

const Scalable = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section>
      <div className=" px-5 pt-4 py-4">
        <Row className="align-items-center gy-4">
          <div>
            <Card>
              <div class="card mx-4">
                <div class="row g-0">
                  <div class="col-md-4">
                    <img src={img1} class="img-fluid rounded-start" alt="..." />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body bg-secondary">
                      <h5 class="card-title my-4 mx-4 fs-24 fw-500">
                        Connect with Millions
                        <br /> Seamlessly on one API
                      </h5>
                      <p class="card-text fs-16 fw-400 my-4 mx-4">
                        Connect with your audience like never before! Simplify
                        your interactions with customers across all channels -
                        from email to social media, chat, and more. Deliver a
                        consistent, personalised experience that resonates at
                        every touchpoint. Level up your communication game
                        today.
                      </p>

                      <button
                        color="danger "
                        className="btn rounded-pill btn-primary d-flex justify-content-center"
                        onClick={() => history("/register")}
                      >
                        Start for free
                        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                      </button>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </Row>

        <Container>
          <Row>
            <Col sm={6} xl={3}>
              <Card>
                <img
                  className="card-img-top img-fluid float-left"
                  style={{ width: 40, height: 40 }}
                  src={icon1}
                  alt="Card cap"
                />
                <CardBody>
                  <h4 className="card-title ft-bold mb-2">Built in CRM</h4>
                  <p className="card-text">
                    Elevate your workflow with our call center CRM. Streamline
                    client management and supercharge productivity.
                  </p>
                  <div className="text-end">
                    <Link
                      to="#"
                      className="soft-primary w-50 mx-auto d-flex justify-content-center"
                    >
                      Talk to an expert
                      <i class="ri-arrow-right-line"></i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={6} xl={3}>
              <Card>
                <img
                  className="card-img-top img-fluid float-left"
                  style={{ width: 40, height: 40 }}
                  src={icon2}
                  alt="Card cap"
                />
                <CardBody>
                  <h4 className="card-title text-center mb-2">Multi tenancy</h4>
                  <p className="card-text">
                    Scale your operations effortlessly with our robust
                    multi-tenancy support. Customize experiences for every
                    client.
                  </p>
                  <div className="text-end">
                    <Link
                      to="#"
                      className="soft-primary w-50 mx-auto d-flex justify-content-center"
                    >
                      Talk to an expert
                      <i class="ri-arrow-right-line"></i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={6} xl={3}>
              <Card>
                <img
                  className="card-img-top img-fluid float-left"
                  style={{ width: 40, height: 40 }}
                  src={icon3}
                  alt="Card cap"
                />
                <CardBody>
                  <h4 className="card-title text-center mb-2">Ticketing</h4>
                  <p className="card-text">
                    Resolve queries faster and keep clients satisfied.
                    Streamline support with our Ticketing system. .
                  </p>
                  <div className="text-end">
                    <Link
                      to="#"
                      className="soft-primary w-50 mx-auto d-flex justify-content-center"
                    >
                      Talk to an expert
                      <i class="ri-arrow-right-line"></i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={6} xl={3}>
              <Card>
                <img
                  className="card-img-top img-fluid float-left"
                  style={{ width: 40, height: 40 }}
                  src={icon4}
                  alt="Card cap"
                />
                <CardBody>
                  <h4 className="card-title text-center mb-2 mx-auto">
                    Voice Call
                  </h4>
                  <p className="card-text text-center">
                    Voice interactions should facilitate understandable and
                    trustworthy communication.
                  </p>
                  <div className="text-end">
                    <Link
                      to="#"
                      className="soft-primary w-50 mx-auto d-flex justify-content-center"
                    >
                      Learn more
                      <i class="ri-arrow-right-line"></i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={6} xl={3}>
              <Card>
                <img
                  className="card-img-top img-fluid float-left"
                  style={{ width: 40, height: 40 }}
                  src={icon5}
                  alt="Card cap"
                />
                <CardBody>
                  <h4 className="card-title text-center mb-2 mx-auto">SMS</h4>
                  <p className="card-text text-center">
                    Effectively communicate with customers via text and Rich
                    Communication Services.
                  </p>
                  <div className="text-end">
                    <Link
                      to="#"
                      className="soft-primary w-50 mx-auto d-flex justify-content-center"
                    >
                      Learn more
                      <i class="ri-arrow-right-line"></i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default Scalable;
