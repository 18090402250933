import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row, Container, Button, Card, CardBody } from "reactstrap";
import Img1 from "../../assets/images/svg/apiconnect.svg";

const meetcustomers = () => {
  return (
    <div className=" " style={{ backgroundColor: "#40196D" }}>
      <Row className="justify-content-between  align-items-center">
        <Col lg={6}>
          <div
            className="p-4 px-5 py-5 pl-4 "
          >
            <h3 className="display-10 fw-bold fs-35 text-light d-flex mb-2 text-light lh-base">
              Use one API for all <br />
              messaging channels
            </h3>

            <p className="lead lh-base mb-4 text-light fs-18 text-primary ">
              Harness the power of a singular API to harmonize all your diverse
              messaging channels. Enhance your communication strategy through
              the implementation of Omnichannel Messaging, make meaningful
              connections, create engagement, and thrive in today's
              interconnected environment. Create flawless experiences and deep
              connections to succeed.
            </p>

            <div className="vstack gap-2">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-2"></div>
              </div>
              <div className="d-flex align-items-center"></div>
              <div></div>
              <div className="d-flex gap-2">
                <button
                  color="danger "
                  className="btn rounded-pill btn-danger"
                  onClick={() => ""}
                >
                  Start for free
                  <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                </button>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="p-4">
            <img src={Img1} alt="" className="img-fluid" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default meetcustomers;
