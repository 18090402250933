import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import img1 from "../../assets/images/svg/Ecommerce-home-img.png";

const Home = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section className="bg-primary">
      <div class="row mx-md-5 px-3 px-md-0">
        <div class="col-md-6 d-none d-md-inline my-5">
          <img src={img1} alt="" className="user-img" />
        </div>
        <div class="col-md-6">
          <h1 className="fw-bold fs-48 lh-base text-light my-4 text-sm-centre ">
            Elevate Your E-commerce User Experience
          </h1>
          <p className="text-light">
            Discover a world of possibilities and take your e-commerce business
            to the next level with our game-changing solution.
          </p>

          <div className="d-flex gap-2 my-5">
            <button
              color="danger"
              className="btn rounded-pill btn-danger text-light"
              onClick={() => "/register"}
            >
              Start for free
            </button>
            <button
              color="success"
              className="btn rounded-pill btn-primary border-light "
              onClick={() => history("/register")}
            >
              <span className="text-light">Talk to an expert</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
