import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import numberandconnectivityhomepage from "../../assets/images/svg/numbers-connectivity-homepage.svg";
import numberandconnectivityhomepage2 from "../../assets/images/svg/numbers-connectivity-homepage2-min.png";
import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import ListIcon from "../../assets/images/svg/voiceservicelogo.svg";

const Home = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <section className="section pb-0" id="hero">
        <div class="row mx-md-5 px-3 px-md-5">
          <div class="col-md-6">
            <h1 className="fw-bold fs-48 lh-base text-primary mb-4 text-sm-centre">
              Buy into Trust and {/* <br></br> */} Innovation
            </h1>
            <p>
              If you're looking for conversations that are crystal clear and
              cost <br></br>
              efficiency that can't be matched, look no further; we are exactly
              what <br></br>
              you need. With our adaptable strategies, we spare no effort to
              ensure
              <br></br> that you can always be reached. 
            </p>

            <div className="d-flex gap-2 my-5">
              <button
                color="primary "
                className="btn rounded-pill btn-primary"
                onClick={() => "/register"}
              >
                Start for free
              </button>
              <button
                color="success"
                className="btn rounded-pill btn-light border-danger"
                onClick={() => history("/register")}
              >
                <span className="text-danger">Talk to an expert</span>
              </button>
            </div>
          </div>
          <div class="col-md-6 d-none d-md-inline">
            <img
              src={numberandconnectivityhomepage}
              alt=""
              className="user-img"
            />
          </div>
        </div>
        <div className="mx-md-5 px-3 px-md-5">
          < div class="row">
            <div class="col-md-6 col-sm-12">
              <h1 className="display-6 fw-bold fs-48 lh-base text-dark text-sm-center text-md-start">
                Connect with the top <span className="text-primary">VoIP</span> service provider
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 order-2 order-md-1">
              <p className="mb-5">
                Why take on the challenges of navigating the complexities of a
                telecom ecosystem when we've got you covered? Get access to a
                dependable, global all-IP network solution without having to set
                up or maintain your own telecom ecosystem by subscribing to this
                solution.
              </p>
              <p className="fw-bold">
                <img
                  src={ListIcon}
                  className=""
                  alt=""
                  height="24"
                  width={24}
                />
                Cost Efficiency Redefined
              </p>
              <p>
                Say goodbye to exorbitant telecom solutions and get the most
                value out of your communication budget.
              </p>
              <br />

              <p className="fw-bold">
                {" "}
                <img
                  src={ListIcon}
                  className="ml-3 "
                  alt=""
                  height="24"
                  width={24}
                />
                Crystal Clear Conversations
              </p>
              <p>
                Ensure every word is heard loud and clear, creating a
                professional impression with every call.
              </p>
              <br></br>
              <p className="fw-bold">
                <img
                  src={ListIcon}
                  className=""
                  alt=""
                  height="24"
                  width={24}
                />
                Flexibility and Mobility
              </p>
              <p>
                Stay connected on the go. Take your business calls wherever you
                are, ensuring you're always reachable.
              </p>

              <div className="d-flex gap-2 my-5">
                <button
                  color="primary "
                  className="btn rounded-pill btn-primary"
                  onClick={() => "/register"}
                >
                  Start for free
                  <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                </button>
                <button
                  color="success"
                  className="btn rounded-pill btn-light border-danger"
                  onClick={() => history("/register")}
                >
                  <span className="text-danger">Talk to an expert</span>
                </button>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 voip order-1">
              <img
                src={numberandconnectivityhomepage2}
                alt=""
                className="user-img w-100"
                // style={{margin:'-10%'}}
                // width="599px"
                // width="380px"
              />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Home;
