import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import IMG from "../../assets/images/svg/Nonprofit-banner4-cardimg.svg";
import img1 from "../../assets/images/svg/EcommWhatsapp.png";
import img2 from "../../assets/images/svg/EcommInsta.png";
import img3 from "../../assets/images/svg/EcommAllsocials.png";

const Bottom = () => {
  return (
    <div style={{ backgroundColor: "#FFF5FF" }}>
      <Row className="mx-md-5 px-3 px-md-5">
        <Col sm={12} md={3} className="">
          <div className=" text-sm-left w-100 ">
            <h1 className="text-dark fs-48 fw-semibold">
              Give customers a smooth omnichannel experience
            </h1>
            <p>
              Our comprehensive platform facilitates seamless customer
              engagement and support across web, mobile, and social channels.
            </p>
          </div>
        </Col>

        <Col sm={4} md={3}>
          <Card>
            <img className="card-img-top img-fluid" src={img1} alt="Card cap" />
            <CardBody className="text-center">
              <h2 className="card-title  ft-bold mb-2">WhatsApp</h2>
              <p className="card-text">
                Engage customers with WhatsApp messaging features.
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col sm={4} md={3}>
          <Card>
            <img className="card-img-top img-fluid" src={img2} alt="Card cap" />
            <CardBody className="text-center">
              <h2 className="card-title ft-bold mb-2">Instagram</h2>
              <p className="card-text ">
                Engage customers with Instagram messaging features.
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col sm={4} md={3}>
          <Card>
            <img
              className="card-img-top shadow-lg img-fluid"
              src={img3}
              alt="Card cap"
            />
            <CardBody className="text-center">
              <h2 className="card-title ft-bold mb-2">
                All Socials
              </h2>
              <p className="card-text">
                Create a social media presence and boost sales.
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Bottom;
