import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import verifiyimage from "../../assets/images/svg/verification-homepage-img.png";
import verifiyimage2 from "../../assets/images/svg/verification-homepage-img2.png";
import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import ListIcon from "../../assets/images/svg/voiceservicelogo.svg";

const Home = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <section className="section pb-0" id="hero">
        <div class="row mx-md-5 px-3 px-md-5">
          <div class="col-md-6">
            <h1 className="fw-bold fs-48 lh-base text-bolder mb-4 text-sm-centre">
              Your product is sleek shouldn't your{" "}
              <span className="text-primary">security</span> be tight?
            </h1>
            <p>
              Protect your customers and make them more comfortable with your
              product or service with our verification platform.
            </p>

            <div className="d-flex gap-2 my-5">
              <button
                color="primary "
                className="btn rounded-pill btn-primary"
                onClick={() => "/register"}
              >
                Start for free
              </button>
              <button
                color="success"
                className="btn rounded-pill btn-light border-danger"
                onClick={() => history("/register")}
              >
                <span className="text-danger">Talk to an expert</span>
              </button>
            </div>
          </div>
          <div class="col-md-6 d-none d-md-inline px-md-5">
            <img src={verifiyimage} alt="" className="user-img" />
          </div>
        </div>

        <div className="mx-md-5 px-3 px-md-5">
          <div class="row">
            <div class="col-md-6 col-sm-12 voip order-1 d-flex align-items-center">
              <img
                src={verifiyimage2}
                alt=""
                className="user-img w-75"
                // style={{margin:'-10%'}}
                // width="599px"
                // width="380px"
              />
            </div>
            <div class="col-md-6 col-sm-12 order-2 order-md-1">
              <h1 className="display-6 fw-bold fs-48 lh-base text-dark text-sm-center text-md-start">
                Eliminate large-scale theft without extra sweat
              </h1>
              <p className="mb-5">
                Confirmation of identities has never been this easy, effective,
                and reliable. We have everything you need to reduce expenses,
                improve security, and deliver the best possible user experience
                Combine SMS, Flash Calls, Phone Calls, Flash Messages, and
                Verification API. With Brivas, you can!
              </p>
              <div className="media d-flex mb-4">
                <img
                  className="align-self-start mx-2"
                  src={ListIcon}
                  alt="Generic placeholder image"
                />
                <div className="media-body">
                  <h5 className="mt-0">Swift OTP Delivery</h5>
                  <p>
                  We pride ourselves in delivering fast verification to your clients.
                  </p>
                </div>
              </div>
              <div className="media d-flex mb-4">
                <img
                  className="align-self-start mx-2"
                  src={ListIcon}
                  alt="Generic placeholder image"
                />
                <div className="media-body">
                  <h5 className="mt-0">Enhance OTP Delivery</h5>
                  <p>
                  Easy, cost-efficient and smooth methods are all at your fingertip.
                  </p>
                </div>
              </div>
              <div className="media d-flex mb-4">
                <img
                  className="align-self-start mx-2"
                  src={ListIcon}
                  alt="Generic placeholder image"
                />
                <div className="media-body">
                  <h5 className="mt-0">Integral Fraud Prevention</h5>
                  <p>
                  Your clients can trust you because they know that your platform is safe
                  </p>
                </div>
              </div>

              <div className="d-flex gap-2 my-5">
                <button
                  color="primary "
                  className="btn rounded-pill btn-primary"
                  onClick={() => "/register"}
                >
                  Start for free
                  <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                </button>
                <button
                  color="success"
                  className="btn rounded-pill btn-light border-danger"
                  onClick={() => history("/register")}
                >
                  <span className="text-danger">Talk to an expert</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Home;
