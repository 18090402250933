import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Col,
  Row,
} from "reactstrap";

// import JobProfile2 from "../../assets/images/job-profile2.png";
import img1 from "../../assets/images/svg/voicebroadcast.png";

import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const Voicebroadcast = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <section
        className="section  bg-secondary"
        id="hero"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className=" bg-home-overlay-pattern2"></div>
        <div className="px-0">
          <Row className=" m-5">
          <Col lg={6}>
              <div className="position-relative home-img text-center mt-3 mt-lg-0">
               
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <h1 className="display-6 text-light fw-medium mb-5 fs-4  lh-base">
                  VOICE BROADCAST
                </h1>
                <h1 className="display-6 fw-medium mb-3 text-light fs-40  lh-base">
                  Keep Your Customers <br></br>
                  Informed with
                </h1>
                <h1 className="text-primary fs-25 my-4">BRIVAS VB</h1>
                <p className="lead lh-base mb-4 text-light w-75">
                  Your customers deserve the best. You can stay ahead of the
                  game with our cutting-edge technology that delivers event reminders,
                  product debuts, and crucial information quickly. We ensure your message is heard clearly.
                </p>

                <div className="d-flex gap-2">
                  <button
                    color="success"
                    className="btn rounded-pill btn-light mb-4 margin-left-20px"
                    onClick={() => history("/register")}
                  >
                    Talk to an expert
                    <i className="align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Voicebroadcast;
