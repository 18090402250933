import {
  VERIFICATION_SMS_ERROR,
  VERIFICATION_SMS_SUCCESS,
  // TEST_VERIFICATION_SMS,
  SEND_VERIFICATION_SMS,
} from "./actonType";

const initialState = {
  loading: false,
  error: null,
  contacts: [],
  balance: 0,
  isSent: false,
};

const Smsotp = (state = initialState, action) => {
  switch (action.type) {
    case VERIFICATION_SMS_SUCCESS:
      switch (action.payload.actionType) {
        // case TEST_VERIFICATION_SMS:
        //   return { ...state, loading: false };
        case SEND_VERIFICATION_SMS:
          return { ...state, isSent: true, loading: false };
        default:
          return { ...state };
      }
    case VERIFICATION_SMS_ERROR:
      switch (action.payload.actionType) {
        // case TEST_VERIFICATION_SMS:
        //   return { ...state, loading: false };

        case SEND_VERIFICATION_SMS:
          return { ...state, loading: false };
        default:
          return { ...state };
      }
    // case TEST_VERIFICATION_SMS:
    //   return { ...state, loading: true };
    case SEND_VERIFICATION_SMS:
      return { ...state, loading: true };
    default:
      return state;
  }
};

export default Smsotp;
