import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Carousel,
  Container,
  Form,
  Input,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

// import JobProfile2 from "../../assets/images/job-profile2.png";
import Dolapo from "../../assets/images/educationbgimage.png";

import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const Banner = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <section
        className="section px-5 bg-secondary pb-0"
        id="hero"
        style={{
          backgroundImage: `url(${Dolapo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "635px",
        }}
      >
        <div className="">
          <div className="d-flex justify-content-around">
            <div></div>
            <div className="pl-5" style={{ width: "57ch" }}>
              <h1 className="display-6 fw-bold mb-3 d-flex just-content-around ft-bold text-dark fs-42  lh-base">
              Brivas Introduces a customized email solution <br></br>
              for Education, Enhancing Communication
              </h1>
              <div className="d-flex gap-2 mt-2">
                <button
                  color="danger "
                  className="btn rounded-pill btn-light text-primary"
                  onClick={() => history("/register")}
                >
                  Start for free
                  <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                </button>

                <button
                  color="success"
                  className="btn rounded-pill btn-light margin-left-20px"
                  onClick={() => history("/register")}
                >
                  Talk to an expert
                  <i className=" align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Banner;
