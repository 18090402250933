import { GENERATE_CODE, GENERATE_CODE_SUCCESS, GENERATE_CODE_ERROR, QR_CODE_LOGIN, QR_CODE_LOGIN_SUCCESS, QR_CODE_LOGIN_ERROR } from "./actionTypes";

const initialState = { qrcode: '', msg: null, loading: false, error: false, success: false };

const qrLogin = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_CODE:
      state = { ...state, loading: true, error: false, success: false, msg: null };
      break;
    case GENERATE_CODE_SUCCESS:
      state = { ...state, loading: false, error: false, success: true, msg: action.payload.msg, qrcode: action.payload.qrcode };
      break;
    case GENERATE_CODE_ERROR:
      state = { ...state, loading: false, error: true, success: false, msg: action.payload.msg };
      break;
    case QR_CODE_LOGIN:
      state = { ...state, loading: true, error: false, success: false, msg: null };
      break;
    case QR_CODE_LOGIN_SUCCESS:
      state = { ...state, loading: false, error: false, success: true, msg: action.payload.msg };
      break;
    case QR_CODE_LOGIN_ERROR:
      state = { ...state, loading: false, error: true, success: false, msg: action.payload.msg };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default qrLogin;
