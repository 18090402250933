import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

//import images
// import slack from '../../assets/images/brands/slack.png';
import OverviewTab from './OverviewTab';
import DocumentsTab from './DocumentsTab';
import ActivitiesTab from './ActivitiesTab';
import TeamTab from './TeamTab';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getProduct } from '../../store/actions';

const Section = () => {
    const { slug } = useParams();

    const dispatch = useDispatch();
 
    useEffect(() => {
        dispatch(getProduct(slug));
    }, [dispatch, slug]);

    const { product } = useSelector(state => ({
        product: state.Landing.app
    }));

    console.log(product);

    //Tab 
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    return (
        <React.Fragment>
            <section className="section">
                <Container>
                    <Row className="g-3">
                        <Col lg={12}>
                            <div className="">
                                {/* <CardBody className="pb-0 px-4"> */}
                                <Row className="mb-3">
                                    <div className="col-md">
                                        <Row className="align-items-center g-3">
                                            <div className="col-md-auto">
                                                <div className="avatar-md">
                                                    <div className="avatar-title bg-white rounded-circle">
                                                        {/* <img src={slack} alt="" className="avatar-xs" /> */}
                                                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="32" cy="32" r="32" fill="#ECECFF" />
                                                            <g clip-path="url(#clip0_1257_12790)">
                                                                <path d="M32 17.332L20 22.6654V30.6654C20 38.0654 25.12 44.9854 32 46.6654C38.88 44.9854 44 38.0654 44 30.6654V22.6654L32 17.332ZM41.3333 30.6654C41.3333 36.692 37.36 42.252 32 43.9054C26.64 42.252 22.6667 36.692 22.6667 30.6654V24.3987L32 20.252L41.3333 24.3987V30.6654ZM25.88 31.452L24 33.332L29.3333 38.6654L40 27.9987L38.12 26.1054L29.3333 34.892L25.88 31.452Z" fill="black" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_1257_12790">
                                                                    <rect width="32" height="32" fill="white" transform="translate(16 16)" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div>
                                                    <h4 className="fw-bold">Verification</h4>
                                                    <div className="hstack gap-3 flex-wrap">
                                                        <div><i className="ri-building-line align-bottom me-1"></i> Themesbrand</div>
                                                        <div className="vr"></div>
                                                        <div>Create Date : <span className="fw-medium">15 Sep, 2021</span></div>
                                                        <div className="vr"></div>
                                                        <div>Due Date : <span className="fw-medium">29 Dec, 2021</span></div>
                                                        <div className="vr"></div>
                                                        <div className="badge rounded-pill bg-info fs-12">New</div>
                                                        <div className="badge rounded-pill bg-danger fs-12">High</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                    <div className="col-md-auto">
                                        <div className="hstack gap-1 flex-wrap">
                                            <button type="button" className="btn py-0 fs-16 favourite-btn active">
                                                <i className="ri-star-fill"></i>
                                            </button>
                                            <button type="button" className="btn py-0 fs-16 text-body">
                                                <i className="ri-share-line"></i>
                                            </button>
                                            <button type="button" className="btn py-0 fs-16 text-body">
                                                <i className="ri-flag-line"></i>
                                            </button>
                                        </div>
                                    </div>
                                </Row>

                                <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' }, "fw-semibold")}
                                            onClick={() => { toggleTab('1'); }}
                                            href="#">
                                            Overview
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' }, "fw-semibold")}
                                            onClick={() => { toggleTab('2'); }}
                                            href="#">
                                            Documents
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '3' }, "fw-semibold")}
                                            onClick={() => { toggleTab('3'); }}
                                            href="#">
                                            Activities
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '4' }, "fw-semibold")}
                                            onClick={() => { toggleTab('4'); }}
                                            href="#">
                                            Team
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                {/* </CardBody> */}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <TabContent activeTab={activeTab} className="text-muted">
                                <TabPane tabId="1">
                                    <OverviewTab />
                                </TabPane>
                                <TabPane tabId="2">
                                    <DocumentsTab />
                                </TabPane>
                                <TabPane tabId="3">
                                    <ActivitiesTab />
                                </TabPane>
                                <TabPane tabId="4">
                                    <TeamTab />
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default Section;