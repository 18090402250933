import { LOGIN_USER, LOGIN_SUCCESS, LOGOUT_USER, LOGOUT_USER_SUCCESS, API_ERROR, RESET_LOGIN_FLAG } from "./actionTypes";

const initialState = {
  msg: null,
  loading: false,
  error: false,
  success: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = { ...state, loading: true, error: false, success: false, msg: null };
      break;
    case LOGIN_SUCCESS:
      state = { ...state, loading: false, error: false, success: true, msg: action.payload.msg };
      break;
    case LOGOUT_USER:
      state = { ...state, isUserLogout: false };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state, isUserLogout: true };
      break;
    case API_ERROR:
      state = { ...state, msg: action.payload.msg, loading: true, error: true, isUserLogout: false, success: false };
      break;
    case RESET_LOGIN_FLAG:
      state = { ...state, msg: null, loading: false, error: false, success: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
