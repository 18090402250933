import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row, Container, Button, Card, CardBody } from "reactstrap";
import Img1 from "../../assets/images/svg/partnerimage-min.png";
import Img2 from "../../assets/images/svg/exploreimage.svg";
import Image1 from "../../assets/images/svg/tipsreport.svg";
import Image2 from "../../assets/images/svg/tipsreport2.svg";
import Image3 from "../../assets/images/svg/tipsguide.svg";
import Image4 from "../../assets/images/fbimage.png";
import SMSTips from "../../Components/SMSTips";
import Whatsapp from "../../assets/images/whatsappicon.png";
import FBmsg from "../../assets/images/fbmessangericon.png";
import DLCfoods from "../../assets/images/dlcfoodsimage.png";
import Telegramlogo from "../../assets/images/telegramicon.png";

import SKYPE from "../../assets/images/iconforskype.png";
import SKYPELOGO from "../../assets/images/imageforskype.png";
import SLACK from "../../assets/images/imageforslack.png";
import SLACKLOGO from "../../assets/images/iconforslack.png";
import INSTAIMAGE from "../../assets/images/imageforinstagram.png";
import INSTAICON from "../../assets/images/iconforinstagram.png";

import TELEGRAMIMAGE from "../../assets/images/telegramimage.png";
import ImgFB from "../../assets/images/fbimage.png";

const RCS = () => (
  <div mt-3>
    <img className="card-img-top W-75 img-fluid" src={Image4} alt="Card cap" />
  </div>
);

const SMSIMAGE = () => (
  <div>
    <img
      className="card-img-top mb-4 pt-0 img-fluid"
      src={DLCfoods}
      alt="Card cap"
      height={"20px"}
    />
  </div>
);

const EMAIL = () => (
  <div mt-3>
    <img className="card-img-top img-fluid" src={Image4} alt="Card cap" />
  </div>
);

const UNDERIFACEBOOK = () => (
  <div className="vstack gap-2 mt-4">
    <div className="media d-flex ">
      <img
        className="align-self-start mt-3 mx-2"
        src={FBmsg}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold"> Instant Reach</h5>
        <p className="w-75">
          With the power of Brivas voice broadcasting, reach a large audience in
          a matter of seconds.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={FBmsg}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold"> No Cost to Customer</h5>
        <p className="w-75">
          Give customers the gratification of reaching you with their concerns
          at no extra cost to them.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={FBmsg}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Scalability</h5>
        <p className="w-75">
          Make your business goals even bigger by automating your responses.
          Focus on those goals, and let us handle customers' messages.
        </p>
      </div>
    </div>

    <div></div>
    <div className="d-flex gap-2 pb-sm-3 pb-5">
      <button
        color="danger "
        className="btn rounded-pill btn-primary"
        onClick={() => ""}
      >
        Start for free
        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
      </button>

      <button
        color="success"
        className="btn rounded-pill btn-light text-primary btn-border border-primary margin-left-20px"
        onClick={() => ""}
      >
        Talk to an expert
        <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
      </button>
    </div>
  </div>
);

const UNDERINSTAGRAM = () => (
  <div className="vstack gap-2 mt-4">
    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={INSTAICON}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold"> Easy Integration</h5>
        <p className="w-75">
          Brivas offers a game-changing opportunity to empower your users to
          engage with your business by seamlessly integrating Instagram
          functionalities within your platform.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={INSTAICON}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Data Insight</h5>
        <p className="w-75">
          The rich data insights provided by Brivas Instagram API data analytics
          let you make data-driven decisions and optimize your strategy.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={INSTAICON}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Engaging Customer Support System</h5>
        <p className="w-75">
          Make yourself accessible to your consumers in real-time via a chatbot
          or a customer service person. Gain their loyalty by offering
          after-sales assistance.
        </p>
      </div>
    </div>

    <div></div>
    <div className="d-flex gap-2">
      <button
        color="danger "
        className="btn rounded-pill btn-primary"
        onClick={() => ""}
      >
        Start for free
        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
      </button>

      <button
        color="success"
        className="btn rounded-pill btn-light text-primary btn-border border-primary margin-left-20px"
        onClick={() => ""}
      >
        Talk to an expert
        <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
      </button>
    </div>
  </div>
);

const UNDEREMAIL = () => (
  <div className="vstack gap-2 mt-4">
    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={Telegramlogo}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold"> Bot Integration</h5>
        <p className="w-75">
          Picture this: automated tasks, instant responses, and personalised
          experiences for your users. It's a game-changer. Get ready to take
          your platform to the next level..
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={Telegramlogo}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold"> Enhanced Security</h5>
        <p className="w-75">
          Give customers the gratification of reaching you with their concerns
          at no extra cost to them..
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={Telegramlogo}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Channel Broadcast</h5>
        <p className="w-75">
          Make your business goals even bigger by automating your responses.
          Focus on those goals, and let us handle customers' messages.
        </p>
      </div>
    </div>

    <div></div>
    <div className="d-flex gap-2 pb-4">
      <button
        color="danger "
        className="btn rounded-pill btn-primary"
        onClick={() => ""}
      >
        Start for free
        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
      </button>

      <button
        color="success"
        className="btn rounded-pill btn-light text-primary btn-border border-primary margin-left-20px"
        onClick={() => ""}
      >
        Talk to an expert
        <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
      </button>
    </div>
  </div>
);

const UNDERSMS = () => (
  <div className="vstack gap-2 mt-4">
    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={Whatsapp}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Personalised Messages</h5>
        <p className="w-75">
          Use Brivas WhatsApp messaging API to send individualised messages to
          your audience on WhatsApp.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={Whatsapp}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">24/7 Customer Support</h5>
        <p className="w-75">
          Make your customers feel secure. With a 24/7 support system, you can
          attend to customers and get rewarding satisfaction from them as a
          result.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={Whatsapp}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Chatbot Integration</h5>
        <p className="w-75">
          Brivas WhatsApp API instant responses revolutionises your business
          support. Elevate your customer experience now!.
        </p>
      </div>
    </div>

    <div></div>
    <div className="d-flex gap-2">
      <button
        color="danger "
        className="btn rounded-pill btn-primary"
        onClick={() => ""}
      >
        Start for free
        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
      </button>

      <button
        color="success"
        className="btn rounded-pill btn-light text-primary btn-border border-primary margin-left-20px"
        onClick={() => ""}
      >
        Talk to an expert
        <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
      </button>
    </div>
  </div>
);

const SKYPEPAGE = () => (
  <div className="mt-3">
    <img
      className="card-img-top w-75 img-fluid"
      src={SKYPELOGO}
      alt="Card cap"
    />
  </div>
);

const TELEGRAM = () => (
  <div>
    <img
      className="card-img-top w-75 img-fluid"
      src={TELEGRAMIMAGE}
      alt="Card cap"
    />
  </div>
);

const INSTAGRAM = () => (
  <div className="pt-3">
    <img
      className="card-img-top mb-4 pt-0 img-fluid"
      src={INSTAIMAGE}
      alt="Card cap"
    />
  </div>
);

const SLACKPAGE = () => (
  <div>
    <img className="card-img-top w-75 img-fluid" src={SLACK} alt="Card cap" />
  </div>
);

const UNDERRCS = () => (
  <div className="vstack gap-2 mt-4">
    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={FBmsg}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold"> Instant Reach</h5>
        <p className="w-75">
          With the power of Brivas voice broadcasting, reach a large audience in
          a matter of seconds.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={FBmsg}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold"> No Cost to Customer</h5>
        <p className="w-75">
          Give customers the gratification of reaching you with their concerns
          at no extra cost to them.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={FBmsg}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Scalability</h5>
        <p className="w-75">
          Make your business goals even bigger by automating your responses.
          Focus on those goals, and let us handle customers' messages.
        </p>
      </div>
    </div>

    <div></div>
    <div className="d-flex gap-2  mb-3">
      <button
        color="danger "
        className="btn rounded-pill btn-primary"
        onClick={() => ""}
      >
        Start for free
        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
      </button>

      <button
        color="success"
        className="btn rounded-pill btn-light text-primary btn-border border-primary margin-left-20px"
        onClick={() => ""}
      >
        Talk to an expert
        <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
      </button>
    </div>
    <div></div>
  </div>
);

const UNDERSLACK = () => (
  <div className="vstack gap-2 mt-4">
    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={SLACKLOGO}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold"> Increased Productivity</h5>
        <p className="w-75">
          Say goodbye to a scattered workspace and hello to a focused,
          productive online work environment.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={SLACKLOGO}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold"> Unified Workspace</h5>
        <p className="w-75">
          Bring your team together in one central hub, simplifying communication
          and enabling efficient project management..
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={SLACKLOGO}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Real-Time Collaboration</h5>
        <p className="w-75">
          Share ideas, feedback, and changes instantly to keep everyone in sync.
        </p>
      </div>
    </div>

    <div></div>
    <div className="d-flex gap-2 mb-3">
      <button
        color="danger "
        className="btn rounded-pill btn-primary"
        onClick={() => ""}
      >
        Start for free
        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
      </button>

      <button
        color="success"
        className="btn rounded-pill btn-light text-primary btn-border border-primary margin-left-20px"
        onClick={() => ""}
      >
        Talk to an expert
        <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
      </button>
    </div>
  </div>
);

const UNDERSKYPE = () => (
  <div className="vstack gap-2 mt-4">
    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={SKYPE}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Global Calling Made Easy</h5>
        <p className="w-75">
          You can easily connect with coworkers and customers located all over
          the world using Skype.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={SKYPE}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Instant Messaging at Your Fingertips</h5>
        <p className="w-75">
          Communicate with your team, share files, and work together on
          projects.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={SKYPE}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Unified Communication Hub</h5>
        <p className="w-75">
          Bring together all your contacts, messages, and calls in one place,
          ensuring connections don't end.
        </p>
      </div>
    </div>

    <div></div>
    <div className="d-flex gap-2 mb-4">
      <button
        color="danger "
        className="btn rounded-pill btn-primary"
        onClick={() => ""}
      >
        Start for free
        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
      </button>

      <button
        color="success"
        className="btn rounded-pill btn-light text-primary btn-border border-primary margin-left-20px"
        onClick={() => ""}
      >
        Talk to an expert
        <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
      </button>
    </div>
  </div>
);

const tabs = [
  {
    title: "Whatsapp",
    header: "Tap into the most used platform in the world.",
    desc: " Looking to boost consumer satisfaction and company growth? Brivas WhatsApp API is your best chance of reaching your customers. Achieve fast engagement, appealing multimedia messaging, dynamic interactions, and secure communication.",
    link: "#telecom",
    component: <SMSIMAGE />,
    component2: <UNDERSMS />,
  },
  {
    title: "Facebook",
    header:
      "Gain your customer's trust and loyalty by interacting with them on a platform they are already using",
    desc: "With over 2 billion monthly users on Facebook, chances are your customer is already familiar with the app. Let Brivas Facebook Messenger API help you take your sales, marketing, and customer service journeys to the next level by meeting customers where they are.",
    link: "#education",
    component: <RCS />,
    component2: <UNDERIFACEBOOK />,
  },
  {
    title: "Telegram",
    header: "Foster dialogue with your audience",
    desc: " Ready to wield the power of SMS like a champion? Brivas next-generation messaging technology raises the bar for communication. Our approach allows for easy, compelling two-way conversations. ",
    link: "#healthcare",
    component: <TELEGRAM />,
    component2: <UNDEREMAIL />,
  },

  {
    title: "Skype",
    header: "Communicate like you are in the same room",
    desc: " Discover the next generation of online communication with Skype. Enhance the effectiveness of your communication strategy by making one phone call at a time.",
    link: "#telecom",
    component: <SKYPEPAGE />,
    component2: <UNDERSKYPE />,
  },

  {
    title: "Instagram",
    header: "Harness Instagram's API and transform your success strategy.",
    desc: "Reach and inspire customers on Instagram, one of the world's most popular social media platforms. Brivas Instagram API simplifies sending end users promotional, transactional, and conversational messages.",
    link: "#education",
    component: <INSTAGRAM />,
    component2: <UNDERINSTAGRAM />,
  },
  {
    title: "Slack",
    header: "Boost Collaboration with Slack",
    desc: " Say hello to Slack-boosted productivity. Chat, exchange files, work on projects, and integrate with your favourite tools in one spot. Embrace a united workspace to avoid dispersed communication. Improve teamwork today. ",
    link: "#healthcare",
    component: <SLACKPAGE />,
    component2: <UNDERSLACK />,
  },
];

const Homenew = () => {
  const history = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <React.Fragment>
      <section
        className="section bg-light pb-0"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: "#FFF5FF",
        }}
      >
        <Container className="">
          <Row className="justify-content-between align-items-center">
            <div>
              <h1 className="text-center fs-45 pt-4 text-dark mb-4 fw-bold">
                Meet customers on their favorite channels
              </h1>

              <div className="d-flex flex-wrap justify-items-center gap-1 w-20 ">
                {tabs.map((i, x) => (
                  // border-primary
                  <button
                    key={`tab-${x}`}
                    color="danger "
                    className={`btn border ${i?.title == tabs[selectedTab]?.title ? "" : "btn-light"} btn-primary`}
                    onClick={() => setSelectedTab(x)}
                  >
                    {i.title}
                  </button>
                ))}
              </div>
            </div>
          </Row>

          <div className="py-5">
            <Row>
              <Col lg={6}>
                <div style={{}}>
                  <h6 className="display-6 fw-bold mt-2 mb-3 text-dark fs-21 lh-base">
                    {tabs[selectedTab]?.header}
                  </h6>
                  <p className="mb-4">{tabs[selectedTab]?.desc}</p>
                  <a className="mt-4" href={tabs[selectedTab]?.link}></a>
                </div>
                {tabs[selectedTab].component2}
              </Col>
              <Col lg={6}>{tabs[selectedTab].component}</Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Homenew;
