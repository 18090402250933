import {
  SEND_VERIFICATION_CODE,
  SEND_VERIFICATION_CODE_SUCCESS,
  SEND_VERIFICATION_CODE_FAILED,
  RESET_ACCOUNT_PHONE_FLAG,
} from "./actionTypes";

const initialState = {
  msg: null,
  loading: false,
  error: false,
};

const AccountPhone = (state = initialState, action) => {

  switch (action.type) {
    case SEND_VERIFICATION_CODE:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case SEND_VERIFICATION_CODE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      };
      break;
    case SEND_VERIFICATION_CODE_FAILED:
      state = {
        ...state,
        msg: action.payload,
        loading: true,
        error: true,
      };
      break;
    case RESET_ACCOUNT_PHONE_FLAG:
      state = {
        ...state,
        msg: null,
        loading: false,
        error: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AccountPhone;
