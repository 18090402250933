import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Img1 from "../../assets/images/empowerimage.png";
import VoiceICON1 from "../../assets/images/voiceicon1.png";
import VoiceICON2 from "../../assets/images/voiceicon2.png";
import VoiceICON3 from "../../assets/images/voiceicon3.png";


const Banner3 = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section style={{ backgroundColor: "#FFF5FF" }}>
      <section className="section px-5" style={{ backgroundColor: "#FFF5FF" }}>
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={5} className="text-center mb-4">
              <img src={Img1} alt="BrivasMobile" className="img-fluid" />
            </Col>
            <Col xs={12} md={7} className="mb-4">
              <div className="mx-md-5 px-md-5">
                <h1 className="display-6 fw-bold w-100 mb-3 text-dark fs-40 lh-base">
                  Empower Your Business with Seamless Experience
                </h1>
                <p className="lead lh-base w-100 mb-4 text-dark">
                  Elevate your retail business with our innovative solution.
                  Streamline operations, enhance customer engagement, and drive
                  growth in the digital era. Experience the power of our
                  solution in revolutionizing retail experiences.
                </p>

                <div className="media d-flex mb-4">
                <img
                  className="align-self-start mx-2"
                  src={VoiceICON1}
                  alt="Generic placeholder image"
                />
                <div className="media-body ">
                  <h5 className="mt-0 fw-bold ">Omnichannel Integration</h5>
                </div>
              </div>
              <div className="media d-flex mb-4">
                <img
                  className="align-self-start mx-2"
                  src={VoiceICON2}
                  alt="Generic placeholder image"
                />
                <div className="media-body ">
                  <h5 className="mt-0 fw-bold ">API Integration</h5>
                </div>
              </div>
              <div className="media d-flex mb-4">
                <img
                  className="align-self-start mx-2"
                  src={VoiceICON3}
                  alt="Generic placeholder image"
                />
                <div className="media-body ">
                  <h5 className="mt-0 fw-bold ">Chatbots and Virtual Assistants </h5>
                </div>
              </div>

                <div className="d-flex gap-2 my-5">
                  <button
                    color="primary "
                    className="btn rounded-pill btn-primary"
                    onClick={() => "/register"}
                  >
                    Start for free
                  </button>
                  <button
                    color="success"
                    className="btn rounded-pill btn-light border-danger"
                    onClick={() => "/register"}
                  >
                    <span className="text-danger">Talk to an expert</span>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </section>
  );
};

export default Banner3;
