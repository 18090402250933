import { VERIFICATION_SMS_ERROR, VERIFICATION_SMS_SUCCESS, TEST_VERIFICATION_SMS, SEND_VERIFICATION_SMS } from "./actonType";

export const verificationSMSSuccess = (actionType, data) => {
  return {
    type: VERIFICATION_SMS_SUCCESS,
    payload: { actionType, data },
  };
};

export const verificationSMSError = (actionType, error) => {
  return {
    type: VERIFICATION_SMS_ERROR,
    payload: { actionType, error },
  };
};

// export const testVerificationSMS = (data) => ({
//   type: TEST_VERIFICATION_SMS,
//   payload: { data },
// });

export const sendVerificationSMS = (data) => ({
  type: SEND_VERIFICATION_SMS,
  payload: { data },
});