import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import img1 from "../../assets/images/svg/realtimeimage.svg";
import VoiceICON1 from "../../assets/images/voiceicon1.png";
import VoiceICON2 from "../../assets/images/voiceicon2.png";
import VoiceICON3 from "../../assets/images/voiceicon3.png";
import VOICEcard1 from "../../assets/images/streamingcol1.png";
import VOICEcard2 from "../../assets/images/streamingcol2.png";
import Voicelogo from "../../assets/images/svg/voiceimage.svg";

const Realtime = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <React.Fragment>
      <section
        className="section  fs-50"
        id="plans"
        style={{ backgroundColor: "#FFF5FF" }}
      >
        <div className=" opacity-100"></div>

        <div>
          <Row className="mt-5">
            <Col>
              <div class="card mx-4">
                <div class="row g-0">
                  <div class="col-md-4">
                    <img
                      src={VOICEcard1}
                      class="img-fluid rounded-start"
                      alt="..."
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title my-4 mx-4 fs-24 fw-500">
                        Make every interaction count
                      </h5>
                      <p class="card-text fs-16 fw-400 my-4 mx-4">
                        The quality of your entertainment experience is
                        important whether you're watching a movie, listening to
                        music, or watching a live broadcast.
                      </p>

                      <button
                        color="danger "
                        className="btn rounded-pill btn-primary d-flex justify-content-center"
                        onClick={() => history("/register")}
                      >
                        Start for free
                        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                      </button>
                      <br />
                      <button
                        color="success"
                        className="btn rounded-pill btn-light margin-left-20px border-danger d-flex justify-content-center"
                        onClick={() => history("/register")}
                      >
                        <span className="text-danger">Talk to an expert</span>
                        <i className="align-middle ms-1 margin-right-3rem  padding-left-30px rounded-pill"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div class="card mx-4">
                <div class="row g-0">
                  <div class="col-md-4">
                    <img
                      src={VOICEcard2}
                      class="img-fluid rounded-start"
                      alt="..."
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title my-4 mx-4 fs-24 fw-500">
                        Create engaging video content
                      </h5>
                      <p class="card-text fs-16 fw-400 my-4 mx-4">
                        From the latest blockbuster movies to exclusive series
                        and live events, our Video API is your gateway to
                        non-stop entertainment..
                      </p>

                      <button
                        color="danger "
                        className="btn rounded-pill btn-primary d-flex justify-content-center"
                        onClick={() => history("/register")}
                      >
                        Start for free
                        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                      </button>
                      <br />
                      <button
                        color="success"
                        className="btn rounded-pill btn-light border-danger margin-left-20px d-flex justify-content-center"
                        onClick={() => history("/register")}
                      >
                        <span className="text-danger">Talk to an expert</span>
                        <i className=" align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Realtime;
