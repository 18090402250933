import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

const OverviewTab = () => {
  const { account, app } = useSelector((state) => ({
    account: state.Account.account,
    app: state.App.app,
  }));

  return (
    <React.Fragment>
      <Card className="card-body shadow-none">
        <Row>
          <Col xxl={1.5} md={4}>
            <div className="mb-5">
              <Label htmlFor="valueInput" className="form-label">
                First name
              </Label>
              <Input
                type="text"
                className="form-control"
                id="valueInput"
                defaultValue={account?.first_name}
              />
            </div>
          </Col>

          <Col xxl={1.5} md={4}>
            <div className="mb-5">
              <Label htmlFor="valueInput" className="form-label">
                Last name
              </Label>
              <Input
                type="text"
                className="form-control"
                id="valueInput"
                defaultValue={account?.last_name}
              />
            </div>
          </Col>
          <Col xxl={1.5} md={4}>
            <div className="mb-4">
              <Label htmlFor="valueInput" className="form-label">
                Username
              </Label>
              <Input
                type="text"
                className="form-control"
                id="valueInput"
                defaultValue="Ayomide22"
              />
            </div>
          </Col>
          <Col xxl={1.5} md={4}>
            <div className="mb-4">
              <Label htmlFor="valueInput" className="form-label">
                Mobile number
              </Label>
              <Input
                type="text"
                className="form-control"
                id="valueInput"
                defaultValue="+1 650 513 0574"
              />
              <div className="mt-0 d-flex">
                <i className="text-success fw-bold ri-checkbox-circle-line"></i>
                <span className="fs-11">Verified</span>
              </div>

              <div className="text-primary fw-bold mt-4">
                {" "}
                <p>Change</p>
              </div>
            </div>
          </Col>
          <Col xxl={1.5} md={4}>
            <div className="mb-4">
              <Label htmlFor="valueInput" className="form-label">
                Email
              </Label>
              <Input
                type="text"
                className="form-control"
                id="valueInput"
                defaultValue="Ayomideodunsi@gmail.com"
              />
              <div className="mt-0 d-flex">
                <i className="text-success fw-bold ri-checkbox-circle-line"></i>
                <span className="fs-11">Verified</span>
              </div>
            </div>
          </Col>
          <Col xxl={1.5} md={4}></Col>
          <div className="mb-4"></div>

          <Col xxl={1.5} md={4}>
            <div className="mb-3">
              <Label htmlFor="valueInput" className="form-label">
                Password Changed
              </Label>
              <Input
                type="text"
                className="form-control"
                id="valueInput"
                defaultValue="15/03/2023"
              />
            </div>
            <div className="text-primary fw-bold">
              <p>Change password</p>
            </div>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};

export default OverviewTab;
