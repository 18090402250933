import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";

import SMS from "../../assets/images/svg/SMS-Healthcare-icon.png";
import RC from "../../assets/images/svg/RC-Healthcare-icon.png";
import Socials from "../../assets/images/svg/Socials-Healthcare-icon.png";
import Bannerimg from "../../assets/images/svg/Healthcare-banner1.png";

const Scalable = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section style={{ backgroundColor: "#FFF5FF" }}>
      <div className="mx-md-5 px-3 px-md-5">
        <div class="row">
          <div class="col-md-6 col-sm-12 order-2 order-md-1">
            <h1 className="display-6 fw-bold fs-48 lh-base text-sm-center text-md-start mt-md-5">
              Elevate the patient journey with interactive messaging
            </h1>
            <p className="mb-5 text-justify">
              Through the power of BRIVAS, healthcare providers can remotely
              administer vital healthcare experiences using various
              communication channels such as SMS, RCS, social media, chatbots,
              and USSD.
            </p>
            <div className="media d-flex mb-4">
              <img
                className="align-self-start mx-2"
                src={SMS}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">SMS & USSD</h5>
                <p>
                  Integrating SMS & USSD for appointment reminders, medication
                  alerts, and health information can be an effective way to
                  reach patients and provide them with important updates.
                </p>
              </div>
            </div>
            <div className="media d-flex mb-4">
              <img
                className="align-self-start mx-2"
                src={RC}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">RCS</h5>
                <p>
                  Enhance messaging with interactive and multimedia content.
                  Work with platforms or mobile network operators that support
                  RCS.
                </p>
              </div>
            </div>
            <div className="media d-flex mb-4">
              <img
                className="align-self-start mx-2"
                src={Socials}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">Socials</h5>
                <p>
                  Engage patients and share healthcare information through
                  platforms like Facebook, Twitter, and Instagram
                </p>
              </div>
            </div>
            <div className="media d-flex mb-3">
              <img
                className="align-self-start mx-2"
                src={SMS}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">chatbots</h5>
                <p>
                  Implement a chatbot for automated and personalized patient
                  support. Use a chatbot platform on websites, messaging apps,
                  or mobile applications.
                </p>
              </div>
            </div>

            <div className="d-flex gap-2 my-5">
              <button
                color="primary "
                className="btn rounded-pill btn-danger"
                onClick={() => "/register"}
              >
                Start for free
          
              </button>
              <button
                color="success"
                className="btn rounded-pill btn-light border-primary"
                onClick={() => history("/register")}
              >
                <span className="text-primary">Talk to an expert</span>
              </button>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 my-5 d-flex align-items-center justify-content-center order-1">
            <img src={Bannerimg} alt="" className="user-img w-75" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Scalable;
