import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import VerticalLayout from "../Layouts/index";

import { authRoutes } from "./allRoutes";
import { useSelector } from 'react-redux';

const AuthProtected = (props) => {

    const { account, isResolving } = useSelector(state => ({
        account: state.Account.account,
        isResolving: state.Account.isResolving,
    }));

    if (!account && isResolving) {
        return <>Loading...</>
    } else if (!account && !isResolving) {
        return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    }

    return (
        <Routes>
            <Route>
                {/* {authRoutes[account?.type]?.map((route, idx) => ( */}
                {authRoutes.map((route, idx) => (
                    <Route
                        path={route.path}
                        element={<VerticalLayout>{route.component}</VerticalLayout>}
                        key={idx}
                        exact={true}
                    />
                ))}
            </Route>
        </Routes>
    );
};

export default AuthProtected;