import {
  LIST_APPS, LIST_APPS_ERROR, LIST_APPS_SUCCESS,
} from "./actionType";

const INIT_STATE = {
  apps: [],
  errorMsg: "",
  loading: true,
  error: false,
  success: false,
};

const Apps = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_APPS:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case LIST_APPS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        success: true,
        apps: action.payload.data,
      };
      break;
    case LIST_APPS_ERROR:
      state = {
        ...state,
        errorMsg: action.payload.msg,
        loading: false,
        error: true,
        success: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;

};
export default Apps;