import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Img1 from "../../assets/images/svg/brivasmobilephoneimage-min.png";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Row,
} from "reactstrap";
import SMSTips from "../../Components/SMSTips";

const Tips = () => {
  return (
    <React.Fragment>
      <section className="section px-5" style={{ backgroundColor: "#FFF5FF" }}>
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={5} className="text-center mb-4">
              <img src={Img1} alt="BrivasMobile" className="img-fluid" />
            </Col>
            <Col xs={12} md={7} className="mb-4">
              <div className="mx-md-5 px-md-5">
                <h1 className="display-6 fw-bold w-100 mb-3 text-dark fs-40 lh-base">
                  Embark on a <span className="text-primary">streaming</span>{" "}
                  journey like never before
                </h1>
                <p className="lead lh-base w-100 mb-4 text-dark">
                  Maximize value, and minimize costs. Our innovative tools adapt
                  to your needs. Plus, 24/7 customer support clears doubts and
                  answers queries. Choose smarter solutions, choose us!
                </p>

                <div className="d-flex gap-2 my-5">
                  <button
                    color="primary "
                    className="btn rounded-pill btn-primary"
                    onClick={() => "/register"}
                  >
                    Start for free
                  </button>
                  <button
                    color="success"
                    className="btn rounded-pill btn-light border-danger"
                    onClick={() =>("/register")}
                  >
                    <span className="text-danger">Talk to an expert</span>
                  </button>
                </div>
              </div>
            </Col>
          </Row>

          <div className="text-center my-5 py-3">
            <h1 className="fw-semibold text-dark">
              Tips to find the right SMS provider for you
            </h1>
          </div>

          <SMSTips />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Tips;
