import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

import img1 from "../../assets/images/svg/Non-profit-banner2.svg";

const Secondbanner = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    
      <div
        className="card text-light my-4 mx-5 px-5 py-4 bg-primary"
        style={{}}
      >
        <div class="row">
          <div class="col-md-12 col-sm-12 d-flex align-items-center">
            <div className="text-center" style={{ width: "100%" }}>
              <h1 className="mb-3 fs-47 text-light">
                Benefits of Brivas for Non-Profit Organizations
              </h1>
              <p class="card-text mb-4 ff-secondary">
                BRIVAS provides SMS, RCS, and social media integration to help
                nonprofits reach and engage with their supporters effectively.
                This is particularly useful for sending updates, event
                invitations, and donation requests.
              </p>
            </div>
          </div>
          <div className="col-md-12 col-sm-12 my-5 text-center d-md-block">
            <img src={img1} alt="" className="img-fluid w-75" />
          </div>
        </div>
      </div>
 
  );
};

export default Secondbanner;
