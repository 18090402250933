import React from "react";
import { Navigate } from "react-router-dom";

import Login from "../pages/Authentication/Login";
import Register from "../pages/Authentication/Register";

import Landing from "../pages/Landing";

import Apps from "../pages/apps";
import SelectedApp from "../pages/SelectedApp";
import CreateApp from "../pages/CreateApp";
import Dashboard from "../pages/Dashboard";
import Notifications from "../pages/notifications";

import PendingVerification from "../pages/Authentication/PendingVerification";
import CreateAccount from "../pages/Authentication/CreateAccount";
import VerifyAccount from "../pages/Authentication/VerifyAccount";
import AccountPhone from "../pages/Authentication/AccountPhone";
import AccountVerify from "../pages/Authentication/AccountVerify";

//Bulk SMS
import BulkSMSGetStarted from "../pages/BulkSMS/GetStarted";
import BulkSMSInsight from "../pages/BulkSMS/Insight";
import BulkSMSCreateSms from "../pages/BulkSMS/CreateSMS";
import BulkSMSStatus from "../pages/BulkSMS/SMSStatus";
import BulkSMSQueued from "../pages/BulkSMS/QueuedMessages";
import BulkSMSSent from "../pages/BulkSMS/SentMessages";
import BulkSMSScheduled from "../pages/BulkSMS/ScheduledSMS";
import BulkSMSDrafts from "../pages/BulkSMS/Drafts";
import BulkSMSDeliveryReports from "../pages/BulkSMS/DeliveryReports";
import BulkSMSDownloadReport from "../pages/BulkSMS/DownloadReports";



//Admin side
import Overview from "../pages/Overview/index";
import Dashboardadmin from "../pages/Dashboardadmin/index";
import Users from "../pages/Users/index";
import Usersadmin from "../pages/Users/userdetails";
import Pendingusers from "../pages/Users/pendingusers";
import Reports from "../pages/Reports";
import SID from "../pages/SID/index";



//Verification\
import Verifyauser from "../pages/Verifications/verifyauser";
import Flashcall from "../pages/Verifications/flash-call";
import Insight from "../pages/Verifications/index";



import QRLogin from "../pages/QRAuth/QRLogin";
import Product from "../pages/Product";
import Profile from "../pages/Profile";
import SendAnSms from "../pages/GettingStarted/SendAnSms";
import MakeAVoicecall from "../pages/GettingStarted/makeavoicecall";
import Lookupanumber from "../pages/GettingStarted/lookupanumber";
import Createaconversation from "../pages/GettingStarted/createaconversation";
import Createameeting from "../pages/GettingStarted/createameeting";
import Messaging from "../pages/Messaging";
import Retail from "../pages/Retail";
import Telehealth from "../pages/telehealth";
import Startup from "../pages/Startup";
import Voice from "../pages/Voice";
import Enterprise from "../pages/Enterprise";
import Streaming from "../pages/Streaming";
import Emaill from "../pages/Emaill";
import NumbersAndConnectivity from "../pages/NumbersAndConnectivity";
import Application from "../pages/Application";
import Nonprofit from "../pages/Nonprofit";
import Telecommunication from "../pages/Telecommunication";
import Partners from "../pages/Partners";
import Ecommerce from '../pages/E-commerce';
import Verification from "../pages/verification";
import Education from "../pages/Education";
import Financial from "../pages/Financial";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";

// const serviceProviderRoutes = [
//   { path: "/dashboard", component: <Dashboard /> },
//   { path: "/apps", component: <Apps /> },
//   { path: "/send-an-sms", component: <SendAnSms /> },
//   { path: "/insight", component: <Insight /> },
//   { path: "/createsms", component: <CreateSms /> },
//   { path: "/make-a-voice-call", component: <MakeAVoicecall /> },
//   { path: "/verify-a-user", component: <Verifyauser /> },
//   { path: "/look-up-a-number", component: <Lookupanumber /> },
//   { path: "/create-a-conversation", component: <Createaconversation /> },
//   { path: "/create-a-meeting", component: <Createameeting /> },
//   { path: "/bulk-sms/get-started", component: <BulkSMS/> },

//   { path: "/app/:slug", component: <SelectedApp /> },
//   { path: "/app/create", component: <CreateApp /> },
//   { path: "/notifications", component: <Notifications /> },
//   { path: "/profile", component: <Profile /> },
//   { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
//   { path: "*", component: <Navigate to="/dashboard" /> },
// ];

// const contentOwnerRoutes = [
//   { path: "/dashboard", component: <Dashboard /> },
//   { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
//   { path: "*", component: <Navigate to="/dashboard" /> },
// ];

// const personalRoutes = [
//   { path: "/dashboard", component: <Dashboard /> },
//   { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
//   { path: "*", component: <Navigate to="/dashboard" /> },
// ];

// const authRoutes = {
//   'Service Provider': serviceProviderRoutes,
//   'Content Owner': contentOwnerRoutes,
//   'Personal': personalRoutes
// };

const authRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/apps", component: <Apps /> },
  { path: "/send-an-sms", component: <SendAnSms /> },
  { path: "/make-a-voice-call", component: <MakeAVoicecall /> },
  { path: "/look-up-a-number", component: <Lookupanumber /> },
  { path: "/create-a-conversation", component: <Createaconversation /> },
  { path: "/create-a-meeting", component: <Createameeting /> },

  //Bulk SMS
  { path: "/bulk-sms/get-started", component: <BulkSMSGetStarted /> },
  { path: "/bulk-sms/insight", component: <BulkSMSInsight /> },
  { path: "/bulk-sms/create-sms", component: <BulkSMSCreateSms /> },
  { path: "/bulk-sms/sms-status", component: <BulkSMSStatus /> },
  { path: "/bulk-sms/queued-messages", component: <BulkSMSQueued /> },
  { path: "/bulk-sms/sent-messages", component: <BulkSMSSent /> },
  { path: "/bulk-sms/scheduled-sms", component: <BulkSMSScheduled /> },
  { path: "/bulk-sms/drafts", component: <BulkSMSDrafts /> },
  { path: "/bulk-sms/delivery-reports", component: <BulkSMSDeliveryReports /> },
  { path: "/bulk-sms/download-reports", component: <BulkSMSDownloadReport /> },


  //Admin path side
  { path: "/overview", component: <Overview/> },
  { path: "/dashboardadmin", component: <Dashboardadmin/> },
  { path: "/users", component: <Users/> },
  { path: "/users/admin", component: <Usersadmin/> },
  { path: "/users/admin/pending", component: <Pendingusers/> },
  { path: "/sID", component: <SID/> },
  { path: "/reports", component: <Reports/> },



  //Verification
  { path: "/verification/sms-otp", component: <Verifyauser /> },
  { path: "/verification/flash-call", component: <Flashcall /> },
  { path: "/verification/insight", component: <Insight /> },

  { path: "/app/:slug", component: <SelectedApp /> },
  { path: "/app/create", component: <CreateApp /> },
  { path: "/notifications", component: <Notifications /> },
  { path: "/profile", component: <Profile /> },
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/qr/login", component: <QRLogin /> },
  { path: "/login", component: <Login /> },

  { path: "/", component: <Landing /> },
  { path: "/index", component: <Landing /> },
  { path: "/messaging", component: <Messaging /> },
  { path: "/enterprise", component: <Enterprise /> },
  { path: "/non-profit", component: <Nonprofit /> },
  { path: "/telecommunication", component: <Telecommunication /> },
  { path: "/partners", component: <Partners /> },
  { path: "/ecommerce", component: <Ecommerce /> },
  { path: "/verification", component: <Verification /> },
  { path: "/education", component: <Education /> },
  { path: "/Financial", component: <Financial /> },




  { path: "/retail", component: <Retail /> },
  { path: "/telehealth", component: <Telehealth /> },
  { path: "/startup", component: <Startup /> },
  { path: "/voice", component: <Voice /> },
  { path: "/streaming", component: <Streaming /> },
  { path: "/email", component: <Emaill /> },
  { path: "/numbers-and-Connectivity", component: <NumbersAndConnectivity /> },
  { path: "/applications", component: <Application /> },
  { path: "/products/:slug", component: <Product /> },

  { path: "/register", component: <Register /> },
  { path: "/pending", component: <PendingVerification /> },
  { path: "/account/create", component: <CreateAccount /> },
  { path: "/account/phone", component: <AccountPhone /> },
  { path: "/account/verify", component: <AccountVerify /> },
  { path: "/account/verified", component: <VerifyAccount /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "*", component: <Navigate to="/login" /> },
];

export { authRoutes, publicRoutes };
