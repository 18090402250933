import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Img1 from "../../assets/images/svg/medicine image.svg";
import Img2 from "../../assets/images/svg/Voice-carousel-img.png";
import Img3 from "../../assets/images/svg/Contactcenter-carousel-img.png";
import Img4 from "../../assets/images/svg/Verification-carousel-img.png";

const tabs = [
  {
    title: "Virtual Care",
    header: "Virtual Care",
    desc: "Immerse yourself in the world of convenient healthcare with our cutting-edge Healthcare - Health App. Designed to empower patients, our innovative solution provides seamless access to healthcare services at the touch of a button. With our intuitive in-app video calling feature, patients can effortlessly engage in virtual consultations, fostering a personalized and effective care experience that transcends physical boundaries. Experience the future of healthcare delivery with our feature-rich app, designed to prioritize your well-being and convenience.",
    pic: Img1,
  },
  {
    title: "Voice",
    header: "Voice",
    desc: "Brivas is a leader in healthcare delivery, driven by our commitment to advancement. We seamlessly integrate IVR technology into our solutions, revolutionizing the way healthcare services are delivered. By combining real-time interactions, secure communication channels, and the transformative power of IVR, we empower healthcare providers to operate with unmatched efficiency and effectiveness. Join us in embracing the future of healthcare with Brivas, where seamless care experiences become a reality.",
    pic: Img2,
  },
  {
    title: "Contact Center",
    header: "Contact Center",
    desc: "Enhance patient satisfaction with our streamlined Contact Centre, facilitating consultations, follow-ups, and referrals with efficiency. Rest assured, we prioritize patient data security and privacy at all times. Our Contact Centre platform empowers healthcare providers to elevate patient satisfaction, improve customer service, and enhance care coordination, ensuring a comprehensive and seamless healthcare experience. Trust us to optimize your patient interactions and deliver excellence in healthcare delivery.",
    pic: Img3,
  },
  {
    title: "Verification",
    header: "Verification",
    desc: "We prioritize the security and confidentiality of sensitive health information by integrating robust Verification (2FA) measures into our solution. With a strong emphasis on Verification (2FA), we ensure the utmost protection and privacy of patient data, instilling trust and confidence among users. Our healthcare solution takes security seriously, providing a secure environment where patients' information remains safeguarded, allowing healthcare providers to deliver exceptional care with peace of mind. Count on us to prioritize security and confidentiality while delivering a seamless and reliable healthcare experience",
    pic: Img4,
  },
];

const Scalable = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <section style={{ backgroundColor: "#FFF5FF" }}>
      <div className="">
        <div className="">
          <section className="section">
            <Row className="">
              <Col
                sm={12}
                lg={12}
                className="text-center d-flex gap-3 flex-column"
              >
                <h1 className=" fs-48 pt-4 text-dark mb-4 fw-bold">
                  Envision an <span className="text-primary">Experience</span>{" "}
                  with no Interruptions.
                </h1>

                <div className=" gap-2 w-20 ">
                  {tabs.map((i, x) => (
                    // border-primary
                    <button
                      style={{ borderRadius: "18px" }}
                      key={`tab-${x}`}
                      color="danger "
                      className={`btn border mx-3 my-2 ${
                        i?.title == tabs[selectedTab]?.title ? "" : "btn-light"
                      } btn-primary`}
                      onClick={() => setSelectedTab(x)}
                    >
                      {i.title}
                    </button>
                  ))}
                </div>
              </Col>
            </Row>
          </section>

          <section className="">
            <div className="mx-md-5 px-3 px-md-5">
              <div className="">
                <Row>
                  <Col lg={6} sm={12}>
                    <div style={{}}>
                      <h6 className="display-6 fw-bold mt-2 mb-3 text-dark fs-21 lh-base">
                        {tabs[selectedTab]?.header}
                      </h6>
                      <p className="mb-4 lh-base w-100">{tabs[selectedTab]?.desc}</p>
                      <div className="d-flex gap-2">
                        <button
                          color="danger "
                          className="btn rounded-pill btn-primary"
                          onClick={() => ""}
                        >
                          Start for free
                          <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                        </button>

                        <button
                          color="success"
                          className="btn rounded-pill btn-light text-primary btn-border border-primary margin-left-20px"
                          onClick={() => ""}
                        >
                          Talk to an expert
                          <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
                        </button>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} sm={12}>
                    <div className="pt-3">
                      <img
                        className="card-img-top mb-4 pt-0 img-fluid w-75"
                        src={tabs[selectedTab]?.pic}
                        alt="Card cap"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default Scalable;
