import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import icon1 from "../../assets/images/svg/Telecom-icon1.svg";
import icon2 from "../../assets/images/svg/Telecom-icon2.svg";
import icon3 from "../../assets/images/svg/Telecom-icon3.svg";
import icon4 from "../../assets/images/svg/Telecom-icon4.svg";
import TOBI from "../../assets/images/tobifinancial.png";

const Bottom = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section>
      <div className="" style={{ marginBottom: "120px" }}>
        <div className="mx-md-5 px-3 px-md-5">
          <div class="row">
            <div class="col-md-6 pt-4 col-sm-12">
              <h1 className="display-6 text-dark fw-bold fs-28 lh-base">
                Consistent interaction and
                <span className="text-danger"> financial </span> services
                across all channels
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 order-2 order-md-1 my-3">
              <p className="mb-5">
                Financial service solutions leverage SMS (transactional &
                promotional), USSD, socials and email functionalities to enhance
                customer communication and engagement. Deliver timely
                notifications, enable convenient banking services, and
                personalize communication. Strengthen customer relationships and
                drive business growth.
              </p>
              <div className="d-flex gap-2 my-5">
                <button
                  color="primary "
                  className="btn rounded-pill btn-primary"
                  onClick={() => "/register"}
                >
                  Start for free
                  <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                </button>
                <button
                  color="success"
                  className="btn rounded-pill btn-light border-danger"
                  onClick={() => history("/register")}
                >
                  <span className="text-danger">Talk to an expert</span>
                </button>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 voip order-1 d-flex align-items-start justify-content-center">
              <img src={TOBI} alt="" className="user-img w-75 mx-md-5" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bottom;
