import {
  VERIFY_ACCOUNT,
  VERIFY_ACCOUNT_FAILED,
  VERIFY_ACCOUNT_SUCCESS,
} from "./actionTypes";

export const verifyAccount = (token, history) => {

  return {
    type: VERIFY_ACCOUNT,
    payload: { token, history },
  };
};

export const verifyAccountSuccess = data => {
  return {
    type: VERIFY_ACCOUNT_SUCCESS,
    payload: data,
  };
};

export const verifyAccountError = error => {
  return {
    type: VERIFY_ACCOUNT_FAILED,
    payload: error,
  };
};
