import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

import img1 from "../../assets/images/svg/worldmapandphone-min.png";

const Scalable = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section>
      <div className=" p-4 pt-4 py-4">
        <Row className="align-items-center gy-4">
          <Col lg={6} className="order-2 order-lg-1">
            <div className="text-bold fs-50 pt-5 py-5 p-5">
              <h1 className="mb-3">
                Send targeted
                <br />
                SMS and boost ROI
                <br />
                automation
              </h1>
              <p className="mb-4 ff-secondary">
                We are delivering you a world of captivating TV
                <br />
                shows, Videos calls, and more.
              </p>

              <Row>
                <Col sm={5}></Col>
              </Row>

              <div className="d-flex pt-5 gap-2">
                <button
                  color="danger "
                  className="btn rounded-pill btn-primary"
                  onClick={() => history("/register")}
                >
                  Start for free
                  <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                </button>
              </div>
            </div>
          </Col>

          <Col lg={6} sm={7} className="col-10 ms-auto pt-5 order-1 order-lg-2">
            <div>
              <img src={img1} alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>

        <div className="text-center mb-5">
          <h1 className="mb-1 ff-secondary fw-bold text-primary lh-base">
            Connect With Your Audience Amplify Your Message
          </h1>
        </div>
      </div>
    </section>
  );
};

export default Scalable;
