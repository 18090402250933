import { SEND_UPLOAD_CONTACT,UPLOAD_CONTACT_ERROR,UPLOAD_CONTACT_SUCCESS } from "./actonType";

export const uploadContactSuccess = (actionType, data) => {
  return {
    type: UPLOAD_CONTACT_SUCCESS,
    payload: { actionType, data },
  };
};

export const uploadContactError = (actionType, error) => {
  return {
    type: UPLOAD_CONTACT_ERROR,
    payload: { actionType, error },
  };
};

export const sendUploadContact = (data) => ({
  type: SEND_UPLOAD_CONTACT,
  payload: { data },
});

