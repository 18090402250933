import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Image1 from "../../assets/images/svg/firstimage.svg";
import Image2 from "../../assets/images/svg/Secondimage.svg";
import Image3 from "../../assets/images/svg/thirdimage.svg";
import Image4 from "../../assets/images/svg/fourthimage.svg";
import icon1 from "../../assets/images/enhanchedbrimail1.png";
import icon2 from "../../assets/images/svg/Telecom-icon2.svg";
import icon3 from "../../assets/images/svg/Telecom-icon3.svg";
import icon4 from "../../assets/images/svg/Telecom-icon4.svg";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Row,
} from "reactstrap";

const Smsretail = () => {
  return (
    <React.Fragment>
      <section className="section" id="plans"style={{ backgroundColor: "#FFF5FF" }}>
        <Container>
          <div className="text-center mt-3 mb-5">
            <h2 className="mb-1 fw-bold text-dark mt-4 mb-3 lh-base">
             <span className=" text-primary"> Stock </span>  Keeping Unit (SKU) Management
            </h2>
          </div>
          <Row>
            <div className="d-flex justify-content-around">
              <div>
                <Card
                  className=""
                  style={{
                    borderRadius: "20px",
                    boxShadow: "10px 10px",
                    width: "276px",
                    height: "351px",
                  }}
                >
                  <img
                    className="pt-2"
                    src={icon1}
                    alt="Card cap"
                    width="40px"
                    height="40px"
                  />
                  <CardBody>
                    <h4 className="card-title ft-bold mb-3 mt-4 fw-bolder fs-24">
                      {" "}
                      SMS OTP
                    </h4>
                    <p className="card-text text-muted">
                      Thanks to our automatic SMS verification, your users don’t
                      need to enter codes manually. Keep your customers
                      protected with Brivas SMS verification.
                    </p>
                    <div className="text-end mt-5">
                      <Link to="#" className="soft-primary">
                        Sign up
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div>
                <Card
                  style={{
                    borderRadius: "20px",
                    boxShadow: "10px 10px",
                    width: "276px",
                    height: "351px",
                  }}
                >
                  <img
                    className="card-img-top pt-3"
                    style={{ width: 40, height: 40 }}
                    src={icon2}
                    alt="Card cap"
                  />
                  <CardBody style={{ width: "276px" }}>
                    <h4 className="card-title ft-bold mb-3 mt-4 fw-bolder fs-24">
                      Flash Call
                    </h4>
                    <p className="card-text text-muted">
                      Brivas Flash Call is not only quick but also affordable
                      and automatic. Verify users during account creation and
                      verify transactions easily.
                    </p>
                    <div className="text-end">
                      <Link to="#" className="soft-primary w-50 mx-auto">
                        Sign up
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div>
                <Card
                  style={{
                    borderRadius: "20px",
                    boxShadow: "10px 10px",
                    width: "276px",
                    height: "351px",
                  }}
                >
                  <img
                    className="card-img-top pt-3"
                    style={{ width: 40, height: 40 }}
                    src={icon3}
                    alt="Card cap"
                  />
                  <CardBody style={{ width: "276px" }}>
                    <h4 className="card-title ft-bold mb-3 mt-4  fw-bolder fs-24">
                      Verification API
                    </h4>
                    <p className="card-text text-muted">
                      Do you want to enjoy swift transactions? Our instant
                      one-time passwords and two-factor authentication are
                      delivered for enhanced security.
                    </p>
                    <div className="text-end">
                      <Link to="#" className="soft-primary w-50 mx-auto">
                        Sign up
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div>
                <Card
                  style={{
                    borderRadius: "20px",
                    boxShadow: "10px 10px",
                    width: "276px",
                    height: "351px",
                  }}
                >
                  <img
                    className="card-img-top pt-3"
                    style={{ width: 40, height: 40 }}
                    src={icon4}
                    alt="Card cap"
                  />
                  <CardBody style={{ width: "276px" }}>
                    <h4 className="card-title ft-bold mb-3 mt-4 fw-bolder fs-24">
                      Voice Call
                    </h4>
                    <p className="card-text text-center text-muted">
                      By leveraging advanced text-to-speech software, we provide
                      you with a seamless and trustworthy way to authenticate
                      individuals and prevent fraud.
                    </p>
                    <div className="text-end">
                      <Link to="#" className="soft-primary mx-auto">
                        Sign up
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
            <Row />

            {/* <Col sm={6} xl={3}>
               <Card>
                  <img className="card-img-top img-fluid" src={Image1} alt="Card cap" />
                      <CardBody>
                         <h4 className="card-title text-center ft-bold mb-2">Location Based Sms</h4>
                            <p className="card-text">Its a brave new world Customer experience </p>
                            <div className="text-end">
                            <Link to="#" className="soft-primary w-50 mx-auto d-flex justify-content-center">
                              Learn more
                              <i class="ri-arrow-right-line"></i>
                            </Link>
                          </div>
                       </CardBody>
              </Card>
           </Col>
           <Col sm={6} xl={3}>
               <Card>
                  <img className="card-img-top img-fluid" src={Image2} alt="Card cap" />
                      <CardBody>
                         <h4 className="card-title text-center mb-2">Guide</h4>
                            <p className="card-text">Its a brave new world Customer experience </p>
                            <div className="text-end">
                            <Link to="#" className="soft-primary w-50 mx-auto d-flex justify-content-center">
                              Learn more
                              <i class="ri-arrow-right-line"></i>
                            </Link>
                          </div>
                       </CardBody>
              </Card>
           </Col>
           <Col sm={6} xl={3}>
               <Card>
                  <img className="card-img-top shadow-lg img-fluid" src={Image3} alt="Card cap" />
                      <CardBody>
                         <h4 className="card-title text-center mb-2">Bulk SMS</h4>
                            <p className="card-text">Its a brave new world Customer experience </p>
                            <div className="text-end">
                            <Link to="#" className="soft-primary w-50 mx-auto d-flex justify-content-center">
                              Learn more
                              <i class="ri-arrow-right-line"></i>
                            </Link>
                          </div>
                       </CardBody>
              </Card>
           </Col>
           <Col sm={6} xl={3}>
               <Card>
                  <img className="card-img-top img-fluid" src={Image4} alt="Card cap" />
                      <CardBody>
                         <h4 className="card-title text-center mb-2 mx-auto">Transactional SMS</h4>
                            <p className="card-text text-center">Its a brave new world Customer experience </p>
                            <div className="text-end">
                            <Link to="#" className="soft-primary w-50 mx-auto d-flex justify-content-center">
                              Learn more
                              <i class="ri-arrow-right-line"></i>
                            </Link>
                          </div>
                       </CardBody>
              </Card>
           </Col> */}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Smsretail;
