import {
  FLASH_CALL_ERROR,
  FLASH_CALL_SUCCESS,
  TEST_FLASH_CALL,
  VERIFY_FLASH_CALL,
} from "./actonType";

const initialState = {
  loading: false,
  error: null,
};

const FlashCall = (state = initialState, action) => {
  switch (action.type) {
    case FLASH_CALL_SUCCESS:
      switch (action.payload.actionType) {
        case TEST_FLASH_CALL:
          return { ...state, loading: false };
        case VERIFY_FLASH_CALL:
          return { ...state, loading: false };
        default:
          return { ...state };
      }
    case FLASH_CALL_ERROR:
      switch (action.payload.actionType) {
        case TEST_FLASH_CALL:
          return { ...state, loading: false };
        case VERIFY_FLASH_CALL:
          return { ...state, loading: false };
        default:
          return { ...state };
      }
    case TEST_FLASH_CALL:
      return { ...state, loading: true };
    case VERIFY_FLASH_CALL:
      return { ...state, loading: true };
    default:
      return state;
  }
};

export default FlashCall;
