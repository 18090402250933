import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { VERIFY_ACCOUNT } from "./actionTypes";
import { verifyAccountSuccess, verifyAccountError } from "./actions";

import { postVerifyAccount } from "../../../helpers/backend_helper";

function* verifyOTP({ payload: { token } }) {
  try {
    const resp = yield call(postVerifyAccount, { token });
    if (resp.status === "success") {
      yield put(verifyAccountSuccess(resp));
    } else {
      yield put(verifyAccountError(resp));
    }
  } catch (error) {
    yield put(verifyAccountError(error));
  }
}

function* verifyAccountSaga() {
  yield takeEvery(VERIFY_ACCOUNT, verifyOTP);
}

export default verifyAccountSaga;
