import React, { useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ParticlesAuth from "./ParticlesAuth";

//import images
import { verifyAccount } from '../../store/actions';
import withRouter from '../../Components/Common/withRouter';

const VerifyAccount = (props) => {
    document.title = "BRIVAS | Verified";

    const history = useNavigate();
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        dispatch(verifyAccount(searchParams.get('token')));
    }, [searchParams, dispatch, props.router.navigate]);

    const { error, success } = useSelector(state => ({
        success: state.VerifyAccount.success, 
        error: state.VerifyAccount.error
    }));

    useEffect(() => {
        if (success) {
            // setTimeout(() => history("/account/create"), 2000);
            history("/account/create");
        }
    }, [dispatch, success, error, history]);

    return (
        <React.Fragment>
            <div className="auth-page-wrapper">
                <ParticlesAuth>
                    <div className="auth-page-content">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                                        {/* <div>
                                            <Link to="/dashboard" className="d-inline-block auth-logo">
                                                <img src={logoLight} alt="" height="20" />
                                            </Link>
                                        </div> */}
                                        {/* <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p> */}
                                    </div>
                                </Col>
                            </Row>

                            <Row className="justify-content-center">
                                <Col md={8} lg={6} xl={5}>
                                    <Card className="mt-4">
                                        <CardBody className="p-4 text-center">
                                            <div className="avatar-lg mx-auto mt-2">
                                                <div className="avatar-title bg-light text-success display-3 rounded-circle">
                                                    <i className="ri-checkbox-circle-fill"></i>
                                                </div>
                                            </div>

                                            <div className="p-2 mt-4">
                                                <div className="text-muted text-center mb-4 mx-lg-3">
                                                    <h4 className="fs-16">Account Verified</h4>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </ParticlesAuth>
            </div>
        </React.Fragment >
    );
};

export default withRouter(VerifyAccount);