import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { SEND_VERIFICATION_CODE } from "./actionTypes";
import { sendVerificationCodeSuccess, sendVerificationCodeError } from "./actions";

//Include Both Helper File with needed methods
import { postSendOTP } from "../../../helpers/backend_helper";

function* sendOTP({ payload: { data, history } }) {
  try {
    const resp = yield call(postSendOTP, {
      country_code: data.country_code,
      phone_number: data.phone_number
    });
    console.log('resp', resp);
    if (resp.status === "success") {
      yield put(sendVerificationCodeSuccess(resp));
      history('/account/verify');
    } else {
      yield put(sendVerificationCodeError(resp?.msg ?? 'Something went wrong'));
    }
  } catch (error) {
    yield put(sendVerificationCodeError(error?.response?.data?.msg ?? error?.message ?? 'An error occured'));
  }
}

function* sendOTPSaga() {
  yield takeEvery(SEND_VERIFICATION_CODE, sendOTP);
}

export default sendOTPSaga;
