import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import CDP from "../../assets/images/svg/cdplogo.svg";
import IOT from "../../assets/images/svg/iot logo.svg";
import Cpass from "../../assets/images/svg/contact center logo.svg";

const Softwareservices = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);

  useEffect(() => {
    document.querySelectorAll('.plan-box').forEach(el => {
      el.classList.remove('shadow-lg');
      el.addEventListener('mouseover', () => el.classList.add('shadow-lg'));
      el.addEventListener('mouseout', () => el.classList.remove('shadow-lg'));
    });
  }, []);

  return (
    <React.Fragment>
      <section className="section" style={{ backgroundColor: "#FFF5FF" }}>
        <Container>
          <Row className="gy-4">
            <Col lg={4}>
              <Card className="plan-box mb-0">
                <CardHeader className="flex-grow-1 d-flex bg-primary text-white align-items-center">
                  <span className="mx-auto">OC</span>
                </CardHeader>
                <CardBody className="p-4 m-1">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="avatar-sm align-center ">
                      <div className="avatar-title bg-light text-primary">
                        <img
                          src={CDP}
                          className=""
                          alt=" Cdp icon"
                          height="30"
                        />
                      </div>
                    </div>
                  </div>
                  <h1 className="month fs-20 lh-base py-4 text-center">
                    OMNICHANNEL COMMUNICATION
                  </h1>
                  <div>
                    <p className="px-md-2 text-center">Enhance your communication by integrating various channels together. Connect, engage and thrive online.</p>
                    <div className="mt-4">
                      <Link
                        to="#"
                        className="soft-primary d-flex justify-content-center mb-4"
                      >
                        Learn more <i class="ri-arrow-right-line"></i>
                      </Link>
                      <Link
                        to="#"
                        className="btn btn-primary w-50 mx-auto d-flex rounded-pill justify-content-center"
                      >
                        Start for free
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card className="plan-box mb-0">
                <CardHeader className="flex-grow-1 d-flex bg-primary text-white align-items-center">
                  <span className="mx-auto">IOT</span>
                </CardHeader>
                <CardBody className="p-4 m-1">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="avatar-sm align-center ">
                      <div className="avatar-title bg-light text-primary">
                        <img
                          src={IOT}
                          className=""
                          alt=" Cdp icon"
                          height="30"
                        />
                      </div>
                    </div>
                  </div>
                  <h1 className="month fs-20 lh-base py-4 text-center">
                    INTERNET OF THINGS (IoT)
                  </h1>
                  <div>
                    <p className="px-md-2 text-center">Use IoT for new and connected solutions. Our platform integrates IoT devices, sensors, and data for smart automation, real-time monitoring, and intelligent decision-making.</p>
                    <div className="mt-4">
                      <Link
                        to="#"
                        className="soft-primary d-flex justify-content-center mb-4"
                      >
                        Learn more <i class="ri-arrow-right-line"></i>
                      </Link>
                      <Link
                        to="#"
                        className="btn btn-primary w-50 mx-auto d-flex rounded-pill justify-content-center"
                      >
                        Start for free
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card className="plan-box mb-0">
                <CardHeader className="flex-grow-1 d-flex bg-primary text-white align-items-center">
                  <span className="mx-auto">ChatBot</span>
                </CardHeader>
                <CardBody className="p-4 m-1">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="avatar-sm align-center ">
                      <div className="avatar-title bg-light text-primary">
                        <img
                          src={Cpass}
                          className=""
                          alt=" Cdp icon"
                          height="30"
                        />
                      </div>
                    </div>
                  </div>
                  <h1 className="month fs-20 lh-base py-4 text-center">
                    CONTACT CENTRE
                  </h1>
                  <div>
                    <p className="px-md-2 text-center">Our software lets your organization manage client
                      interactions across phone, chat, email, and social media
                      from a single interface.</p>
                    <div className="mt-4">
                      <Link
                        to="#"
                        className="soft-primary d-flex justify-content-center mb-4"
                      >
                        Learn more <i class="ri-arrow-right-line"></i>
                      </Link>
                      <Link
                        to="#"
                        className="btn btn-primary w-50 mx-auto d-flex rounded-pill justify-content-center"
                      >
                        Start for free
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Softwareservices;
