import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Button, Spinner } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { accountResetPassword } from "../../store/actions";

import logoLight from "../../assets/images/logo.svg";

import ParticlesAuth from "./ParticlesAuth";

const ResetPassword = () => {
    document.title = "BRIVAS | Reset Password";

    const history = useNavigate();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const [passwordShow, setPasswordShow] = useState(false);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            pwd: '',
            cpwd: ''
        },
        validationSchema: Yup.object({
            pwd: Yup.string().required("Please enter your new password"),
            cpwd: Yup.string().required("Please confirm new password"),
        }),
        onSubmit: (values) => {
            if (values.pwd === values.cpwd) {
                const data = { password: values.pwd, token: searchParams.get('token') };
                dispatch(accountResetPassword(data, history));
            } else {
                toast.error('Password must match', { autoClose: 3000 });
            }
        }
    });

    const { loading, forgetError, forgetSuccessMsg } = useSelector(state => ({
        loading: state.Account.loading,
        forgetError: state.Account.forgetError,
        forgetSuccessMsg: state.Account.forgetSuccessMsg,
    }));

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-1 mb-0 text-black-55">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="20" />
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">

                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Reset Password</h5>
                                        </div>

                                        <div className="p-2">
                                            {forgetError ? (
                                                <Alert color="danger" style={{ marginTop: "13px" }}>
                                                    {forgetError}
                                                </Alert>
                                            ) : null}
                                            {forgetSuccessMsg ? (
                                                <Alert color="success" style={{ marginTop: "13px" }}>
                                                    {forgetSuccessMsg}
                                                </Alert>
                                            ) : null}
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                            >

                                                <div className="mb-3">
                                                    <Label className="form-label fw-normal fs-12" htmlFor="pwd">New Password</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control pe-5 password-input"
                                                            placeholder="Enter your password"
                                                            id="pwd"
                                                            name="pwd"
                                                            value={validation.values.pwd || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.pwd && validation.errors.pwd ? true : false
                                                            }
                                                        />
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}>
                                                            <i className="ri-eye-fill align-middle"></i>
                                                        </button>
                                                    </div>
                                                    {validation.touched.pwd && validation.errors.pwd ? (
                                                        <FormFeedback type="invalid">{validation.errors.pwd}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-normal fs-12" htmlFor="cpwd">Confirm New Password</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control pe-5 password-input"
                                                            placeholder="Enter your password"
                                                            id="cpwd"
                                                            name="cpwd"
                                                            value={validation.values.cpwd || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.cpwd && validation.errors.cpwd ? true : false
                                                            }
                                                        />
                                                        {/* <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}>
                                                            <i className="ri-eye-fill align-middle"></i>
                                                        </button> */}
                                                    </div>
                                                    {validation.touched.cpwd && validation.errors.cpwd ? (
                                                        <FormFeedback type="invalid">{validation.errors.cpwd}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mt-4">
                                                    <button
                                                        disabled={loading}
                                                        color="success"
                                                        className="btn w-100 rounded-pill btn-primary"
                                                        type="submit">
                                                        {loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                        Reset Password
                                                    </button>
                                                </div>
                                            </Form>
                                            <div className="mt-4 text-center">
                                                <p className="mb-0 fs-13">Wait, I remember my password... <Link to="/login" className="text-primary"> Click here </Link> </p>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default ResetPassword;