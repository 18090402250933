import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Carousel,
  Container,
  Form,
  Input,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

// import JobProfile2 from "../../assets/images/job-profile2.png";
import Dolapo from "../../assets/images/svg/peopleinoffice-min.png";

import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const Homenew = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <section
        className="section  bg-secondary pb-0"
        id="hero"
        style={{
          backgroundImage: `url(${Dolapo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className=" bg-home-overlay-pattern2"></div>
        <div className="px-4 pt-5 pb-5">
          <Row className="justify-content-between align-items-center">
          <Col lg={6}>
            <div>
                <h1 className="display-6 fw-medium mb-3 text-light fs-40  lh-base">
                  Maximize the Potential<br></br>
                  of the Business
                  <br/>
                   Communication
                   <br/> 
                   System
                </h1>
                <p className="lead lh-base mb-4 text-light ">
               
                </p>

              </div>
          </Col>
            <Col lg={4}>
              <div className="position-relative  text-center text-light mt-3 mt-lg-0">
                <p>Elevate your commmunication with seamless video confrencing! Share ideas, documents, and 
                  presentation easily. Get real-time screen sharing, making virtual meetings productive 
                  and enjoyable. Say goodbye to distance barriers and hello to immersive video confrencing experiences. 
                </p>
              </div>
              
              <div className="d-flex gap-2">
                  <button
                    color="danger "
                    className="btn rounded-pill btn-primary"
                    onClick={() => history("/register")}
                  >
                    Start for free
                    <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                  </button>
                </div>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Homenew;
