import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Carousel,
  Container,
  Form,
  Input,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

// import JobProfile2 from "../../assets/images/job-profile2.png";
import img1 from "../../assets/images/svg/isaacimageapi.svg";

import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const Isaac = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <section
        className="section  bg-secondary pb-0"
        id="hero"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className=" bg-home-overlay-pattern2"></div>
        <div className="px-4">
          <Row className="justify-content-between mt-5 align-items-center">
            <Col lg={6}>
              <div>
                {/* <h1 className="display-6 text-light fw-medium mb-5 fs-4  lh-base">
                  VOICE BROADCAST
                </h1> */}
                <h1 className="display-6 fw-bold mb-3 text-light fs-40  lh-base">
                  Integrate Effortlessly the Ideal API for Retail Success
                </h1>
                <p className="lead lh-base mb-5 text-light ">
                  The integration of Omnichannel and API Integration allows for
                  seamless connectivity across various platforms and systems,
                  enabling a consistent and cohesive brand experience.
                </p>

                <div className="d-flex gap-2">
                  <button
                    color="success"
                    className="btn rounded-pill btn-light text-primary fw-bold mb-5 margin-left-20px"
                    onClick={() => history("/register")}
                  >
                    Talk to an expert
                    <i className="align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
                  </button>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="position-relative home-img text-center mt-3 mt-lg-0">
                {/* <img src={JobProfile2} alt="" className="user-img" /> */}

                {/* <img
                  src={HomeImg}
                  alt=""
                  className="landing-home-img"
                  height={560.2}
                /> */}
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Isaac;
