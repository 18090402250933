import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import numberandconnectivityhomepage from "../../assets/images/educationhome.png";
import numberandconnectivityhomepage2 from "../../assets/images/svg/numbers-connectivity-homepage2-min.png";
import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import ListIcon from "../../assets/images/svg/voiceservicelogo.svg";
import Education from "../../assets/images/educationimagebrivas.png";
import ListIcon1 from "../../assets/images/educationicon1.png";
import ListIcon2 from "../../assets/images/educationicon2.png";

const Home = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <section className="section pb-0" id="hero">
        <div class="row mx-md-5 px-3 px-md-5">
          <div class="col-md-6">
            <h1 className="fw-bold fs-42 lh-base w-100 text-primary mb-4 text-sm-centre">
              Unlocking Learning Potential: Your Future, Your{" "}
              <span className="text-danger">Education,</span> Powered by Brivas
            </h1>
            <div className="d-flex gap-2 my-5">
              <button
                color="primary "
                className="btn rounded-pill btn-primary"
                onClick={() => "/register"}
              >
                Start for free
              </button>
              <button
                color="success"
                className="btn rounded-pill btn-light border-danger"
                onClick={() => history("/register")}
              >
                <span className="text-danger">Talk to an expert</span>
              </button>
            </div>
          </div>
          <div class="col-md-6 d-none d-md-inline">
            <img
              src={numberandconnectivityhomepage}
              alt=""
              className="user-img"
            />
          </div>
        </div>
        <div className="mx-md-5 px-3 px-md-5">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <h1 className="display-6 fw-bold fs-40 lh-base text-dark text-sm-center text-md-start">
                Transforming Education through Innovative Technology
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 order-2 order-md-1">
              <p className="mb-5 pt-3">
                By providing a robust set of tools and features, Brivas's
                solution improves the educational experience for both students
                and teachers, enabling educational institutions to embrace the
                future of learning.
              </p>

              <div className="vstack gap-2 mt-4">
                <div className="media d-flex">
                  <img
                    className="align-self-start mx-2"
                    src={ListIcon1}
                    alt="Generic placeholder image"
                  />
                  <div className="media-body">
                    <h5 className="mt-0 fw-bold">Streaming</h5>
                    <p className="w-75">
                      We provide e-learning, video conferencing, audiobooks,
                      podcasts, and webinars. These streaming capabilities
                      enable dynamic, interesting learning anywhere, anytime.
                    </p>
                  </div>
                </div>

                <div className="media d-flex">
                  <img
                    className="align-self-start mx-2"
                    src={ListIcon2}
                    alt="Generic placeholder image"
                  />
                  <div className="media-body">
                    <h5 className="mt-0 fw-bold">Messaging</h5>
                    <p className="w-75">
                      SMS, RCS, social media integration, and USSD allow
                      students, instructors, and administrators to communicate.
                      Notifications, reminders, and tailored messaging help
                      students communicate and participate.
                    </p>
                  </div>
                </div>
 
              </div>

              <div className="d-flex gap-2 my-5">
                <button
                  color="primary "
                  className="btn rounded-pill btn-primary"
                  onClick={() => "/register"}
                >
                  Start for free
                  <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                </button>
                <button
                  color="primary"
                  className="btn rounded-pill btn-light text-primary border-primary"
                  onClick={() => history("/register")}
                >
                  <span className="text-danger">Talk to an expert</span>
                </button>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 voip order-1">
              <img
                src={Education}
                alt=""
                className="user-img w-100"
                // style={{margin:'-10%'}}
                // width="599px"
                // width="380px"
              />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Home;
