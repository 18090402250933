import { call, put, takeEvery } from "redux-saga/effects";

import { GENERATE_CODE, QR_CODE_LOGIN } from "./actionTypes";
import { generateQRCodeError, generateQRCodeSuccess, qrCodeLogin, qrCodeLoginError, qrCodeLoginSuccess } from "./actions";
import { genLoginQRCode } from "../../../helpers/backend_helper";

function* genQRCode() {
  try {
    const resp = yield call(genLoginQRCode);
    if (resp.status === "success") {
      yield put(generateQRCodeSuccess(resp));
    } else {
      yield put(generateQRCodeError(resp));
    }
  } catch (error) {
    yield put(generateQRCodeError(error));
  }
}

function* QRLogin({ payload: { user, history } }) {
  try {
    const resp = yield call(qrCodeLogin, {
      email: user.email, 
      password: user.password,
    });
    if (resp.status === "success") {
      yield put(qrCodeLoginSuccess(resp)); 
      history('/dashboard');
      sessionStorage.setItem("authUser", JSON.stringify(resp.data));
    } else {
      yield put(qrCodeLoginError(resp.msg));
    }
  } catch (error) {
    yield put(qrCodeLoginError(error));
  }
}

function* qrLoginSaga() {
  yield takeEvery(GENERATE_CODE, genQRCode);
  yield takeEvery(QR_CODE_LOGIN, QRLogin);
}

export default qrLoginSaga;
