import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Carousel,
  Container,
  Form,
  Input,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

// import JobProfile2 from "../../assets/images/job-profile2.png";
import Dolapo from "../../assets/images/retailbg.png";

import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const Homenew = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <section
        className="section px-5 bg-secondary pb-0"
        id="hero"
        style={{
          backgroundImage: `url(${Dolapo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "635px",
        }}
      >
        <div className="">
          <div className="d-flex justify-content-around">
            <div></div>
            <div style={{ width: "57ch" }}>
              <h1 className="display-6 fw-medium mb-3 ft-bold text-light fs-50  lh-base">
                Create an online shopping<br></br>
                environment
              </h1>
              <p className="lead lh-base mb-5 mt-5 text-light mx-auto ">
                Our solution revolutionizes the customer journey from browsing
                to purchase and beyond, ensuring a streamlined and enjoyable
                process..
              </p>

              <div className="d-flex gap-2 mt-2">
                <button
                  color="danger "
                  className="btn rounded-pill btn-primary"
                  onClick={() => history("/register")}
                >
                  Start for free
                  <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                </button>

                <button
                  color="success"
                  className="btn rounded-pill btn-light margin-left-20px"
                  onClick={() => history("/register")}
                >
                  Talk to an expert
                  <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Homenew;
