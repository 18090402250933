import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { Grid, _ } from "gridjs-react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import avtarImage1 from "../../assets/images/suredlogo.png";
import avtarImage2 from "../../assets/images/audioimage.png";
import avtarImage3 from "../../assets/images/suredlogo.png";
import avtarImage4 from "../../assets/images/sugreenlogo.png";
import avtarImage5 from "../../assets/images/suredlogo.png";
import avtarImage6 from "../../assets/images/sugreenlogo.png";

const Users = () => {
  const [showLiveKey, setShowLiveKey] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState(false);

  const { account } = useSelector((state) => ({
    account: state.Account.account,
  }));

  const data2 = [
    [
      "Jhonnyboy201",
      " Jonathan  ",
      "Senior Implementation Architect",
      " jonathan@example.com",
      "...",
    ],
    [
      "Harold",
      " Metz Inc ",
      "Forward Creative Coordinator",
      " harold@example.com ",
      "Iran",
    ],
    [
      "Shannon",
      "shannon@example.com",
      "Legacy Functionality Associate",
      "Zemlak Group",
      "South Georgia",
    ],
    [
      "Robert",
      "robert@example.com",
      "Product Accounts Technician",
      "Hoeger",
      "San Marino",
    ],
    [
      "Noel",
      "noel@example.com",
      "Customer Data Director",
      "Howell - Rippin",
      "Germany",
    ],
    [
      "Traci",
      "traci@example.com",
      "Corporate Identity Director",
      "Koelpin - Goldner",
      "Vanuatu",
    ],
    [
      "Kerry",
      "kerry@example.com",
      "Lead Applications Associate",
      "Feeney, Langworth and Tremblay",
      "Niger",
    ],
    [
      "Patsy",
      "patsy@example.com",
      "Dynamic Assurance Director",
      "Streich Group",
      "Niue",
    ],
    [
      "Cathy",
      "cathy@example.com",
      "Customer Data Director",
      "Ebert, Schamberger and Johnston",
      "Mexico",
    ],
    [
      "Tyrone",
      "tyrone@example.com",
      "Senior Response Liaison",
      "Raynor, Rolfson and Daugherty",
      "Qatar",
    ],
  ];

  useEffect(() => {}, []);

  document.title = "BRIVAS | Dashboard";

  return (
    <React.Fragment>
      <div className="shadow-none">
        <Row className="mb-3 p-3 pb-1">
          <Col xs={12}>
            <div className="d-flex p-4 align-items-lg-center flex-lg-row flex-column">
              <div className="flex-grow-1">
                <h1 className=" fw-bold text-dark mb-1">Sender Identification</h1>
                <p className="text-muted mb-0">
                  Getting started with our voice and video API
                </p>
              </div>
            </div>
          </Col>
        </Row>
        

        <div className="table-responsive">
          <Table className="align-middle table-nowrap mb-0">
            <thead className="table-light">
              <tr>
                <th scope="col" style={{ width: "42px" }}>
                  <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="responsivetableCheck"
                    />
                    <Label
                      className="form-check-label"
                      for="responsivetableCheck"
                    ></Label>
                  </div>
                </th>
                {/* <th scope="col">ID</th>
                <th scope="col">Date</th>
                <th scope="col">Status</th> */}
                <th scope="col">Username</th>
                <th scope="col">Contact Name</th>
                <th scope="col">SID's</th>
                <th scope="col">Contact Emails</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="responsivetableCheck01"
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="responsivetableCheck01"
                    ></Label>
                  </div>
                </th>
                {/* <td>
                  <Link to="#w-medium">#VZ2110</Link>
                </td>
                <td>10 Oct, 14:47</td>
                <td className="text-success">
                  <i className="ri-checkbox-circle-line fs-17 align-middle"></i>{" "}
                  Paid
                </td> */}
                <td>
                  <div className="d-flex gap-2 align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        src={avtarImage3}
                        alt=""
                        className="avatar-xs rounded-circle"
                      />
                    </div>
                    <div className="flex-grow-1">Sample User</div>
                  </div>
                </td>
                <td>Client Name</td>
                <td>3</td>
                <td>isaacchukwuka67@gmail.com</td>
              </tr>
              <tr>
                <th scope="row">
                  <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="responsivetableCheck02"
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="responsivetableCheck02"
                    ></Label>
                  </div>
                </th>
                {/* <td>
                  <Link to="#" className="fw-medium">
                    #VZ2109
                  </Link>
                </td>
                <td>17 Oct, 02:10</td>
                <td className="text-success">
                  <i className="ri-checkbox-circle-line fs-17 align-middle"></i>{" "}
                  Paid
                </td> */}
                <td>
                  <div className="d-flex gap-2 align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        src={avtarImage4}
                        alt=""
                        className="avatar-xs rounded-circle"
                      />
                    </div>
                    <div className="flex-grow-1">Sample Customer</div>
                  </div>
                </td>
                <td>Client Name 2</td>
                <td>5</td>
                <td>monadesola@gmail.com</td>
              </tr>
              <tr>
                <th scope="row">
                  <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="responsivetableCheck03"
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="responsivetableCheck03"
                    ></Label>
                  </div>
                </th>
                {/* <td>
                  <Link to="#" className="fw-medium">
                    #VZ2108
                  </Link>
                </td>
                <td>26 Oct, 08:20</td>
                <td className="text-primary">
                  <i className="ri-refresh-line fs-17 align-middle"></i>{" "}
                  Refunded
                </td> */}
                <td>
                  <div className="d-flex gap-2 align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        src={avtarImage5}
                        alt=""
                        className="avatar-xs rounded-circle"
                      />
                    </div>
                    <div className="flex-grow-1">Sample New</div>
                  </div>
                </td>
                <td>Client Name 1</td>
                <td>7</td>
                <td>melvinjiseikedev@gmail.com</td>
              </tr>
              <tr>
                <th scope="row">
                  <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="responsivetableCheck04"
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="responsivetableCheck04"
                    ></Label>
                  </div>
                </th>
                {/* <td>
                  <Link to="#" className="fw-medium">
                    #VZ2107
                  </Link>
                </td>
                <td>02 Nov, 04:52</td>
                <td className="text-danger">
                  <i className="ri-close-circle-line fs-17 align-middle"></i>{" "}
                  Cancel
                </td> */}
                <td>
                  <div className="d-flex gap-2 align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        src={avtarImage6}
                        alt=""
                        className="avatar-xs rounded-circle"
                      />
                    </div>
                    <div className="flex-grow-1">Sample Customer</div>
                  </div>
                </td>
                <td>Client Name 4</td>
                <td>2</td>
                <td>oluwarantitoluwani@gmail.com</td>
              </tr>
              <tr>
                <th scope="row">
                  <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="responsivetableCheck05"
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="responsivetableCheck05"
                    ></Label>
                  </div>
                </th>
                {/* <td>
                  <Link to="#" className="fw-medium">
                    #VZ2106
                  </Link>
                </td>
                <td>10 Nov, 07:20</td>
                <td className="text-success">
                  <i className="ri-checkbox-circle-line fs-17 align-middle"></i>{" "}
                  Paid
                </td> */}
                <td>
                  <div className="d-flex gap-2 align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        src={avtarImage1}
                        alt=""
                        className="avatar-xs rounded-circle"
                      />
                    </div>
                    <div className="flex-grow-1">Jason schuller</div>
                  </div>
                </td>
                <td>Splashify</td>
                <td>4</td>
                <td>isaacchukwuka67@gmail.com</td>
              </tr>
            </tbody>
           
          </Table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Users;
