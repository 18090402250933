import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Button,
  ButtonGroup,
  Alert,
  Nav,
  NavItem,
  NavLink,
  Row,
  Formcontrol,
  InputGroup,
  Table,
  TabContent,
  TabPane,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  UncontrolledDropdown,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";

import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import classnames from "classnames";
import { BasicRaterExample, TestSMSCurl } from "../../Components/UiRatingsCode";
import { Textarea } from "@mui/joy";
import img1 from "../../assets/images/svg/realtimeimage.svg";
import img2 from "../../assets/images/dashboardimage.png";
import img3 from "../../assets/images/dashimage.png";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Dashboard = () => {
  const [activeTab, setactiveTab] = useState(1);
  const [progressbarvalue, setprogressbarvalue] = useState(0);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [codeTab, setCodeTab] = useState("cURL"); // Define isTypeTab variable
  const [isLoader, setIsLoader] = useState(false);
  const [showLiveKey, setShowLiveKey] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState(false);
  // Add separate state variables for controlling visibility of Live Key and Secret Key

  const handleShowLiveKey = () => {
    setShowLiveKey(!showLiveKey);
  };

  const handleShowSecretKey = () => {
    setShowSecretKey(!showSecretKey);
  };
  // Define separate functions to toggle visibility of Live Key and Secret Key

  //   const [userLogin, setUserLogin] = useState([]);

  //   const validation = useFormik({
  //     // enableReinitialize : use this flag when initial values needs to be changed
  //     enableReinitialize: true,

  //     initialValues: {
  //         email: userLogin.email || "admin@themesbrand.com" || '',
  //         password: userLogin.password || "123456" || '',
  //     },
  //     // validationSchema: Yup.object({
  //     //     email: Yup.string().required("Please Enter Your Email"),
  //     //     password: Yup.string().required("Please Enter Your Password"),
  //     // }),
  //     // onSubmit: (values) => {
  //     //     dispatch(loginUser(values, props.router.navigate));
  //     // }
  // });

  // const handleShow = () => {
  //   setShow(!show);
  // };
  // const handleShow2 = () => {
  //   setShow2(!show2);
  // };

  const { account } = useSelector((state) => ({
    account: state.Account.account,
  }));

  // Define handleShow function
  // const handleShow = () => {
  //   setIsLoader(!isLoader); // Toggle the value of isLoader
  // };

  function toggleTab(tab, ntype, value) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setprogressbarvalue(value);
  }

  document.title = "BRIVAS | Dashboard";
  return (
    <React.Fragment>
      <div className="shadow-none">
        {/* <h2 className="fw-bold text-dark">Ahoy Melvin, welcome to BRIVAS!</h2> */}
        <Row className="mt-4 px-sm-1 px-lg-5">
          <Col xl={12}>
            <Card
              className="rounded-4 text-light"
  
            >
              <TabPane tabId="2" id="pill-justified-profile-1" >
                <div>
                  <Table className="align-middle table-nowrap mb-0 px-3">
                    <thead>
                      <tr>
                        <th scope="col">Status</th>
                        <th scope="col">Type</th>
                        <th scope="col">Error</th>
                        <th scope="col">Reason</th>
                        <th scope="col">From</th>
                        <th scope="col">To</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          <Link to="#" className=" text-success fw-medium">
                            Accepted
                          </Link>
                        </th>
                        <td className="text-muted">Outbound</td>
                        <td className="text-muted">None</td>
                        <td className="text-muted">Network_Recognized</td>
                        <td>
                          <Link to="#" className="link-success">
                            +234 8056342345 <p>Feb 12, 14:00:59</p>
                          </Link>
                        </td>
                        <td>
                          <Link to="#" className="link-success">
                            +234 8056342345 <p>Feb 12, 14:00:59</p>
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <Link to="#" className=" text-danger fw-medium">
                            Declined
                          </Link>
                        </th>
                        <td className="text-muted">Inbound</td>
                        <td className="text-muted">All</td>
                        <td className="text-muted">Network_Unrecognized</td>
                        <td>
                          <Link to="#" className="link-success">
                            +234 9057469410 <p>Feb 12, 14:00:59</p>
                          </Link>
                        </td>
                        <td>
                          <Link to="#" className="link-success">
                            +234 8056342345 <p>Feb 12, 14:00:59</p>
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </TabPane>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
