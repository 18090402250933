import {
  SEND_UPLOAD_CONTACT,
  UPLOAD_CONTACT_ERROR,
  UPLOAD_CONTACT_SUCCESS,
} from "./actonType";

const initialState = {
  loading: false,
  error: null,
  contacts: [],
};

const UploadContact = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_CONTACT_SUCCESS:
      switch (action.payload.actionType) {
        case SEND_UPLOAD_CONTACT:
          return { ...state, loading: false };
        default:
          return { ...state };
      }
    case UPLOAD_CONTACT_ERROR:
      switch (action.payload.actionType) {
        case SEND_UPLOAD_CONTACT:
          return { ...state, loading: false };
        default:
          return { ...state };
      }
    case SEND_UPLOAD_CONTACT:
      return { ...state, loading: true };
    default:
      return state;
  }
};

export default UploadContact;
