import {
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,
  VERIFY_OTP_INIT,
  VERIFY_OTP_INIT_SUCCESS,
  VERIFY_OTP_RESET_FLAG,
  // VERIFY_OTP_INIT_FAILED
} from "./actionTypes";

const initialState = {
  msg: null,
  loading: false,
  error: false,
  success: false,
  country_code: '',
  phone_number: '',
};

const AccountVerify = (state = initialState, action) => {

  switch (action.type) {
    case VERIFY_OTP_INIT:
      state = { ...state };
      break;
    case VERIFY_OTP_INIT_SUCCESS:
      state = { ...state, country_code: action.payload.data.country_code, phone_number: action.payload.data.phone_number };
      break;
    // case VERIFY_OTP_INIT_FAILED:
    //   state = { ...state };
    //   break;

    case VERIFY_OTP:
      state = { ...state, loading: true, error: false };
      break;
    case VERIFY_OTP_SUCCESS:
      state = { ...state, loading: false, error: false, success: true };
      break;
    case VERIFY_OTP_FAILED:
      state = { ...state, msg: action.payload, loading: false, error: true };
      break;
    case VERIFY_OTP_RESET_FLAG:
      state = { ...state, msg: null, error: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AccountVerify;
