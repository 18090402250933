import { FLASH_CALL_ERROR, FLASH_CALL_SUCCESS, TEST_FLASH_CALL, VERIFY_FLASH_CALL} from "./actonType";

export const flashCallSuccess = (actionType, data) => {
  return {
    type: FLASH_CALL_SUCCESS,
    payload: { actionType, data },
  };
};

export const flashCallError = (actionType, error) => {
  return {
    type: FLASH_CALL_ERROR,
    payload: { actionType, error },
  };
};

export const testFlashCall = (data) => ({
  type: TEST_FLASH_CALL,
  payload: { data },
});
export const verifyFlashCall = (data) => ({
  type: VERIFY_FLASH_CALL,
  payload: { data },
});
