import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";

import img1 from "../../assets/images/svg/instagramimage-min.png";
import img2 from "../../assets/images/svg/feedbackimage.svg";
import img3 from "../../assets/images/svg/abandonedcart-min.png";
import img4 from "../../assets/images/svg/salesimage.svg";
import img5 from "../../assets/images/svg/supportimage.svg";

const Scalable = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section>
      <div>
        <Row className="align-items-center p-4 text-light  gy-4">
          <div className="text-center mb-5">
            <h1 className="mb-1 ff-secondary fw-bold text-primary lh-base">
              Use chatbots to speed up your
              <br />
              business growth
            </h1>
          </div>
          <Col lg={6} className="order-2 order-lg-1">
            <div className="text-bold  fs-40 pt-5 py-5">
              <h1 className="mb-3 text-dark">
                INSTAGRAM DIRECT
                <br />
                MESSAGES
                <br />
                automation
              </h1>
              <p className="mb-4 ff-secondary text-dark">
                With a focus on providing a smooth healthcare journey, our
                platform offers a seamless experience where you can effortlessly
                schedule appointments and enjoy reliable streaming for virtual
                consultations and accessing medical content.{" "}
              </p>

              <div className="d-flex pt-5 gap-2">
                <button
                  color="danger "
                  className="btn-primary rounded-pill btn-light"
                  onClick={() => history("/register")}
                >
                  Start for free
                  <i className=" align-middle ms-1  padding-right-30px  rounded-pill"></i>
                </button>

                <button
                  color="success"
                  className="btn rounded-pill text-danger btn-danger text-primary  margin-left-20px"
                  onClick={() => history("/register")}
                >
                  Talk to an expert
                  <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
                </button>
              </div>
            </div>
          </Col>

          <Col lg={6} sm={7} className="col-10 ms-auto order-1 order-lg-2">
            <div>
              <img src={img1} alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>

        <div className="text-center mt-3 mb-5">
          <h2 className="mb-1 ff-secondary fw-bold text-dark lh-base">
            SPARK UP YOUR ENGAGEMENT IDEAS
          </h2>
        </div>

        <section className="section " id="plans">
          <Container>
            <Row>
              <Col sm={6} xl={3}>
                <Card>
                  <img
                    className="card-img-top img-fluid"
                    src={img2}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title text-center ft-bold mb-2">
                      Feedback
                    </h4>
                    <p className="card-text">
                      Its a brave new world Customer experience{" "}
                    </p>
                    <div className="text-end">
                      <Link
                        to="#"
                        className="soft-primary w-50 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={6} xl={3}>
                <Card>
                  <img
                    className="card-img-top img-fluid"
                    src={img3}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title text-center mb-2">
                      Abandoned Cart
                    </h4>
                    <p className="card-text">
                      Its a brave new world Customer experience{" "}
                    </p>
                    <div className="text-end">
                      <Link
                        to="#"
                        className="soft-primary w-50 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={6} xl={3}>
                <Card>
                  <img
                    className="card-img-top shadow-lg img-fluid"
                    src={img4}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title text-center mb-2">Sales</h4>
                    <p className="card-text">
                      Its a brave new world Customer experience{" "}
                    </p>
                    <div className="text-end">
                      <Link
                        to="#"
                        className="soft-primary w-50 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={6} xl={3}>
                <Card>
                  <img
                    className="card-img-top img-fluid"
                    src={img5}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title text-center mb-2 mx-auto">
                      Support
                    </h4>
                    <p className="card-text text-center">
                      Its a brave new world Customer experience{" "}
                    </p>
                    <div className="text-end">
                      <Link
                        to="#"
                        className="soft-primary w-50 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </section>
  );
};

export default Scalable;
