import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { listAppsSuccess, listAppsError } from "./action";

import { getApps } from "../../helpers/backend_helper";
import { LIST_APPS } from "./actionType";

function* listApps() {
  try {
    const json = yield call(getApps); 
    if (json.status === "success") {
      yield put(listAppsSuccess(json));
    } else {
      yield put(listAppsError(json));
    }
  } catch (err) {
    yield put(listAppsError(err?.message ?? 'An error occured'));
  }
}

function* listAppSaga() {
  yield takeEvery(LIST_APPS, listApps);
}

export default listAppSaga;
