import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import Image1 from "../assets/images/svg/tipsreport.svg";
import Image2 from "../assets/images/svg/tipsreport2.svg";
import Image3 from "../assets/images/svg/tipsguide.svg";
import Image4 from "../assets/images/svg/tipsguide2.svg";

const SMSTips = ({xl}) => <Container >
<Row className="">
  <Col sm={6} xl={xl??3}>
    <Card>
      <img
        className="card-img-top img-fluid" 
        src={Image1}
        alt="Card cap"
      />
      <CardBody>
        <h4 className="card-title text-center mb-2">Report</h4>
        <p className="text-center">
          Its a brave new world Customer experience{" "}
        </p>
        <div className="text-end">
          <Link
            to="#"
            className="soft-primary w-50 mx-auto d-flex justify-content-center"
          >
            Learn more
            <i class="ri-arrow-right-line"></i>
          </Link>
        </div>
      </CardBody>
    </Card>
  </Col>
  <Col sm={6} xl={xl??3}>
    <Card>
      <img
        className="card-img-top img-fluid"
        src={Image2}
        alt="Card cap"
      />
      <CardBody>
        <h4 className="card-title text-center mb-2">Guide</h4>
        <p className="text-center">
          Its a brave new world Customer experience{" "}
        </p>
        <div className="text-end">
          <Link
            to="#"
            className="soft-primary w-50 mx-auto d-flex justify-content-center"
          >
            Learn more
            <i class="ri-arrow-right-line"></i>
          </Link>
        </div>
      </CardBody>
    </Card>
  </Col>
  <Col sm={6} xl={xl??3}>
    <Card>
      <img
        className="card-img-top shadow-lg img-fluid"
        src={Image3}
        alt="Card cap"
      />
      <CardBody>
        <h4 className="card-title text-center mb-2">Report</h4>
        <p className="text-center">
          Its a brave new world Customer experience{" "}
        </p>
        <div className="text-end">
          <Link
            to="#"
            className="soft-primary w-50 mx-auto d-flex justify-content-center"
          >
            Learn more
            <i class="ri-arrow-right-line"></i>
          </Link>
        </div>
      </CardBody>
    </Card>
  </Col>
  <Col sm={6} xl={xl??3}>
    <Card>
      <img
        className="card-img-top img-fluid"
        src={Image4}
        alt="Card cap"
      />
      <CardBody>
        <h4 className="card-title text-center mb-2 mx-auto">Guide</h4>
        <p className="text-center">
          Its a brave new world Customer experience{" "}
        </p>
        <div className="text-end">
          <Link
            to="#"
            className="soft-primary w-50 mx-auto d-flex justify-content-center"
          >
            Learn more
            <i class="ri-arrow-right-line"></i>
          </Link>
        </div>
      </CardBody>
    </Card>
  </Col>
</Row>
</Container>;

export default SMSTips;