import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';

//SimpleBar
import SimpleBar from "simplebar-react";

const TestApp = () => {
    return (
        <React.Fragment>
            <Row>
                <Col xl={9} lg={8}>
                    {/* <Card>
                        <CardBody>
                            
                        </CardBody>
                    </Card> */}
                </Col>

                <Col xl={3} lg={4}>
                    <Card className='shadow-none'>
                        <CardBody>
                            <h5 className="card-title mb-4">Other Apps</h5>
                            <div className="d-flex flex-wrap gap-2 fs-16">
                                <div className="badge fw-medium badge-soft-secondary">SMS</div>
                                <div className="badge fw-medium badge-soft-secondary">Bulk SMS</div>
                                <div className="badge fw-medium badge-soft-secondary">IVR</div>
                                <div className="badge fw-medium badge-soft-secondary">Audio Streaming</div>
                                <div className="badge fw-medium badge-soft-secondary">Video Streaming</div>
                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default TestApp;