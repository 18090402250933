import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import img1 from "../../assets/images/powerfulautomation.png";
import img2 from "../../assets/images/comprehensiveanalytics.png";
import img3 from "../../assets/images/channelsupport.png";

const Contact = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <React.Fragment>
      <section className="section bg-dark fs-50" id="plans">
        <div
          className="bg-overlay opacity-100"
          style={{ backgroundColor: "#FFF5FF" }}
        ></div>
        <div className="w-75 mx-auto">
          <Row className="justify-content-center text-dark">
            <Col lg={35}>
              <Row className="align-items-center gy-4">
                <Col lg={4}>
                <div className="p-4 text-center">
                     <img src={img1} alt="" className="img-fluid" />
                </div>
                  <h3 className="mb-3 fs-20 text-center fw-bold text-dark">
                    Powerful Automation and Personalisation
                  </h3>
                  <p className="mb-4 text-center ff-secondary fs-13">
                    Ensure that your messaging is automated and tailored to each
                    individual. You can communicate with your customers on a
                    more personal level by utilizing intelligent chatbots,
                    dynamic content, and tailored marketing.
                  </p>
                </Col>
                <Col lg={4}>
                <div className="p-4 text-center">
                     <img src={img2} alt="" className="img-fluid" />
                </div>
                  <h3 className="mb-3 fs-20 fw-bold text-dark ">
                    Steady Comprehensive Analytics
                  </h3>
                  <p className="mb-4 ff-secondary text-center fs-13">
                    The effectiveness of your messaging is revealed by our
                    analytics tools. Monitoring delivery rates, engagement
                    metrics, and conversions is an important step toward
                    optimizing a data-driven communications strategy.
                  </p>
                </Col>
                <Col lg={4}>
                <div className="p-4 text-center">
                     <img src={img3} alt="" className="img-fluid" />
                </div>
                  <h3 className="mb-3 fs-20 fw-bold text-center text-dark">
                    Flexible Channel Support
                  </h3>
                  <p className="mb-4 text-center ff-secondary fs-13">
                    Use the channels your audience prefers. Our Messaging APIs
                    provide text messaging, Rich Communication Services, chat
                    apps, and social networking. Maintain client contact
                    regardless of location or device.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Contact;
