import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

import Img1 from "../../assets/images/svg/partnerimage-min.png";
import Img2 from "../../assets/images/svg/exploreimage.svg";

const tabs = [
  {
    title: "Telecom",
    header: "Sparking Limitless Connection Through Telecom Solution",
    desc: "Power your organization with a full range of innovative telecom solutions, seamless connection, and enhanced client experiences. Take your organization to new heights with reliable and secure communication solutions.",
    link: "#telecom",
  },
  {
    title: " Healthcare",
    header: "Provide World-Class Healthcare Anytime Anywhere",
    desc: "Brivas's healthcare technology delivers high-quality virtual healthcare. Our platform facilitates home-based healthcare with secure video consultations, real-time communications, and remote monitoring. We offer world-class digital healthcare services 24/7.",
    link: "#education",
  },
  {
    title: "Education",
    header: "Brivas: Empower Teachers, Transform Education",
    desc: "Get seamless collaboration for a linked learning environment. Our dynamic communication solutions connect students, instructors, administrators through video conferencing, SMS messaging, and unified communications.",
    link: "#healthcare",
  },
];

const Homenew = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <section className="section bg-light pb-0">
      <section>
        <Row className="mx-md-5 px-3 px-md-5">
          <Col xs={12} md={6} className="text-center mx-sm-3 mx-md-0 mt-3">
            <img
              src={Img1}
              alt="BrivasMobile"
              style={{ width: "90%", maxWidth: "458px" }}
            />
          </Col>

          <Col className="p-3" xs={12} md={6}>
            <h1 className="fw-bold fs-40 lh-base text-dark mb-4 text-sm-centre">
              Partner with us for Exponential Growth
            </h1>
            <p>
              Maximize value, and minimize costs. Our innovative tools adapt to
              your needs. Plus, 24/7 customer support clears doubts and answers
              queries. Choose smarter solutions, choose us!.
            </p>

            <div className="d-flex gap-2 my-5">
              <button
                color="primary"
                className="btn rounded-pill btn-primary"
                onClick={() =>
                  (window.location.href = "https://brivas.io/login")
                }
              >
                Start for free
              </button>
              <button
                color="success"
                className="btn rounded-pill btn-light border-danger"
                onClick={() => "/register"}
              >
                <span className="text-danger">Talk to an expert</span>
              </button>
            </div>
          </Col>
        </Row>

        {/* <section className="section d-flex  px-4 pb-0">
          <div className="justify-content-between">
            <div className="">
              <p className="lead lh-base mb-4 text-dark">
                Explore all solutions best for your Brands
              </p>

              <div className="d-flex flex-wrap gap-2">
                {tabs.map((item, index) => (
                  <button
                    key={index}
                    className={`btn rounded-pill btn-primary ${selectedTab === index ? 'active' : ''}`}
                    onClick={() => setSelectedTab(index)}
                  >
                    {item.title}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </section> */}

        <section className="">
          <Row className="mx-md-5 px-3 px-md-5">
            <Col xs={12} md={6}>
              <p className="lead  lh-base mb-4 text-dark">
                Explore all solutions best for your Brands
              </p>

              <div className=" d-flex gap-2">
                {tabs.map((i, x) => (
                  <button
                    key={`tab-${x}`}
                    color="danger "
                    className={`btn ${
                      i?.title == tabs[selectedTab]?.title ? "" : "btn-light"
                    } active rounded-pill btn-primary`}
                    onClick={() => setSelectedTab(x)}
                  >
                    {i.title}
                    <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                  </button>
                ))}
              </div>
            </Col>
          </Row>

          <Row className="mx-md-5 px-3 px-md-5">
            <Col xs={12} md={6}>
              <div className="">
                <h6 className="display-6 fw-bold mt-4 mb-3 text-dark fs-25 lh-base">
                  {tabs[selectedTab]?.header}
                </h6>
                <p className="mb-4">{tabs[selectedTab]?.desc}</p>
                <a className="mt-4" href={tabs[selectedTab]?.link}>
                  Learn more
                </a>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className=" mt-3 mt-md-0 mx-sm explore">
                <img src={Img2} alt="BrivasMobile" />
              </div>
            </Col>
          </Row>
        </section>
      </section>
    </section>
  );
};

export default Homenew;
