import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listProductGroup } from "../../store/actions";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
// import img1 from "../../assets/images/svg/carousel1.svg";
import img1 from "../../assets/images/landing/carousel1.jpg";
import img2 from "../../assets/images/landingcarousel2.png";
import img3 from "../../assets/images/landingcarousel3.png";
import img4 from "../../assets/images/landingcarousel4.png"; // Fixed the file extension
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./CarouselStyle.css";

const Home = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  const carouselItems = [
    {
      image: img1,
      content: (
        <div
          style={{
            position: "relative",
            maxWidth: "50%",
            padding: "20px",
            marginTop: "10%",
          }}
          className=" col-xs-12"
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.2)",
            }}
          ></div>
          <h3
            className="text-center text-darker "
            style={{
              zIndex: 2,
              fontSize: "48px",
              color: "#000",
            }}
          >
            Simple <span className="text-danger">Reliable</span> and Smart Tools
          </h3>
          <p
            className="text-center"
            style={{ color: "#000", fontWeight: "700" }}
          >
            Unlock your brand's potential by utilizing our all-inclusive power
            bundle, which includes features in the areas of messaging, audio,
            verification, and streaming. Enjoy the simplicity of a platform that
            includes everything you could possibly need, you do not need any
            other platform.
          </p>
        </div>
      ),
    },

    {
      image: img2,
      content: (
        <div
          style={{
            position: "relative",
            maxWidth: "50%",
            padding: "50px",
            marginTop: "10%",
          }}
          className="col-xs-12"
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 1)",
            }}
          ></div>
          <h3
            className="text-center"
            style={{
              zIndex: 2,
              fontSize: "50px",
              color: "#000",
            }}
          >
            <Row className="align-items-center gy-4">
              <Col xs={12} sm={6} lg={3}>
                <h3 className="mb-3 fs-20 text-center  fw-bold text-dark"></h3>
                <p className="mb-4 text-left  justify-content-end ff-secondary fs-15">
                  Access vital tools in one place to reach and engage with your
                  audience.
                </p>
              </Col>
              <Col lg={3}>
                <h3 className="mb-3 fs-20 fw-bold text-dark "></h3>
                <p className="mb-4 ff-secondary text-center fs-15">
                  Elevate your communication to new heights.
                </p>
              </Col>
              <Col lg={3}>
                <h3 className="mb-3 fs-20 fw-bold text-center text-dark"></h3>
                <p className="mb-4 text-center ff-secondary fs-15">
                  Amplify your reach with our platform.
                </p>
              </Col>

              <Col lg={3}>
                <button
                  color="danger "
                  className="btn rounded-pill btn-danger"
                  onClick={() => history("/register")}
                >
                  Start for free
                  <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                </button>
              </Col>
            </Row>
          </h3>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveSlide((prevSlide) =>
        prevSlide === carouselItems.length - 1 ? 0 : prevSlide + 1
      );
    }, 5000);

    return () => clearInterval(intervalId);
  }, [carouselItems]);

  const goToSlide = (slideIndex) => {
    setActiveSlide(slideIndex);
  };

  return (
    <React.Fragment>
      <div
        id="carouselExampleSlidesOnly"
        className="carousel slide container-fluid"
        data-bs-ride="carousel"
        data-bs-interval="5000"
      >
        <ol className="carousel-indicators">
          {carouselItems.map((item, index) => (
            <li
              key={index}
              data-bs-target="#carouselExampleSlidesOnly"
              data-bs-slide-to={index}
              className={index === activeSlide ? "active" : ""}
              onClick={() => goToSlide(index)}
            ></li>
          ))}
        </ol>
        <div className="carousel-inner">
          {carouselItems.map((item, index) => (
            <div
              className={`carousel-item ${
                index === activeSlide ? "active" : ""
              }`}
              key={index}
            >
              {/* <img
                src={item.image}
                className="d-block w-100"
                alt={`Slide ${index + 1}`}
                style={{ maxWidth: '100%', minHeight: "40vh", padding: 0  }}

              /> */}

              <div className="d-block w-100" style={{ backgroundImage:`url(${item.image})`, minHeight: "70vh", padding: 0, backgroundPosition: "center", backgroundSize:'auto'}} >

              </div>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  backgroundPosition: "center",
                  backgroundsize: "100%",
                }}
              >
                <div className="m-auto d-none d-md-block">
                  <div className="d-flex justify-content-center">
                    {item.content}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleSlidesOnly"
          role="button"
          data-bs-slide="prev"
          onClick={() =>
            setActiveSlide(
              activeSlide === 0 ? carouselItems.length - 1 : activeSlide - 1
            )
          }
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleSlidesOnly"
          role="button"
          data-bs-slide="next"
          onClick={() =>
            setActiveSlide(
              activeSlide === carouselItems.length - 1 ? 0 : activeSlide + 1
            )
          }
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </a>
      </div>
    </React.Fragment>
  );
};

export default Home;
