import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import img1 from "../../assets/images/svg/cartoonseating.svg";
import img2 from "../../assets/images/purplecheckbox.png";
import img3 from "../../assets/images/retailimage.png";

const Home = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <React.Fragment>
      <div className="fs-50" id="plans">
        <div className="bg-light  opacity-100"></div>
        <div className="w-75 mx-auto">
          <Row className="align-items-center mt-5 pt-lg-5 gy-4">
            <Col lg={6} sm={7} className="col-10 mx-auto">
              <div>
                <img src={img1} alt="" className="img-fluid" />
              </div>
            </Col>
            <Col lg={6}>
              <div className="text-muted ps-lg-5">
                <h1 className=" fs-30 fw-bold text-uppercase text-dark mb-3 ">
                  Thrive with Brivas.
                </h1>
                <p className="mb-4 text-dark">
                  Our platform empowers entrepreneurs with the tools they
                  require to expedite operations, interact with consumers, and
                  grow their businesses. Explore the effectiveness of Brivas in
                  fostering innovation and attaining startup success..
                </p>
                <div className="d-flex gap-2">
                  <button
                    color="danger "
                    className="btn rounded-pill btn-primary"
                    onClick={() => history("/register")}
                  >
                    Start for free
                    <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                  </button>

                  <button
                    color="success"
                    className="btn rounded-pill text-danger border-danger  margin-left-20px"
                    onClick={() => history("/register")}
                  >
                    Talk to an expert
                    <i className=" align-middle border-danger ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <section>
        <div className="mx-md-5 px-3 px-md-5">
          <div class="row">
            <div class="col-md-6 col-sm-12 order-2 order-md-1">
              <h1 className="display-6 fw-bold fs-35 lh-base text-sm-center text-md-start mt-md-5 text-dark">
                Access the power of
                <span className="text-primary"> SEO </span> for your startup
              </h1>
              <p className="mb-5 text-dark">
                With our SEO-friendly features and optimized performance,
                startups can enhance their visibility, connect with customers
                seamlessly, and gain a competitive edge in the digital landscape
              </p>
              <div className="media d-flex mb-4">
                <img
                  className="align-self-start mx-2"
                  src={img2}
                  alt="Generic placeholder image"
                />
                <div className="media-body fw-bold">
                  <h5 className="mt-0 text-dark">
                  Seamless Customer Engagement
                  </h5>
                </div>
              </div>
              <div className="media d-flex mb-4">
                <img
                  className="align-self-start mx-2"
                  src={img2}
                  alt="Generic placeholder image"
                />
                <div className="media-body fe-bold">
                  <h5 className="mt-0 text-dark">Developer-Friendly Integration</h5>
                </div>
              </div>
              <div className="media d-flex mb-4">
                <img
                  className="align-self-start mx-2"
                  src={img2}
                  alt="Generic placeholder image"
                />
                <div className="media-body fw-bold">
                  <h5 className="mt-0 text-dark">Customizable Integrations</h5>
                </div>
              </div>
              <div className="media d-flex mb-3">
                <img
                  className="align-self-start mx-2"
                  src={img2}
                  alt="Generic placeholder image"
                />
                <div className="media-body fw-bold">
                  <h5 className="mt-0 text-dark">Scalable and Reliable</h5>
                </div>
              </div>

              <div className="d-flex gap-2 my-5">
                <button
                  color="primary "
                  className="btn rounded-pill btn-primary"
                  onClick={() => "/register"}
                >
                  Start for free
                  <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                </button>
                <button
                  color="success"
                  className="btn rounded-pill btn-light border-primary"
                  onClick={() => history("/register")}
                >
                  <span className="text-primary">Talk to an expert</span>
                </button>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 my-5 d-flex align-items-center order-1">
              <img src={img3} alt="" className="user-img w-75" />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Home;
