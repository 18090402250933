import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row, Container, Button, Card, CardBody } from "reactstrap";
import Img1 from "../../assets/images/svg/partnerimage-min.png";
import Img2 from "../../assets/images/svg/exploreimage.svg";
import Image1 from "../../assets/images/subwaypin.png";
import Image2 from "../../assets/images/mailpin.png";
import Image3 from "../../assets/images/ringpin.png";
import Image4 from "../../assets/images/rcsmessaging.png";
import SMSTips from "../../Components/SMSTips";

const RCS = () => (
  <div>
    <img className="card-img-top img-fluid" src={Image4} alt="Card cap" />
  </div>
);

const EMAIL = () => (
  <div>
    <img className="card-img-top img-fluid" src={Image4} alt="Card cap" />
  </div>
);

const UNDERRCS = () => (
  <div>
    <div className="vstack gap-2">
      <div className="media d-flex">
        <img
          className="align-self-start mx-2"
          src={Image1}
          alt="Generic placeholder image"
        />
        <div className="media-body">
          <h5 className="mt-0 fw-bold">Two-Way Communication</h5>
          <p className="w-75">
            Our RCS system simpifies interaction. Allow people to respond to SMS
            messages for easy, engaging communication.
          </p>
        </div>
      </div>

      <div className="media d-flex">
        <img
          className="align-self-start mx-2"
          src={Image1}
          alt="Generic placeholder image"
        />
        <div className="media-body">
          <h5 className="mt-0 fw-bold">Easy Integration</h5>
          <p className="w-75">
            Brivas Offers a hassle-free integration process, allowing you to
            incorporate our RCS technology into your operations with minimal
            disruption.
          </p>
        </div>
      </div>

      <div></div>
      <div className="d-flex gap-2 mb-3">
        <button
          color="danger "
          className="btn rounded-pill btn-danger"
          onClick={() => ""}
        >
          Start for free
          <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
        </button>

        <button
          color="success"
          className="btn rounded-pill text-primary border-primary margin-left-20px"
          onClick={() => ""}
        >
          Talk to an expert
          <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
        </button>
      </div>
    </div>
  </div>
);

const UNDEREMAIL = () => (
  <div>
    <div>
      <div className="vstack gap-2">
        <div className="media d-flex">
          <img
            className="align-self-start mx-2"
            src={Image1}
            alt="Generic placeholder image"
          />
          <div className="media-body">
            <h5 className="mt-0 fw-bold">Two-Way Communication</h5>
            <p className="w-75">
              Our RCS system simpifies interaction. Allow people to respond to
              SMS messages for easy, engaging communication.
            </p>
          </div>
        </div>

        <div className="media d-flex">
          <img
            className="align-self-start mx-2"
            src={Image1}
            alt="Generic placeholder image"
          />
          <div className="media-body">
            <h5 className="mt-0 fw-bold">Easy Integration</h5>
            <p className="w-75">
              Brivas Offers a hassle-free integration process, allowing you to
              incorporate our RCS technology into your operations with minimal
              disruption.
            </p>
          </div>
        </div>

        <div></div>
        <div className="d-flex gap-2">
          <button
            color="danger "
            className="btn rounded-pill btn-danger"
            onClick={() => ""}
          >
            Start for free
            <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
          </button>

          <button
            color="success"
            className="btn rounded-pill btn-light margin-left-20px"
            onClick={() => ""}
          >
            Talk to an expert
            <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
);

const UNDERSMS = () => (
  <div className="vstack gap-2 mt-4">
    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={Image1}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Personalised Messages</h5>
        <p className="w-75">
          Capture the minds of your customers through personalized SMS messages
          that seize their attention and drive conversations.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={Image2}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Messaging Templates</h5>
        <p className="w-75">
          Don't sweat over what to tell your customers. Use our templates to
          write messages that will get their attention.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={Image3}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Instant Notifications</h5>
        <p className="w-75">
          Keep your users updated with lightning-fast SMS notifications and make
          those big announcements that get them excited
        </p>
      </div>
    </div>

    <div></div>
    <div className="d-flex gap-2">
      <button
        color="danger "
        className="btn rounded-pill btn-danger"
        onClick={() => ""}
      >
        Start for free
        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
      </button>

      <button
        color="success"
        className="btn rounded-pill btn-light margin-left-20px"
        onClick={() => ""}
      >
        Talk to an expert
        <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
      </button>
    </div>
  </div>
);

const tabs = [
  {
    title: "SMS",
    header: "Connect and interact with Brivas SMS API",
    desc: "  Count on Brivas for reliable and instant message delivery. Take advantage of our developer-friendly resources to smoothly integrate SMS functionality. Contact us now, connect with your audience effortlessly and discover the possibilities with Brivas.",
    link: "#telecom",
    component: <SMSTips xl={6} />,
    component2: <UNDERSMS />,
  },
  {
    title: "RCS",
    header: "Foster dialogue with your audience",
    desc: "Ready to wield the power of SMS like a champion? Brivas next-generation messaging technology raises the bar for communication. Our approach allows for easy, compelling two-way conversations.",
    link: "#education",
    component: <RCS />,
    component2: <UNDERRCS />,
  },
  {
    title: "Email",
    header: "Foster dialogue with your audience",
    desc: " Ready to wield the power of SMS like a champion? Brivas next-generation messaging technology raises the bar for communication. Our approach allows for easy, compelling two-way conversations. ",
    link: "#healthcare",
    component: <EMAIL />,
    component2: <UNDEREMAIL />,
  },
];

const Homenew = () => {
  const history = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <React.Fragment>
      <section
        className="section bg-light pb-0"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: "#FFF5FF",
        }}
      >
        <Container className="">
          <Row className="justify-content-between align-items-center">
            <div>
              <h1 className="text-center fs-40 pt-4 text-dark mb-4 fw-bold">
                Reach your goals with our trusted
                <br /> Messaging Ecosystem{" "}
              </h1>
              <h5 className="text-center text-dark fs-10 mb-2">
                Reach all of your objectives—expand your messaging strategy,
                engage with customers, and improve
                <br />
                customer service—on a single platform.
              </h5>
            </div>
          </Row>

          <div className="py-4">
            <Row className="justify-content-between align-items-center">
              <Col lg={6}>
                {/* <div> */}
                <p className="lead lh-base mb-4 text-dark ">
                  Explore all solutions best for your Brands
                </p>

                <div className="d-flex gap-2">
                  {tabs.map((i, x) => (
                    <button
                      key={`tab-${x}`}
                      color="danger "
                      className={`btn ${
                        i?.title == tabs[selectedTab]?.title ? "" : "btn-light"
                      } active rounded-pill btn-primary`}
                      onClick={() => setSelectedTab(x)}
                    >
                      {i.title}
                      <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                    </button>
                  ))}
                </div>
                {/* </div> */}
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div style={{}}>
                  <h6 className="display-6 fw-bold mt-4 mb-3 text-dark fs-25 lh-base">
                    {tabs[selectedTab]?.header}
                  </h6>
                  <p className="mb-4">{tabs[selectedTab]?.desc}</p>
                  <a className="mt-4" href={tabs[selectedTab]?.link}></a>
                </div>
                {tabs[selectedTab].component2}
              </Col>
              <Col lg={6}>{tabs[selectedTab].component}</Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Homenew;
