import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  postScheduleBulkSMS,
  postUploadContacts,
} from "../../helpers/backend_helper";
import { BULK_SMS_SCHEDULE, SEND_UPLOAD_CONTACT } from "./actonType";
import { uploadContactError, uploadContactSuccess } from "./action";

function* uploadContactSaga({ payload: { data } }) {
  const formData = data;
  console.log('data', formData);
  try {
    const resp = yield call(postUploadContacts, formData);
    console.log('resp', resp);
    if (resp.status === "success") {
      yield put(uploadContactSuccess(SEND_UPLOAD_CONTACT, resp));
      toast.success(resp?.msg ?? 'Contact Has been Uploaded', { autoClose: 3000 });
    } else {
      toast.error(resp?.msg ?? 'An error occured', { autoClose: 3000 });
      yield put(uploadContactError(SEND_UPLOAD_CONTACT, resp?.msg ?? 'An error occured'));
    }
  } catch (error) {
    yield put(uploadContactError(SEND_UPLOAD_CONTACT, error?.message ?? 'Failed'));
    toast.error(error?.response?.data?.msg ?? 'Failed', { autoClose: 3000 });
  }
}

// Define selectedFiles and showToast
const selectedFiles = []; // Initialize as an empty array or with appropriate initial value
const showToast = (message) => {
  // Function to display toast messages
  console.log(message); // Implement your toast functionality here
};

// Now you can use selectedFiles and showToast in your handleContactUpload function



export function handleContactUpload() {
  // Your implementation of handleContactUpload function
  const handleContactUpload = async () => {
    // Prepare data (if needed)
    const formData = new FormData();
    formData.append("file", selectedFiles[0]);
    formData.append("label", "something");
  
    try {
      const response = await fetch("/contacts/upload", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "multipart/form-data" },
        body: formData,
      });
  
      if (response.ok) {
        showToast("Contacts uploaded successfully!");
      } else {
        showToast("Error uploading contacts. Please try again.");
      }
    } catch (error) {
      console.error("Error uploading contacts:", error);
      showToast("Error uploading contacts. Please try again.");
    }
  };
}





// function* scheduleBulkSMSSaga({ payload: { data } }) {
//   try {
//     const resp = yield call(postScheduleBulkSMS, data);
//     if (resp.status === "success") {
//       yield put(uploadContactSuccess(BULK_SMS_SCHEDULE, resp));
//       toast.success(resp?.msg ?? 'Message scheduled', { autoClose: 3000 });
//     } else {
//       toast.error(resp?.msg ?? 'An error occured', { autoClose: 3000 });
//       yield put(uploadContactError(BULK_SMS_SCHEDULE, resp?.msg ?? 'An error occured'));
//     }
//   } catch (error) {
//     yield put(uploadContactError(BULK_SMS_SCHEDULE, error?.message ?? 'Something went wrong'));
//     toast.error(error?.response?.data?.msg ?? 'Something went wrong', { autoClose: 3000 });
//   }
// }

// function* bulkSMSListContactSaga({ payload: { data } }) {
//   try {
//     const resp = yield call(postUploadContacts, data);
//     if (resp.status === "success") {
//       yield put(uploadContactSuccess(SEND_UPLOAD_CONTACT, resp));
//       toast.success(resp?.msg ?? 'Message sent', { autoClose: 3000 });
//     } else {
//       toast.error(resp?.msg ?? 'An error occured', { autoClose: 3000 });
//       yield put(uploadContactError(SEND_UPLOAD_CONTACT, resp?.msg ?? 'An error occured'));
//     }
//   } catch (error) {
//     yield put(uploadContactError(SEND_UPLOAD_CONTACT, error?.message ?? 'Failed'));
//     toast.error(error?.response?.data?.msg ?? 'Failed', { autoClose: 3000 });
//   }
// }

export function* watchUploadContact() {
  yield takeEvery(SEND_UPLOAD_CONTACT, uploadContactSaga);
}
// export function* watchUploadContact() {
//   yield takeEvery(BULK_SMS_SCHEDULE, scheduleBulkSMSSaga);
// }

function* uploadContact() {
  yield all([fork(watchUploadContact)]);
  // yield all([fork(watchScheduleBulkSMS)]);
}

export default uploadContact;
