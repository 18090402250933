import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import icon1 from "../../assets/images/svg/Telecom-icon1.svg";
import icon2 from "../../assets/images/svg/Telecom-icon2.svg";
import icon3 from "../../assets/images/svg/Telecom-icon3.svg";
import icon4 from "../../assets/images/svg/Telecom-icon4.svg";
import icon5 from "../../assets/images/svg/Telecom-icon5.svg";
import Cardimg1 from "../../assets/images/svg/Partner-card1.png";
import Cardimg2 from "../../assets/images/svg/Partner-card2.png";
import Cardimg3 from "../../assets/images/svg/Partner-card3.png";
import Cardimg4 from "../../assets/images/svg/Partner-card4.png";
const Banner3 = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section>
      <div className="" style={{ marginBottom: "120px", marginTop: "150px"}}>
        <div>
          <div className=" mx-5" style={{ marginBottom: "80px" }}>
            <h2 className=" text-dark mb-5 fs-48">
              Scalable transformation of
              <br />
              your business opportunities
            </h2>
            <p className="" style={{ width: "" }}>
              At BRIVAS, we understand the power of collaboration and the role
              it plays in driving business transformation. Our partnership
              program is designed to equip businesses with the tools they need
              to excel in the ever-evolving landscape of customer engagement. By
              partnering with BRIVAS, you'll tap into a world of opportunities
              that will enable you to thrive. Discover how our expertise and
              cutting-edge conversational technology can elevate your customer
              interactions and streamline your operations.
            </p>
          </div>

          <div className="d-flex justify-content-center gap-5">
            <div class="card" style={{ width: "250px" }}>
              <img src={Cardimg1} class="card-img-top" alt="..." />
              <div class="card-body">
                <p class="card-text">
                  Leverage BRIVAS' expertise and world-leading conversational
                  technology to deliver unparalleled value to your customers.
                </p>
                <h6 className="mt-4 text-dark text-bolder fs-16 fw-700">Unleash Maximum Value</h6>
              </div>
            </div>
            <div class="card" style={{ width: "250px" }}>
              <img src={Cardimg2} class="card-img-top" alt="..." />
              <div class="card-body">
                <p class="card-text">
                  Our solutions seamlessly integrate with existing cloud-based
                  customer engagement platforms, ensuring a smooth and efficient
                  transition.
                </p>
                <h6 className="mt-3 text-dark text-bolder fs-16 fw-700">Seamless Integration</h6>
              </div>
            </div>
            <div class="card" style={{ width: "250px" }}>
              <img src={Cardimg3} class="card-img-top" alt="..." />
              <div class="card-body">
                <p class="card-text">
                  Partnering with BRIVAS allows you to scale your business
                  opportunities and stay ahead in the dynamic customer
                  engagement landscape.
                </p>
                <h6 className="mt-3 text-dark text-bolder fs-16 fw-700">Scalable Transformation</h6>
              </div>
            </div>
            <div class="card" style={{ width: "250px" }}>
              <img src={Cardimg4} class="card-img-top" alt="..." />
              <div class="card-body">
                <p class="card-text">
                  Embrace future-proof solutions that adapt and evolve with the
                  changing needs of your customers, ensuring long-term success.
                </p>
                <h6 className="mt-4 text-dark text-bolder fs-16 fw-700">Future-Proof Solutions</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner3;
