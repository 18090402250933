import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Crypto Redux States
import {
    LIST_PRODUCT_GROUP_SUCCESS,
    LIST_PRODUCT_GROUP_ERROR,
    LIST_PRODUCT_GROUP,
    GET_PRODUCT,
} from "./actionType";
import {
    listProductGroupSuccess,
    listProductGroupError,
    getProductSuccess,
    getProductError,
} from "./action";

import { productGroups } from "../../common/data/products";

function* listProductGroups() {
    try {
        // const response = yield call(getProductGroups);
        yield put(listProductGroupSuccess(LIST_PRODUCT_GROUP_SUCCESS, productGroups)); 
    } catch (error) {
        yield put(listProductGroupError(LIST_PRODUCT_GROUP_ERROR, error));
    }
}

function* getProduct({ payload }) {
    try {
        const result = productGroups.filter(prd => prd.slug === payload);
        // const response = yield call(addProjectList, data);
        yield put(getProductSuccess({ data: result[0] }));
        toast.success("Product fetched Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(getProductError(error));
        toast.error("project-list Added Failed", { autoClose: 3000 });
    }
}

// function* onUpdateProjectList({ payload: project }) {
//     try {
//         const response = yield call(updateProjectList, project);
//         yield put(updateProjectListSuccess(response));
//         toast.success("project-list Updated Successfully", { autoClose: 3000 });
//     } catch (error) {
//         yield put(updateProjectListFail(error));
//         toast.error("project-list Updated Failed", { autoClose: 3000 });
//     }
// }

// function* onDeleteProjectList({ payload: data }) {
//     try {
//         const response = yield call(deleteProjectList, data);
//         yield put(deleteProjectListSuccess(response));
//         toast.success("project-list Delete Successfully", { autoClose: 3000 });
//     } catch (error) {
//         yield put(deleteProjectListFail(error));
//         toast.error("project-list Delete Failed", { autoClose: 3000 });
//     }
// }

export function* watchGetProjectList() {
    yield takeEvery(LIST_PRODUCT_GROUP, listProductGroups);
}

export function* watchAddProjectList() {
    yield takeEvery(GET_PRODUCT, getProduct);
}

// export function* watchUpdateProjectList() {
//     yield takeEvery(UPDATE_PROJECT_LIST, onUpdateProjectList);
// }

// export function* watchDeleteProjectList() {
//     yield takeEvery(DELETE_PROJECT_LIST, onDeleteProjectList);
// }

function* projectSaga() {
    yield all([
        fork(watchGetProjectList),
        fork(watchAddProjectList),
        // fork(watchUpdateProjectList),
        // fork(watchDeleteProjectList),
    ]);
}

export default projectSaga;
