import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Tomideface from "../../assets/images/svg/tomide-min.png";
import Voicelogo from "../../assets/images/svg/voiceimage.svg";
import VoiceICON1 from "../../assets/images/voiceicon1.png";
import VoiceICON2 from "../../assets/images/voiceicon2.png";
import VoiceICON3 from "../../assets/images/voiceicon3.png";
import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
// import "animate.css/animate.min.css";
import ListIcon from "../../assets/images/svg/voiceservicelogo.svg";

const Home = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <section
        className="section"
        id="hero"
        style={{ backgroundColor: "#FFF5FF" }}
      >
        <div className="mx-md-5 px-3 px-md-5 my-5">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <h1 className="display-6 fw-bold fs-48 lh-base text-dark">
                Next-level communications on a{" "}
                <span className="text-primary">large scale</span>
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 order-2 order-md-1 my-3">
              <h5 className="my-5">SIP TRUNKING</h5>
              <p className="mb-5">
                Unleash the power of unlimited communication with no boundaries.
                Reach out to anyone, anytime, without any restrictions. Seize
                the future of connectivity with Brivas SIP trunking.
              </p>

              <div className="d-flex gap-2 my-5">
                <button
                  color="primary "
                  className="btn rounded-pill btn-primary"
                  onClick={() => "/register"}
                >
                  Start for free
                  <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                </button>
                <button
                  color="success"
                  className="btn rounded-pill btn-light border-danger"
                  onClick={() => history("/register")}
                >
                  <span className="text-danger">Talk to an expert</span>
                </button>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 voip order-1 d-flex align-items-start">
              <img
                src={Tomideface}
                alt=""
                className="user-img w-75 my-4 mx-md-5 "
              />
            </div>
          </div>
        </div>

        <div className="mx-md-5 px-3 px-md-5">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <h1 className="display-6 fw-bold fs-48 lh-base text-dark">
                Interactive Voice Response{" "}
                <span className="text-primary">(IVR)</span>
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 order-2 order-md-1 my-3">
              <p className="mb-5">
                Integrate two-way communication into phone conversations to
                improve customer relationships. Engage with your target audience
                in their natural habitat. Unlock interactive experiences by
                discovering tailored responses for customers’ needs.
              </p>
              <div className="media d-flex mb-4">
                <img
                  className="align-self-start mx-2"
                  src={VoiceICON1}
                  alt="Generic placeholder image"
                />
                <div className="media-body text-dark">
                  <h5 className="mt-0">Valuable Insights</h5>
                </div>
              </div>
              <div className="media d-flex mb-4">
                <img
                  className="align-self-start mx-2"
                  src={VoiceICON2}
                  alt="Generic placeholder image"
                />
                <div className="media-body text-dark">
                  <h5 className="mt-0">Intelligent Virtual Assistant</h5>
                </div>
              </div>
              <div className="media d-flex mb-4">
                <img
                  className="align-self-start mx-2"
                  src={VoiceICON3}
                  alt="Generic placeholder image"
                />
                <div className="media-body text-dark">
                  <h5 className="mt-0">Personalised Integration</h5>
                </div>
              </div>

              <div className="d-flex gap-2 my-5">
                <button
                  color="primary "
                  className="btn rounded-pill btn-primary"
                  onClick={() => "/register"}
                >
                  Start for free
                  <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                </button>
                <button
                  color="success"
                  className="btn rounded-pill btn-light border-danger"
                  onClick={() => history("/register")}
                >
                  <span className="text-danger">Talk to an expert</span>
                </button>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 voip order-1 d-flex align-items-start justify-content-center">
              <img
                src={Voicelogo}
                alt=""
                className="user-img w-75 mx-md-5"
              />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Home;
