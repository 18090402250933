import React from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import Home from "./Home";
import Banner from "./Banner";
import Banner2 from "./Banner2";
import Banner3 from "./Banner3";
import Bottom from "./Bottom";


const Education = () => {
  
  document.title = "BRIVAS";

  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };

  const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{backgroundColor:'#FFF5FF'}}>
        <Navbar />
        <Home/>
        <Banner/>
        <Banner2/>
        <Banner3/>
        <Bottom/>

        <Footer />
        <button
          onClick={() => toTop()}
          className="btn btn-danger btn-icon landing-back-top"
          id="back-to-top"
        >
          <i className="ri-arrow-up-line"></i>
        </button>
      </div>
    </React.Fragment>
  );
};

export default Education;
