import React from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import Mobilebrand from "./mobilebrand";
import Softwareservices1 from "./Softwareservices1";
import Customers from "./cutomers";
import Secure from "./secure";
import Homenew from "./Homenew";
import Reach from "./reach";
import Meetcustomers from "./meetcustomers";
import Useapi from "./useapi";
import Contact from "./Contact";


const Messaging = () => {
  document.title = "BRIVAS";

  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };

  const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <React.Fragment>
      <div className="layout-wrapper landing">
        <Navbar />
        <Homenew />
        <Reach />
        <Meetcustomers />
        {/* <Mobilebrand /> */}
        <Secure />
        <Useapi />
        <Contact />
        {/* <Customers /> */}
        {/* <Softwareservices1 /> */}

        {/* <h1 id="sms">Messaging</h1> */}

        <Footer />
        <button
          onClick={() => toTop()}
          className="btn btn-danger btn-icon landing-back-top"
          id="back-to-top"
        >
          <i className="ri-arrow-up-line"></i>
        </button>
      </div>
    </React.Fragment>
  );
};

export default Messaging;
