import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import img1 from "../../assets/images/codeimage.png";

const Contact = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <React.Fragment>
      <section className="section bg-dark fs-50" id="plans">
        <div
          className="bg-overlay opacity-100"
          style={{ backgroundColor: "#121C2D" }}
        ></div>
        <Container>
          <Row className="justify-content-center mx-auto text-light">
            <Col xs={12} lg={8}>
              <Row className="align-items-center gy-4">
                <Col xs={12} md={6}>
                  <div className="text-light">
                    <h3 className="mb-3 fw-bold  text-light">
                      Get your app up and running in no time
                      <br />
                      and running in
                      <br />
                      record time
                    </h3>
                    <p className="mb-4 ff-secondary fs-15 text-light">
                      Build the experiences your customers want with our
                      flexible API stack. Integrate their favorite channels with
                      your business workflow to elevate their customer journey.
                    </p>

                    <Link
                      to="#"
                      className="soft-primary w-75  d-flex  text-light"
                    >
                      Talk to an expert{" "}
                      <i className="pl-2 ri-arrow-right-circle-fill text-danger"></i>
                    </Link>
                  </div>
                </Col>

                <Col xs={12} md={6}>
                  <img src={img1} alt="IOT icon" className="img-fluid" />
                </Col>

                <Col xs={12} lg={4}>
                  <h3 className="mb-3 fs-20 fw-bold text-light">SDKs</h3>
                  <p className="mb-4 ff-secondary fs-10">
                    You can easily leverage the full capabilities of our
                    platform and provide a seamless experience for your
                    customers.
                  </p>
                </Col>
                <Col xs={12} lg={4}>
                  <h3 className="mb-3 fs-20 fw-bold text-light ">Functions</h3>
                  <p className="mb-4 ff-secondary fs-10">
                    Brivas is able to take care of the challenging
                    responsibilities so that you can focus on turning your
                    dreams into reality.
                  </p>
                </Col>
                <Col xs={12} lg={4}>
                  <h3 className="mb-3 fs-20 fw-bold text-light ">
                    Reliable APIs
                  </h3>
                  <p className="mb-4 ff-secondary fs-10">
                    With our battle-tested APIs, you can harness the power of
                    Brivas and unleash the full potential of your application.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contact;
