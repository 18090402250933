import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import img1 from "../../assets/images/powerfulautomation.png";
import img2 from "../../assets/images/communicatestreaming.png";
import img3 from "../../assets/images/channelsupport.png";

const Contact = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <React.Fragment>
      <section className="section bg-dark fs-50" id="plans">
        <div
          className="bg-overlay opacity-100"
          style={{ backgroundColor: "#FFF5FF" }}
        ></div>
        <div className="w-75 mx-auto">
          <Row className="justify-content-center text-dark">
            <Col lg={35}>
              <Row className="align-items-center gy-4">
                <Col lg={4}>
                  <h1 className=" fs-35 fw-bold w-100 text-dark">
                    Communicate  <span className="text-danger">Face-to-face</span> Anytime Anywhere
                  </h1>
                </Col>
                <Col lg={4}>
                  <div className="text-center">
                    <img src={img2} alt="" className="img-fluid" />
                  </div>
                </Col>
                <Col lg={4}>
                  <p className=" text-center fs-13">
                    With In-app video calling, stay connected with people on
                    your app. Our seamless live video calls with crystal-clear
                    video and audio quality, privacy and security, and many more
                    features are all you need. You cannot get it better
                    elsewhere.
                  </p>
                  <div>
                    <div className="d-flex justify-content-center my-5">
                      <button
                        color="danger "
                        className=" btn-danger btn rounded-pill btn-primary ml-2"
                        onClick={() => history("/register")}
                      >
                        Start for free
                        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Contact;
