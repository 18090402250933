import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { getAppSuccess, getAppError, createAppSuccess, createAppError, listMyAppsSuccess, listMyAppsError } from "./action";

import { getApp, getMyApps, postApp } from "../../helpers/backend_helper";
import { CREATE_APP, GET_APP, LIST_MY_APPS } from "./actionType";

function* createApp({ payload }) {
  try {
    const json = yield call(postApp, payload);
    if (json.status === "success") {
      yield put(createAppSuccess(json));
    } else {
      yield put(createAppError(json?.msg ?? 'An error occured'));
    }
  } catch (err) {
    yield put(createAppError(err?.message ?? 'Something went wrong'));
  }
}

function* readApp({ payload: { slug } }) {
  try {
    const json = yield call(getApp, slug);
    if (json.status === "success") {
      yield put(getAppSuccess(json));
    } else {
      yield put(getAppError(json));
    }
  } catch (err) {
    yield put(getAppError(err?.message ?? 'An error occured'));
  }
}

function* listMyApps({ payload: { slug } }) {
  try {
    const json = yield call(getMyApps, slug);
    if (json.status === "success") {
      yield put(listMyAppsSuccess(json));
    } else {
      yield put(listMyAppsError(json.msg ?? 'An error occured'));
    }
  } catch (err) {
    yield put(listMyAppsError(err?.message ?? 'Something went wrong'));
  }
}

function* appsSaga() {
  yield takeEvery(GET_APP, readApp);
  yield takeEvery(CREATE_APP, createApp);
  yield takeEvery(LIST_MY_APPS, listMyApps);
}

export default appsSaga;
