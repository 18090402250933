// import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
// import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
// import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
// import {
//   postLogin,
//   postJwtLogin,
//   postSocialLogin,
// } from "../../../helpers/backend_helper";

const fireBaseBackend = getFirebaseBackend();

// function* loginUser({ payload: { user, history } }) {
//   sessionStorage.setItem("authUser", JSON.stringify({ "status": "success", "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzZTliZWZjOWUzZGJjNWJhOWE0NzA4NyIsImlhdCI6MTY4NDc2MTE0MiwiZXhwIjoxNjkyNTM3MTQyfQ.ur5WmskiFN17o6eVzRhIsyCGQBqpOt97ZY0IIwJCJdY", "data": { "_id": "63e9befc9e3dbc5ba9a47087", "first_name": "Siva", "last_name": "Mohammadi", "email": "admin@themesbrand.com", "phone": 93353299096, "image": "", "password": "$2a$12$OdX.AB8Oiz6PEXohnREMjOtIy8h4/Ha3wPMHVcA/J373tQ0afoco2", "role": "0", "confirm_password": "123456789", "changePasswordAt": "2023-02-13T04:32:11.228Z", "skills": ["Photoshop", "illustrator", "HTML", "CSS", "Javascript", "Php", "Python"], "exp_year": ["2018-01-01T00:00:00.000Z", "2023-01-01T00:00:00.000Z"], "__v": 0, "city": "California", "company_name": "Themesbrand", "country": "use", "designation": "Lead Designer / Developer", "job_title": "Developer", "joining_date": "2023-01-01T00:00:00.000Z", "website": "www.velzon.com", "zipcode": "90011", "description": "tehran", "job_description": "You always want to make sure that your fonts work well together and try to limit the number of fonts you use to three or less. Experiment and play around with the fonts that you already have in the software you're working with reputable font websites. ", "portfolio": [{ "logo": "github", "bg_color": "dark", "link": "@daveadame", "_id": "63eb4c2f356e48830e807dba" }, { "logo": "global", "bg_color": "primary", "link": "www.velzon.com", "_id": "63eb4c2f356e48830e807dbb" }, { "logo": "dribbble", "bg_color": "success", "link": "@dave_adame", "_id": "63eb4c2f356e48830e807dbc" }, { "logo": "pinterest", "bg_color": "danger", "link": "Advance Dave", "_id": "63eb4c2f356e48830e807dbd" }], "passwordtoken": "1c408a132d51730497079339d340e273c10cecdd5c7995abd780329dde05cc3d", "passwordtokenexp": "2023-05-16T06:33:11.731Z" } }));
//   history('/dashboard');
//   try {
//     const resp = yield call(postLogin, {
//       email: user.email,
//       password: user.password,
//     });
//     if (resp.status === "success") {
//       yield put(loginSuccess(resp));
//       history('/dashboard');
//       // sessionStorage.setItem("authUser", JSON.stringify(resp.data));
//       sessionStorage.setItem("authUser", JSON.stringify({ "status": "success", "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzZTliZWZjOWUzZGJjNWJhOWE0NzA4NyIsImlhdCI6MTY4NDc2MTE0MiwiZXhwIjoxNjkyNTM3MTQyfQ.ur5WmskiFN17o6eVzRhIsyCGQBqpOt97ZY0IIwJCJdY", "data": { "_id": "63e9befc9e3dbc5ba9a47087", "first_name": "Siva", "last_name": "Mohammadi", "email": "admin@themesbrand.com", "phone": 93353299096, "image": "", "password": "$2a$12$OdX.AB8Oiz6PEXohnREMjOtIy8h4/Ha3wPMHVcA/J373tQ0afoco2", "role": "0", "confirm_password": "123456789", "changePasswordAt": "2023-02-13T04:32:11.228Z", "skills": ["Photoshop", "illustrator", "HTML", "CSS", "Javascript", "Php", "Python"], "exp_year": ["2018-01-01T00:00:00.000Z", "2023-01-01T00:00:00.000Z"], "__v": 0, "city": "California", "company_name": "Themesbrand", "country": "use", "designation": "Lead Designer / Developer", "job_title": "Developer", "joining_date": "2023-01-01T00:00:00.000Z", "website": "www.velzon.com", "zipcode": "90011", "description": "tehran", "job_description": "You always want to make sure that your fonts work well together and try to limit the number of fonts you use to three or less. Experiment and play around with the fonts that you already have in the software you're working with reputable font websites. ", "portfolio": [{ "logo": "github", "bg_color": "dark", "link": "@daveadame", "_id": "63eb4c2f356e48830e807dba" }, { "logo": "global", "bg_color": "primary", "link": "www.velzon.com", "_id": "63eb4c2f356e48830e807dbb" }, { "logo": "dribbble", "bg_color": "success", "link": "@dave_adame", "_id": "63eb4c2f356e48830e807dbc" }, { "logo": "pinterest", "bg_color": "danger", "link": "Advance Dave", "_id": "63eb4c2f356e48830e807dbd" }], "passwordtoken": "1c408a132d51730497079339d340e273c10cecdd5c7995abd780329dde05cc3d", "passwordtokenexp": "2023-05-16T06:33:11.731Z" } }));
//     } else {
//       yield put(apiError(resp));
//     }
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

// function* logoutUser() {
//   try {
//     sessionStorage.removeItem("authUser");
//     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//       const resp = yield call(fireBaseBackend.logout);
//       yield put(logoutUserSuccess(LOGOUT_USER, resp));
//     } else {
//       yield put(logoutUserSuccess(LOGOUT_USER, true));
//     }
//   } catch (error) {
//     yield put(apiError(LOGOUT_USER, error));
//   }
// }

// function* socialLogin({ payload: { data, history, type } }) {
//   try {
//     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//       const fireBaseBackend = getFirebaseBackend();
//       const resp = yield call(
//         fireBaseBackend.socialLoginUser,
//         data,
//         type,
//       );
//       sessionStorage.setItem("authUser", JSON.stringify(resp));
//       yield put(loginSuccess(resp));
//     } else {
//       const resp = yield call(postSocialLogin, data);
//       sessionStorage.setItem("authUser", JSON.stringify(resp));
//       yield put(loginSuccess(resp));
//     }
//     history('/dashboard');
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

function* authSaga() {
  // yield takeEvery(LOGIN_USER, loginUser);
  // yield takeLatest(SOCIAL_LOGIN, socialLogin);
  // yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
