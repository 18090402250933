import {
  VERIFY_ACCOUNT,
  VERIFY_ACCOUNT_FAILED,
  VERIFY_ACCOUNT_SUCCESS
} from "./actionTypes";

const initialState = {
  errorMsg: "",
  loading: false,
  error: false,
  success: false,
};

const VerifyAccount = (state = initialState, action) => {

  switch (action.type) {
    case VERIFY_ACCOUNT:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case VERIFY_ACCOUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        success: true,
      };
      break;
    case VERIFY_ACCOUNT_FAILED:
      state = {
        ...state,
        errorMsg: action.payload.data,
        loading: true,
        error: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default VerifyAccount;
