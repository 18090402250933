export const CREATE_APP = "CREATE_APP";
export const CREATE_APP_SUCCESS = "CREATE_APP_SUCCESS";
export const CREATE_APP_ERROR = "CREATE_APP_ERROR";

export const GET_APP = "GET_APP";
export const GET_APP_SUCCESS = "GET_APP_SUCCESS";
export const GET_APP_ERROR = "GET_APP_ERROR";

export const LIST_MY_APPS = "LIST_MY_APPS";
export const LIST_MY_APPS_SUCCESS = "LIST_MY_APPS_SUCCESS";
export const LIST_MY_APPS_ERROR = "LIST_MY_APPS_ERROR";