import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';

const OverviewTab = () => {

    const { app } = useSelector(state => ({
        app: state.App.app
    }));

    return (
        <React.Fragment>
            <Row>
                <Col xl={9} lg={8}>
                    <Card className='shadow-none'>
                        <CardBody>
                            <div className="text-muted">
                                <p>{app?.description}</p>

                                <h6 className="mb-3 fw-semibold text-uppercase">Features</h6>
                                <ul className="ps-4 vstack gap-2">
                                    <li>Product Design, Figma (Software), Prototype</li>
                                    <li>Four Dashboards : Ecommerce, Analytics, Project,etc.</li>
                                    <li>Create calendar, chat and email app pages.</li>
                                    <li>Add authentication pages.</li>
                                    <li>Content listing.</li>
                                </ul>
                            </div>
                        </CardBody>

                    </Card>
                </Col>

                <Col xl={3} lg={4}>
                    <Card className='shadow-none'>
                        <CardBody>
                            <h5 className="card-title mb-4">Other Apps</h5>
                            <div className="d-flex flex-wrap gap-2 fs-16">
                                <div className="badge fw-medium badge-soft-secondary">SMS</div>
                                <div className="badge fw-medium badge-soft-secondary">Bulk SMS</div>
                                <div className="badge fw-medium badge-soft-secondary">IVR</div>
                                <div className="badge fw-medium badge-soft-secondary">Audio Streaming</div>
                                <div className="badge fw-medium badge-soft-secondary">Video Streaming</div>
                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default OverviewTab;