import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

import Banner2img from "../../assets/images/svg/Telecomm-banner2-img.svg";
import Banner3img from "../../assets/images/svg/Telecom-banner3-min.png";
import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const Banner2 = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <Row>
        <div className="mt-10">
          <img src={Banner3img} alt="" className="img-fluid" />
        </div>
      </Row>
      <div class="row mx-md-5 px-3 px-md-5 my-5">
        <div class="col-md-6 d-none d-md-inline">
          <img src={Banner2img} alt="" className="user-img" />
        </div>
        <div class="col-md-6 my-md-5">
          <h1
            className="fw-bold lh-base text-primary mb-4  "
            style={{ fontSize: "40px" }}
          >
            Upgrade Privacy with Our Telecommunications Solution
          </h1>

          <div className="d-flex gap-2 my-5">
            <button
              color="primary "
              className="btn rounded-pill btn-primary"
              onClick={() => "/register"}
            >
              Start for free
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Banner2;
