import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { VERIFY_OTP, VERIFY_OTP_INIT } from "./actionTypes";
import { verifyOTPSuccess, verifyOTPError, verifyOTPInitSuccess } from "./actions";

import { getVerifyOTP, postVerifyOTP } from "../../../helpers/backend_helper";

function* verifyOTPInit() {
  try {
    const json = yield call(getVerifyOTP);
    if (json.status === "success") {
      yield put(verifyOTPInitSuccess(json));
    }
  } catch (err) {
    // 
  }
}

function* verifyOTP({ payload: { otp, history } }) {
  try {
    const json = yield call(postVerifyOTP, { otp });
    if (json.status === "success") {
      yield put(verifyOTPSuccess(json));
    } else {
      yield put(verifyOTPError(json?.msg ?? 'Something went wrong'));
    }
  } catch (err) {
    yield put(verifyOTPError(err?.response?.data?.msg ?? err?.message ?? 'An error occured'));
  }
}

function* verifyOTPSaga() {
  yield takeEvery(VERIFY_OTP, verifyOTP);
  yield takeEvery(VERIFY_OTP_INIT, verifyOTPInit);
}

export default verifyOTPSaga;
