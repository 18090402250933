import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Container, Input, Label, Row, Button, FormFeedback, Spinner, Alert } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";

import * as Yup from "yup";
import { useFormik } from "formik";

import AuthSlider from './authCarousel';

import logoLight from "../../assets/images/logo.svg";
import withRouter from '../../Components/Common/withRouter';
// import { generateQRCode } from '../../store/actions';

const QRLogin = (props) => {
    document.title = "BRIVAS | QR Login";

    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(generateQRCode());
    }, [dispatch]);

    const { qrcode, msg, loading, error, success } = useSelector(state => ({
        qrcode: state.QRLogin.qrcode,
        msg: state.QRLogin.msg,
        loading: state.QRLogin.loading,
        error: state.QRLogin.error,
        success: state.QRLogin.success,
    }));

    console.log(msg);

    return (
        <React.Fragment>
            <div className="auth-page-wrapper py-3 d-flex justify-content-center align-items-center min-vh-100">
                <div className=""></div>
                <div className="auth-page-content overflow-hidden pt-lg-1">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden">
                                    <Row className="g-0">
                                        <AuthSlider />

                                        <Col lg={6}>
                                            <div className="p-lg-5 p-4">
                                                {/* <div>
                                                    <h5 className="text-primary">Welcome Back !</h5>
                                                    <p className="text-muted">Sign in to continue to Velzon.</p>
                                                </div> */}

                                                <Row>
                                                    <Col lg={12}>
                                                        {/* text-white-50 */}
                                                        <div className="text-center mt-sm-1 mb-0 text-black-55">
                                                            <div>
                                                                <Link to="/" className="d-inline-block auth-logo">
                                                                    <img src={logoLight} alt="" height="20" />
                                                                </Link>
                                                            </div>
                                                            {/* fw-medium */}
                                                            <p className="mt-3 fs-13">Scan to Login</p>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <div className="mt-4">
                                                    {error && msg ? (
                                                        <Alert color="danger">{msg}</Alert>
                                                    ) : null}
                                                    {success && msg ? (
                                                        <Alert color="success">
                                                            {msg}
                                                        </Alert>
                                                    ) : null}

                                                    <div className="text-center">
                                                        <img alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIQAAACECAYAAABRRIOnAAAAAklEQVR4AewaftIAAAO9SURBVO3BQY5bSwIDwWRB979yTi/+gqsChCf12AYj4g9m/nOYKYeZcpgph5lymCmHmXKYKYeZcpgph5lymCmHmXKYKYeZcpgpLx5Kwm9SuUlCU3lHEt6hcpOE36TyxGGmHGbKYaa8+DCVT0rCTRKayhMqLQlNpSWhqdyofFISPukwUw4z5TBTXnxZEt6h8g6VloSm8o4kNJVvSsI7VL7pMFMOM+UwU17845Jwo9JU3qHyLznMlMNMOcyUF3+5JNyoPJGEptKS0FT+ZoeZcpgph5ny4stUvkmlJeGbktBUnlD5kxxmymGmHGbKiw9Lwm9KQlNpSWgqLQlN5UalJaGp3CThT3aYKYeZcpgp8Qf/kCQ0lZaEd6i0JNyo/M0OM+UwUw4z5cVDSWgqLQlNpSWhqbQkNJV3JKGpvCMJTeWTktBUbpLQVD7pMFMOM+UwU+IPHkjCN6k8kYSm0pLQVJ5Iwo3KTRKayk0SmsoTh5lymCmHmfLiIZWWhHeo3CShqbQk3Ki0JNwkoancJOFGpSWhqfxJDjPlMFMOM+XFL1O5SUJTaUloKi0J71D5JJUnVG6S8E2HmXKYKYeZ8uKhJNyotCTcqLQkPJGEptKS0FRuktBUWhJuVG6ScKPyTYeZcpgph5kSf/BBSfgklZskNJWWhCdUbpLwDpU/2WGmHGbKYabEHzyQhKZyk4Sm0pLwSSotCTcqLQlNpSWhqbQkvEPlJgk3Kk8cZsphphxmyouHVFoSmspNEprKTRKayjtUWhJaEt6h0pJwo/InO8yUw0w5zJT4gweS0FRaEm5UWhLeodKS8A6VmyT8JpX/p8NMOcyUw0x58ZDKjco7VN6RhN+k0pLQVN6RhJskNJVvOsyUw0w5zJQXDyXhN6k0lZaEG5WbJDSVloR3JKGpPJGEG5UnDjPlMFMOM+XFh6l8UhI+KQlNpancqLQk3Kg8oXKThE86zJTDTDnMlBdfloR3qLwjCU2lJaGptCQ0lSeS8ElJaCpN5ZMOM+UwUw4z5cVfTuUdSbhJQlNpSWgqLQlNpSWhqbQk3CShqXzSYaYcZsphprz4yyXhRqUl4UalJaGp3Ki8IwlNpSWhqbQkNJUnDjPlMFMOM+XFl6l8k0pLQktCU2lJaEm4SUJTaUloKjcqNyotCU3lkw4z5TBTDjPlxYcl4Tcl4UalJaGp3CThJglNpSXhJgk3KjdJaCpPHGbKYaYcZkr8wcx/DjPlMFMOM+UwUw4z5TBTDjPlMFMOM+UwUw4z5TBTDjPlMFMOM+V/+ajEAtz90t0AAAAASUVORK5CYII=' />
                                                    </div>

                                                    <div className="mt-4">
                                                        <div className="signin-other-title text-center">
                                                            <h5 className="fs-13 mb-4 title text-muted">OR</h5>
                                                        </div>

                                                        <button className="btn w-100 rounded-pill p-2 d-flex justify-content-center align-items-center btn-outline-secondary" type="submit">
                                                            <i className="ri-google-fill fs-13"></i> <span className="fs-11"> Login with Google</span>
                                                        </button>
                                                        <button className="btn w-100 rounded-pill mt-3 p-2 d-flex justify-content-center align-items-center btn-outline-secondary" type="submit">
                                                            <i className="ri-apple-fill fs-13"></i> <span className="fs-11"> Login with Apple</span>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p className="mb-0 fs-12">Don't have an account ? <a href="/register" className="text-primary"> Sign up</a> </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>
        </React.Fragment>
    );
};

export default withRouter(QRLogin);