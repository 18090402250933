import {
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,
  VERIFY_OTP_INIT,
  VERIFY_OTP_INIT_SUCCESS,
  VERIFY_OTP_RESET_FLAG,
  // VERIFY_OTP_INIT_FAILED,
} from "./actionTypes";

export const verifyOTPInit = () => {
  return {
    type: VERIFY_OTP_INIT
  };
};

export const verifyOTPInitSuccess = data => {
  return {
    type: VERIFY_OTP_INIT_SUCCESS,
    payload: data,
  };
};

export const verifyOTP = (otp, history) => {
  return {
    type: VERIFY_OTP,
    payload: { otp, history },
  };
};

export const verifyOTPSuccess = data => {
  return {
    type: VERIFY_OTP_SUCCESS,
    payload: data,
  };
};

export const verifyOTPError = error => {
  return {
    type: VERIFY_OTP_FAILED,
    payload: error,
  };
};

export const resetVerifyOTPFlag = () => { 
  return {
    type: VERIFY_OTP_RESET_FLAG,
  }
}


