import {
  CREATE_ACCOUNT,
  CREATE_SUCCESS,
  CREATE_ACCOUNT_FAILED,
  RESET_CREATE_ACCOUNT_FLAG,
  CREATE_ACCOUNT_INIT,
  CREATE_ACCOUNT_INIT_SUCCESS,
  CREATE_ACCOUNT_INIT_FAILED
} from "./actionTypes";

const initialState = {
  msg: null,
  loading: false,
  error: false,
  success: false,
  type: null,
};

const CreateAccount = (state = initialState, action) => {

  switch (action.type) {
    case CREATE_ACCOUNT_INIT:
      state = { ...state, loading: true, error: false, success: false };
      break;
    case CREATE_ACCOUNT_INIT_SUCCESS:
      state = { ...state, loading: false, error: false, type: action.payload.data };
      break;
    case CREATE_ACCOUNT_INIT_FAILED:
      state = { ...state, loading: true, error: true, msg: action.payload.msg };
      break;

    case CREATE_ACCOUNT:
      state = { ...state, loading: true, error: false };
      break;
    case CREATE_SUCCESS:
      state = { ...state, loading: false, error: false };
      break;
    case CREATE_ACCOUNT_FAILED:
      state = { ...state, msg: action.payload.msg, loading: false, error: true };
      break;

    case RESET_CREATE_ACCOUNT_FLAG:
      state = { ...state, msg: null, loading: false, error: false, success:false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CreateAccount;
