import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import LogoLight from "../assets/images/logo.svg";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="custom-footer py-5 position-relative">
        <div className="text-center mb-5 px-md-5">
          <h2 className="mb-1 text-primary lh-base w-50 m-auto px-md-5">
            Effortless communication mastery. You focus, we take charge!
          </h2>
        </div>

        <div className="d-flex justify-content-center">
          <button color="danger" className="btn rounded-pill btn-danger">
            Talk to an expert
            <i className=" align-middle ms-1 rounded-pill"></i>
          </button>
        </div>

        <div className="row my-5 py-2 mx-md-5 mx-sm-2 px-md-5 mx-2">
          <div className="col-md-4 col-sm-12 mb-sm-5">
            <img src={LogoLight} alt="logo light" height="30" />
            <div className="mt-4 fs-15">
              <ul className="list-inline mb-0 footer-social-link">
                <li className="list-inline-item">
                  <Link to="#!" className="avatar-xs d-block">
                    <div className="avatar-title rounded-circle">
                      <i className="ri-facebook-fill"></i>
                    </div>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#!" className="avatar-xs d-block">
                    <div className="avatar-title rounded-circle">
                      <i className="ri-github-fill"></i>
                    </div>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#!" className="avatar-xs d-block">
                    <div className="avatar-title rounded-circle">
                      <i className="ri-linkedin-fill"></i>
                    </div>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#!" className="avatar-xs d-block">
                    <div className="avatar-title rounded-circle">
                      <i className="ri-google-fill"></i>
                    </div>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#!" className="avatar-xs d-block">
                    <div className="avatar-title rounded-circle">
                      <i className="ri-dribbble-line"></i>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-2 col-sm-12">
            <h5 className="mb-0">Products</h5>
            <div className=" mt-3">
              <ul className="list-unstyled ff-secondary footer-list fs-16">
                <li>
                  <Link to="/messaging#sms">Messaging</Link>
                </li>
                <li>
                  <Link to="/voice#voice-services">Voice</Link>
                </li>
                <li>
                  <Link to="/verification">Verification </Link>
                </li>
                <li>
                  <Link to="/streaming">Streaming</Link>
                </li>
                <li>
                  <Link to="/email">Email</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-2 col-sm-12">
            <h5 className="mb-0">Solutions</h5>
            <div className="text-muted mt-3">
              <ul className="list-unstyled ff-secondary footer-list fs-16">
                <li>
                  <Link to="../startup">Start-up</Link>
                </li>
                <li>
                  <Link to="../enterprise">Enterprise</Link>
                </li>
                <li>
                  <Link to="../ecommerce">Ecommerce</Link>
                </li>
                <li>
                  <Link to="../retail">Retail</Link>
                </li>
                <li>
                  <Link to="../telehealth">Healthcare</Link>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="col-md-2 col-sm-12">
            <h5 className="mb-0">Cooperate</h5>
            <div className="text-muted mt-3">
              <ul className="list-unstyled ff-secondary footer-list fs-16">
                <li>
                  <Link to="/pages-profile">Customer engagement</Link>
                </li>
                <li>
                  <Link to="/pages-gallery">Customer data platform</Link>
                </li>
                <li>
                  <Link to="/pages-team">Customer testimonies</Link>
                </li>
                <li>
                  <Link to="/pages-pricing">Chat bot building</Link>
                </li>
                <li>
                  <Link to="/pages-timeline">Timeline</Link>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="col-md-2 col-sm-12">
            <h5 className="mb-0">Company</h5>
            <div className="text-muted mt-3">
              <ul className="list-unstyled ff-secondary footer-list fs-16">
                <li>
                  <Link to="https://www.billyronks.net">About Us</Link>
                </li>
                <li>
                  <Link to="/pages-gallery">Gallery</Link>
                </li>
                <li>
                  <Link to="/pages-team">Team</Link>
                </li>
                <li>
                  <Link to="/pages-pricing">Pricing</Link>
                </li>
                <li>
                  <Link to="/pages-timeline">Timeline</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p className="copy-rights mb-0 fs-12 text-center mt-5">
          Copyright © {new Date().getFullYear()} Brivas. All rights reserved
        </p>

        <Container>
          <Row>
            <Col className="col-lg-4 mt-4"></Col>

            <Col className="col-lg-7 ms-lg-auto">
              <Row>
                <Col className="col-sm-4 mt-4"></Col>
                <Col className="col-sm-4 mt-4"></Col>
                <Col className="col-sm-4 mt-4"></Col>
              </Row>
            </Col>
          </Row>

          <Row className="text-center text-sm-start align-items-center mt-5">
            <Col className="col-sm-6">
              <div></div>
            </Col>
            {/* <Col className="col-sm-6">
              <div className="text-sm-end mt-3 mt-sm-0">
                <ul className="list-inline mb-0 footer-list gap-4 fs-15">
                  <li className="list-inline-item">
                    <Link to="/pages-privacy-policy">Privacy Policy</Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="/pages-term-conditions">
                      Terms & Conditions
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="/pages-privacy-policy">Security</Link>
                  </li>
                </ul>
              </div>
            </Col> */}
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
