import {
  GET_APP,
  GET_APP_SUCCESS,
  GET_APP_ERROR,
  CREATE_APP,
  CREATE_APP_SUCCESS,
  CREATE_APP_ERROR,
  LIST_MY_APPS,
  LIST_MY_APPS_SUCCESS,
  LIST_MY_APPS_ERROR
} from "./actionType";

export const createApp = (data) => ({
  type: CREATE_APP,
  payload: data,
});

export const createAppSuccess = json => {
  return {
    type: CREATE_APP_SUCCESS,
    payload: json,
  };
};

export const createAppError = msg => {
  return {
    type: CREATE_APP_ERROR,
    payload: msg,
  };
};

export const getApp = (slug) => ({
  type: GET_APP,
  payload: { slug },
});

export const getAppSuccess = data => {
  return {
    type: GET_APP_SUCCESS,
    payload: data,
  };
};

export const getAppError = msg => {
  return {
    type: GET_APP_ERROR,
    payload: msg,
  };
};

export const listMyApps = (slug) => ({
  type: LIST_MY_APPS,
  payload: { slug },
});

export const listMyAppsSuccess = json => {
  return {
    type: LIST_MY_APPS_SUCCESS,
    payload: json,
  };
};

export const listMyAppsError = msg => {
  return {
    type: LIST_MY_APPS_ERROR,
    payload: msg,
  };
};