import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import img1 from "../../assets/images/svg/Telecom-banner4.svg";
import TowerIcon from "../../assets/images/svg/network-tower.png";
import Banner3img from "../../assets/images/educationbanner3.png";

const Banner3 = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section>
      <div className="mx-md-5 px-3 px-md-5">
        <div class="row">
          <div class="col-md-6 col-sm-12 order-2 order-md-1">
            <p
              className="display-6 fw-normal pt-5 lh-base text-sm-center text-md-start mt-md-5 mb-5"
              style={{ fontSize: "20px" }}
            >
              The provision of full streaming services entails the inclusion of
              a wide array of resources, such as E-Learning Apps, video
              conferencing, Audiobooks, Podcasts, and Webinars. These dynamic
              materials provide interactive and adaptable learning experiences
              that accommodate diverse learning styles and interests.
            </p>
           

          </div>
          <div class="col-md-6 col-sm-12 my-5 d-flex align-items-center order-1">
            <img src={Banner3img} alt="" className="user-img w-75" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner3;
