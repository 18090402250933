import React from 'react';
import { Container } from 'reactstrap';


const CreateApp = () => {
    document.title = "Create App | BRIVAS";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <iframe
                        title='sce'
                        // src="https://google.com/search?q=https://rolaay-friendly-enigma-7rr94xpvj5qhxjw-3000.preview.app.github.dev/app/create" 
                        // src="http://18.135.66.85:8080/#/login"
                        src='http://sdp.brivas.io/visual-designer'
                        width={'100%'} height={700}>
                    </iframe>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CreateApp;