import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

const Notifications = () => {
  document.title = "BRIVAS | Notifications";

  const notifs = [
    {
      title:
        "You were granted access to IVR service, please open and view details on your application.",
      time: "Februray 16, 2023",
    },
    {
      title:
        "You were granted access to IVR service, please open and view details on your application.",
      time: "Februray 16, 2023",
    },
    {
      title:
        "You were granted access to IVR service, please open and view details on your application.",
      time: "Februray 16, 2023",
    },
    {
      title:
        "You were granted access to IVR service, please open and view details on your application.",
      time: "Februray 16, 2023",
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <h4 className="fs-23 mb-1 fw-300">
            All your notifications in one place
          </h4>
          {notifs.map((item, index) => {
            return (
              <Card Key={`notif-${index}`} className="card-animate">
                <CardBody>
                  <p className="fw-medium text-truncate mb-2">{item.title}</p>
                  <p className="fs-12 text-muted text-truncate mb-0">
                    {item.time}
                  </p>
                </CardBody>
              </Card>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Notifications;
