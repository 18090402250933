import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
  Cell,
} from "recharts";

import Chart from "chart.js/auto";

import classnames from "classnames"; // Import classnames here

import { Link, NavLink } from "react-router-dom";
import img2 from "../../assets/images/dashboardimage.png";
import img3 from "../../assets/images/dashimage.png";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CardActions } from "@mui/joy";

import avtarImage1 from "../../assets/images/suredlogo.png";
import avtarImage2 from "../../assets/images/audioimage.png";
import avtarImage3 from "../../assets/images/suredlogo.png";
import avtarImage4 from "../../assets/images/sugreenlogo.png";
import avtarImage5 from "../../assets/images/suredlogo.png";
import avtarImage6 from "../../assets/images/sugreenlogo.png";

const Users = () => {
  const [showLiveKey, setShowLiveKey] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState(false);

  // Custom Tabs Bordered
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const [showExtraContent, setShowExtraContent] = useState(false);

  const handleUserClick = () => {
    setShowExtraContent(!showExtraContent);
  };

  /**
   * {showExtraContent ? <div>true</div> : <div>false</div>}
   */

  // ChartJS.register(
  //   ArcElement,
  //   Tooltip,
  //   Legend
  // );

  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const myChartRef = chartRef.current.getContext("2d");

    chartInstance.current = new Chart(myChartRef, {
      type: "doughnut",
      data: {
        labels: ["45 % Calls", "55 % Calls"],

        datasets: [
          {
            data: [45, 55],
            backgroundColor: ["#118959", "#C8C8C8"],
          },
        ],
      },
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  const chartRef2 = useRef(null);
  const chartInstance2 = useRef(null);

  useEffect(() => {
    if (chartInstance2.current) {
      chartInstance2.current.destroy();
    }
    const mychartRef2 = chartRef2.current.getContext("2d");

    chartInstance2.current = new Chart(mychartRef2, {
      type: "doughnut",
      data: {
        labels: ["20% calls", "80% Calls"],

        datasets: [
          {
            data: [20, 80],
            backgroundColor: ["#118959", "#C8C8C8"],
          },
        ],
      },
    });

    return () => {
      if (chartInstance2.current) {
        chartInstance2.current.destroy();
      }
    };
  }, []);

  const chartRef3 = useRef(null);
  const chartInstance3 = useRef(null);

  useEffect(() => {
    if (chartInstance3.current) {
      chartInstance3.current.destroy();
    }
    const mychartRef3 = chartRef3.current.getContext("2d");

    chartInstance.current = new Chart(mychartRef3, {
      type: "doughnut",
      data: {
        labels: ["25 % calls", "75 % calls"],

        datasets: [
          {
            data: [25, 75],
            backgroundColor: ["#118959", "#C8C8C8"],
          },
        ],
      },
    });

    return () => {
      if (chartInstance3.current) {
        chartInstance3.current.destroy();
      }
    };
  }, []);

  const data = [
    {
      name: "Bulk SMS",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Voice",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Flash Call",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "WhatsApp",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Instagram",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Socials",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Socials",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  useEffect(() => {}, []);

  document.title = "BRIVAS | Dashboard";

  return (
    <React.Fragment>
      <div className="shadow-none">
        <div className="shadow-none">
          <Row className="mb-3 p-3 pb-1">
            <Col xs={12}>
              <div className="d-flex p-4 align-items-lg-center flex-lg-row flex-column">
                <div className="flex-grow-1">
                  <h1 className=" fw-bold text-dark mb-1">Users</h1>
                  <p className="text-muted mb-0">
                    Getting started with our voice and video API
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col" style={{ width: "42px" }}>
                    <div className="form-check">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="responsivetableCheck"
                      />
                      <Label
                        className="form-check-label"
                        for="responsivetableCheck"
                      ></Label>
                    </div>
                  </th>
                  <th scope="col">Username</th>
                  <th scope="col">Contact Name</th>
                  <th scope="col">Phone Numbers</th>
                  <th scope="col">Contact Emails</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <div className="form-check">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="responsivetableCheck01"
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="responsivetableCheck01"
                      ></Label>
                    </div>
                  </th>
                  <td>
                    <div className="d-flex gap-2 align-items-center">
                      <div className="flex-shrink-0">
                        <img
                          src={avtarImage1}
                          alt=""
                          className="avatar-xs rounded-circle"
                        />
                      </div>
                      <div className="flex-grow-1">
                        <span
                          onClick={handleUserClick}
                          style={{ cursor: "pointer", color: "blue" }}
                        >
                          <Link
                            to="/users/admin"
                            className=" text-dark  fw-semibold text-center py-2"
                          >
                            Sample User
                          </Link>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <Link
                      to="/users/admin"
                      className=" text-dark text-center py-2"
                    >
                      Client Name
                    </Link>
                  </td>
                  <td>
                  <Link
                      to="/users/admin"
                      className=" text-dark  text-center py-2"
                    >
                      +234 76 444 44 2
                    </Link>
                  </td>
                  <td>isaacchukwuka67@gmail.com</td>
                </tr>

                <tr>
                  <th scope="row">
                    <div className="form-check">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="responsivetableCheck05"
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="responsivetableCheck05"
                      ></Label>
                    </div>
                  </th>
                  <td>
                    <div className="d-flex gap-2 align-items-center">
                      <div className="flex-shrink-0">
                        <img
                          src={avtarImage1}
                          alt=""
                          className="avatar-xs rounded-circle"
                        />
                      </div>
                      <div className="flex-grow-1">Sample Customer</div>
                    </div>
                  </td>
                  <td>Client name 2</td>
                  <td>+234 76 444 44 2</td>
                  <td>isaacchukwuka67@gmail.com</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>

        {showExtraContent && (
          <div className="p-3">
            <Col xs={12}>
              <div className="d-flex p-4 align-items-lg-center flex-lg-row flex-column">
                <div className="flex-grow-1">
                  <h2 className=" fw-bold mb-1">Sample Users</h2>
                  <p className="text-muted mb-0">User Sender ID Details</p>
                </div>
              </div>
            </Col>

            <Col xxl={10}>
              <Nav
                tabs
                className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3"
              >
                <NavItem className="text-start text-dark fw-bold">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1");
                    }}
                  >
                    User Details
                  </NavLink>
                </NavItem>
                <NavItem className="text-start">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2");
                    }}
                  >
                    API Usage
                  </NavLink>
                </NavItem>
                <NavItem className="text-start">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleCustom("3");
                    }}
                  >
                    Sender ID's
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={customActiveTab} className="text-muted">
                <TabPane tabId="1" id="home1">
                  <div className="d-flex">
                    <Col xxl={12}>
                      <Card>
                        <CardBody>
                          <div className="live-preview mt-2">
                            <form action="#">
                              <Row className="mb-3">
                                <Col lg={3}>
                                  <Label
                                    htmlFor="nameInput"
                                    className="form-label fw-bold"
                                  >
                                    First Name:{" "}
                                    <span className="text-danger"></span>
                                  </Label>
                                </Col>
                                <Col lg={9}>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="nameInput"
                                    placeholder="Enter your name"
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col lg={3}>
                                  <Label
                                    htmlFor="websiteUrl"
                                    className="form-label fw-bold"
                                  >
                                    Last Name:{" "}
                                    <span className="text-danger"></span>
                                  </Label>
                                </Col>
                                <Col lg={9}>
                                  <Input
                                    type="url"
                                    className="form-control "
                                    id="websiteUrl"
                                    placeholder="Enter your url"
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col lg={3}>
                                  <Label
                                    htmlFor="leaveemails"
                                    className="form-label fw-bold"
                                  >
                                    Username:{" "}
                                    <span className="text-danger"></span>
                                  </Label>
                                </Col>
                                <Col lg={9}>
                                  <Input
                                    type="email"
                                    className="form-control"
                                    id="leaveemails"
                                    placeholder="Enter your Username"
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col lg={3}>
                                  <Label
                                    htmlFor="contactNumber"
                                    className="form-label fw-bold"
                                  >
                                    User Email:{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                </Col>
                                <Col lg={9}>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    id="contactNumber"
                                    placeholder="Enter your Email here "
                                  />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Col lg={3}>
                                  <Label
                                    htmlFor="contactNumber"
                                    className="form-label fw-bold"
                                  >
                                    User ID:{" "}
                                  </Label>
                                </Col>
                                <Col lg={9}>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    id="contactNumber"
                                    placeholder=" input your User ID"
                                  />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Col lg={3}>
                                  <Label
                                    htmlFor="contactNumber"
                                    className="form-label fw-bold"
                                  >
                                    Contact Number:{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                </Col>
                                <Col lg={9}>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    id="contactNumber"
                                    placeholder="+91 9876543210"
                                  />
                                </Col>
                              </Row>

                              <div className="text-start">
                                <button type="submit" className="btn btn-light">
                                  Edit Fields
                                </button>
                              </div>
                            </form>
                          </div>
                        </CardBody>
                      </Card>

                      <div>
                        <h3 className="fw-bold">Additional Information...</h3>
                      </div>

                      <Card>
                        <CardBody>
                          <div className="live-preview mt-2">
                            <form action="#">
                              <Row className="mb-3">
                                <Col lg={3}>
                                  <Label
                                    htmlFor="nameInput"
                                    className="form-label fw-bold"
                                  >
                                    Conmanys Name:{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                </Col>
                                <Col lg={9}>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="nameInput"
                                    placeholder="Enter your companys name"
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col lg={3}>
                                  <Label
                                    htmlFor="websiteUrl"
                                    className="form-label fw-bold"
                                  >
                                    Website :{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                </Col>
                                <Col lg={9}>
                                  <Input
                                    type="url"
                                    className="form-control "
                                    id="websiteUrl"
                                    placeholder="Enter your url"
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col lg={3}>
                                  <Label
                                    htmlFor="leaveemails"
                                    className="form-label fw-bold"
                                  >
                                    Companys Address:{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                </Col>
                                <Col lg={9}>
                                  <Input
                                    type="email"
                                    className="form-control"
                                    id="leaveemails"
                                    placeholder="....xyz LTD"
                                  />
                                </Col>
                              </Row>

                              <div className="text-start">
                                <button type="submit" className="btn btn-light">
                                  Edit Fields
                                </button>
                              </div>
                            </form>
                          </div>
                        </CardBody>
                      </Card>

                      <div className="text-start">
                        <button type="submit" className="btn mx-3 btn-primary">
                          Save Changes
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Delete User
                        </button>
                      </div>
                    </Col>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="">
                    <div className="shadow-none">
                      <Row className=" p-3  mt-2">
                        <Col xs={12} sm={6} md={3} className="mb-3">
                          <Card className="card-animate rounded-4">
                            <CardBody>
                              <h6 className="card-title fs-12 fw-bold  mb-2">
                                Total API Calls
                              </h6>
                              <div className="display-5 fs-35 fw-bold text-primary">
                                571{" "}
                                <span className="text-end fs-10 text-success">
                                  +23%
                                </span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={12} sm={6} md={3} className="mb-3">
                          <Card className="card-animate rounded-4">
                            <CardBody>
                              <h6 className="card-title fs-12 fw-bold mb-3">
                                Total Products
                              </h6>
                              <div className="display-5 fs-35 fw-bold text-sucess">
                                3
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={12} sm={6} md={3} className="mb-3">
                          <Card className="card-animate rounded-4">
                            <CardBody>
                              <h6 className="card-title fs-12 fw-bold mb-3">
                                Average Calls Per Project
                              </h6>
                              <div className="display-5 fs-35 fw-bold text-success">
                                456
                              </div>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col xs={12} sm={6} md={3} className="mb-3">
                          <Card className="card-animate rounded-4">
                            <CardBody>
                              <h6 className="card-title fs-12 fw-bold mb-3">
                                Average Response Time
                              </h6>
                              <div className="display-5 fs-35 fw-bold text-success">
                                456
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>

                      <Row>
                        <Card>
                          <div className=" p-3 border-4">
                            <h3 className=" p-4 fw-bold">
                              {" "}
                              API Usage Statistics
                            </h3>

                            <ResponsiveContainer width="60%" height={400}>
                              <BarChart
                                width={500}
                                height={300}
                                data={data}
                                margin={{
                                  top: 20,
                                  right: 30,
                                  left: 20,
                                  bottom: 5,
                                }}
                              >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="pv" stackId="a" fill="#40196D" />
                                <Bar dataKey="uv" stackId="a" fill="#82ca9d" />
                              </BarChart>
                            </ResponsiveContainer>
                          </div>
                        </Card>
                      </Row>

                      <Row className=" p-3  mt-2 w-full d-flex justify-content-around align-item-center">
                        <Col xs={12} sm={6} md={3} className="mb-3">
                          <Card className="card-animate rounded-4">
                            <CardBody>
                              <h6 className="card-title fs-12 fw-bold  mb-2">
                                Total API Calls....
                              </h6>
                              <div className="d-flex justify-content-around">
                                <canvas
                                  ref={chartRef}
                                  style={{ width: "50%", height: "auto" }}
                                />
                                <div
                                  style={{ width: "50%", textAlign: "right" }}
                                ></div>
                              </div>
                            </CardBody>
                            <div className="d-flex justify-content-between w-100">
                              <CardActions>
                                {/* <div className=" p-3 mb-0 fs-12 text-secondary text-start">
                                Request
                              </div> */}
                              </CardActions>
                              <CardActions>
                                <div className=" p-3 mb-0 fs-12 text-muted text-end">
                                  SMS
                                </div>
                              </CardActions>
                            </div>
                          </Card>
                        </Col>
                        <Col xs={12} sm={6} md={3} className="mb-3">
                          <Card className="card-animate rounded-4">
                            <CardBody>
                              <h6 className="card-title fs-12 fw-bold mb-3">
                                Average Calls Per Project
                              </h6>
                              <div className="d-flex justify-content-around">
                                <canvas
                                  ref={chartRef2}
                                  style={{ width: "50%", height: "auto" }}
                                />
                                <div
                                  style={{ width: "50%", textAlign: "right" }}
                                ></div>
                              </div>
                            </CardBody>
                            <div className="d-flex justify-content-between w-100">
                              <CardActions>
                                {/* <div className=" p-3 mb-0 fs-12 text-secondary text-start">
                                Request
                              </div> */}
                              </CardActions>
                              <CardActions>
                                <div className=" p-3 mb-0 fs-12 text-muted text-end">
                                  VOICE
                                </div>
                              </CardActions>
                            </div>
                          </Card>
                        </Col>

                        <Col xs={12} sm={6} md={3} className="mb-3">
                          <Card className="card-animate rounded-4">
                            <CardBody>
                              <h6 className="card-title fs-12 fw-bold mb-3">
                                Average Response Time
                              </h6>
                              <div className="d-flex justify-content-around">
                                <canvas
                                  ref={chartRef3}
                                  style={{ width: "50%", height: "auto" }}
                                />
                                <div
                                  style={{ width: "50%", textAlign: "right" }}
                                ></div>
                              </div>
                            </CardBody>
                            <div className="d-flex justify-content-between w-100">
                              <CardActions>
                                {/* <div className=" p-3 mb-0 fs-12 text-secondary text-start">
                                Request
                              </div> */}
                              </CardActions>
                              <CardActions>
                                <div className=" p-3 mb-0 fs-12 text-muted text-end">
                                  OTHERS
                                </div>
                              </CardActions>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="w-100" style={{ borderColor: "red" }}>
                    <Row className=" p-3  mt-2">
                      <Col xs={12} sm={6} md={3} className="mb-3">
                        <Link to="/users/admin/pending">
                          <Card
                            style={{ overflow: "hidden" }}
                            className="card-animate rounded-4"
                          >
                            <div className="d-flex g-1">
                              <div
                                className="bg-primary"
                                style={{
                                  width: "23px",
                                  height: "inherit",
                                  backgroundColor: "primary",
                                }}
                              ></div>
                              <div className="flex-1">
                                <CardBody>
                                  <h6 className="card-title fs-12 text-secondary mb-3">
                                    Pending SID
                                  </h6>
                                  <div className="display-5 fs-35 fw-bold text-primary">
                                    45
                                  </div>
                                </CardBody>
                                <div className="d-flex justify-content-between w-100">
                                  <CardActions>
                                    <div className=" p-3 mb-0 fs-12 text-secondary text-start">
                                      Today
                                    </div>
                                  </CardActions>
                                  <CardActions>
                                    <div className=" p-3 mb-0 fs-12 text-primary text-end">
                                      0
                                    </div>
                                  </CardActions>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Link>
                      </Col>
                      <Col xs={12} sm={6} md={3} className="mb-3">
                        <Card
                          style={{ overflow: "hidden" }}
                          className="card-animate rounded-4"
                        >
                          <div className="d-flex g-1">
                            <div
                              className=""
                              style={{
                                width: "23px",
                                height: "inherit",
                                backgroundColor: "#118959",
                              }}
                            ></div>
                            <div className="flex-1">
                              <CardBody>
                                <h6 className="card-title fs-12 text-secondary mb-3">
                                  Approved SID
                                </h6>
                                <div className="display-5 fs-35 fw-bold text-primary">
                                  1524
                                </div>
                              </CardBody>
                              <div className="d-flex justify-content-between w-100">
                                <CardActions>
                                  <div className=" p-3 mb-0 fs-12 text-secondary text-start">
                                    Today
                                  </div>
                                </CardActions>
                                <CardActions>
                                  <div className=" p-3 mb-0 fs-12 text-secondary text-end">
                                    100
                                  </div>
                                </CardActions>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                      <Col xs={12} sm={6} md={3} className="mb-3">
                        <Card
                          style={{ overflow: "hidden" }}
                          className="card-animate rounded-4"
                        >
                          <div className="d-flex g-1">
                            <div
                              className=""
                              style={{
                                width: "23px",
                                height: "inherit",
                                backgroundColor: "#FFA3BF",
                              }}
                            ></div>
                            <div className="flex-1">
                              <CardBody>
                                <h6 className="card-title fs-12 text-secondary mb-3">
                                  Denied SID
                                </h6>
                                <div className="display-5 fs-35 fw-bold text-primary">
                                  12
                                </div>
                              </CardBody>
                              <div className="d-flex justify-content-between w-100">
                                <CardActions>
                                  <div className=" p-3 mb-0 fs-12 text-secondary text-start">
                                    Today
                                  </div>
                                </CardActions>
                                <CardActions>
                                  <div className=" p-3 mb-0 fs-12 text-secondary text-end">
                                    0
                                  </div>
                                </CardActions>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <div className="d-flex flex-column align-items-center">
                      <Link
                        to="/users/admin/pending"
                        className="bg-primary rounded-pill text-light px-5 mb-4 fw-bold text-center py-2"
                        style={{ maxWidth: "230px" }}
                      >
                        + Assign SID
                      </Link>
                    </div>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </div>
        )}

        <Row className="p-3">
          <Col xxl={10}>
            <TabContent activeTab={customActiveTab} className="text-muted">
              <TabPane tabId="2">
                <div className="">
                  <div className="shadow-none">
                    <Row className=" p-3  mt-3 w-full d-flex justify-content-around align-item-center">
                      <Col xs={12} sm={6} md={3} className="mb-3">
                        <Card className="card-animate rounded-4">
                          <CardBody>
                            <h6 className="card-title fs-12 fw-bold  mb-2">
                              Total API Calls
                            </h6>
                            <div className="d-flex justify-content-around">
                              <canvas
                                ref={chartRef}
                                style={{ width: "50%", height: "auto" }}
                              />
                              <div
                                style={{ width: "50%", textAlign: "right" }}
                              ></div>
                            </div>
                          </CardBody>
                          <div className="d-flex justify-content-between w-100">
                            <CardActions>
                              <div className=" p-3 mb-0 fs-12 text-muted text-end">
                                SMS
                              </div>
                            </CardActions>
                          </div>
                        </Card>
                      </Col>
                      <Col xs={12} sm={6} md={3} className="mb-3">
                        <Card className="card-animate rounded-4">
                          <CardBody>
                            <h6 className="card-title fs-12 fw-bold mb-3">
                              Average Calls Per Project
                            </h6>
                            <div className="d-flex justify-content-around">
                              <canvas
                                ref={chartRef2}
                                style={{ width: "50%", height: "auto" }}
                              />
                              <div
                                style={{ width: "50%", textAlign: "right" }}
                              ></div>
                            </div>
                          </CardBody>
                          <div className="d-flex justify-content-between w-100">
                            <CardActions>
                              {/* <div className=" p-3 mb-0 fs-12 text-secondary text-start">
                                Request
                              </div> */}
                            </CardActions>
                            <CardActions>
                              <div className=" p-3 mb-0 fs-12 text-muted text-end">
                                VOICE
                              </div>
                            </CardActions>
                          </div>
                        </Card>
                      </Col>

                      <Col xs={12} sm={6} md={3} className="mb-3">
                        <Card className="card-animate rounded-4">
                          <CardBody>
                            <h6 className="card-title fs-12 fw-bold mb-3">
                              Average Response Time
                            </h6>
                            <div className="d-flex justify-content-around">
                              <canvas
                                ref={chartRef3}
                                style={{ width: "50%", height: "auto" }}
                              />
                              <div
                                style={{ width: "50%", textAlign: "right" }}
                              ></div>
                            </div>
                          </CardBody>
                          <div className="d-flex justify-content-between w-100">
                            <CardActions>
                              {/* <div className=" p-3 mb-0 fs-12 text-secondary text-start">
                                Request
                              </div> */}
                            </CardActions>
                            <CardActions>
                              <div className=" p-3 mb-0 fs-12 text-muted text-end">
                                OTHERS
                              </div>
                            </CardActions>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div className="w-100" style={{ borderColor: "red" }}>
                  <Row className=" p-3  mt-2"></Row>
                </div>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Users;
