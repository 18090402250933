import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import img1 from "../../assets/images/svg/Telecom-banner1-min.png";
import TowerIcon from "../../assets/images/svg/network-tower.png";

const Banner = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section>
      <div className="mx-md-5 px-3 px-md-5">
        <div class="row">
          <div class="col-md-6 col-sm-12 order-2 order-md-1">
            <h1 className="display-6 fw-bold fs-48 lh-base text-sm-center text-md-start mt-md-5">
              Updating objective Techniques of Communication
            </h1>
            <div className="media d-flex mb-4">
              <img
                className="align-self-start mx-2"
                src={TowerIcon}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">Messaging</h5>
                <p>
                  Engage customers through various messaging channels for
                  personalized communication.
                </p>
              </div>
            </div>
            <div className="media d-flex mb-4">
              <img
                className="align-self-start mx-2"
                src={TowerIcon}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">Voice</h5>
                <p>
                  Ensure reliable and transparent communication with clients and
                  partners.
                </p>
              </div>
            </div>
            <div className="media d-flex mb-4">
              <img
                className="align-self-start mx-2"
                src={TowerIcon}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">Verification</h5>
                <p>
                  Implement secure verification methods for data protection and
                  trust.
                </p>
              </div>
            </div>
            <div className="media d-flex mb-3">
              <img
                className="align-self-start mx-2"
                src={TowerIcon}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">Application</h5>
                <p>
                  Access user-friendly applications for efficient operations and
                  customer engagement.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 my-5 d-flex align-items-center order-1">
            <img src={img1} alt="" className="user-img w-75" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
