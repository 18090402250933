import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Container, Row, Form, Label, Input, FormFeedback, Button, Spinner, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle } from 'reactstrap';

import ParticlesAuth from './ParticlesAuth';

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { resetAccountPhoneFlag, sendVerificationCode } from '../../store/actions';
import withRouter from '../../Components/Common/withRouter';

//i18n
import countries from '../../common/countries';

const GroupOptions2 = [
    { value: 'Zero', label: 'Zero' },
    { value: 'Two', label: 'Two' },
    { value: 'Four', label: 'Four' },
    { value: 'One', label: 'One' },
    { value: 'Five', label: 'Five' },
    { value: 'Three', label: 'Three' },
    { value: 'Six', label: 'Six' },
];

const AccountPhone = (props) => {

    document.title = "BRIVAS | Phone Number";

    const history = useNavigate();
    const dispatch = useDispatch();

    const [selectedCountry, setSelectedCountry] = useState(countries[0]);

    const { loading, success, error, msg } = useSelector(state => ({
        loading: state.AccountPhone.loading,
        success: state.AccountPhone.success,
        error: state.AccountPhone.error,
        msg: state.AccountPhone.msg,
    }));

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            country_code: selectedCountry.code,
            phone_number: ''
        },
        validationSchema: Yup.object({
            phone_number: Yup.string().required("Please enter your phone number"),
        }),
        onSubmit: (values) => {
            dispatch(sendVerificationCode(values, props.router.navigate));
        }
    });

    useEffect(() => {
        setTimeout(() => {
            dispatch(resetAccountPhoneFlag());
        }, 5000);
    }, [dispatch, msg, error, success]);

    return (
        <ParticlesAuth>
            <div className="auth-page-content">
                <Container>

                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <div className="mt-2">
                                <p className="fs-12 fw-medium">Step 2/3</p>
                                <h4 className="">Verify your phone number</h4>
                                <p className="text-muted fs-9">Verifying your phone number enables you to keep your account secure</p>
                            </div>

                            <Card className="mt-4">
                                <CardBody className="p-4">
                                    <div className="p-2">
                                        {error && msg ? (
                                            <Alert color="danger">{msg}</Alert>
                                        ) : null}
                                        {success && msg ? (
                                            <Alert color="success">{msg}</Alert>
                                        ) : null}

                                        <Form onSubmit={validation.handleSubmit}>
                                            <div className="mb-3">
                                                <Label className="form-label fs-12 fw-normal">Enter your phone number</Label>

                                                <UncontrolledDropdown className="input-group">
                                                    <DropdownToggle tag="button" className="btn border border-right-0" type="button">
                                                        <div className='d-flex'>
                                                            <img
                                                                src={selectedCountry.flag}
                                                                alt=""
                                                                height="16"
                                                                className="rounded"
                                                            />
                                                            <span className='p-1' />
                                                            <i className='ri-arrow-down-s-line'></i>
                                                            <span className='p-1' />
                                                            +{selectedCountry.code}
                                                        </div>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {countries.map((item, index) => (
                                                            <DropdownItem
                                                                key={`country_${index}`}
                                                                onClick={() => setSelectedCountry(item)}
                                                                className={`notify-item ${selectedCountry.code === item.code ? "active" : "none"
                                                                    }`}
                                                            >
                                                                <img
                                                                    src={item.flag}
                                                                    alt="Skote"
                                                                    className="me-2 rounded"
                                                                    height="18"
                                                                />
                                                                <span className="align-middle">
                                                                    {item.name}
                                                                </span>
                                                                <span>{" "}(+{item.code})</span>
                                                            </DropdownItem>
                                                        ))}
                                                    </DropdownMenu>
                                                    <Input
                                                        name='phone_number'
                                                        type="number"
                                                        className="form-control"
                                                        aria-label="Text input with dropdown button"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.phone_number || ""}
                                                        invalid={
                                                            validation.touched.phone_number && validation.errors.phone_number ? true : false
                                                        }
                                                    />
                                                </UncontrolledDropdown>

                                                {validation.errors.phone_number && validation.touched.phone_number ? (
                                                    <FormFeedback type="invalid">{validation.errors.phone_number}</FormFeedback>
                                                ) : null}
                                            </div>

                                            <div className="mt-4">
                                                <button disabled={error ? null : loading ? true : false} color="success"
                                                    className="btn btn-primary w-100 rounded-pill" type="submit">
                                                    {error ? null : loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                    Send verification code
                                                </button>
                                            </div>

                                            <div className="mb-2 mt-4 text-center row">
                                                <p className="mb-0 fs-12 text-muted">{'Experiencing issues? '}
                                                    <Link to="#" className="text-primary fst-normal fs-12 fw-medium">Contact Support</Link>
                                                </p>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ParticlesAuth>
    );
};

export default withRouter(AccountPhone);