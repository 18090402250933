import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import img1 from "../../assets/images/svg/Enterprise-Banner2.svg";

const Banner2 = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section>
      <div
        className="card text-dark"
        style={{
          backgroundColor: "#F5F5F5",
          margin: "50px 80px 60px 80px",
          padding: "90px 80px 60px 80px",
          borderRadius:'24px'
        }}
      >
        <div className="d-flex justify-content-center">
          <div className=" text-center">
            <div className="w-100"style={{ width: "" }}>
              <h1 className="mb-3 fs-40 text-dark">
                "Harness the strength of partnerships to
                propel your enterprise forward. Our partnership initiative is
                meticulously crafted to empower enterprises like yours with
                state-of-the-art technology and solutions."
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner2;
