import {
  GENERATE_CODE,
  GENERATE_CODE_SUCCESS,
  GENERATE_CODE_ERROR,
  QR_CODE_LOGIN,
  QR_CODE_LOGIN_SUCCESS,
  QR_CODE_LOGIN_ERROR,
} from "./actionTypes";

export const generateQRCode = () => {
  return {
    type: GENERATE_CODE
  };
};

export const generateQRCodeSuccess = user => {
  return {
    type: GENERATE_CODE_SUCCESS,
    payload: user,
  };
};

export const generateQRCodeError = error => {
  return {
    type: GENERATE_CODE_ERROR,
    payload: error,
  };
};

export const qrCodeLogin = (user, history) => {
  return {
    type: QR_CODE_LOGIN,
    payload: { user, history },
  };
};

export const qrCodeLoginSuccess = user => {
  return {
    type: QR_CODE_LOGIN_SUCCESS,
    payload: user,
  };
};

export const qrCodeLoginError = error => {
  return {
    type: QR_CODE_LOGIN_ERROR,
    payload: error,
  };
};
