import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Form, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

import slack from '../../assets/images/svg/Enterprise-Banner2.svg';
import OverviewTab from './OverviewTab';
import RequirementsTab from './RequirementsTab';
import { Link, useParams } from 'react-router-dom';
import MyApps from './MyApps';
import TestApp from './TestApp';
import { useDispatch, useSelector } from 'react-redux';
import { createApp, getApp, listMyApps } from '../../store/actions';
import Forms from './Forms';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { appSlugs } from '../../common/consts';

const Section = () => {
    const { slug } = useParams();

    const [activeTab, setActiveTab] = useState('1');
    const [modal, setModal] = useState(false);

    const dispatch = useDispatch();

    const { app, appCreated } = useSelector(state => ({
        app: state.App.app,
        appCreated: state.App.appCreated,
    }));

    useEffect(() => {
        dispatch(getApp(slug));
    }, [dispatch, slug]);

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const initials = () => {
        if (slug === appSlugs.FLASH_CALL) {
            return {
                FriendlyName: '',
            }
        } else if (slug === appSlugs.USSD) {
            return {
                FriendlyName: '',
            }
        } else {
            return {

            }
        }
    }

    const schema = () => {
        if (slug === appSlugs.FLASH_CALL) {
            return {
                FriendlyName: Yup.string().required("Please enter a name"),
            }
        } else if (slug === appSlugs.USSD) {
            return {
                FriendlyName: Yup.string().required("Please enter a name"),
            }
        } else {
            return {

            }
        }
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: initials(),
        validationSchema: Yup.object(schema()),
        onSubmit: (values) => {
            dispatch(createApp({ ...values, slug, type: app.type }));
        },
    });

    const toggleModal = useCallback(() => {
        if (modal) {
            setModal(false);
            validation.resetForm();
        } else {
            setModal(true);
            //   setDate(defaultdate());
        }
    }, [modal, validation]);

    useEffect(() => {
        if (appCreated) {
            toggleTab('4');
            toggleModal();
        }
    });

    return (
        <React.Fragment >
            <Row>
                <Col lg={12}>
                    <Card className="mt-n4 mx-n4 shadow-none">
                        <div className="">
                            <CardBody className="pb-0 px-4">
                                <Row className="mb-3">
                                    <div className="col-md">
                                        <Row className="align-items-center g-3">
                                            {/* <div className="col-md-auto">
                                                <div className="avatar-md">
                                                    <div className="avatar-title bg-white rounded-circle">
                                                        <img src={slack} alt="" className="avatar-xs" />
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="col-md">
                                                <div>
                                                    <h4 className="fw-bold">{app?.name ?? ''}</h4>
                                                    <div className="vstack gap-2 flex-wrap">
                                                        <div>Good to see you here, kindly check each of the tabs below to know more about this application.</div>
                                                        <div>Click on the Create button to create this app.</div>
                                                        <div>
                                                            {app?.create_mode === 'self' && <Link
                                                                className="btn btn-primary"
                                                                onClick={() => toggleModal()}>
                                                                <i className="ri-add-line me-1 align-bottom"></i> Create
                                                            </Link>}
                                                            {app?.create_mode !== 'self' && <Link to="/app/create" className="btn btn-primary">
                                                                <i className="ri-add-line me-1 align-bottom"></i> Create
                                                            </Link>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                </Row>

                                <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' }, "fw-semibold")}
                                            onClick={() => { toggleTab('1'); }}
                                            href="#">
                                            Overview
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' }, "fw-semibold")}
                                            onClick={() => { toggleTab('2'); }}
                                            href="#">
                                            Requirements
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '3' }, "fw-semibold")}
                                            onClick={() => { toggleTab('3'); }}
                                            href="#">
                                            Test App
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '4' }, "fw-semibold")}
                                            onClick={() => { toggleTab('4'); }}
                                            href="#">
                                            My Apps
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <TabContent activeTab={activeTab} className="text-muted">
                        <TabPane tabId="1">
                            <OverviewTab />
                        </TabPane>
                        <TabPane tabId="2">
                            <RequirementsTab />
                        </TabPane>
                        <TabPane tabId="3">
                            <TestApp />
                        </TabPane>
                        <TabPane tabId="4">
                            <MyApps />
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>

            <Modal id="showModal" isOpen={modal} toggle={toggleModal} centered>
                <ModalHeader className="bg-light p-3" toggle={toggleModal}>
                    Create Application
                </ModalHeader>
                <Form className="tablelist-form" onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <ModalBody>
                        <input type="hidden" id="id-field" />

                        <Forms appSlug={slug} validation={validation} />
                    </ModalBody>
                    <div className="modal-footer">
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                            <button type="submit" className="btn btn-primary">Create</button>
                        </div>
                    </div>
                </Form>
            </Modal>
            {/* <ToastContainer closeButton={false} limit={1} /> */}
        </React.Fragment >
    );
};

export default Section;