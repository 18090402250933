import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ParticlesAuth from "./ParticlesAuth";

import logoLight from "../../assets/images/logo.svg";

import { resolveEmail } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';

const PendingVerification = () => {

    document.title = "BRIVAS | Pending verification";

    const dispatch = useDispatch();

    const { email } = useSelector(state => ({
        email: state.PendingVerification.email,
    }));

    useEffect(() => {
        dispatch(resolveEmail());
    }, [dispatch]);

    return (
        <React.Fragment>
            <div className="auth-page-wrapper">
                <ParticlesAuth>
                    <div className="auth-page-content">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                                        <div>
                                            <Link to="/dashboard" className="d-inline-block auth-logo">
                                                <img src={logoLight} alt="" height="20" />
                                            </Link>
                                        </div>
                                        {/* <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p> */}
                                    </div>
                                </Col>
                            </Row>

                            <Row className="justify-content-center">
                                <Col md={8} lg={6} xl={5}>
                                    <Card className="mt-4">
                                        <CardBody className="p-4 text-center">
                                            <div className="avatar-lg mx-auto mt-2">
                                                <div className="avatar-title bg-light text-success display-3 rounded-circle">
                                                    <i className="ri-checkbox-circle-fill"></i>
                                                </div>
                                            </div>

                                            <div className="p-2 mt-4">
                                                <div className="text-muted text-center mb-4 mx-lg-3">
                                                    <h4 className="fs-16">Confirmation link sent to</h4>
                                                    <span className="fw-semibold">{email}</span>
                                                    <p className='pt-4 fs-12'>You need to click the link to confirm sign up. If the email does not arrive in a couple of minutes, check your spam folder.</p>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </ParticlesAuth>
            </div>
        </React.Fragment >
    );
};

export default PendingVerification;