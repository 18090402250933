import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Container, Row, Form, Label, Input, FormFeedback, Button, Spinner } from 'reactstrap';
import Cleave from "cleave.js/react";

import ParticlesAuth from './ParticlesAuth';
import logoLight from "../../assets/images/logo.svg";

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { resetVerifyOTPFlag, verifyOTP, verifyOTPInit } from '../../store/actions';
import withRouter from '../../Components/Common/withRouter';

const AccountVerify = (props) => {

    document.title = "BRIVAS | Enter OTP";

    const history = useNavigate();
    const dispatch = useDispatch();

    const { country_code, phone_number, loading, success, error, msg } = useSelector(state => ({
        country_code: state.AccountVerify.country_code,
        phone_number: state.AccountVerify.phone_number,
        loading: state.AccountVerify.loading,
        success: state.AccountVerify.success,
        error: state.AccountVerify.error,
        msg: state.AccountVerify.msg,
    }));

    useEffect(() => {
        dispatch(verifyOTPInit());
    }, [dispatch]);

    useEffect(() => {
        if (success) {
            history("/login")
        }

        setTimeout(() => {
            dispatch(resetVerifyOTPFlag());
        }, 3000);

    }, [dispatch, msg, success, error, history]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            digit1: '',
            digit2: '',
            digit3: '',
            digit4: '',
            digit5: '',
            digit6: '',
        },
        validationSchema: Yup.object({
            digit1: Yup.string().required(),
            digit2: Yup.string().required(),
            digit3: Yup.string().required(),
            digit4: Yup.string().required(),
            digit5: Yup.string().required(),
            digit6: Yup.string().required(),
        }),
        onSubmit: (values) => {
            const otp = `${values.digit1}${values.digit2}${values.digit3}${values.digit4}${values.digit5}${values.digit6}`;
            dispatch(verifyOTP(otp, props.router.navigate));
        }
    });

    const getInputElement = (index) => {
        return document.getElementById('digit' + index + '-input');
    }

    const moveToNext = (index) => {
        if (getInputElement(index).value.length === 1) {
            if (index !== 6) {
                getInputElement(index + 1).focus();
            } else {
                getInputElement(index).blur();
                // Submit code
                // console.log('submit code');
            }
        }
    }

    return (
        <ParticlesAuth>
            <div className="auth-page-content">
                <Container>

                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <div className="mt-2">
                                <p className="fs-12 fw-medium">Step 3/3</p>
                                <h4 className="">Enter your OTP</h4>
                                <p className="text-muted fs-9">Verifying your phone number enables you to test messages and keep your account secure</p>
                            </div>

                            {error && msg ? (<Alert color="danger"> {msg} </Alert>) : null}

                            <Card className="mt-4">
                                <CardBody className="p-4">
                                    <div className="text-muted text-center mb-4 mx-lg-3">
                                        <p>Please enter the code sent to <span className="fw-semibold">+{`${country_code}${phone_number}`}</span></p>
                                    </div>

                                    <form onSubmit={validation.handleSubmit}>
                                        <Row>
                                            <Col className="col-2">
                                                <div className="mb-3">
                                                    <label htmlFor="digit1-input" className="visually-hidden">Digit 1</label>
                                                    <input type="text"
                                                        className="form-control form-control-lg text-center p-0"
                                                        maxLength="1"
                                                        name='digit1'
                                                        value={validation.values.digit1}
                                                        onBlur={validation.handleBlur}
                                                        onChange={validation.handleChange}
                                                        id="digit1-input" onKeyUp={() => moveToNext(1)} />
                                                </div>
                                            </Col>

                                            <Col className="col-2">
                                                <div className="mb-3">
                                                    <label htmlFor="digit2-input" className="visually-hidden">Digit 2</label>
                                                    <input type="text"
                                                        className="form-control form-control-lg text-center p-0"
                                                        maxLength="1"
                                                        name='digit2'
                                                        value={validation.values.digit2}
                                                        onBlur={validation.handleBlur}
                                                        onChange={validation.handleChange}
                                                        id="digit2-input" onKeyUp={() => moveToNext(2)} />
                                                </div>
                                            </Col>

                                            <Col className="col-2">
                                                <div className="mb-3">
                                                    <label htmlFor="digit3-input" className="visually-hidden">Digit 3</label>
                                                    <input type="text"
                                                        className="form-control form-control-lg text-center p-0"
                                                        maxLength="1"
                                                        name='digit3'
                                                        value={validation.values.digit3}
                                                        onBlur={validation.handleBlur}
                                                        onChange={validation.handleChange}
                                                        id="digit3-input" onKeyUp={() => moveToNext(3)} />
                                                </div>
                                            </Col>

                                            <Col className="col-2">
                                                <div className="mb-3">
                                                    <label htmlFor="digit4-input" className="visually-hidden">Digit 4</label>
                                                    <input type="text"
                                                        className="form-control form-control-lg text-center p-0"
                                                        maxLength="1"
                                                        name='digit4'
                                                        value={validation.values.digit4}
                                                        onBlur={validation.handleBlur}
                                                        onChange={validation.handleChange}
                                                        id="digit4-input" onKeyUp={() => moveToNext(4)} />
                                                </div>
                                            </Col>

                                            <Col className="col-2">
                                                <div className="mb-3">
                                                    <label htmlFor="digit5-input" className="visually-hidden">Digit 5</label>
                                                    <input type="text"
                                                        className="form-control form-control-lg text-center p-0"
                                                        maxLength="1"
                                                        name='digit5'
                                                        value={validation.values.digit5}
                                                        onBlur={validation.handleBlur}
                                                        onChange={validation.handleChange}
                                                        id="digit5-input" onKeyUp={() => moveToNext(5)} />
                                                </div>
                                            </Col>

                                            <Col className="col-2">
                                                <div className="mb-3">
                                                    <label htmlFor="digit6-input" className="visually-hidden">Digit 6</label>
                                                    <input type="text"
                                                        className="form-control form-control-lg text-center p-0"
                                                        maxLength="1"
                                                        name='digit6'
                                                        value={validation.values.digit6}
                                                        onBlur={validation.handleBlur}
                                                        onChange={validation.handleChange}
                                                        id="digit6-input" onKeyUp={() => moveToNext(6)} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="mt-4">
                                            <button disabled={error ? null : loading ? true : false} color="success"
                                                // className="btn btn-success w-100"
                                                className="btn btn-primary w-100 rounded-pill" type="submit">
                                                {error ? null : loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                Verify
                                            </button>
                                        </div>
                                    </form>

                                    <div className="mb-2 mt-4 row justify-content-center">
                                        <p className="mb-0 fs-12 text-muted">{'Experiencing issues? '}
                                            <Link to="#" className="text-primary fst-normal fs-12 fw-medium">Contact Support</Link>
                                        </p>
                                    </div>

                                    <div className="mt-3">
                                        <Link color="primary" className="btn-link">Resend Code</Link>
                                    </div>

                                    <div className="mt-3">
                                        <Link color="success" className="btn-link">Change phone number</Link>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ParticlesAuth>
    );
};

export default withRouter(AccountVerify);