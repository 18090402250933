import React, { useState } from "react";
import {
    Badge,
    Button,
    Card,
    Row,
    CardBody,
    CardHeader,
    Col,
    ListGroupItem,
    ListGroup,
    Container,
    Form,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    Label,
} from "reactstrap";

const Lookupanumber = () => {
    const [accordionOpen, setAccordionOpen] = useState(1);
    const [activeTab, setactiveTab] = useState(1);
    const [progressbarvalue, setprogressbarvalue] = useState(0);
    const [passedSteps, setPassedSteps] = useState([1]);

    const handleAccordionToggle = (accordionItem) => {
        setAccordionOpen((prev) => (prev === accordionItem ? null : accordionItem));
    };


    const [modal_grid, setmodal_grid] = useState(false);

    function tog_grid() {
        setmodal_grid(!modal_grid);
    }

    function toggleTab(tab, ntype, value) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab];

            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab);
                setPassedSteps(modifiedSteps);
            }
        }
        setprogressbarvalue(value);
    }

    document.title = "Wizard | Velzon - React Admin & Dashboard Template";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Card className="shadow-none">
                        <CardHeader>
                            <h1 className=" mb-0 fs-120">SEND BULK SMS </h1>
                            <br />
                            <p className="card-title mb-0 text-muted mt-2 fs-13"></p>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xl={6}>
                                    <Form action="#" className="form-steps">
                                        <div class="accordion" id="accordionExample">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button
                                                        class="accordion-button"
                                                        type="button"
                                                        onClick={() => handleAccordionToggle(1)}
                                                        aria-expanded={accordionOpen === 1}
                                                    >
                                                        Added Numbers for Bulk SMS
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseOne"
                                                    class={`accordion-collapse collapse ${accordionOpen === 1 ? "show" : ""
                                                        }`}
                                                    aria-labelledby="headingOne"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div class="accordion-body">
                                                        <strong>
                                                            <div>
                                                                <div className="mb-4 fw-normal"></div>
                                                                <h5></h5>
                                                                <div className="text-muted">
                                                                    <p>
                                                                        In free trial, you can only send messages to
                                                                        verified numbers. To test sending in bulk,
                                                                        you can verify Up to 5 additional mobile
                                                                        numbers. Be sure to use numbers for phones
                                                                        you gave access to because every new number
                                                                        you add will go through you add will go
                                                                        through SMS verification process.
                                                                    </p>
                                                                </div>


                                                            </div>


                                                            <div class="accordion-body d-flex flex-column">
                                                                <ListGroup>
                                                                    <ListGroupItem><i className="ri-bill-line align-middle me-2"></i> +2349057469410</ListGroupItem>
                                                                    <ListGroupItem><i className="ri-bill-line align-middle me-2"></i>+2348023419843</ListGroupItem>
                                                                    <ListGroupItem><i className="ri-bill-line align-middle me-2"></i>+234703804740</ListGroupItem>
                                                                    <ListGroupItem><i className="ri-bill-line align-middle me-2"></i>+23493827823899</ListGroupItem>
                                                                </ListGroup>

                                                                <div className=" align-items-start gap-3 mt-3">
                                                                    
                                                                    <Button color="primary" onClick={() => setmodal_grid(true)}>
                                                                        Add Number
                                                                    </Button>

                                                                    <Modal
                                                                        isOpen={modal_grid}
                                                                        toggle={() => {
                                                                            tog_grid();
                                                                        }}
                                                                    >
                                                                        <ModalHeader>
                                                                            <h5 className="modal-title">
                                                                                ADD DETAILS
                                                                            </h5>
                                                                            <Button
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    setmodal_grid(false);
                                                                                }}
                                                                                className="btn-close"
                                                                                aria-label="Close"
                                                                            >
                                                                            </Button>
                                                                        </ModalHeader>
                                                                        <ModalBody>
                                                                            <form action="#">
                                                                                <div className="row g-3">
                                                                                    <Col xxl={6}>
                                                                                        <div>
                                                                                            <label htmlFor="firstName" className="form-label">First Name</label>
                                                                                            <Input type="text" className="form-control" id="firstName" placeholder="Enter firstname" />
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col xxl={6}>
                                                                                        <div>
                                                                                            <label htmlFor="lastName" className="form-label">Last Name</label>
                                                                                            <Input type="text" className="form-control" id="lastName" placeholder="Enter lastname" />
                                                                                        </div>
                                                                                    </Col>
                                                                                    <div className="col-lg-12">
                                                                                        <label className="form-label">Gender</label>
                                                                                        <div>
                                                                                            <div className="form-check form-check-inline">
                                                                                                <Input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                                                                                <label className="form-check-label" htmlFor="inlineRadio1">Male</label>
                                                                                            </div>
                                                                                            <div className="form-check form-check-inline">
                                                                                                <Input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                                                                                <label className="form-check-label" htmlFor="inlineRadio2">Female</label>
                                                                                            </div>
                                                                                            <div className="form-check form-check-inline">
                                                                                                <Input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3" />
                                                                                                <label className="form-check-label" htmlFor="inlineRadio3">Others</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Col xxl={6}>
                                                                                        <label htmlFor="emailInput" className="form-label">Email</label>
                                                                                        <Input type="email" className="form-control" id="emailInput" placeholder="Enter your email" />
                                                                                    </Col>
                                                                                    {/* <Col xxl={6}>
                                                                                        <label htmlFor="passwordInput" className="form-label">Password</label>
                                                                                        <Input type="password" className="form-control" id="passwordInput" value="451326546" placeholder="Enter password" />
                                                                                    </Col> */}
                                                                                    <div className="col-lg-12">
                                                                                        <div className="hstack gap-2 justify-content-end">
                                                                                            <Button color="light" onClick={() => setmodal_grid(false)}>Close</Button>
                                                                                            <Button color="primary">ADD</Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </ModalBody>
                                                                    </Modal>







                                                                </div>
                                                            </div>

                                                        </strong>


                                                    </div>
                                                </div>
                                            </div>


                                            {/* Add more accordion items as needed */}
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Lookupanumber;
