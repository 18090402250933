import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

import img1 from "../../assets/images/svg/Ecommerce-banner3-img.png";

const Banner3 = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <Card className="" style={{ backgroundColor: "#FFF5FF" }}>
      <div
        className="card text-dark my-4 mx-5 px-5 py-4"
        style={{
          backgroundImage: `url(${img1})`,
          borderRadius: "24px",
          backgroundRepeat: "no-repeat",
          width:'100%',
          height:'648px'
        }}
      >
        <div className="row">
          <div className=" col-sm-12 col-md-6 text-bold text-light fs-40" style={{width:'57ch', }}>
            <h1 className="mb-3 text-light">
              Improving the
              <br />
              online shopping
              <br />
              experience
            </h1>
            <p className="mb-4 ff-secondary">
              Integrating our IoT capabilities opens up exciting opportunities
              for enhanced customer experiences through connected devices.
            </p>
          </div>
          <div className="col-sm-12 col-md-6">
            <button
              color="danger "
              className=" btn-light btn rounded-pill btn-primary text-primary text-bolder text-dark"
              onClick={() => history("/register")}
              style={{ width: "232px",height:'51px' }}
            >
              Talk to an expert
              <i className="rounded-pill"></i>
            </button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Banner3;
