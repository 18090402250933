import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import img1 from "../../assets/images/svg/enterpriseinstagramimage-min.png";

const Scalable = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section className="bg-primary">
      <div class="row mx-md-5 px-3 px-md-5">
        <div class="col-md-6">
          <h1 className="fw-bold fs-48 lh-base text-light my-4 text-sm-centre">
            STAY CONNECTED WITH PEOPLE AROUND THE WORLD
          </h1>
          <p className="text-light">
            Our enterprise solutions are designed to empower organizations of
            all sizes and industries to achieve their goals with efficiency,
            agility, and precision.
          </p>

          <div className="d-flex gap-2 my-5">
            <button
              color="light"
              className="btn rounded-pill btn-light text-primary"
              onClick={() => "/register"}
            >
              Start for free
            </button>
            <button
              color="success"
              className="btn rounded-pill btn-primary border-light "
              onClick={() => history("/register")}
            >
              <span className="text-light">Talk to an expert</span>
            </button>
          </div>
        </div>
        <div class="col-md-6 d-none d-md-inline my-5">
          <img src={img1} alt="" className="user-img" />
        </div>
      </div>
    </section>
  );
};

export default Scalable;
