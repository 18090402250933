import PrismCode from "./Common/Prism";

const basicRaterCode =
    `
curl --location 'https://api.brivas.io/sms/send' \
--data '{
    "from":"Sender",
    "to":"01370xxxx",
    "message":"Test SMS from me"
}'
`;

const basicRaterCodenode =
    `
 
var request = require('request');
var options = {
  'method': 'POST',
  'url': 'https://api.brivas.io/sms/send',
  'headers': {
  },
  body: '{\r\n    "from":"Sender",\r\n    "to":"01370xxxx",\r\n    "message":"Test SMS from me"\r\n}'

};
request(options, function (error, response) {
  if (error) throw new Error(error);
  console.log(response.body);
});

`;





const basicRaterCodejava =
    `
Unirest.setTimeouts(0, 0);
HttpResponse<String> response = Unirest.post("https://api.brivas.io/sms/send")
  .body("{\r\n    \"from\":\"Sender\",\r\n    \"to\":\"01370xxxx\",\r\n    \"message\":\"Test SMS from me\"\r\n}")
  .asString();

`;


const basicRaterCodepython =
    ` 
import requests

url = "https://api.brivas.io/sms/send"

payload = "{\r\n    \"from\":\"Sender\",\r\n    \"to\":\"01370xxxx\",\r\n    \"message\":\"Test SMS from me\"\r\n}"
headers = {}

response = requests.request("POST", url, headers=headers, data=payload)

print(response.text)
`;



const basicRaterCodephp =
    ` 
<?php

$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.brivas.io/sms/send',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS =>'{
    "from":"Sender",
    "to":"01370xxxx",
    "message":"Test SMS from me"
}',
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;
`;




const TestSMSCurl = () => (
    <PrismCode
        code={`curl --location 'https://api.brivas.io/sms/otp' \
        --data '{
            "to":"01370xxxx",
            "message":"Test SMS from me"
        }' \
        -H 'Content-Type: application/json'   
        -H 'Authorization: Bearer {Token}' \
        -d '{
            "from":"Sender",
            
        }'`}
        language="javascript"
        plugins={["line-numbers"]}
    />
);




const BasicRaterExample = () => (
    <PrismCode
        code={basicRaterCode}
        language="javascript" 
        plugins={["line-numbers"]}
    />
);

const BasicRaterExample2 = () => (
    <PrismCode
        code={basicRaterCode}
        language={("js", "css", "html")}
        plugins={["line-numbers"]}
    />
);

const BasicRaterExamplefornode = () => (
    <PrismCode
        code={basicRaterCodenode}
        language="javascript"
        plugins={["line-numbers"]}
    />
);

const BasicRaterExampleforjava = () => (
    <PrismCode
        code={basicRaterCodejava}
        language="javascript"
        plugins={["line-numbers"]}
    />
);

const BasicRaterExampleforpython = () => (
    <PrismCode
        code={basicRaterCodepython}
        language="javascript"
        plugins={["line-numbers"]}
    />
);


const BasicRaterExampleforphp = () => (
    <PrismCode
        code={basicRaterCodephp}
        language="javascript"
        plugins={["line-numbers"]}
    />
);




// Rater with Step
const raterWithStepCode =
    `
<Rating
initialRating={1.5}
fractions={2}
emptySymbol="mdi mdi-star-outline text-muted "
fullSymbol="mdi mdi-star text-warning "
/>
`;

const RaterWithStepExample = () => (
    <PrismCode
        code={raterWithStepCode}
        language={("js", "css", "html")}
        plugins={["line-numbers"]}
    />
);


// Custom Messages
const customMsgCode =
    `
<Rating
emptySymbol="mdi mdi-star-outline text-muted "
fullSymbol="mdi mdi-star text-warning "
/>
`;

const CustomMsgExample = () => (
    <PrismCode
        code={customMsgCode}
        language={("js", "css", "html")}
        plugins={["line-numbers"]}
    />
);


// Example with unlimited number of stars. readOnly option is set to true.
const readOnlyRaterCode =
    `
<Rating
stop={16}
emptySymbol="mdi mdi-star-outline text-muted fa-1x"
fullSymbol="mdi mdi-star text-warning"
initialRating={4.5}
readonly
/>
`;

const ReadOnlyRaterExample = () => (
    <PrismCode
        code={readOnlyRaterCode}
        language={("js", "css", "html")}
        plugins={["line-numbers"]}
    />
);


// On Hover Event
const onHoverCode =
    `
const [customize, setcustomize] = useState("");

<Rating
stop={5}
emptySymbol="mdi mdi-star-outline text-muted "
fullSymbol="mdi mdi-star text-warning "
onChange={(customize) => setcustomize(customize)}
/>
`;

const OnHoverExample = () => (
    <PrismCode
        code={onHoverCode}
        language={("js", "css", "html")}
        plugins={["line-numbers"]}
    />
);


// Clear/Reset Rater	
const reasetRaterCode =
    `
const [reset, setreset] = useState("");

<Rating
emptySymbol="mdi mdi-star-outline text-muted"
fullSymbol={reset}
onHover={() => setreset("mdi mdi-star text-warning")}
/>
`;

const ReasetRaterExample = () => (
    <PrismCode
        code={reasetRaterCode}
        language={("js", "css", "html")}
        plugins={["line-numbers"]}
    />
);

export { TestSMSCurl, BasicRaterExample, BasicRaterExample2, BasicRaterExamplefornode, BasicRaterExampleforjava, BasicRaterExampleforpython, BasicRaterExampleforphp, RaterWithStepExample, CustomMsgExample, ReadOnlyRaterExample, OnHoverExample, ReasetRaterExample };