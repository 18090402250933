import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row, Container, Button, Card, CardBody } from "reactstrap";
import Img1 from "../../assets/images/svg/partnerimage-min.png";
import Img2 from "../../assets/images/svg/exploreimage.svg";
import Image1 from "../../assets/images/svg/tipsreport.svg";
import Image2 from "../../assets/images/svg/tipsreport2.svg";
import Image3 from "../../assets/images/svg/tipsguide.svg";
import Image4 from "../../assets/images/fbimage.png";
import SMSTips from "../../Components/SMSTips";
import Whatsapp from "../../assets/images/whatsappicon.png";
import FBmsg from "../../assets/images/fbmessangericon.png";
import DLCfoods from "../../assets/images/dlcfoodsimage.png";
import Telegramlogo from "../../assets/images/telegramicon.png";

import IMG1 from "../../assets/images/msgboticon1.png";
import IMG2 from "../../assets/images/msgboticon2.png";
import IMG3 from "../../assets/images/msgboticon3.png";
import Man from "../../assets/images/radioimage.png";
import Woman from "../../assets/images/audioimage.png";
import Brivassupport from "../../assets/images/videoimage.png";

import SKYPE from "../../assets/images/iconforskype.png";
import SKYPELOGO from "../../assets/images/imageforskype.png";
import SLACK from "../../assets/images/imageforslack.png";
import SLACKLOGO from "../../assets/images/iconforslack.png";
import INSTAIMAGE from "../../assets/images/imageforinstagram.png";
import INSTAICON from "../../assets/images/iconforinstagram.png";

import TELEGRAMIMAGE from "../../assets/images/telegramimage.png";
import ImgFB from "../../assets/images/fbimage.png";

const CHATGPT = () => (
  <div className=" align-items-center ">
    <img className="card-img-top W-75  img-fluid" src={Woman} alt="Card cap" />
  </div>
);

const Videoimage = () => (
  <div>
    <img
      className="card-img-top w-75 mb-4 pt-0 img-fluid"
      src={Brivassupport}
      alt="Card cap"
    />
  </div>
);

const EMAIL = () => (
  <div>
    <img className="card-img-top img-fluid" src={Image4} alt="Card cap" />
  </div>
);

const UNDERCHATGPT = () => (
  <div className="vstack gap-2 mt-4">
    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG1}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Music Streaming</h5>
      </div>
    </div>
    <br/>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG2}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Podcast</h5>
      </div>
    </div>
    <br/>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG3}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Audio Books</h5>
      </div>
    </div>

    <div></div>
    <div className="d-flex gap-2">
      <button
        color="danger "
        className="btn rounded-pill btn-primary"
        onClick={() => ""}
      >
        Start for free
        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
      </button>

      <button
        color="success"
        className="btn rounded-pill btn-light text-primary btn-border border-primary margin-left-20px"
        onClick={() => ""}
      >
        Talk to an expert
        <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
      </button>
    </div>
  </div>
);


const UNDERSMSCGW = () => (
  <div className="vstack gap-2 mt-4">
    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG1}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">24/7 Updates</h5>
      </div>
    </div>
    <br/>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG2}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Global Updates</h5>
      </div>
    </div>
    <br/>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG3}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Exclusive Channels</h5>
      </div>
    </div>

    <div></div>
    <div className="d-flex gap-2">
      <button
        color="danger "
        className="btn rounded-pill btn-primary"
        onClick={() => ""}
      >
        Start for free
        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
      </button>

      <button
        color="success"
        className="btn rounded-pill btn-light text-primary btn-border border-primary margin-left-20px"
        onClick={() => ""}
      >
        Talk to an expert
        <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
      </button>
    </div>
  </div>
);

const UNDERCHATBOT = () => (
  <div className="vstack gap-2 mt-4">
    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG1}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Media and Entertainment</h5>
      </div>
    </div>
    <br/>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG2}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Healthcare</h5>
      </div>
    </div>
    <br/>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG3}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Educative Content</h5>
      </div>
    </div>
    <br/>

    <div></div>
    <div className="d-flex gap-2">
      <button
        color="danger "
        className="btn rounded-pill btn-primary"
        onClick={() => ""}
      >
        Start for free
        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
      </button>

      <button
        color="success"
        className="btn rounded-pill btn-light text-primary btn-border border-primary margin-left-20px"
        onClick={() => ""}
      >
        Talk to an expert
        <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
      </button>
    </div>
  </div>
);

const SKYPEPAGE = () => (
  <div>
    <img
      className="card-img-top w-75 img-fluid"
      src={SKYPELOGO}
      alt="Card cap"
    />
  </div>
);

const TELEGRAM = () => (
  <div>
    <img
      className="card-img-top w-75 img-fluid"
      src={Man}
      alt="Card cap"
    />
  </div>
);



const UNDERRCS = () => (
  <div className="vstack gap-2 mt-4">
    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG1}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold"> 24/7 Support</h5>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG2}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold"> No Codes Required</h5>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={IMG3}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Omnichannel</h5>
      </div>
    </div>

    <div></div>
    <div className="d-flex gap-2">
      <button
        color="danger "
        className="btn rounded-pill btn-primary"
        onClick={() => ""}
      >
        Start for free
        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
      </button>

      <button
        color="success"
        className="btn rounded-pill btn-light text-primary btn-border border-primary margin-left-20px"
        onClick={() => ""}
      >
        Talk to an expert
        <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
      </button>
    </div>
  </div>
);



const UNDERSKYPE = () => (
  <div className="vstack gap-2 mt-4">
    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={SKYPE}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Global Calling Made Easy</h5>
        <p className="w-75">
          You can easily connect with coworkers and customers located all over
          the world using Skype.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={SKYPE}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Instant Messaging at Your Fingertips</h5>
        <p className="w-75">
          Communicate with your team, share files, and work together on
          projects.
        </p>
      </div>
    </div>

    <div className="media d-flex">
      <img
        className="align-self-start mx-2"
        src={SKYPE}
        alt="Generic placeholder image"
      />
      <div className="media-body">
        <h5 className="mt-0 fw-bold">Unified Communication Hub</h5>
        <p className="w-75">
          Bring together all your contacts, messages, and calls in one place,
          ensuring connections don't end.
        </p>
      </div>
    </div>

    <div></div>
    <div className="d-flex gap-2">
      <button
        color="danger "
        className="btn rounded-pill btn-primary"
        onClick={() => ""}
      >
        Start for free
        <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
      </button>

      <button
        color="success"
        className="btn rounded-pill btn-light text-primary btn-border border-primary margin-left-20px"
        onClick={() => ""}
      >
        Talk to an expert
        <i className="ri-arrow-right-line align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
      </button>
    </div>
  </div>
);

const tabs = [
  {
    title: "Video",
    header: "Video on Demand",
    desc: " Enjoy this experience. Get access to recommended content based on your history and preferences, and watch videos on the device of your choice without worrying about device compatibility.",
    link: "#telecom",
    component: <Videoimage />,
    component2: <UNDERCHATBOT />,
  },
  {
    title: "Audio",
    header: "Audio on Demand",
    desc: " Whether you want to stream music, listen to your favourite podcast, get access to audiobooks from your favourite authors, or broadcast live events, explore all with our Audio Streaming Services.",
    link: "#education",
    component: <CHATGPT />,
    component2: <UNDERCHATGPT />,
  },
  {
    title: "Live Radio",
    header: "Live Radio",
    desc: " Transcend boundaries in the captivating realm of live radio, where voices paint pictures, music sets the mood, and stories come alive. We ensure seamless transmission, providing easy access to live streams via traditional radio frequencies or online platforms. ",
    link: "#healthcare",
    component: <TELEGRAM />,
    component2: <UNDERSMSCGW />,
  },

  {
    title: "USSD",
    header: "Embrace the Wonders of USSD Gateway (USSD GW).",
    desc: " Connect mobile networks and apps/services. Discover how USSD-based services can alter information flow and empower consumers in real time.",
    link: "#telecom",
    component: <SKYPEPAGE />,
    component2: <UNDERSKYPE />,
  },

];

const Homenew = () => {
  const history = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <React.Fragment>
      <section
        className="section pb-0"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: "#FFF5FF",
        }}
      >
        <Container className="">
          <Row className="justify-content-between  align-items-center">
            <div className=" ">
              <h5 className="mb-2">
                <div className="d-flex  gap-2 ">
                  {tabs.map((i, x) => (
                    <button
                      key={`tab-${x}`}
                      color="danger "
                      className="btn btn-outline-primary border border-primary  btn-light rounded-pill text-dark w-20"
                      onClick={() => setSelectedTab(x)}
                    >
                      {i.title}
                      <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                    </button>
                  ))}
                </div>
              </h5>
            </div>
          </Row>

          <div className="py-5">
            <Row>
              <Col lg={6}>
                <div style={{}}>
                  <h6 className="display-6 fw-bold mt-2 mb-3 text-dark fs-21 lh-base">
                    {tabs[selectedTab]?.header}
                  </h6>
                  <p className="mb-4">{tabs[selectedTab]?.desc}</p>
                  <a className="mt-4" href={tabs[selectedTab]?.link}></a>
                </div>
                {tabs[selectedTab].component2}
              </Col>
              <Col lg={6}>{tabs[selectedTab].component}</Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Homenew;
