import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  postSendVerificationSMS,
  // postTestVerificationSMS,
} from "../../helpers/backend_helper";
import { SEND_VERIFICATION_SMS } from "./actonType";
import { verificationSMSError, verificationSMSSuccess } from "./action";

// function* testVericationSMS({ payload: { data } }) {
//   try {
//     const resp = yield call(postTestVerificationSMS, data);
//     if (resp.status === "success") {
//       yield put(verificationSMSSuccess(TEST_VERIFICATION_SMS, resp));
//     } else {
//       toast.error(resp?.msg ?? 'An error occured', { autoClose: 3000 });
//       yield put(verificationSMSError(TEST_VERIFICATION_SMS, resp?.msg ?? 'An error occured'));
//     }
//   } catch (error) {
//     console.log('error'. error);
//     yield put(verificationSMSError(TEST_VERIFICATION_SMS, error?.message ?? 'Failed'));
//     toast.error(error?.response?.data?.msg ?? 'Failed', { autoClose: 3000 });
//   }
// }

function* sendVerificationSMSSaga({ payload: { data } }) {
  // console.log('::::::::::::', data);
  // alert('hey');
try {
  const resp = yield call(postSendVerificationSMS, data);
  if (resp.status === "success") {
    yield put(verificationSMSSuccess(SEND_VERIFICATION_SMS, resp));
  } else {
    toast.error(resp?.msg ?? 'An error occured', { autoClose: 3000 });
    yield put(verificationSMSError(SEND_VERIFICATION_SMS, resp?.msg ?? 'An error occured'));
  }
} catch (error) {
  yield put(verificationSMSError(SEND_VERIFICATION_SMS, error?.message ?? 'Failed'));
  toast.error(error?.response?.data?.msg ?? 'Failed', { autoClose: 3000 });
}
}

// export function* watchTestVerificationSMS() {
//   yield takeEvery(TEST_VERIFICATION_SMS, testVericationSMS);
// }
export function* watchSendVerificationSMS() {
  yield takeEvery(SEND_VERIFICATION_SMS, sendVerificationSMSSaga);
}

function* VerificationSMSSaga() {
  // yield all([fork(watchTestVerificationSMS)]);
  yield all([fork(watchSendVerificationSMS)]);
}

export default VerificationSMSSaga;
