import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Img1 from "../../assets/images/brivasfinancehomepage.png";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Row,
} from "reactstrap";
import SMSTips from "../../Components/SMSTips";

const Tips = () => {
  return (
    <React.Fragment>
      <section className="section px-5" style={{ backgroundColor: "#FFF5FF" }}>
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={5} className="text-center mb-4">
              <img src={Img1} alt="BrivasMobile" className="img-fluid" />
            </Col>
            <Col xs={12} md={7} className="mb-4">
              <div className="mx-md-5 px-md-5">
              <p className="lead lh-base w-100 mb-1 text-dark">
                  BRIVAS FINANCE
                </p>
                <h1 className="display-6 fw-bold w-100 mb-3 text-dark fs-40 lh-base">
                  Cutting-Edge Solution Set to{" "}
                  <span className="text-danger">Revolutionize</span> Financial
                  services
                </h1>
                <p className="lead lh-base w-100 mb-4 text-dark">
                  Financial services with our comprehensive solution empower
                  institutions to provide secure, efficient, and personalized
                  experiences for customers. Stay ahead of the competition and
                  navigate the ever-changing landscape of the financial industry
                  with confidence
                </p>

                <div className="d-flex gap-2 my-5">
                  <button
                    color="primary "
                    className="btn rounded-pill text-light btn-danger"
                    onClick={() => "/register"}
                  >
                    Start for free
                  </button>
                  <button
                    color="success"
                    className="btn rounded-pill text-primary btn-light border-primary"
                    onClick={() => "/register"}
                  >
                    <span className="text-danger">Talk to an expert</span>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Tips;
