import {
  RESOLVE_EMAIL,
  RESOLVE_EMAIL_SUCCESS,
  RESOLVE_EMAIL_FAILED
} from "./actionTypes";

const initialState = {
  msg: null,
  loading: true,
  error: false,
  success: false,
};

const PendingVerification = (state = initialState, action) => {

  switch (action.type) {
    case RESOLVE_EMAIL:
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
        email: null,
      };
      break;
    case RESOLVE_EMAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        success: true,
        email: action.payload.data,
      };
      break;
    case RESOLVE_EMAIL_FAILED:
      state = {
        ...state,
        msg: action.payload.msg,
        loading: false,
        error: true,
        success: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default PendingVerification;
