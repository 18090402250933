import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import {
  Collapse,
  Container,
  NavbarToggler,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Card,
  CardHeader,
  UncontrolledDropdown,
  CardBody,
} from "reactstrap";
// import LogoDark from "../assets/images/logo-dark.png";
import LogoDark from "../assets/images/logo.svg";
import StartupIcon from "../assets/images/svg/startup.svg";
import EnterprisesIcon from "../assets/images/svg/Enterprise.svg";
import NonprofitIcon from "../assets/images/svg/Nonprofit.svg";
import OmnichannelIcon from "../assets/images/svg/Omnichannel.svg";
import OndemandIcon from "../assets/images/svg/Ondemand.svg";
import RetailIcon from "../assets/images/svg/Retail.svg";
import SMBIcon from "../assets/images/svg/SMB.svg";
import ChatBotIcon from "../assets/images/svg/Chatbots.svg";
import FinancialservicesIcon from "../assets/images/svg/Financialservices.svg";
import MarketingIcon from "../assets/images/svg/Marketing.svg";
import EcommerceIcon from "../assets/images/svg/Ecommerce.svg";

import Smslogo from "../assets/images/svg/smslogo.svg";
import Instagramlogo from "../assets/images/svg/instagramlogo.svg";
import Telegramlogo from "../assets/images/svg/telegramlogo.svg";
import Whatsapplogo from "../assets/images/svg/whatsapplogo.svg";
import Messanger from "../assets/images/svg/fbmessangerlogo.svg";
import Rcs from "../assets/images/svg/Rcslogo.svg";
import Voiceserviceslogo from "../assets/images/svg/voiceservicelogo.svg";
import TollfreeVoicelogo from "../assets/images/svg/Toll-free-voicelogo.svg";
import Inappvoicecall from "../assets/images/svg/in-app-voice-call-logo.svg";
import Emergencyservices from "../assets/images/svg/emergency-service-logo.svg";
import VideoApilogo from "../assets/images/svg/video-api-logo.svg";
import Recording from "../assets/images/svg/recording-logo.svg";
import Confrencinglogo from "../assets/images/svg/conferencing-logo.svg";
import Inappvideocallinglogo from "../assets/images/svg/in-app-video-calling-logo.svg";
import Emailapi from "../assets/images/svg/emailapilogo.svg";
import numbersapilogo from "../assets/images/svg/numbersapilogo.svg";
import dlclogo from "../assets/images/svg/dlclogo.svg";
import Tollfree from "../assets/images/svg/Toll-free-voicelogo.svg";
import shortcodes from "../assets/images/svg/shortcodeslogo.svg";
import Campaignslogo from "../assets/images/svg/campaignslogo.svg";
import Contactcenter from "../assets/images/svg/contact-centerlogo.svg";
import Chatbotlogo from "../assets/images/svg/chat-botlogo.svg";
import IOTlogo from "../assets/images/svg/IOTlogo.svg";
import Channelpartner from "../assets/images/svg/channelpartnerlogo.svg";
import Telcopartner from "../assets/images/svg/telcopartnershiplogo.svg";
import Strategic from "../assets/images/svg/strategic partnershiplogo.svg";
import Technologypartner from "../assets/images/svg/technologypartnerslogo.svg";
import TelecomIcon from "../assets/images/svg/Telecom-icon.png";
import EducationIcon from "../assets/images/svg/Education-icon.png";
import HealthcareIcon from "../assets/images/svg/Healthcare-icon.png";
import Skypelogo from "../assets/images/svg/Skype-icon.svg";
import SlackLogo from "../assets/images/svg/Slack-icon.svg";
import verificationicon1 from "../assets/images/svg/Verification-icon1.svg";

// import LogoLight from "../assets/images/logo-light.png";
import LogoLight from "../assets/images/logo.svg";
import { bestSellingProducts } from "../common/data";

const products = [
  {
    label: "Messaging",
    desc: "Versatile communications with our messaging...",
    items: [
      {
        label: "SMS",
        desc: "Reliable, Instant, Personalized Messaging.",
        image: Smslogo,
        href: "/messaging#sms",
      },
      {
        label: "Instagram",
        desc: "Transactional, Conversational, Promotional.",
        image: Instagramlogo,
        href: "/messaging#instagram",
      },
      {
        label: "RCS",
        desc: "Easy, Compelling Two-Way Conversations",
        image: Rcs,
        href: "/messaging#rcs",
      },
      {
        label: "Telegram",
        desc: "Safe, Automated, Rich Media Engagement.",
        image: Telegramlogo,
        href: "/messaging#telegram",
      },
      {
        label: "Whatsapp",
        desc: "Fast, Secure, Engaging Communication.",
        image: Whatsapplogo,
        href: "/messaging#whatsapp",
      },
      {
        label: "Skype",
        desc: "Global Calling, Instant Messaging, Unified Hub",
        image: Skypelogo,
        href: "/messaging#kakao",
      },
      {
        label: "Messenger",
        desc: "Instant Reach, Cost-Free Interaction. ",
        image: Messanger,
        href: "/messaging#messenger",
      },
      {
        label: "Slack",
        desc: "Unified, Productive, Real-Time Workspace.",
        image: SlackLogo,
        href: "/messaging#viber",
      },
    ],
    href: "/messaging",
  },
  {
    label: "Voice",
    desc: "Enhance client relationships with seamless voice solutions for clear communication.",
    items: [
      {
        label: "SIP-Trunking",
        desc: "Cost-Effective, Scalable, Worldwide Connectivity.",
        image: Voiceserviceslogo,
        href: "/voice#voice-services",
      },

      {
        label: "In-App Voice",
        desc: "Secure & Instant Communication.",
        image: TollfreeVoicelogo,
        href: "/voice#toll-free-voice",
      },
      {
        label: "IVR",
        desc: "Insights, Virtual Assistance, Personalized Intgeraction.",
        image: Inappvoicecall,
        href: "/voice#voice-services",  
      },
      {
        label: "Voice-Broadcasting",
        desc: "Timely, Instant, Omni-Channel Notifications.",
        image: Emergencyservices,
        href: "/messaging#emergency-services",
      },
    ],
    href: "/voice",
  },
  {
    label: "Verification",
    desc: "Our Verification solutions ensure user security and confidence.",
    items: [
      {
        label: "Flash Call & Phone Call",
        desc: " Instant Identity Confirmation, Enhanced Security, Seamless User Authentication",
        image: Campaignslogo,
        href: "/Applicationsmessaging#campaigns",
      },
      {
        label: "SMS Verification",
        desc: " Automated, Reliable, and Secure SMS Verification for Customer Protection.",
        image: Contactcenter,
        href: "/Applications#contactcenter",
      },
      {
        label: "Verification API Integration",
        desc: "Manage Client Journeys with Swift Transactions, Enhanced Security Measures, and Exceptional User Experiences.",
        image: Chatbotlogo,
        href: "/Application#chatbot",
      }
    ],
    href: "/verification",
  },
  {
    label: "Streaming",
    desc: "Explore an immersive streaming experience with our diverse selection of content.",
    items: [
      {
        label: "Live Streaming",
        desc: "Immersive, Buffer-Free Broadcasting Experiences.",
        image: VideoApilogo,
        href: "/messaging#video-api",
      },
      {
        label: "Audio On Demand",
        desc: "Real-Time, Immersive Audio Streaming Solutions.",
        image: Recording,
        href: "/messaging#recording",
      },
      {
        label: "Video-Conferencing",
        desc: "Productive, Collaborative, Virtual Meeting Experiences.",
        image: Confrencinglogo,
        href: "/messaging#confrencing",
      },
      {
        label: "In App Video Calling",
        desc: "Easy, Secure, Crystal-Clear Communication.",
        image: Inappvideocallinglogo,
        href: "/messaging#in-app-video-calling",
      },
    ],
    href: "/streaming",
  },
  {
    label: "Email",
    desc: "Simplify communication with our  email solutions.",
    items: [
      {
        label: "Brimail",
        desc: "Elevate your email experience with Brimail's professional branding, secure platform, and seamless data integration for a connected and efficient communication network.",
        image: Emailapi,
        href: "/email#emailapi",
      },
    ],
    href: "/email",
  },
  {
    label: "Numbers & Connectivity",
    desc: "Discover VoIP, Toll-Free and Non-Toll-Free numbers, and streamlined Fax API.",
    items: [
      {
        label: "Voip",
        desc: "Clear Conversations, Flexible Connectivity, Cost Efficiency.",
        image: numbersapilogo,
        href: "/Numbers&connectivity#numbersapi",
      },
      {
        label: "Fax-API",
        desc: "Digital Fax Experience, Simplified Workflow, Enhanced Productivity.",
        image: dlclogo,
        href: "/Numbers&connectivity#10dlc",
      },
      {
        label: "Toll-Free & Non-Toll-Free ",
        desc: "Trustworthy Connections, Enhanced Customer Service.",
        image: Tollfree,
        href: "/Numbers&connectivity#tollfreenumbers",
      },
    ],
    href: "/numbers-and-Connectivity",
  },
  {
    label: "Applications",
    desc: "Enhance customer connections with our versatile application suite.",
    items: [
      {
        label: "Omini-Channel Communication",
        desc: "Easy, Personalized Customer Interaction.",
        image: Campaignslogo,
        href: "/Applicationsmessaging#campaigns",
      },
      {
        label: "Contact-Center",
        desc: "Efficient, Integrated Client Management System.",
        image: Contactcenter,
        href: "/Applications#contactcenter",
      },
      {
        label: "IoT Integration",
        desc: "Smart Automation, Real-Time Monitoring, Seamless Scaling.",
        image: Chatbotlogo,
        href: "/Application#chatbot",
      },
    ],
    href: "/applications",
  },
];

const Partners = [
  {
    label: "Partnership",
    desc: "Collaborate with us on customer engagement",
    image: Telcopartner,
    href: "/partners",
  },
];
const Resources = [
  {
    label: "Resources",
    desc: "Brivas resources",
    image: Telcopartner,
    href: "/",
  },
];

const Navbar = () => {
  const [isOpenMenu, setisOpenMenu] = useState(false);
  const [navClass, setnavClass] = useState("");
  const [subMenus, setSubMenus] = useState(products[0].items);

  //is state for the nav bar added by isaac//
  const [isProductDropdown, setIsProductDropdown] = useState(false);
  const [isSolutionDropdown, setIsSolutionDropdown] = useState(false);
  const [isPartnerDropdown, setIsPartnerDropdown] = useState(false);
  const [isResourcesDropdown, setIsResourcesDropdown] = useState(false);
  const [isDeveloperDropdown, setIsDeveloperDropdown] = useState(false);

  const toggle = () => setisOpenMenu(!isOpenMenu);

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  useEffect(() => {
    setnavClass(" nav");
  }, []);

  const scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setnavClass(" is-sticky navv");
    } else {
      setnavClass(" nav");
    }
  };

  return (
    <React.Fragment>
      <nav
        className={
          "navbar navbar-expand-lg  navbar-landing fixed-top job-navbar " +
          navClass
        }
        id="navbar"
      >
        <Container fluid className="container-fluid px-5">
          <Link className="navbar-brand col-xs-4" to="/">
            <img
              src={LogoDark}
              className="card-logo card-logo-dark"
              alt="logo dark"
              height="28"
            />
            <img
              src={LogoLight}
              className="card-logo card-logo-light"
              alt="logo light"
              height="28"
            />
          </Link>
          <NavbarToggler
            onClick={toggle}
            className="navbar-toggler py-0 fs-20 text-body col-xs-4"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="mdi mdi-menu"></i>
          </NavbarToggler>

          <Collapse className="navbar-collapse col-xs-4" id="navbarSupportedContent">
            <Scrollspy
              offset={-18}
              items={[
                "hero",
                "process",
                "categories",
                "findJob",
                "candidates",
                "blog",
              ]}
              currentClassName="active"
              className="navbar-nav mx-auto mt-2 mt-lg-0"
              id="navbar-example"
            >
              <li className="nav-item">
                <Dropdown
                  nav
                  inNavbar
                  isOpen={isProductDropdown}
                  toggle={() => setIsProductDropdown(!isProductDropdown)}
                >
                  <DropdownToggle nav caret>
                    Products
                  </DropdownToggle>
                  <DropdownMenu>
                    <div className="mega-menu">
                      <div className="menu">
                        <ul>
                          {(products || []).map((item, key) => (
                            <li key={`products_menu_${key}`}>
                              <Link
                                to={item.href}
                                className="text-reset"
                                onMouseEnter={() => setSubMenus(item.items)}
                              >
                                <div className="">
                                  <h5 className="fs-13 fw-bold">
                                    {item.label}
                                  </h5>
                                  <span className="fs-11">{item.desc}</span>
                                </div>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="sub-menu-container">
                        <Row>
                          {subMenus.map((i, index) => (
                            <Col
                              lg={6}
                              className="mb-3"
                              key={`sub-menu-${index}`}
                            >
                              <Link to={i.href} className="d-flex text-reset">
                                <img
                                  src={i.image}

                                  className=""
                                  alt=""
                                  height="24"
                                  width={24}
                                />
                                <div className="ml-2">
                                  <h5 className="fs-13">{i.label}</h5>
                                  <span className="fs-11">{i.desc}</span>
                                </div>
                              </Link>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </li>

              <li className="nav-item">
                <Dropdown
                  nav
                  inNavbar
                  isOpen={isSolutionDropdown}
                  toggle={() => setIsSolutionDropdown(!isSolutionDropdown)}
                >
                  <DropdownToggle nav caret>
                    Solutions
                  </DropdownToggle>
                  <DropdownMenu>
                    <div className="p-3">
                      <table className="">
                        <tbody>
                          <tr
                            align="center"
                            className=" text-muted w-100"
                            key=""
                          >
                            <th width="12000" className="pb-2 fw-normal">
                              Business
                            </th>
                            <th className="pb-2 fw-normal">Industries</th>
                          </tr>

                          <tr key="">
                            <td className="pl-5">
                              <DropdownItem>
                                <Link
                                  to="../startup"
                                  className="d-flex text-reset"
                                >
                                  <div className="d-flex gap-1 w- align-items-center mb-3">
                                    <span className=" px-2 py-1 mr-2 box shadow ">
                                      {/* <i class="ri-rocket-2-fill"></i> */}
                                      <img
                                        src={StartupIcon}
                                        className=""
                                        alt="startup icon"
                                        height="18"
                                      />
                                    </span>
                                    Startup
                                  </div>
                                </Link>
                              </DropdownItem>
                            </td>
                            <td>
                              <DropdownItem>
                                <Link
                                  to="../ecommerce"
                                  className="d-flex text-reset"
                                >
                                  <div className="d-flex gap-1 w- align-items-center mb-3">
                                    <span className=" px-2 py-1 mr-2 box shadow">
                                      <img
                                        src={EcommerceIcon}
                                        className=""
                                        alt="Ecommerce icon"
                                        height="18"
                                      />
                                    </span>
                                    Ecommerce
                                  </div>
                                </Link>
                              </DropdownItem>
                            </td>
                          </tr>
                          <tr key="">
                            <td>
                              <DropdownItem>
                                <Link
                                  to="../enterprise"
                                  className="d-flex text-reset"
                                >
                                  <div className="d-flex gap-1 align-items-center mb-3">
                                    <img
                                      src={EnterprisesIcon}
                                      className=" px-2 py-1 mr-2 box  shadow"
                                      alt="Enterprises icon"
                                      height="28"
                                    />
                                    Enterprise
                                  </div>
                                </Link>
                              </DropdownItem>
                            </td>
                            <td>
                              <DropdownItem>
                                <Link
                                  to="../retail"
                                  className="d-flex text-reset"
                                >
                                  <div className="d-flex gap-1 w- align-items-center mb-3">
                                    <span className=" ">
                                      <img
                                        src={RetailIcon}
                                        className=" px-2 py-1 mr-2 box shadow"
                                        alt="Retail icon"
                                        height="28"
                                      />
                                    </span>
                                    Retail
                                  </div>
                                </Link>
                              </DropdownItem>
                            </td>
                          </tr>
                          <tr key="">
                            <td>
                              <DropdownItem>
                                <Link
                                  to="/non-profit"
                                  className="d-flex text-reset"
                                >
                                  <div className="d-flex gap-1 align-items-center mb-3 ">
                                    <img
                                      src={NonprofitIcon}
                                      className=" px-2 py-1 mr-2 box  shadow"
                                      alt="Enterprises icon"
                                      height="28"
                                    />
                                    Non profit
                                  </div>
                                </Link>
                              </DropdownItem>
                            </td>
                            <td>
                              <DropdownItem>

                              <Link
                                  to="/financial"
                                  className="d-flex text-reset"
                                >
                                <div className="d-flex gap-1 w- align-items-center mb-3">
                                  <span className=" px-2 py-1 mr-2 box shadow">
                                    <img
                                      src={FinancialservicesIcon}
                                      className=""
                                      alt="Financialservices icon"
                                      height="20"
                                    />
                                  </span>
                                  Financial Services
                                </div>
                                </Link>
                              </DropdownItem>
                            </td>
                          </tr>
                          <tr key="">
                            <td>
                              <DropdownItem>
                                <div className="d-flex gap-1 w- align-items-center mb-3">
                                  <span className="">
                                    <img
                                      src={SMBIcon}
                                      className=" px-2 py-1 mr-2 box shadow"
                                      alt="Smbs icon"
                                      height="28"
                                    />
                                  </span>
                                  SMBs
                                </div>
                              </DropdownItem>
                            </td>
                            <td>
                              <DropdownItem>
                                <Link
                                  to="/telecommunication"
                                  className="d-flex text-reset"
                                >
                                  <div className="d-flex gap-1 w- align-items-center mb-3">
                                    <img
                                      src={TelecomIcon}
                                      className=""
                                      alt="Ecommerce icon"
                                      height="28"
                                    />
                                    Telecommunication
                                  </div>
                                </Link>
                              </DropdownItem>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              <DropdownItem>
                                <Link
                                  to="/education"
                                  className="d-flex text-reset"
                                >
                                  <div className="d-flex gap-1 w- align-items-center mb-3">
                                    <img
                                      src={EducationIcon}
                                      className=""
                                      alt="Ecommerce icon"
                                      height="28"
                                    />
                                    Education
                                  </div>
                                </Link>
                              </DropdownItem>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              <DropdownItem>
                                <Link
                                  to="../telehealth"
                                  className="d-flex text-reset"
                                >
                                  <div className="d-flex gap-1 align-items-center mb-3">
                                    <img
                                      src={HealthcareIcon}
                                      className=""
                                      alt="Ecommerce icon"
                                      height="28"
                                    />
                                    Healthcare
                                  </div>
                                </Link>
                              </DropdownItem>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </li>

              <li className="nav-item">
                <Dropdown
                  nav
                  inNavbar
                  isOpen={isPartnerDropdown}
                  toggle={() => setIsPartnerDropdown(!isPartnerDropdown)}
                >
                  <DropdownToggle nav caret>
                    Partners
                  </DropdownToggle>
                  <DropdownMenu>
                    <div className="mega-menu">
                      <div className="menu">
                        <ul>
                          {(Partners || []).map((item, key) => (
                            <li key={`products_menu_${key}`}>
                              <Link to={item.href} className="text-reset">
                                <div className="">
                                  <h5 className="fs-13 fw-bold">
                                    {item.label}
                                  </h5>
                                  <span className="fs-11">{item.desc}</span>
                                </div>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </li>

              <Dropdown
                nav
                inNavbar
                isOpen={isDeveloperDropdown}
                toggle={() => setIsDeveloperDropdown(!isDeveloperDropdown)}
              >
                <DropdownToggle nav caret>
                  Developers
                </DropdownToggle>
              </Dropdown>
              <li className="nav-item">
                <Dropdown
                  nav
                  inNavbar
                  isOpen={isResourcesDropdown}
                  toggle={() => setIsResourcesDropdown(!isResourcesDropdown)}
                >
                  <DropdownToggle nav caret>
                    Resources
                  </DropdownToggle>
                  <DropdownMenu>
                    <div className="mega-menu">
                      <div className="menu">
                        <ul>
                          {(Resources || []).map((item, key) => (
                            <li key={`resources_menu_${key}`}>
                              <Link to={item.href} className="text-reset">
                                <div className="">
                                  <h5 className="fs-13 fw-bold">
                                    {item.label}
                                  </h5>
                                  <span className="fs-11">{item.desc}</span>
                                </div>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </li>
            </Scrollspy>
            <div className="">
              <Link
                to="/login"
                className="btn btn-link fw-medium text-decoration-none text-dark"
              >
                Login
              </Link>
              <Link to="/register" className="btn btn-primary rounded-pill">
                Sign up
              </Link>
            </div>
          </Collapse>
        </Container>
      </nav>
    </React.Fragment>
  );
};

export default Navbar;
