import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';

const RequirementsTab = () => {
    return (
        <React.Fragment>
            <Row>
                <Col xl={9} lg={8}>
                    <Card className='shadow-none'>
                        <CardBody>
                            <div className="text-muted">
                                <p>In order to create our SMS Application it is required to download and sign our terms of service agreement for SMS. You will be required to upload the signed copy during the application creation.</p>

                                <div className="pt-3 border-top border-top-dashed mt-4">
                                    <h6 className="mb-3 fw-semibold text-uppercase">Documents</h6>
                                    <Row className="g-3">
                                        <Col xxl={6} lg={8}>
                                            <div className="border rounded border-dashed p-2">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar-sm">
                                                            <div className="avatar-title bg-light text-secondary rounded fs-24">
                                                                <i className="ri-folder-zip-line"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1 overflow-hidden">
                                                        <h5 className="fs-13 mb-1"><Link to="#" className="text-body text-truncate d-block">Terms of Service Agreement.pdf</Link></h5>
                                                        <div>2.2MB</div>
                                                    </div>
                                                    <div className="flex-shrink-0 ms-2">
                                                        <div className="d-flex gap-1">
                                                            <button type="button" className="btn btn-icon text-muted btn-sm fs-18"><i className="ri-download-2-line"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </CardBody>

                    </Card>
                </Col>

                <Col xl={3} lg={4}>
                    <Card className='shadow-none'>
                        <CardBody>
                            <h5 className="card-title mb-4">Other Apps</h5>
                            <div className="d-flex flex-wrap gap-2 fs-16">
                                <div className="badge fw-medium badge-soft-secondary">SMS</div>
                                <div className="badge fw-medium badge-soft-secondary">Bulk SMS</div>
                                <div className="badge fw-medium badge-soft-secondary">IVR</div>
                                <div className="badge fw-medium badge-soft-secondary">Audio Streaming</div>
                                <div className="badge fw-medium badge-soft-secondary">Video Streaming</div>
                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default RequirementsTab;