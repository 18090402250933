import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import NonAuthLayout from "../Layouts/NonAuthLayout";

import { publicRoutes } from "./allRoutes";
import { useDispatch, useSelector } from 'react-redux';
import AuthProtected from './AuthProtected';
import { accountProfile } from '../store/account/actions';

const Index = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const history = useNavigate();

    const { account, isResolving } = useSelector(state => ({
        account: state.Account.account,
        isResolving: state.Account.isResolving,
    }));

    useEffect(() => {
        dispatch(accountProfile(history));
    }, [location?.pathname]);

    if (!account && isResolving) {
        return <div style={{ display: 'absolute', bottom: 0, width: '100%', textAlign: 'center' }}>
            <div style={{ marginTop: '20%' }}>Loading...</div>
        </div>
    }

    return (
        <React.Fragment>
            {!account && <Routes>
                <Route>
                    {publicRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <NonAuthLayout>
                                    {route.component}
                                </NonAuthLayout>
                            }
                            key={idx}
                            exact={true}
                        />
                    ))}
                </Route>
            </Routes>}
            {account && <AuthProtected />}
        </React.Fragment>
    );
};

export default Index;