import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import img1 from "../../assets/images/svg/brivasmap-min.png";
import img3 from "../../assets/images/svg/shoediscount-min.png";

const Home = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <React.Fragment>
      <div class="row d-flex align-items-center justify-content-center text-center">
        <div class="col-md-6">
          <h1 className="mb-3 fs-47 fw-bold text-dark">
            Why Choose BillyRonks?
          </h1>
          <p class=" mb-1 align-items-center p-3 text-dark lh-base">
            BRIVAS is a leading aggregator of versatile Value-Added Services
            (VAS) tailored to meet the unique needs of various industries. With
            a solid foundation in telecommunications and IT, we offer innovative
            VAS solutions that enhance communication, engagement, and efficiency
            across diverse sectors.
          </p>

          {/* <div className="text-center">
            
          </div>
          <div className="row col-md-8 text-center w-50" style={{border:'10px double red'}}>
            
          </div> */}
        </div>
        <div
          className="col-md-12 col-sm-12 my-0 text-center d-md-block"
        >
          <img src={img1} alt="" className="img-fluid w-75" />
        </div>
      </div>
      <div className="fs-50" id="plans">
        <div className="bg-light  opacity-100"></div>
        <div className="w-75 mx-auto">
          <Row className="align-items-center mt-2 pt-lg-5 gy-4">
            <Col lg={6} sm={7} className="col-10 mx-auto">
              <div>
                <img src={img3} alt="" className="img-fluid" />
              </div>
            </Col>
            <Col lg={6}>
              <div className="text-muted ps-lg-2">
                <h1 className=" fs-25 text-bold w-75 text-dark mb-3">
                  Surpass those customer expectations with Brivas solution
                </h1>

                <div className="d-flex gap-2">
                  <button
                    color="danger "
                    className="btn rounded-pill btn-primary"
                    onClick={() => history("/register")}
                  >
                    Talk to an Expert
                    <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
