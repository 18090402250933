import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

// import JobProfile2 from "../../assets/images/job-profile2.png";
import img1 from "../../assets/images/svg/Patners-home-img.png";

import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const Home = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <section
        className="section  bg-secondary pb-0"
        id="hero"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "auto",
          height: "590px",
        }}
      >
        <div className=" bg-home-overlay-pattern2"></div>
        <div className="px-4">
          <Row className="align-items-center p-3 p-4 gy-4">
            <div className="text-bold text-light fs-40 pt-5 py-5 text-center">
              <h1 className="mb-3 text-light">
                Connecting Visions, Empowering
                <br />
                Futures: Join The Brivas Partner
                <br />
                Network.
              </h1>
              <p>
                Join us in shaping the future of customer engagement. Partner
                with BRIVAS today, and let's unlock new opportunities together.
              </p>

              <div className="d-flex justify-content-center my-5">
                <button
                  color="danger "
                  className=" btn-light btn rounded-pill btn-primary mx-3 text-primary"
                  onClick={() => history("/register")}
                >
                  Start for free
                </button>

                <button
                  color="success"
                  className="btn rounded-pill text-danger  text-light border border-white margin-left-20px"
                  onClick={() => history("/register")}
                >
                  Talk to an expert
                  <i className="align-middle ms-1 margin-right-3rem padding-left-30px rounded-pill"></i>
                </button>
              </div>
            </div>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Home;
