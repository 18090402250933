import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { listApps } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import TextTruncate from 'react-text-truncate';


const Apps = () => {
  document.title = "BRIVAS | Apps";

  const dispatch = useDispatch();

  const { apps } = useSelector(state => ({
    apps: state.Apps.apps
  }));

  useEffect(() => {
    dispatch(listApps());
  }, [dispatch]);

  const importIcon = name => {
    try {
      return require(`../assets/images/apps/${name}.svg`)?.default
    } catch (error) {
      return require(`../assets/images/apps/default.svg`)?.default
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">

          <Row>
            <Col lg={12}>
              <div className="d-flex align-items-center mb-4">
                <div className="flex-grow-1">
                  <p className="text-muted fs-14 mb-0">
                    Result: <span id="total-result">9</span>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <UncontrolledDropdown className="dropdown">
                    <DropdownToggle
                      className="btn text-muted fs-14 dropdown-toggle"
                      to="#"
                      role="button"
                      tag="button"
                      type="button"
                    >
                      All Apps
                    </DropdownToggle>
                    <DropdownMenu
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <li>
                        <DropdownItem className="dropdown-item" href="#">
                          All
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem className="dropdown-item" href="#">
                          Activated
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem className="dropdown-item" href="#">
                          Not Activated
                        </DropdownItem>
                      </li>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            </Col>
          </Row>

          <Row id="job-list">
            {/* <Col lg={3} md={6} id="job-widget">
              <Card className="card-height-100 bg-info bg-job">
                <CardBody className="p-5">
                  <h2 className="lh-base text-white">
                    Velzon invites young professionals for an intership!
                  </h2>
                  <p className="text-white-75 mb-0 fs-14">
                    Don't miss your opportunity to improve your skills!
                  </p>
                  <div className="mt-5 pt-2">
                    <button type="button" className="btn btn-light w-100">
                      View More{" "}
                      <i className="ri-arrow-right-line align-bottom"></i>
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
            {apps.map((item, key) => (
              <Col lg={3} md={6} key={key}>
                <Link to={`/app/${item.slug}`}>
                  <Card>
                    <CardBody>
                      {/* <button
                        type="button"
                        className="btn btn-icon btn-soft-primary float-end"
                        data-bs-toggle="button"
                        aria-pressed="true"
                      >
                        <i className="mdi mdi-cards-heart fs-16"></i>
                      </button> */}
                      <div className="avatar-sm mb-4">
                        <div className="avatar-title bg-light rounded">
                          <img
                            src={importIcon(item.slug)}
                            alt=""
                            className="avatar-xxs"
                          />
                        </div>
                      </div>
                      <h5 className="text-dark">{item.name}</h5>
                      {/* <p className="text-muted">{item.description}</p> */}
                      <TextTruncate
                        className=""
                        line={4}
                        element="p"
                        truncateText="…"
                        text={item.description}
                      // textTruncateChild={<a href="#">Read on</a>}
                      />
                      <div className="hstack gap-2">
                        {/* {item.status === 'Active' ? */}
                        <span className="badge badge-soft-success">Activated</span>
                        {/* :
                          <span className="badge badge-soft-danger">Not Activated</span>} */}
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>

          <Row
            className="g-0 justify-content-end mb-4"
            id="pagination-element"
          >
            <Col sm={6}>
              <div className="pagination-block pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                <div className="page-item">
                  <Link to="" className="page-link" id="page-prev">
                    Previous
                  </Link>
                </div>
                <span id="page-num" className="pagination"></span>
                <div className="page-item">
                  <Link to="" className="page-link" id="page-next">
                    Next
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Apps;
