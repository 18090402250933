import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

// import JobProfile2 from "../../assets/images/job-profile2.png";
import img1 from "../../assets/images/streamingbanner.png";

import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const Voicebroadcast = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <section
        className="section  bg-secondary pb-0"
        id="hero"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "auto",
          height: "590px",
        }}
      >
        <div className=" bg-home-overlay-pattern2"></div>
        <div className="px-4">
          <Row className="align-items-center p-3 p-4 gy-4">
            <div className="text-bold text-light  pt-5 py-5 text-center">
              <h5 className="text-light"> STREAMING SERVICES</h5>
              <h1 className="mb-3 fs-50 text-light">
                Your Final Destination for
                <br />
                Immersive  <span className="text-danger">Entertainment</span>
              </h1>

              <br />
              <p className="align-items-center fs-40 ">
                We are delivering you a world of captivating TV shows, Video &
                Audio on demand, live streaming and more.
              </p>
              <div className="d-flex justify-content-center my-5">
                <button
                  color="danger "
                  className=" btn-danger btn rounded-pill ml-2"
                  onClick={() => history("/register")}
                >
                  Start for free
                  <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                </button>
              </div>
            </div>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Voicebroadcast;
