import {
  CREATE_APP,
  CREATE_APP_ERROR,
  CREATE_APP_SUCCESS,
  GET_APP,
  GET_APP_ERROR,
  GET_APP_SUCCESS,
  LIST_MY_APPS,
  LIST_MY_APPS_ERROR,
  LIST_MY_APPS_SUCCESS,
} from "./actionType";

const INIT_STATE = {
  app: null,
  mApps: [],
  msg: null,
  loading: true,
  error: false,
  success: false,
  action: null,
  appCreated: false,
};

const Apps = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_APP:
      state = { ...state, loading: true, error: false, success: false, appCreated: false };
      break;
    case CREATE_APP_SUCCESS:
      state = { ...state, loading: false, error: false, success: true, appCreated: true };
      break;
    case CREATE_APP_ERROR:
      state = { ...state, loading: false, error: true, success: false };
      break;

    case GET_APP:
      state = { ...state, loading: true, error: false, success: false, appCreated: false };
      break;
    case GET_APP_SUCCESS:
      state = { ...state, loading: false, error: false, success: true, app: action.payload.data, appCreated: false };
      break;
    case GET_APP_ERROR:
      state = { ...state, msg: action.payload.msg, loading: false, error: true, success: false };
      break;

    case LIST_MY_APPS:
      state = { ...state, loading: true, error: false, success: false, appCreated: false };
      break;
    case LIST_MY_APPS_SUCCESS:
      state = { ...state, loading: false, error: false, success: true, mApps: action.payload.data, appCreated: false };
      break;
    case LIST_MY_APPS_ERROR:
      state = { ...state, loading: false, error: true, success: false, msg: action.payload.msg, };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;

};
export default Apps;