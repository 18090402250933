import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import img1 from "../../assets/images/svg/Ecommerce-banner2-img.png";
import TowerIcon from "../../assets/images/svg/Purpletick-img.png";
import Icon1 from "../../assets/images/svg/Ecommerce-banner2-icon1.png";
import Icon2 from "../../assets/images/svg/Ecommerce-banner2-icon2.png";
import Icon3 from "../../assets/images/svg/Ecommerce-banner2-icon3.png";
import Icon4 from "../../assets/images/svg/Ecommerce-banner2-icon4.png";

const Banner2 = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section style={{ backgroundColor: "#FFF5FF" }}>
      <div className="mx-md-5 px-3 px-md-5">
        <div className="row">
          <h1 className="col-sm-12 col-md-12 text-center mb-1 ff-secondary fw-bold text-bolder lh-base fs-48 mb-4 text-dark">
            Connect with your customers through a diverse
            <br />
            range of messaging channels.
          </h1>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-12 my-5 d-flex align-items-center order-1">
            <img src={img1} alt="" className="user-img w-75" />
          </div>
          <div class="col-md-6 col-sm-12 order-2 order-md-1">
            <h4 className=" lh-base text-sm-center text-md-start mt-md-5 text-dark">
              Strengthen Your Transactions!
            </h4>
            <p className="mb-5">
              Rest easy knowing your transactions are safe and secure. Our
              robust verification solutions implement multi-factor
              authentication, validate user credentials, and protect against
              fraudulent activities. Build trust with your customers by
              prioritizing their data security and ensuring a worry-free
              transaction experience.
            </p>
            <div className="media d-flex mb-4">
              <img
                className="align-self-start mx-2"
                src={Icon1}
                alt="Generic placeholder image"
              />
              <div className="media-body text-dark">
                <h5 className="mt-0">Send personalized SMS notifications</h5>
              </div>
            </div>
            <div className="media d-flex mb-4">
              <img
                className="align-self-start mx-2"
                src={Icon2}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0 text-dark">
                  Leverage RCS messaging for immersive rich media experiences
                </h5>
              </div>
            </div>
            <div className="media d-flex mb-4">
              <img
                className="align-self-start mx-2"
                src={Icon3}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0 text-dark">
                  Engage with customers on social media platforms
                </h5>
              </div>
            </div>
            <div className="media d-flex mb-3">
              <img
                className="align-self-start mx-2"
                src={Icon4}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0 text-dark">
                  Deploy intelligent chatbots for automated interactions
                </h5>
              </div>
            </div>

            <div className="d-flex gap-2 my-5">
              <button
                color="primary "
                className="btn rounded-pill btn-primary"
                onClick={() => "/register"}
              >
                Start for free
                <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
              </button>
              <button
                color="success"
                className="btn rounded-pill btn-light border-primary"
                onClick={() => history("/register")}
              >
                <span className="text-primary">Talk to an expert</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner2;
