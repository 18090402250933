import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Row,
} from "reactstrap";

import img1 from "../../assets/images/Frame 285176.svg";
import img2 from "../../assets/images/Rectangle 4684.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Header from "./Header";

const Insight = () => {
  const [activeTab, setactiveTab] = useState(1);
  const [progressbarvalue, setprogressbarvalue] = useState(0);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };
  const handleShow2 = () => {
    setShow2(!show2);
  };

  const { account } = useSelector((state) => ({
    account: state.Account.account,
  }));

  function toggleTab(tab, ntype, value) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setprogressbarvalue(value);
  }

  document.title = "BRIVAS | Insight";

  return (
    <Container fluid>
      <Card className="shadow-none">
        <Header>
          <h1 className="fw-bold">
            Brivas Bulk SMS
          </h1>
          <p className="card-title mb-0 w-50 text-muted mt-2 fs-13">
            Getting started with our Bulk Sms service?
          </p>
        </Header>
        <CardBody className="mx-4">
          <Link to="/bulk-sms/create-sms" className="bg-primary rounded-pill text-light px-5 fw-bold text-center py-2">
            Create SMS
          </Link>
          <Row className="justify-content-center mt-3">
            <Col xs={6} sm={6} md={3} className="mb-3">
              <Card
                className="d-flex flex-column p-3 rounded justify-content-center align-items-center"
                style={{
                  width: "230px",
                  height: "203px",
                  borderRadius: "16px",
                }}
              >
                <p>Sent</p>
                <div className="display-5">0</div>
              </Card>
            </Col>
            <Col xs={6} sm={6} md={3} className="mb-3">
              <Card
                className="d-flex flex-column p-3 rounded justify-content-center align-items-center"
                style={{
                  width: "230px",
                  height: "203px",
                  borderRadius: "16px",
                }}
              >
                <p>Delivered</p>
                <div className="display-5">0</div>
              </Card>
            </Col>
            <Col xs={6} sm={6} md={3} className="mb-3">
              <Card
                className="d-flex flex-column p-5 rounded justify-content-center align-items-center"
                style={{
                  width: "230px",
                  height: "203px",
                  borderRadius: "16px",
                }}
              >
                <p>Pending</p>
                <div className="display-5">0</div>
              </Card>
            </Col>
            <Col xs={6} sm={6} md={3} className="mb-3">
              <Card
                className="d-flex flex-column p-5 rounded justify-content-center align-items-center"
                style={{
                  width: "230px",
                  height: "203px",
                  borderRadius: "16px",
                }}
              >
                <p>Rejected</p>
                <div className="display-5">0</div>
              </Card>
            </Col>
          </Row>

          <div className="d-flex my-2">
            <img src={img1} />
            <div className="d-flex flex-column flex-grow-1 align-items-center">
              <Card
                className="d-flex flex-column p-5 rounded justify-content-center align-items-center"
                style={{
                  width: "230px",
                  height: "203px",
                  borderRadius: "16px",
                  backgroundColor: "#F5F5F5",
                }}
              >
                <p>Contacts</p>
                <div className="display-5">0</div>
              </Card>
              <Button className="bg-primary rounded-pill px-5 fw-bold text-center py-2 my-4">
                Import Contacts
              </Button>
            </div>
          </div>

          <Row className="d-flex justify-content-around mt-5">
            <Col md={6} sm={12} className="rounded">
              <div className="fw-bold text-dark mb-3">
                Other exciting applications
              </div>
              <div>
                <Card
                  className="d-flex flex-column px-5 py-2 rounded justify-content-center align-items-center"
                  style={{
                    borderRadius: "16px",
                    backgroundColor: "#F5F5F5",
                  }}
                >
                  <div className=" mt-3text-center">
                    <h1>More platforms more reach</h1>
                    <p className="">
                      Lorem ipsum dolor sit amet consectetur. Bibendum nullam
                      lorem elit mattis suspendisse eget
                    </p>
                  </div>

                  <CardBody>
                    <div className="d-flex  justify-content-center">
                      <Button className="bg-primary rounded-pill px-5 mx-5 fw-bold text-center py-3 my-4 d-flex gap-2">
                        <i class="ri-whatsapp-fill"></i> WhatsApp
                      </Button>
                      <Button className="bg-primary rounded-pill px-5 mx-5 fw-bold text-center py-3 my-4 d-flex gap-2">
                        <i class="ri-facebook-circle-fill"></i> Facebook
                      </Button>
                    </div>
                    <div className="d-flex justify-content-center">
                      <Button className="bg-primary rounded-pill px-5 mx-5 fw-bold text-center py-3 my-4 d-flex gap-2">
                        <i class="ri-instagram-fill"></i> Instagram
                      </Button>
                      <Button className="bg-primary rounded-pill px-5 mx-5 fw-bold text-center py-3 my-4 d-flex gap-2">
                        <i class="ri-macbook-fill"></i> More
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col md={6} sm={12} className="rounded">
              <div className="fw-bold text-dark mb-3">
                Other exciting applications
              </div>
              <Card className="rounded" style={{ backgroundColor: "#F5F5F5" }}>
                <CardBody>
                  <div className="text-center">
                    <h2 className="mb-3">Boost campaign with AI</h2>
                    <p className="mb-4">
                      Lorem ipsum dolor sit amet consectetur. Bibendum nullam
                      lorem elit mattis suspendisse eget malesuada eu feugiat.
                    </p>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src={img2} className="img-fluid mb-3" alt="AI Image" />
                    <Button className="bg-primary rounded-pill px-4 py-2 fw-bold">
                      Try it out
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container >
  );
};

export default Insight;
