import { combineReducers } from "redux";

// Landing
import Landing from "./landing/reducer";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
// import Account from "./auth/register/reducer";
import PendingVerification from "./auth/pending-verification/reducer";
// import Account from "./auth/register/reducer";
import Profile from "./auth/profile/reducer";
import Account from "./account/reducer";

import QRLogin from "./auth/qr-login/reducer";

// Onboarding
import CreateAccount from "./auth/createaccount/reducer";
import VerifyAccount from "./auth/verify-account/reducer";
import AccountPhone from "./auth/account-phone/reducer";
import AccountVerify from "./auth/account-verify/reducer";

import Apps from "./apps/reducer";
import App from "./app/reducer";

import BulkSMS from "./bulksms/reducer";
import Smsotp from "./smsotp/reducer";
import FlashCall from "./flashcall/reducer";

import UploadContact from "./uploadcontacts/reducer";


// //Calendar
// import Calendar from "./calendar/reducer";
// //Chat
// import chat from "./chat/reducer";
// //Ecommerce
// import Ecommerce from "./ecommerce/reducer";

// //Project
// import Projects from "./projects/reducer";

// // Tasks
// import Tasks from "./tasks/reducer";
// //Form advanced
// import changeNumber from "./formAdvanced/reducer";

// //Crypto
// import Crypto from "./crypto/reducer";

// //TicketsList
// import Tickets from "./tickets/reducer";
// //Crm
// import Crm from "./crm/reducer";

// //Invoice
// import Invoice from "./invoice/reducer";

// //Mailbox
// import Mailbox from "./mailbox/reducer";

// // Dashboard Analytics
// import DashboardAnalytics from "./dashboardAnalytics/reducer";

// // Dashboard CRM
// import DashboardCRM from "./dashboardCRM/reducer";

// // Dashboard Ecommerce
// import DashboardEcommerce from "./dashboardEcommerce/reducer";

// // Dashboard Cryto
// import DashboardCrypto from "./dashboardCrypto/reducer";

// // Dashboard Cryto
// import DashboardProject from "./dashboardProject/reducer";

// // Dashboard NFT
// import DashboardNFT from "./dashboardNFT/reducer";

// // Pages > Team
// import Team from "./team/reducer";

// // File Manager
// import FileManager from "./fileManager/reducer"

// // To do
// import Todos from "./todos/reducer"

// //Job 
// import Jobs from "./job/reducer";

// //API Key
// import APIKey from "./apikey/reducer";

const rootReducer = combineReducers({
    // public
    Landing,
    Layout,
    Login,
    CreateAccount,
    PendingVerification,
    VerifyAccount,
    AccountPhone,
    AccountVerify,
    Account,
    Profile,
    QRLogin,
    Apps,
    App,
    BulkSMS,
    UploadContact,

    Smsotp,
    FlashCall
    
    

    // Calendar,
    // chat,
    // Projects,
    // Ecommerce,
    // Tasks,
    // changeNumber,
    // Crypto,
    // Tickets,
    // Crm,
    // Invoice,
    // Mailbox,
    // DashboardAnalytics,
    // DashboardCRM,
    // DashboardEcommerce,
    // DashboardCrypto,
    // DashboardProject,
    // DashboardNFT,
    // Team,
    // FileManager,
    // Todos,
    // Jobs,
    // APIKey
});

export default rootReducer;