import React, { useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Input,
    Button,
    Alert,
    NavLink,
    ModalHeader,
    ModalBody,
    Modal,
    Accordion,
    AccordionItem,
    Collapse,
    Badge,
    Col,
    Container,
    Form,
    Label,
    Nav,
    NavItem,
    Row,
    TabContent,
    TabPane,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledTooltip,
    UncontrolledDropdown,
} from "reactstrap";

import SimpleBar from "simplebar-react";
import classnames from "classnames";
import {
    BasicRaterExample,
    BasicRaterExamplefornode,
    BasicRaterExampleforjava,
    BasicRaterExampleforpython,
    BasicRaterExampleforphp,

} from "../../Components/UiRatingsCode";
import { TestSMSCurl, } from "../../Components/UiRatingsCode";
import { Textarea } from "@mui/joy";

const SendAnSms = () => {
    const [activeTab, setactiveTab] = useState(1);
    const [progressbarvalue, setprogressbarvalue] = useState(0);
    const [passedSteps, setPassedSteps] = useState([1]);
    const [codeTab, setCodeTab] = useState("cURL"); // Define isTypeTab variable
    const [isLoader, setIsLoader] = useState(false);




    const [modal_grid, setmodal_grid] = useState(false);
    function tog_grid() {
        setmodal_grid(!modal_grid);
    }

    const [col1, setcol1] = useState(true);
    const [col2, setcol2] = useState(false);
    const [col3, setcol3] = useState(false);

    const t_col1 = () => {
        setcol1(!col1);
        setcol2(false);
        setcol3(false);
    };

    const t_col2 = () => {
        setcol2(!col2);
        setcol1(false);
        setcol3(false);
    };

    const t_col3 = () => {
        setcol3(!col3);
        setcol1(false);
        setcol2(false);
    };





    function toggleTab(tab, ntype, value) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab];

            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab);
                setPassedSteps(modifiedSteps);
            }
        }
        setprogressbarvalue(value);
    }

    document.title = "Wizard | Velzon - React Admin & Dashboard Template";
    return (
        <Container fluid>
            <div></div>
            <Card className="shadow-none mt-5">
                <CardHeader>
                    <h1 className=" mb-0 fs-120 fw-bold">Get started with Brivas SMS API</h1>
                    <p className="card-title mb-0 w-50 text-muted mt-2 fs-13">
                        Try our API by sending an SMS to your phone. Sending SMS uses
                        your account credit. Learn more
                    </p>
                </CardHeader>
                <CardBody>
                    <Alert color="text-dark" style={{ backgroundColor: '#F0F0F0' }}>
                        <strong>
                            When sending SMS in Nigeria, we <span className="text-primary">may override the ‘From’ field</span>
                        </strong>
                        <br />
                        This is to ensure delivery when restrictions apply. These
                        restrictions can be avoided in some locations. Find out more
                        <br />
                    </Alert>
                    <Row>
                        <Col xl={6}>
                            <Form action="#" className="form-steps">
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId={1}>
                                        <div>
                                            <div className="mb-4"></div>
                                            <h5>
                                                <p>
                                                    <strong>Run test </strong>
                                                </p>
                                            </h5>

                                            <div className="mb-3 col-md-10">
                                                <Label
                                                    className="form-label fw-bold fs-12"
                                                    htmlFor="gen-info-email-input"
                                                >
                                                    From
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="gen-info-email-input"
                                                    placeholder="Brivas"
                                                />
                                            </div>
                                            <div className="mb-3 col-md-10">
                                                <Label
                                                    className="form-label fw-bold"
                                                    htmlFor="gen-info-password-input"
                                                >
                                                    Registered phone number
                                                </Label>
                                                <Input
                                                    type="password"
                                                    className="form-control"
                                                    id="gen-info-password-input"
                                                    placeholder="2348165661377"
                                                />

                                                <Label
                                                    className="form-label text-muted fw-normal"
                                                    htmlFor="gen-info-password-input"
                                                >
                                                    You can only use the registered number to receive messages in this example.
                                                </Label>
                                            </div>
                                            {/* <div>
                                                        <Accordion id="default-accordion-example">
                                                            <AccordionItem>
                                                                <h2
                                                                    className="accordion-header"
                                                                    id="headingOne"
                                                                >
                                                                    <button
                                                                        className={classnames("accordion-button", {
                                                                            collapsed: !col1,
                                                                        })}
                                                                        type="button"
                                                                        onClick={t_col1}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        Added numbers
                                                                    </button>
                                                                </h2>

                                                                <Collapse
                                                                    isOpen={col1}
                                                                    className="accordion-collapse"
                                                                    id="collapseOne"
                                                                >
                                                                    <div className="accordion-body">
                                                                        In the free trial, you can only send
                                                                        messages to verfied numbers. To test sending
                                                                        in bulk you can upload uo to 5 numbers
                                                                        <br />
                                                                        <br />
                                                                        Phones that you ahve easy access to should
                                                                        be preferred, all numbers have to be
                                                                        verified
                                                                    </div>
                                                                    <CardBody>
                                                                        <div className="live-preview">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => tog_grid()}
                                                                            >
                                                                                Add number
                                                                            </Button>
                                                                        </div>

                                                                    </CardBody>
                                                                    <Modal
                                                                        isOpen={modal_grid}
                                                                        toggle={() => {
                                                                            tog_grid();
                                                                        }}
                                                                    >
                                                                        <ModalHeader
                                                                            className="modal-title"
                                                                            toggle={() => {
                                                                                tog_grid();
                                                                            }}
                                                                        >
                                                                            Add Numbers
                                                                        </ModalHeader>
                                                                        <ModalBody>
                                                                            <form action="#">
                                                                                <div className="row g-3">
                                                                                    <Col xxl={6}>
                                                                                        <div>
                                                                                            <label
                                                                                                htmlFor="firstName"
                                                                                                className="form-label"
                                                                                            >
                                                                                                First Name
                                                                                            </label>
                                                                                            <Input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                id="firstName"
                                                                                                placeholder="Enter firstname"
                                                                                            />
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col xxl={6}>
                                                                                        <div>
                                                                                            <label
                                                                                                htmlFor="lastName"
                                                                                                className="form-label"
                                                                                            >
                                                                                                Last Name
                                                                                            </label>
                                                                                            <Input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                id="lastName"
                                                                                                placeholder="Enter lastname"
                                                                                            />
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col lg={12}>
                                                                                        Add listing for countries
                                                                                    </Col>
                                                                                    <Col xxl={6}>
                                                                                        <label
                                                                                            htmlFor="emailInput"
                                                                                            className="form-label"
                                                                                        >
                                                                                            Email
                                                                                        </label>
                                                                                        <Input
                                                                                            type="email"
                                                                                            className="form-control"
                                                                                            id="emailInput"
                                                                                            placeholder="Enter your email"
                                                                                        />
                                                                                    </Col>
                                                                                    <Col xxl={6}>
                                                                                        <label
                                                                                            htmlFor="passwordInput"
                                                                                            className="form-label"
                                                                                        >
                                                                                            Phone number
                                                                                        </label>
                                                                                        <Input
                                                                                            type="password"
                                                                                            className="form-control"
                                                                                            id="passwordInput"
                                                                                            value="451326546"
                                                                                            placeholder="Enter password"
                                                                                        />
                                                                                    </Col>
                                                                                    <Col lg={12}>
                                                                                        <div className="hstack gap-2 justify-content-end">
                                                                                            <Button
                                                                                                color="light"
                                                                                                onClick={() =>
                                                                                                    setmodal_grid(false)
                                                                                                }
                                                                                            >
                                                                                                Close
                                                                                            </Button>
                                                                                            <Button color="primary">
                                                                                                Submit
                                                                                            </Button>
                                                                                        </div>
                                                                                    </Col>
                                                                                </div>
                                                                            </form>
                                                                        </ModalBody>
                                                                    </Modal>
                                                                </Collapse>
                                                            </AccordionItem>
                                                        </Accordion>
                                                    </div> */}

                                            <div className="mt-4 mr-5 ml-3"  >
                                                <div className="mb-5">
                                                    <Label
                                                        htmlFor="exampleFormControlTextarea5"
                                                        className="form-label "
                                                    >
                                                        Message
                                                    </Label>
                                                    <textarea
                                                        className="form-control rounded-4 "
                                                        id="exampleFormControlTextarea5"
                                                        rows="3"
                                                        placeholder="Testing SMS Api"
                                                        style={{ maxWidth: "85%" }}></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div className=" align-items-start gap-3 mt-0">
                                            <button
                                                type="button"
                                                className="btn rounded-pill btn-primary  right ms-auto nexttab nexttab"

                                            >
                                                <i className=" label-icon text-center align-middle fs-16 ms-2"></i>
                                                Send SMS
                                            </button>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </Form>
                        </Col>
                        <Col xl={6}>
                            <br />

                            <h5>
                                <strong>
                                    <p>Run Test code</p>
                                </strong>
                            </h5>

                            <p>Use these pre-built snippets to try it out with code.</p>

                            <div className="email-content">
                                <div className="p-4 pb-0">
                                    <div className="border-bottom border-bottom-dashed">
                                        <Row className="row align-items-end mt-3">
                                            <Col>
                                                <div id="mail-filter-navlist">
                                                    <Nav
                                                        className="nav nav-tabs nav-tabs-custom nav-success gap-1 text-center border-bottom-0"
                                                        role="tablist"
                                                    >
                                                        <NavItem>
                                                            <NavLink
                                                                className={classnames(
                                                                    { active: codeTab === "cURL" },
                                                                    "fw-semibold"
                                                                )}
                                                                onClick={() => setCodeTab("cURL")}
                                                                href="#"
                                                            >
                                                                <span className="ms-1 d-none d-sm-inline-block">
                                                                    cURL
                                                                </span>
                                                            </NavLink>
                                                        </NavItem>

                                                        <NavItem>
                                                            <NavLink
                                                                className={classnames(
                                                                    { active: codeTab === "Node" },
                                                                    "fw-semibold"
                                                                )}
                                                                onClick={() => setCodeTab("Node")}
                                                                href="#"
                                                            >
                                                                <span className="ms-1 d-none d-sm-inline-block">
                                                                    Node
                                                                </span>
                                                            </NavLink>
                                                        </NavItem>

                                                        <NavItem>
                                                            <NavLink
                                                                className={classnames(
                                                                    { active: codeTab === "Java" },
                                                                    "fw-semibold"
                                                                )}
                                                                onClick={() => setCodeTab("Java")}
                                                                href="#"
                                                            >
                                                                <span className="ms-1 d-none d-sm-inline-block">
                                                                    Java
                                                                </span>
                                                            </NavLink>
                                                        </NavItem>

                                                        <NavItem>
                                                            <NavLink
                                                                className={classnames(
                                                                    { active: codeTab === "PYTHON" },
                                                                    "fw-semibold"
                                                                )}
                                                                onClick={() => setCodeTab("PYTHON")}
                                                                href="#"
                                                            >
                                                                <span className="ms-1 d-none d-sm-inline-block">
                                                                    PYTHON
                                                                </span>
                                                            </NavLink>
                                                        </NavItem>

                                                        <NavItem>
                                                            <NavLink
                                                                className={classnames(
                                                                    { active: codeTab === "PHP" },
                                                                    "fw-semibold"
                                                                )}
                                                                onClick={() => setCodeTab("PHP")}
                                                                href="#"
                                                            >
                                                                <span className="ms-1 d-none d-sm-inline-block">
                                                                    PHP
                                                                </span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <SimpleBar className="message-list-content mx-n4 px-4 message-list-scroll">
                                        {isLoader ? (
                                            <div id="elmLoader">
                                                <div
                                                    className="spinner-border text-primary avatar-sm"
                                                    role="status"
                                                >
                                                    <span className="visually-hidden">
                                                        Loading...
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                {codeTab === "cURL" && (
                                                    <div>
                                                        <h6 className=" mt-2">Install the Library</h6>

                                                        <pre>
                                                            <code className="language-markup">
                                                                <BasicRaterExample />
                                                            </code>
                                                        </pre>

                                                        {/* <h6 className=" mt-2">
                                                                    Initialize the library
                                                                </h6>

                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre>
                                                                <h6 className=" mt-2">Write the code</h6>
                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre> */}
                                                    </div>
                                                )}

                                                {codeTab === "Node" && (
                                                    <div>
                                                        <div>
                                                            <h6 className=" mt-2">Install the Library</h6>

                                                            <pre>
                                                                <code className="language-markup">
                                                                    <BasicRaterExamplefornode />
                                                                </code>
                                                            </pre>

                                                            {/* <h6 className=" mt-2">
                                                                    Initialize the library
                                                                </h6>

                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre>
                                                                <h6 className=" mt-2">Write the code</h6>
                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre> */}
                                                        </div>
                                                    </div>
                                                )}

                                                {codeTab === "Java" && (
                                                    <div>
                                                        <div>
                                                            <h6 className=" mt-2">Install the Library</h6>

                                                            <pre>
                                                                <code className="language-markup">
                                                                    <BasicRaterExampleforjava />
                                                                </code>
                                                            </pre>

                                                            {/* <h6 className=" mt-2">
                                                                    Initialize the library
                                                                </h6>

                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre>
                                                                <h6 className=" mt-2">Write the code</h6>
                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre> */}
                                                        </div>
                                                    </div>
                                                )}

                                                {codeTab === "PYTHON" && (
                                                    <div>
                                                        <div>
                                                            <h6 className=" mt-2">Install the Library</h6>

                                                            <pre>
                                                                <code className="language-markup">
                                                                    <BasicRaterExampleforpython />
                                                                </code>
                                                            </pre>

                                                            {/* <h6 className=" mt-2">
                                                                    Initialize the library
                                                                </h6>

                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre>
                                                                <h6 className=" mt-2">Write the code</h6>
                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre> */}
                                                        </div>
                                                    </div>
                                                )}

                                                {codeTab === "PHP" && (
                                                    <div>
                                                        <div>
                                                            <h6 className=" mt-2">Install the Library</h6>

                                                            <pre>
                                                                <code className="language-markup">
                                                                    <BasicRaterExampleforphp />
                                                                </code>
                                                            </pre>

                                                            {/* <h6 className=" mt-2">
                                                                    Initialize the library
                                                                </h6>

                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre>
                                                                <h6 className=" mt-2">Write the code</h6>
                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre> */}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </SimpleBar>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Container>
    );
};

export default SendAnSms;
