import React, { useEffect, useState, PureComponent } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Label,
  Row,
} from "reactstrap";

import CountUp from "react-countup";
// import { useSelector, useDispatch } from "react-redux";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { CardActions } from "@mui/joy";
import Img1 from "../../assets/images/brivasplants.png";
import { Hidden } from "@mui/material";

const Overview = () => {
  const [showLiveKey, setShowLiveKey] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState(false);

  const { account } = useSelector((state) => ({
    account: state.Account.account,
  }));

  const data = [
    {
      name: "Bulk SMS",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Voice",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Flash Call",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "WhatsApp",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Instagram",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Socials",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Socials",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  useEffect(() => {}, []);

  document.title = "BRIVAS | Dashboard";

  return (
    <React.Fragment>
      <div className="shadow-none">
        <Row className="mb-3 p-3 pb-1">
          <Col xs={12}>
            <div className="d-flex p-4 align-items-lg-center flex-lg-row flex-column">
              <div className="flex-grow-1">
                <h1 className=" fw-bold text-dark mb-1">Frequently Viewed</h1>
                <p className="text-muted mb-0">
                  Check out recent updates on your unsers currently being onboarded
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className=" p-3  mt-2">
          <Col xs={12} sm={6} md={3} className="mb-3">
            <Card style={{overflow: "hidden"}} className="card-animate rounded-4">
              <div className="d-flex g-1">
                <div className="bg-primary" style={{width: "23px", height: "inherit", backgroundColor: "primary"}}></div>
                <div className="flex-1">

                  <CardBody>
                    <h6 className="card-title fs-12 text-secondary mb-3">
                      Pending Sender ID
                    </h6>
                    <div className="display-5 fs-35 fw-bold text-primary">45</div>
                  </CardBody>
                  <div className="d-flex justify-content-between w-100">

                    <CardActions>
                      <div className=" p-3 mb-0 fs-12 text-secondary text-start">
                        Request
                      </div>
                    </CardActions>
                    <CardActions>
                      <div className=" p-3 mb-0 fs-12 text-secondary text-end">
                        Today
                      </div>
                    </CardActions>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={3} className="mb-3">
          <Card style={{overflow: "hidden"}} className="card-animate rounded-4">
              <div className="d-flex g-1">
                <div className="" style={{width: "23px", height: "inherit", backgroundColor: "#118959"}}></div>
                <div className="flex-1">

                  <CardBody>
                    <h6 className="card-title fs-12 text-secondary mb-3">
                      Registered Customners
                    </h6>
                    <div className="display-5 fs-35 fw-bold text-primary">1524</div>
                  </CardBody>
                  <div className="d-flex justify-content-between w-100">

                    <CardActions>
                      <div className=" p-3 mb-0 fs-12 text-secondary text-start">
                        Today
                      </div>
                    </CardActions>
                    <CardActions>
                      <div className=" p-3 mb-0 fs-12 text-secondary text-end">
                        100
                      </div>
                    </CardActions>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={3} className="mb-3">
          <Card style={{overflow: "hidden"}} className="card-animate rounded-4">
              <div className="d-flex g-1">
                <div className="" style={{width: "23px", height: "inherit", backgroundColor: "#5A959B"}}></div>
                <div className="flex-1">

                  <CardBody>
                    <h6 className="card-title fs-12 text-secondary mb-3">
                      Blacklisten Customers
                    </h6>
                    <div className="display-5 fs-35 fw-bold text-primary">12</div>
                  </CardBody>
                  <div className="d-flex justify-content-between w-100">

                    <CardActions>
                      <div className=" p-3 mb-0 fs-12 text-secondary text-start">
                        Today
                      </div>
                    </CardActions>
                    <CardActions>
                      <div className=" p-3 mb-0 fs-12 text-secondary text-end">
                        0
                      </div>
                    </CardActions>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        <Row className="align-items-center p-4">
          <Col xl={8}>
            <h5 className=" text-center">+Onboard new customers</h5>
            <Card className=" rounded-4">
              <CardBody className="p-4 mb-3 mt-3 text-center">
                <div className="mx-auto avatar-md mb-4 pb-3">
                  <img
                    src={Img1}
                    alt=""
                    className="img-fluid  rounded-circle"
                  />
                </div>
              </CardBody>

              <div className="d-flex flex-column align-items-center">
                <Link
                  to="/bulk-sms/create-sms"
                  className="bg-primary rounded-pill text-light px-5 mb-4 fw-bold text-center py-2"
                  style={{ maxWidth: "230px" }}
                >
                  Onboard Customers
                </Link>
              </div>
            </Card>
          </Col>
        </Row>

        <Row>
          <div>
            <h3 className=" p-4 fw-bold"> API Usage Statistics</h3>

            <ResponsiveContainer width="60%" height={400}>
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="pv" stackId="a" fill="#40196D" />
                <Bar dataKey="uv" stackId="a" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Overview;
