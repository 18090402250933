import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  ModalBody,
  Alert,
  Button,
  Form,
  ModalHeader,
  Modal,
  TabContent,
  TabPane,
  Container,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
  Table,
} from "reactstrap";
import img2 from "../../assets/images/numberimage.png";
import classnames from "classnames";
import { BasicRaterExample } from "../../Components/UiRatingsCode";
import SimpleBar from "simplebar-react";

const Createameeting = () => {
  const [activeTab, setactiveTab] = useState(1);
  const [codeTab, setCodeTab] = useState("cURL"); // Define isTypeTab variable
  const [isLoader, setIsLoader] = useState(false);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardHeader className="">
            <h1 className=" mb-0 fs-120 align-items-center fw-bold">
              Get started with Brivas SMS API
            </h1>
            <p className="card-title mb-0 w-50 align-items-center text-muted mt-2 fs-13">
              Buy and search numbers
            </p>
          </CardHeader>

          <div className="text-center mt-lg-5 pt-2">
            <h1 className="display-6 fw-semibold mb-3 lh-base">
              Buy and search numbers
            </h1>

            <div
              id="mail-filter-navlist"
              className="d-flex justify-content-center"
            >
              <Nav
                className="nav nav-tabs nav-tabs-custom nav-success gap-5 text-center border-bottom-0"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: codeTab === "cURL" },
                      "fw-semibold"
                    )}
                    onClick={() => setCodeTab("cURL")}
                    href="#"
                  >
                    <span className="ms-1 d-none d-sm-inline-block">
                      My Numbers
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: codeTab === "Node" },
                      "fw-semibold"
                    )}
                    onClick={() => setCodeTab("Node")}
                    href="#"
                  >
                    <span className="ms-1 d-none d-sm-inline-block">
                      Number Search
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: codeTab === "Java" },
                      "fw-semibold"
                    )}
                    onClick={() => setCodeTab("Java")}
                    href="#"
                  >
                    <span className="ms-1 d-none d-sm-inline-block">
                      Buy new numbers
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <SimpleBar className="message-list-content mx-n4 px-4 message-list-scroll">
              {isLoader ? (
                <div id="elmLoader">
                  <div
                    className="spinner-border text-primary avatar-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div>
                  {codeTab === "cURL" && (
                    <div className="mt-5">
                      <Input
                        type=" ri-search-line text"
                        className="d-flex justify-content-center"
                        placeholder="Look up existing Phone numbers"
                        style={{ width: "300px", border:'10px double red' }}
                      />
                      <div>
                        <img
                          src={img2}
                          className="rounded-start text-center mt-lg-5 img-fluid"
                          alt="..."
                        />
                      </div>
                    </div>
                  )}

                  {codeTab === "Node" && (
                    <div className="mt-4 ">
                      <h2>Get started with number Insights</h2>
                    </div>
                  )}
                  {codeTab === "Java" && <div>This is for Java</div>}
                </div>
              )}
            </SimpleBar>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Createameeting;
