import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Container, Input, Label, Row, Button, FormFeedback, Spinner, Alert } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";

import * as Yup from "yup";
import { useFormik } from "formik";

import AuthSlider from './authCarousel';

import logoLight from "../../assets/images/logo.svg";
import withRouter from '../../Components/Common/withRouter';
import { accountLogin } from '../../store/account/actions';


const Login = (props) => {
    document.title = "BRIVAS | Login";

    const dispatch = useDispatch();

    const { loading, error } = useSelector((state) => ({
        loading: state.Account.loading,
        error: state.Account.error,
    }));

    const [passwordShow, setPasswordShow] = useState(false);

    // useEffect(() => {
    //     setTimeout(() => {
    //         dispatch(resetLoginFlag()); 
    //     }, 3000);
    // }, [dispatch, msg, error, success]);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please enter your email address"),
            password: Yup.string().required("Please enter your password"),
        }),
        onSubmit: (values) => {
            dispatch(accountLogin(values, props.router.navigate));
        }
    });

    return (
        <React.Fragment>
            <div className="auth-page-wrapper py-3 d-flex justify-content-center align-items-center min-vh-100">
                <div className=""></div>
                <div className="auth-page-content overflow-hidden pt-lg-1">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden">
                                    <Row className="g-0">
                                        <AuthSlider />

                                        <Col lg={6}>
                                            <div className="p-lg-5 p-4">
                                                {/* <div>
                                                    <h5 className="text-primary">Welcome Back !</h5>
                                                    <p className="text-muted">Sign in to continue to Velzon.</p>
                                                </div> */}

                                                <Row>
                                                    <Col lg={12}>
                                                        {/* text-white-50 */}
                                                        <div className="text-center mt-sm-1 mb-0 text-black-55">
                                                            <div>
                                                                <Link to="/" className="d-inline-block auth-logo">
                                                                    <img src={logoLight} alt="" height="20" />
                                                                </Link>
                                                            </div>
                                                            {/* fw-medium */}
                                                            <p className="mt-3 fs-13">Login to Brivas</p>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <div className="mt-4">
                                                    {error ? (
                                                        <Alert color="danger">{error}</Alert>
                                                    ) : null}
                                                    <form onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                        action="#">

                                                        <div className="mb-3">
                                                            <Label htmlFor="email" className="form-label fw-normal fs-12">Email address</Label>
                                                            <Input
                                                                type="email"
                                                                className="form-control"
                                                                id="email"
                                                                placeholder="Enter your email address"
                                                                name="email"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.email || ""}
                                                                invalid={
                                                                    validation.touched.email && validation.errors.email ? true : false
                                                                }
                                                            />
                                                            {validation.touched.email && validation.errors.email ? (
                                                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label className="form-label fw-normal fs-12" htmlFor="password-input">Password</Label>
                                                            <div className="position-relative auth-pass-inputgroup mb-3">
                                                                <Input
                                                                    type={passwordShow ? "text" : "password"}
                                                                    className="form-control pe-5 password-input"
                                                                    placeholder="Enter your password"
                                                                    id="password"
                                                                    name="password"
                                                                    value={validation.values.password || ""}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    invalid={
                                                                        validation.touched.password && validation.errors.password ? true : false
                                                                    }
                                                                />
                                                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}>
                                                                    <i className="ri-eye-fill align-middle"></i>
                                                                </button>
                                                            </div>
                                                            {validation.touched.password && validation.errors.password ? (
                                                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="text-center fs-12 mt-2">
                                                            <Link to="/forgot-password" className="text-primary">Forgot your password?</Link>
                                                        </div>

                                                        <div className="mt-4">
                                                            {/* <button className="btn w-100 rounded-pill btn-primary" type="submit">Sign In</button> */}
                                                            <button
                                                                disabled={error ? null : loading ? true : false}
                                                                color="success"
                                                                className="btn w-100 rounded-pill btn-primary"
                                                                type="submit">
                                                                {error ? null : loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                                Sign In
                                                            </button>
                                                        </div>

                                                        <div className="mt-4">
                                                            <div className="signin-other-title text-center">
                                                                <h5 className="fs-13 mb-4 title text-muted">OR</h5>
                                                            </div>

                                                            <button className="btn w-100 rounded-pill p-2 d-flex justify-content-center align-items-center btn-outline-secondary" type="submit">
                                                                <i className="ri-google-fill fs-13"></i> <span className="fs-11"> Login with Google</span>
                                                            </button>
                                                            <button className="btn w-100 rounded-pill mt-3 p-2 d-flex justify-content-center align-items-center btn-outline-secondary" type="submit">
                                                                <i className="ri-apple-fill fs-13"></i> <span className="fs-11"> Login with Apple</span>
                                                            </button>
                                                        </div>

                                                    </form>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p className="mb-0 fs-12">Don't have an account ? <a href="/register" className="text-primary"> Sign up</a> </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>
        </React.Fragment>
    );
};

export default withRouter(Login);