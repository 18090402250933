import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isSMS, setIsSMS] = useState(false);
  const [isAnalytics, setIsAnalytics] = useState(false);
  const [isVoice, setIsVoice] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const [isNumbers, setIsNumbers] = useState(false);
  // const [isApps, setIsApps] = useState(false);
  // const [isAuth, setIsAuth] = useState(false);
  // const [isPages, setIsPages] = useState(false);
  // const [isBaseUi, setIsBaseUi] = useState(false);
  // const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  // const [isForms, setIsForms] = useState(false);
  // const [isTables, setIsTables] = useState(false);
  // const [isCharts, setIsCharts] = useState(false);
  // const [isIcons, setIsIcons] = useState(false);
  // const [isMaps, setIsMaps] = useState(false);
  // const [isMultiLevel, setIsMultiLevel] = useState(false);

  // const [isLanding, setIsLanding] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const [isAnalyticsState, setIsAnalyticsState] = useState("Analytics");
  const [isVoicetState, setIsVoiceState] = useState("Voice");
  const [isVerificationState, setIsVerificationState] = useState("Verification");
  const [isNumbersState, setIsNumbersState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  // useEffect(() => {
  //   document.body.classList.remove("twocolumn-panel");
  //   if (iscurrentState !== "Dashboard") {
  //     setIsDashboard(false);
  //   }
  //   if (iscurrentState !== "SMS") {
  //     setIsSMS(false);
  //   }
  //   if (iscurrentState !== "Apps") {
  //     setIsApps(false);
  //   }
  //   if (iscurrentState !== "Auth") {
  //     setIsAuth(false);
  //   }
  //   if (iscurrentState !== "Pages") {
  //     setIsPages(false);
  //   }
  //   if (iscurrentState !== "BaseUi") {
  //     setIsBaseUi(false);
  //   }
  //   if (iscurrentState !== "AdvanceUi") {
  //     setIsAdvanceUi(false);
  //   }
  //   if (iscurrentState !== "Forms") {
  //     setIsForms(false);
  //   }
  //   if (iscurrentState !== "Tables") {
  //     setIsTables(false);
  //   }
  //   if (iscurrentState !== "Charts") {
  //     setIsCharts(false);
  //   }
  //   if (iscurrentState !== "Icons") {
  //     setIsIcons(false);
  //   }
  //   if (iscurrentState !== "Maps") {
  //     setIsMaps(false);
  //   }
  //   if (iscurrentState !== "MuliLevel") {
  //     setIsMultiLevel(false);
  //   }
  //   if (iscurrentState === "Widgets") {
  //     history("/widgets");
  //     document.body.classList.add("twocolumn-panel");
  //   }
  //   if (iscurrentState !== "Landing") {
  //     setIsLanding(false);
  //   }
  // }, [
  //   history,
  //   iscurrentState,
  //   isDashboard,
  //   isSMS,
  //   isApps,
  //   isAuth,
  //   isPages,
  //   isBaseUi,
  //   isAdvanceUi,
  //   isForms,
  //   isTables,
  //   isCharts,
  //   isIcons,
  //   isMaps,
  //   isMultiLevel,
  // ]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "widgets",
      label: "Dashboard",
      icon: "ri-home-2-line",
      link: "/dashboard",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Widgets");
      },
    },
    {
      id: "bulk-sms",
      label: "Bulk SMS",
      icon: "ri-message-2-line",
      link: "/#",
      stateVariables: isSMS,
      click: function (e) {
        e.preventDefault();
        setIsSMS(!isSMS);
        setIscurrentState("SMS");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "get-started",
          label: "Get Started",
          link: "/bulk-sms/get-started",
          parentId: "bulk-sms",
        },
        {
          id: "insight",
          label: "Insight",
          link: "/bulk-sms/insight",
          parentId: "bulk-sms",
        },
        {
          id: "create-sms",
          label: "Create SMS",
          link: "/bulk-sms/create-sms",
          parentId: "bulk-sms",
        },
        {
          id: "sms-status",
          label: "SMS Status",
          link: "/bulk-sms/sms-status",
          parentId: "bulk-sms",
        },
        {
          id: "queued-messages",
          label: "Queued Messages",
          link: "/bulk-sms/queued-messages",
          parentId: "bulk-sms",
        },
        {
          id: "sent-messages",
          label: "Sent Messages",
          link: "/bulk-sms/sent-messages",
          parentId: "bulk-sms",
        },
        {
          id: "scheduled-sms",
          label: "Scheduled SMS",
          link: "/bulk-sms/scheduled-sms",
          parentId: "bulk-sms",
        },
        {
          id: "drafts",
          label: "Drafts",
          link: "/bulk-sms/drafts",
          parentId: "bulk-sms",
        },
        {
          id: "delivery-reports",
          label: "Delivery Reports",
          link: "/bulk-sms/delivery-reports",
          parentId: "bulk-sms",
        },
        {
          id: "download-reports",
          label: "Download Reports",
          link: "/bulk-sms/download-reports",
          parentId: "bulk-sms",
        },
      ],
    },
    // {
    //   id: "analytics",
    //   label: "Analytics",
    //   icon: "ri-line-chart-line",
    //   link: "/#",
    //   stateVariables: isAnalytics,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsAnalytics(!isAnalytics);
    //     setIscurrentState("Analytics");
    //     updateIconSidebar(e);
    //   },
    //   subItems: [
    //     {
    //       id: "get-started",
    //       label: "Get Started",
    //       link: "/send-an-sms",
    //       parentId: "analytics",
    //     },
    //     {
    //       id: "insight",
    //       label: "Insight",
    //       link: "/insight",
    //       parentId: "analytics",
    //     },
    //   ],
    // },
    // {
    //   id: "voice-and-video",
    //   label: "Voice & Video",
    //   icon: "ri-video-chat-line",
    //   link: "/#",
    //   stateVariables: isVoice,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsVoice(!isVoice);
    //     setIsVoiceState("Voice");
    //     updateIconSidebar(e);
    //   },
    //   subItems: [
    //     {
    //       id: "get-started",
    //       label: "Get Started",
    //       link: "/make-a-voice-call",
    //       parentId: "voice-and-video",
    //     },
    //     {
    //       id: "insight",
    //       label: "Insight",
    //       link: "/insight",
    //       parentId: "voice-and-video",
    //     },
    //   ],
    // },
    {
      id: "verification",
      label: "Verification",
      icon: "ri-key-line",
      link: "/#",
      stateVariables: isVerification,
      click: function (e) {
        e.preventDefault();
        setIsVerification(!isVerification);
        setIsVerificationState("Verification");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "SMS-OTP",
          label: "SMS-OTP",
          link: "/verification/sms-otp",
          parentId: "verification",
        },
        {
          id: "FLASH CALL",
          label: "Flash Call",
          link: "/verification/flash-call",
          parentId: "verification",
        },
        {
          id: "insight",
          label: "Insight",
          link: "/verification/insight",
          parentId: "verification",
        },
      ],
    },
    // {
    //   id: "numbers",
    //   label: "Numbers",
    //   icon: "ri-contacts-book-line",
    //   link: "/#",
    //   stateVariables: isNumbers,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsNumbers(!isNumbers);
    //     setIsNumbersState("Numbers");
    //     updateIconSidebar(e);
    //   },
    //   subItems: [
    //     {
    //       id: "get-started",
    //       label: "Get Started",
    //       link: "/create-a-meeting",
    //       parentId: "numbers",
    //     },
    //     {
    //       id: "insight",
    //       label: "Insight",
    //       link: "/insight",
    //       parentId: "numbers",
    //     },
    //   ],
    // },
    // {
    //   id: "widgets",
    //   label: "Apps",
    //   icon: "ri-apps-2-line",
    //   link: "/apps",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIscurrentState("Widgets");
    //   },
    // },

    // {
    //   id: "widgets",
    //   label: "Overview",
    //   icon: "ri-home-8-line",
    //   link: "/overview",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIscurrentState("Widgets");
    //   },
    // },

    // {
    //   id: "widgets",
    //   label: "Dashboard",
    //   icon: "ri-dashboard-line",
    //   link: "/dashboardadmin",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIscurrentState("Widgets");
    //   },
    // },

    // {
    //   id: "widgets",
    //   label: "Users",
    //   icon: "ri-team-line",
    //   link: "/users",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIscurrentState("Widgets");
    //   },
    // },

    // {
    //   id: "widgets",
    //   label: "SID",
    //   icon: "ri-dual-sim-1-line",
    //   link: "/sID",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIscurrentState("Widgets");
    //   },
    // },

    // {
    //   id: "widgets",
    //   label: "Reports",
    //   icon: "ri-pulse-fill",
    //   link: "/reports",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIscurrentState("Widgets");
    //   },
    // },



  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
