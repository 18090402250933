import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Container, Row, Form, Label, Input, FormFeedback, Button, Spinner } from 'reactstrap';
import ParticlesAuth from './ParticlesAuth';

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createAccount, createAccountInit, createResetFlag } from '../../store/actions';
import withRouter from '../../Components/Common/withRouter';

const ACCOUNT_TYPE_PERSONAL = 'Personal';
const ACCOUNT_TYPE_SERVICE_PROVIDER = 'Service Provider';
const ACCOUNT_TYPE_CONTENT_OWNER = 'Content Owner';

const CreateAccount = (props) => {

    document.title = "BRIVAS | Create account";

    const history = useNavigate();
    const dispatch = useDispatch();

    const [passwordShow, setPasswordShow] = useState(false);

    const { loading, success, user, error, msg, account, type } = useSelector(state => ({
        loading: state.CreateAccount.loading,
        success: state.CreateAccount.success,
        account: state.CreateAccount.account,
        // user: state.Account.user,
        error: state.CreateAccount.error,
        msg: state.CreateAccount.msg,
        type: state.CreateAccount.type,
    }));

    useEffect(() => {
        dispatch(createAccountInit());
    }, [dispatch]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: type === ACCOUNT_TYPE_SERVICE_PROVIDER ? {
            business_name: '',
            username: '',
            password: ''
        } : {
            username: '',
            password: ''
        },
        validationSchema: Yup.object(type === ACCOUNT_TYPE_SERVICE_PROVIDER ? {
            business_name: Yup.string().required("Please enter your business"),
            username: Yup.string().required("Please enter your username"),
            password: Yup.string().required("Please enter your password"),
        } : {
            username: Yup.string().required("Please enter your username"),
            password: Yup.string().required("Please enter your password"),
        }),
        onSubmit: (values) => {
            dispatch(createAccount(values, props.router.navigate));
        }
    });

    useEffect(() => {
        setTimeout(() => {
            dispatch(createResetFlag());
        }, 5000);

    }, [dispatch, success, error, history]);

    return (
        <ParticlesAuth>
            <div className="auth-page-content">
                <Container>

                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <div className="mt-2">
                                <p className="fs-12 fw-medium">Step 1/3</p>
                                <h4 className="">Create a username and password</h4>
                                <p className="text-muted fs-6">These password would be required when you need to login</p>
                            </div>

                            {msg && msg ? (<Alert color="danger"> {msg} </Alert>) : null}

                            <Card className="mt-4">
                                <CardBody className="p-4">
                                    <div className="p-2">
                                        <Form onSubmit={validation.handleSubmit}>
                                            {type === ACCOUNT_TYPE_SERVICE_PROVIDER && <div className="mb-3">
                                                <Label className="form-label">Business name</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter your business name"
                                                    name="business_name"
                                                    value={validation.values.business_name}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    invalid={validation.errors.business_name && validation.touched.business_name ? true : false}
                                                />
                                                {validation.errors.business_name && validation.touched.business_name ? (
                                                    <FormFeedback type="invalid">{validation.errors.business_name}</FormFeedback>
                                                ) : null}
                                            </div>}
                                            <div className="mb-3">
                                                <Label className="form-label">Username</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter your username"
                                                    name="username"
                                                    value={validation.values.username}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    invalid={validation.errors.username && validation.touched.username ? true : false}
                                                />
                                                {validation.errors.username && validation.touched.username ? (
                                                    <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                                ) : null}
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="password-input">Password</Label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <Input
                                                        name="password"
                                                        value={validation.values.password || ""}
                                                        type={passwordShow ? "text" : "password"}
                                                        className="form-control pe-5"
                                                        placeholder="Enter Password"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.password && validation.errors.password ? true : false
                                                        }
                                                    />
                                                    {validation.touched.password && validation.errors.password ? (
                                                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                    ) : null}
                                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}>
                                                        <i className="ri-eye-fill align-middle"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="mt-4">
                                                <button
                                                    disabled={error ? null : loading ? true : false}
                                                    color="success"
                                                    className="btn btn-primary w-100 rounded-pill"
                                                    type="submit">
                                                    {error ? null : loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                    Create account
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ParticlesAuth>
    );
};

export default withRouter(CreateAccount);