import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import img1 from "../../assets/images/svg/Enterprise-Banner3-min.png";
import TowerIcon from "../../assets/images/svg/Purpletick-img.png";

const Banner3 = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <div className="mx-md-5 px-3 px-md-5 my-5">
      <div class="row">
        <div class="col-md-6 col-sm-12"></div>
        <div class="col-md-6 col-sm-12">
          <h1 className="display-6 fw-bold fs-48 lh-base text-sm-center ">
            Creating a Positive Experience for Your Clients
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12 voip order-1 d-flex align-items-center">
          <img
            src={img1}
            alt=""
            className="user-img w-50 my-4"
          />
        </div>
        <div class="col-md-6 col-sm-12 order-2 order-md-1 my-3">
          <p className="mb-5">
            By incorporating our voice communication services into your
            business, you can construct a robust and adaptable communication
            infrastructure that accommodates a variety of business requirements,
            including customer service, marketing campaigns, and internal
            operations.
          </p>
          <div className="media d-flex mb-4">
            <img
              className="align-self-start mx-2"
              src={TowerIcon}
              alt="Generic placeholder image"
            />
            <div className="media-body">
              <h5 className="mt-0">In-App Voice Integration</h5>
            </div>
          </div>
          <div className="media d-flex mb-4">
            <img
              className="align-self-start mx-2"
              src={TowerIcon}
              alt="Generic placeholder image"
            />
            <div className="media-body">
              <h5 className="mt-0">Toll-Free Voice Integration</h5>
            </div>
          </div>
          <div className="media d-flex mb-4">
            <img
              className="align-self-start mx-2"
              src={TowerIcon}
              alt="Generic placeholder image"
            />
            <div className="media-body">
              <h5 className="mt-0">Voice Broadcast Integration</h5>
            </div>
          </div>
          <div className="media d-flex mb-3">
            <img
              className="align-self-start mx-2"
              src={TowerIcon}
              alt="Generic placeholder image"
            />
            <div className="media-body">
              <h5 className="mt-0">IVR Integration</h5>
            </div>
          </div>

          <div className="d-flex gap-2 my-5">
            <button
              color="primary "
              className="btn rounded-pill btn-primary"
              onClick={() => "/register"}
            >
              Start for free
              <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
            </button>
            <button
              color="success"
              className="btn rounded-pill btn-light border-danger"
              onClick={() => history("/register")}
            >
              <span className="text-danger">Talk to an expert</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner3;
