import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Label, Row } from "reactstrap";

import { Link } from "react-router-dom";
import img2 from "../../assets/images/dashboardimage.png";
import img3 from "../../assets/images/dashimage.png";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Dashboard = () => {
  const [showLiveKey, setShowLiveKey] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState(false);

  const handleShowLiveKey = () => {
    setShowLiveKey(!showLiveKey);
  };

  const handleShowSecretKey = () => {
    setShowSecretKey(!showSecretKey);
  };

  const { account } = useSelector((state) => ({
    account: state.Account.account,
  }));

  // Card Spinner
  const spinner = (id) => {
    document.getElementById(id).classList.remove("d-none");
    document.getElementById(id).classList.add("d-block");
    setTimeout(function () {
      document.getElementById(id).classList.remove("d-block");
      document.getElementById(id).classList.add("d-none");
    }, 3000);
  };

  useEffect(() => {}, []);

  document.title = "BRIVAS | Dashboard";

  return (
    <React.Fragment>
      <div className="shadow-none">
        {/* <h2 className="fw-bold text-dark">Ahoy Melvin, welcome to BRIVAS!</h2> */}
        <Row className="mt-4 px-sm-1 px-lg-5">
          <Col xl={12}>
            <Card
              className="rounded-4 bg-primary text-light"
              style={{ marginTop: "0px" }}
            >
              <CardBody className="py-4 px-4">
                <div className="d-flex justify-content-between align-items-start">
                  <Row>
                    <Col sm={12} lg={6}>
                      <div>
                        <h1 className="fw-bold text-light mt-3">Welcome!</h1>
                        <h2 className="font-bold text-light">
                          {account?.first_name}
                        </h2>
                        <Row>
                          <Col sm={12} lg={11}>
                            <p className="fs-14">
                              Now is the ideal time to launch your account. You
                              can now test out our various products by using the
                              test credits we have added to your account.
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="px-4">
          <Col sm={12} md={6}>
            <Card
              className="rounded-3 px-2"
              style={{ backgroundColor: "#f3f3f3" }}
            >
              <CardBody>
                <Row className="  position-relative">
                  <Col sm={12} lg={6} className="p-4">
                    <h5 className="mt-0 fw-bold fs-40">
                      BRIVAS <span className="text-primary"> Phone number</span>
                    </h5>
                    <p>
                      Enhance your communication strategy with BRiVAS. Create
                      your BRiVAS number now to send bulk SMS messages and
                      engage effectively with your audience. Start maximizing
                      your messaging potential with BRiVAS today!
                    </p>
                    <div className="mb-1">
                      <Link
                        to="/pages-pricing"
                        className="btn rounded-pill fw-bold  mr-3 btn-outline-primary"
                      >
                        Request
                      </Link>
                    </div>
                  </Col>

                  <Col className="  pt-4 d-md-inline" sm={12} md={6}>
                    <img
                      src={img2}
                      className="rounded-start img-fluid"
                      alt="..."
                      style={{
                        width: "90%",
                        height: "auto",
                      }}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col sm={12} md={6}>
            <Card
              className="rounded-3 px-2"
              style={{ backgroundColor: "#f3f3f3" }}
            >
              <CardBody>
                <Row className="  position-relative">
                  <Col sm={12} lg={6} className="p-4">
                    <h5 className="mt-0 fw-bold fs-40">
                      Gain Customers with Targeted{" "}
                      <span className="text-primary">campaigns</span>
                    </h5>
                    <p>
                      Ready to reach your audience effectively? With our
                      easy-to-use campaign creation tool, you can create
                      targeted SMS campaigns in minutes. Simply define your
                      audience, craft your message, and schedule delivery.
                    </p>
                    <div className="mb-1">
                      <Link
                        to="/pages-pricing"
                        className="btn rounded-pill fw-bold  mr-3 btn-outline-primary"
                      >
                        Create
                      </Link>
                    </div>
                  </Col>

                  <Col className="  pt-4 d-md-inline" sm={12} md={6}>
                    <img
                      src={img3}
                      className="rounded-start img-fluid"
                      alt="..."
                      style={{
                        width: "90%",
                        height: "auto",
                      }}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="px-5">
          <Col lg={6} sm={12} className="  card ">
            <div className="card-header rounded-3 fw-bold mb-3 mt-3 text-dark">
              Developers Corner
            </div>
            <Col md={6} sm={12} lg={12}>
              <Label
                className="form-label fw-bold normal mb-2 text-dark"
                htmlFor="gen-info-email-input"
              >
                Test Key
              </Label>
              <div
                className="input-group"
                style={{ borderRadius: "90px", width: "100%" }}
              >
                <input
                  disabled
                  type={showLiveKey ? "text" : "password"}
                  className="form-control border-0 bg-light"
                  id="gen-info-password-input"
                  placeholder="Live Secret Key"
                  value={account?.test_secret_key}
                />
                <button
                  onClick={handleShowLiveKey}
                  type="button"
                  className="btn btn-light"
                >
                  {showLiveKey ? (
                    <i className="ri-eye-off-line"></i>
                  ) : (
                    <i className="ri-eye-off-line"></i>
                  )}
                </button>
                <CopyToClipboard
                  className=" bg-light border-0 px-3"
                  text={account?.test_secret_key}
                  onCopy={(text, result) => console.log(result)}
                >
                  <button
                    type="button"
                    className="btn btn-primary "
                    id="PopoverBottom"
                  >
                    <i className="ri-file-copy-line"></i>
                  </button>
                </CopyToClipboard>
                <button
                  type="button"
                  id="PopoverBottom"
                  to="#"
                  className="align-middle bg-light border-0 px-3"
                  role="button"
                  onClick={() => spinner("spinner2")}
                >
                  <i className="mdi mdi-refresh align-middle"></i>
                </button>
              </div>

              <div class=" mt-1">
                <Label
                  className="form-label fw-bold text-dark"
                  htmlFor="gen-info-email-input"
                >
                  Live Key
                </Label>
                <div className="input-group" style={{ borderRadius: "90px" }}>
                  <input
                    disabled
                    type={showSecretKey ? "text" : "password"}
                    className="form-control border-0 bg-light"
                    id="gen-info-password-input"
                    placeholder="Live Secret Key"
                    value={account?.live_secret_key}
                  />
                  <button
                    onClick={handleShowSecretKey}
                    type="button"
                    className="btn btn-light"
                  >
                    {showSecretKey ? (
                      <i className="ri-eye-off-line"></i>
                    ) : (
                      <i className="ri-eye-off-line"></i>
                    )}
                  </button>
                  <CopyToClipboard
                    className=" bg-light border-0 px-3"
                    text={account?.live_secret_key}
                    onCopy={(text, result) => console.log(result)}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      id="PopoverBottom"
                    >
                      <i className="ri-file-copy-line"></i>
                    </button>
                  </CopyToClipboard>
                  <button
                    type="button"
                    id="PopoverBottom"
                    to="#"
                    className="align-middle bg-light border-0 px-3"
                    role="button"
                    // onClick={() => spinner("spinner2")}
                  >
                    <i className="mdi mdi-refresh align-middle"></i>
                  </button>
                </div>
              </div>
            </Col>
          </Col>

          <Col lg={6} sm={6}>
            <div class="card mt-1 rounded-3 px-2">
              <div class="card-header fw-bold text-dark">Helpful Links</div>
              <div class="card-body">
                <Link>
                  How does brivas work <i class="ri-external-link-fill"></i>
                </Link>
                <p>Understand how to use BRIVAS in a 2-minute video</p>
                <Link>
                  API documentation <i class="ri-external-link-fill"></i>
                </Link>
                <p>Learn the basics of BRIVAS APIs</p>
                <Link>
                  Explore Marketplace <i class="ri-external-link-fill"></i>
                </Link>
                <p>
                  Do more with less code. Explore our partner integrations
                  available on marketplace
                </p>
                <Link>
                  Support help center <i class="ri-external-link-fill"></i>
                </Link>
                <p>Troubleshoot common issues</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
