import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Img1 from "../../assets/images/experinceimage.png";
import Img2 from "../../assets/images/experienceimage2.png";
import Img3 from "../../assets/images/experienceimage3.png";
import Img4 from "../../assets/images/experience4.png";

const tabs = [
  {
    title: "Messaging",
    header: "Anywhere, Anytime Messaging Connect with Ease",
    desc: "Solutions such as SMS, RCS, social media integration, chatbots, and USSD enable retailers to establish real-time connections with customers.",
    pic:Img1,
  },
  {
    title: "Voice",
    header: "Empowering Seamless Communication and Care",
    desc: "Ensure transparent interactions with clients. Our technology streamlines communication, providing quick responses to questions and enhancing client experiences. Our powerful tools connect with clients, fulfilling their requirements, and increasing satisfaction.",
    pic:Img2,
},
  {
    title: "Geofencing",
    header: "Connect with Customers Anywhere, Anytime",
    desc: "Leverage geofencing technology to deliver personalized offers, notifications, and location-based services to customers in specific geographical areas.",
    pic:Img3,
  },
  {
    title: "Application",
    header: "Advanced Retail Applications",
    desc: "Our application solution integrates Omnichannel Communication, Contact Centre Integration, and IoT to improve retail experiences and operations. Our intuitive apps streamline navigation, customize suggestions, and centralize customer assistance, improving consumer engagement and growth.",
    pic:Img4,
  },
];

const Scalable = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <section className="mb-0" style={{ backgroundColor: "#FFF5FF" }}>
      <div className="p-5">
        <div className="">
          <section
            className="section d-flex pl-5 p-5 pb-0"
            id="hero"
            style={{ width: "458px" }}
          >
            <div className="justify-content-between"style={{ width: "704px" }}>
              <div className="mx-3">
                <h1 className="fs-40 fw-bold mb-5">
                  Envision an <span className="text-primary">Experience</span>{" "}
                  with
                  <br />
                  no Interruptions.
                </h1>

                <div className="d-flex gap-2 ">
                  {tabs.map((i, x) => (
                    <button
                      key={`tab-${x}`}
                      color="danger "
                      className="btn rounded-pill border-primary "
                      onClick={() => setSelectedTab(x)}
                      style={{ width: "135px", height: "51px" }}
                    >
                      {i.title}
                      <i className=" align-middle ms-1 padding-right-30px   rounded-pill"></i>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </section>

          <section className="">
            <div className=" d-flex justify-content-around" style={{marginRight:'110px'}}>
              <div className=" " style={{ width: "57ch" }}>
                <h4 className="fs-20 fw-bold fw-500 mb-4 mt-5">
                  {tabs[selectedTab]?.header}
                </h4>
                <p className="mb-2">{tabs[selectedTab]?.desc}</p>
                <div className="d-flex pt-5 gap-2">
                  <button
                    color="danger "
                    className=" btn-primary btn rounded-pill btn-primary border-primary"
                    onClick={() => history("/register")}
                  >
                    Start for free
                    <i className="rounded-pill"></i>
                  </button>

                  <button
                    color="success"
                    className="btn rounded-pill btn-light text-danger border text-bolder border-danger"
                    onClick={() => history("/register")}
                  >
                    Talk to an expert
                  </button>
                </div>
              </div>
              <div className="mt-0">
                <img  src={tabs[selectedTab]?.pic} alt="BrivasMobile" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default Scalable;
