import {
    LIST_PRODUCT_GROUP_SUCCESS,
    LIST_PRODUCT_GROUP_ERROR,
    GET_PRODUCT,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_ERROR,
} from "./actionType";

const INIT_STATE = {
    productGroups: [],
    msg: null,
    error: false,
    success: false,
    product: null,
};

const Landing = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_PRODUCT_GROUP_SUCCESS:
            return { ...state, productGroups: action.payload.data };
        case LIST_PRODUCT_GROUP_ERROR:
            return { ...state, msg: action.payload.msg, error: true };
        case GET_PRODUCT_SUCCESS:
            return { ...state, product: action.payload.data };
        case GET_PRODUCT_ERROR:
            return { ...state, msg: action.payload.msg, error: true };
        default:
            return { ...state };

    }
};

export default Landing;