import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import img1 from "../../assets/images/svg/Enterprise-banner-min.png";
import TowerIcon from "../../assets/images/svg/Purpletick-img.png";

const Banner = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section>
      <div className="mx-md-5 px-3 px-md-5">
        <div class="row">
          <div class="col-md-6 col-sm-12 order-2 order-md-1">
          <h1 className="display-6 fw-bold fs-48 lh-base text-sm-center text-md-start mt-md-5">
              Facilitating the Achievement of{" "}
              <span className="text-primary">Business</span>
            </h1>
            <p className="mb-5">
              Facilitates enterprise-level accomplishment. Utilize our
              comprehensive communication infrastructure, scalable solutions,
              enhanced security, and customizable integrations to increase your
              organization's productivity, collaboration, and growth.
            </p>
            <div className="media d-flex mb-4">
              <img
                className="align-self-start mx-2"
                src={TowerIcon}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">Streamline communication and collaboration</h5>
              </div>
            </div>
            <div className="media d-flex mb-4">
              <img
                className="align-self-start mx-2"
                src={TowerIcon}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">Enhanced Security and Compliance</h5>
              </div>
            </div>
            <div className="media d-flex mb-4">
              <img
                className="align-self-start mx-2"
                src={TowerIcon}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">Customizable Integrations</h5>
              </div>
            </div>
            <div className="media d-flex mb-3">
              <img
                className="align-self-start mx-2"
                src={TowerIcon}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">Scalable Solutions</h5>
              </div>
            </div>

            <div className="d-flex gap-2 my-5">
              <button
                color="primary "
                className="btn rounded-pill btn-primary"
                onClick={() => "/register"}
              >
                Start for free
                <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
              </button>
              <button
                color="success"
                className="btn rounded-pill btn-light border-primary"
                onClick={() => history("/register")}
              >
                <span className="text-primary">Talk to an expert</span>
              </button>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 my-5 d-flex align-items-center order-1">
            <img
              src={img1}
              alt=""
              className="user-img w-75"
              
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
