import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Form,
  Spinner,
  Label,
} from "reactstrap";
import { Textarea } from "@mui/joy";
import Option from "@mui/joy/Option";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import { scheduleBulkSMS, sendBulkSMS } from "../../store/actions";

import Flatpickr from "react-flatpickr";

import Select from "react-select";
// import { setAuthorization } from "../../helpers/api_helper";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setAuthorization } from "../../helpers/api_helper";
import { sendUploadContact } from "../../store/actions";
// import { getContactDetails } from "../../store/getcontactlist/action";
import * as Yup from "yup";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import Header from "./Header";
import axios from "axios";

// Function to format bytes to a human-readable format
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const CreateSMS = () => {
  const [strContact, setStrContact] = useState("");
  const [contacts, setContacts] = useState([]);
  const [message, setMessage] = useState("");
  const [from, setFrom] = useState(null);
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  const [selectedFiles, setselectedFiles] = useState([]); // State for selected files
  const [isScheduled, setIsScheduled] = useState(false);
  const [contactDetails, setContactDetails] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);

  // const contactDetails = useSelector(
  //   (state) => state.contactBox.contactDetails
  // );

  // Function to fetch contact details from the API
  const fetchContactDetails = async () => {
    try {
      const response = await fetch("/contacts/get_contact_list"); 
      const data = await response.json();
      // Assuming the response data is an array of contact objects
      setContactDetails(data);
    } catch (error) {
      console.error("Error fetching contact details:", error);
    }
  };

  // Call the fetchContactDetails function once when the component mounts
  useEffect(() => {
    fetchContactDetails();
  }, []);

  // Event handler for contact selection
  const handleContactSelect = (selectedOption) => {
    setSelectedContact(selectedOption);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      sender: "",
      recipients: "",
      messageBody: "",
    },
    validationSchema: Yup.object({
      // recipients: Yup.string().required("Please enter your email address"),
      // messageBody: Yup.string().required("Please enter a message"),
    }),
    onSubmit: (values) => {
      setAuthorization(account?.test_secret_key);
      dispatch(
        sendBulkSMS({
          from: values.sender,
          to: values.recipients,
          message: values.messageBody,
        })
      );
    },
  });

  // const handleUploadContacts = async () => {
  //   try {
  //     const formData = new FormData();
  //     selectedFiles.forEach((file) => {
  //       formData.append('files', file);
  //     });

  //     // Send files to backend API
  //     const response = await fetch('/contacts/upload', {
  //       method: 'POST',
  //       body: formData,
  //     });

  //     if (response.ok) {
  //       // Files uploaded successfully
  //       showToast('Contacts uploaded successfully!');
  //     } else {
  //       // Handle error
  //       showToast('Error uploading contacts. Please try again.');
  //     }
  //   } catch (error) {
  //     console.error('Error uploading contacts:', error);
  //     showToast('Error uploading contacts. Please try again.');
  //   }
  // };

  // const [newList, setNewList] = useState(null);
  const [isUploadSelected, setIsUploadSelected] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const showToast = (message) => {
    setToastMessage(message);
    // You can implement your toast display logic here
  };

  const handleUploadSelectChange = () => {
    setIsUploadSelected(!isUploadSelected);
  };

  const dispatch = useDispatch();

  const [modal_grid, setmodal_grid] = useState(false);

  const [rechargemodal_grid, setrechargemodal_grid] = useState(false);

  const { account, loading } = useSelector((state) => ({
    account: state.Account.account,
    loading: state.BulkSMS.loading,
  }));

  function tog_grid() {
    setmodal_grid(!modal_grid);
  }

  function tog_grid() {
    setrechargemodal_grid(!rechargemodal_grid);
  }

  useEffect(() => {
    setContacts(strContact.split(",").filter((i) => i != ""));
  }, [strContact]);

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("clicked");

    validation.handleSubmit();
  };

  const onSendORSchedule = () => {
    setAuthorization(account?.live_secret_key);
    if (!isScheduled)
      dispatch(
        sendBulkSMS({ from: from?.value ?? "", to: strContact, message })
      );
    else
      dispatch(
        scheduleBulkSMS({ from: from?.value ?? "", to: strContact, message })
      );
  };

  const [contactlabel, setcontactLabel] = useState("");

  const onUploadContact = () => {
    const formData = new FormData();
    formData.append("file", selectedFiles[0]);
    formData.append("label", contactlabel);
    dispatch(sendUploadContact(formData));
  };

  const ongetContactList = () => {
    const formData = new FormData();
    formData.append("file", selectedFiles[0]);
    formData.append("label", contactlabel);
    dispatch(sendUploadContact(formData));
  };

  document.title = "BRIVAS | Create SMS";

  return (
    <Container fluid>
      <Card className="shadow-none p-2">
        <Row className="">
          <Col xl={12}>
            <div className="rounded-3 text-dark">
              <CardBody>
                <div className="">
                  <div className="">
                    <h1 className="fw-bold text-dark d-flex gap-2 m-0">
                      <i class="ri-send-plane-fill"></i> Create SMS
                    </h1>
                    <p className="w-75 mx-5">
                      The Verify API lets you send a PIN to a user's phone and
                      validate that they received it. Learn more
                    </p>
                  </div>
                </div>
              </CardBody>
            </div>
          </Col>
        </Row>
        <div>
          <a
            href="#"
            className="d-flex justify-content-start mt-1"
            onClick={() => setrechargemodal_grid(true)}
          >
            <Button
              color="primary"
              className="btn-sm fw-bold"
              onClick={() => setrechargemodal_grid(true)}
            >
              Buy Unit
            </Button>
          </a>
        </div>
          
        <CardBody className="mx-4 my-5">
          <Row className="d-flex justify-content-around">
            <Col sm={12} md={4}>
              <Label
                htmlFor="choices-single-default"
                className="form-label fw-normal"
              >
                Sender ID
              </Label>
              <Select
                value={from}
                onChange={(obj) => setFrom(obj)}
                options={[
                  { value: "BR Test", label: "BR Test" },
                  { value: "BR Bulk Test", label: "BR Bulk Test" },
                ]}
              />
              <p className="d-flex justify-content-start mt-1 fs-12">
                Don't have an ID yet?{" "}
                <a href="#">
                  <span className="text-success mx-1">Request sender ID</span>
                </a>
              </p>
            </Col>
            <Col sm={12} md={4}>
              <Label
                htmlFor="choices-single-default"
                className="form-label fw-bold"
              >
                Choose from contact list
              </Label>
              <Select
                placeholder="Select a contact"
                style={{ width: 200 }}
                // onChange={handleContactSelect}
                // value={selectedContact}
                options={contactDetails.map((contact) => ({
                  value: contact.id,
                  label: contact.name,
                }))}
              />
              <a
                href="#"
                className="d-flex justify-content-start mt-1"
                onClick={() => setmodal_grid(true)}
              >
                <i class="ri-download-2-line text-success fs-12"></i>
                <span className="text-success mx-1 fs-12">Import Contacts</span>
              </a>
            </Col>
            <Col sm={12} md={4}>
              <Label
                htmlFor="choices-single-default"
                className="form-label fw-bold"
              >
                Country Code
              </Label>
              <Select placeholder="Select code" sx={{ width: 240 }}>
                <Option value="dog">Dog</Option>
                <Option value="cat">Cat</Option>
                <Option value="fish">Fish</Option>
                <Option value="bird">Bird</Option>
              </Select>
            </Col>
          </Row>
          <Row className="d-flex mt-3 mb-2 justify-content-around">
            <Col>
              <FormControl>
                <FormLabel>Enter number</FormLabel>
                <Textarea
                  placeholder="Separate numbers using comma, e.g 08123456345, 07044365234"
                  minRows={4}
                  onChange={(e) => setStrContact(e.target.value)}
                  style={{ borderRadius: "10px" }}
                />
                <FormHelperText>
                  <div className="d-flex flex-row gap-3">
                    <p>Total number count: {contacts.length}</p>
                    <p>Maximum count: 560</p>
                  </div>
                </FormHelperText>
              </FormControl>

              <div className="mt-4 px-0 d-flex align-items-center form-check form-switch">
                <Label className="form-check-label">Send now</Label>
                <Input
                  className="form-check-input mx-2"
                  type="checkbox"
                  role="switch"
                  onChange={() => setIsScheduled(!isScheduled)}
                />

                <Label className="form-check-label">Schedule</Label>
              </div>

              {isScheduled && (
                <div className="mt-5">
                  <Form>
                    <Row className="gy-3">
                      <Col lg={6}>
                        <div>
                          <FormLabel placeholder="Enter your email">
                            Send Date
                          </FormLabel>
                          {/* <Flatpickr
                            className="form-control mt-2"
                            options={{
                              dateFormat: "d M, Y",
                            }}
                            style={{ borderRadius: "10px" }}
                          /> */}
                          <div>
                            <Input
                              type="date"
                              className="form-control mt-2"
                              id="exampleInputdate"
                              style={{ borderRadius: "10px" }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <FormLabel>Send Time</FormLabel>
                          <Flatpickr
                            className="form-control mt-2"
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: "H:i",
                              time_12hr: true,
                              defaultDate: "HH:MM",
                            }}
                            style={{ borderRadius: "10px" }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              )}

              <div className=" text-start  mt-5">
                <Button
                  color="primary"
                  className="btn-load rounded-pill"
                  onClick={onSendORSchedule}
                  disabled={loading}
                >
                  <span className="d-flex align-items-center">
                    {loading && (
                      <Spinner
                        size="sm"
                        type="grow"
                        className="flex-shrink-0"
                      />
                    )}
                    <span className="flex-grow-1 ms-1">
                      {isScheduled ? "Schedule SMS" : "Send SMS"}
                    </span>
                  </span>
                </Button>
              </div>
            </Col>
            <Col>
              <FormControl>
                <FormLabel>Type Message</FormLabel>
                <Textarea
                  placeholder="Enter message here"
                  minRows={4}
                  onChange={(e) => setMessage(e.target.value)}
                  style={{ borderRadius: "10px" }}
                />
                <FormHelperText>
                  <div className="d-flex flex-row gap-3">
                    480 characters per page
                    <p>Total Characters: {message.length}</p>
                    <p>Pages: </p>
                  </div>
                </FormHelperText>
              </FormControl>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Modal
        isOpen={modal_grid}
        toggle={() => {
          tog_grid();
        }}
      >
        <ModalHeader>
          <h5 className="modal-title">UPLOAD CONTACTS</h5>
          <h6 className="ft-normal mt-2">
            Upload format Select a CSV File, Each line should contain(Phone
            number and name)
          </h6>
        </ModalHeader>
        <ModalBody>
          <form action="#" onSubmit={handleSubmit}>
            <Form action="#" className="form-steps" onSubmit={handleSubmit}>
              <div className="row g-3">
                <Card>
                  <CardBody>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone dz-clickable">
                          <div
                            className="dz-message fw-bold needsclick"
                            {...getRootProps()}
                          >
                            <div className="mb-3">
                              <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                            </div>
                            <h6>
                              Select and upload your reciepients in{" "}
                              <span className="text-primary">.csv</span>
                            </h6>
                            <h6>
                              Max File Size{" "}
                              <span className="text-primary">10MB</span>{" "}
                            </h6>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="list-unstyled mb-0" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  {/* <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link> */}
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </CardBody>
                </Card>

                <div>
                  <h6 className="fw-bold text-center">
                    How do you want to upload your contacts?
                  </h6>
                </div>

                <div className="mt-4 px-0 d-flex justify-content-center form-check form-switch">
                  <Label className="form-check-label">Create New List</Label>
                  <Input
                    className="form-check-input mx-2"
                    type="checkbox"
                    role="switch"
                    onChange={handleUploadSelectChange}
                    color="primary"
                  />
                  <Label className="form-check-label">Upload to List</Label>
                </div>

                <div className="align-items-center">
                  {!isUploadSelected ? (
                    <div>
                      <Input
                        type="text"
                        placeholder="Name for new List"
                        className="form-control"
                        id="basicInput"
                        value={contactlabel}
                        onChange={(e) => setcontactLabel(e.target.value)}
                      />
                    </div>
                  ) : (
                    <div>
                      {/* Your select component for uploading to an existing list */}
                      <Select
                        placeholder="Select a contact"
                        style={{ width: 200 }}
                        onChange={handleContactSelect}
                        value={selectedContact}
                        options={contactDetails.map((contact) => ({
                          value: contact.id,
                          label: contact.name,
                        }))}
                      />
                    </div>
                  )}
                </div>

                <div className="col-lg-12">
                  <div className="hstack gap-2 justify-content-end">
                    <Button color="light" onClick={() => setmodal_grid(false)}>
                      Close
                    </Button>
                    <Button color="primary" onClick={onUploadContact}>
                      UPLOAD CONTACT
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={rechargemodal_grid}
        toggle={() => {
          tog_grid();
        }}
      >
        <ModalHeader>
          <h5 className="modal-title">Online Payment</h5>
          <h6 className="ft-normal mt-2">Recharge your account online</h6>
        </ModalHeader>
        <ModalBody>
          <form action="#">
            <div className="row g-3">
              <div>
                <Label
                  htmlFor="choices-single-default"
                  className="form-label fw-bold"
                >
                  Select Plan Type
                </Label>
              </div>
              <Select
                value={from}
                onChange={(obj) => setFrom(obj)}
                options={[
                  { value: "Pay as you Use", label: "Pay as you Use" },
                  { value: "Transfer", label: "Transfer" },
                ]}
              />
              <div className="mt-2 mb-2">
                <Label htmlFor="basiInput" className="form-label">
                  Number of input
                </Label>
                <Input type="text" className="form-control" id="basiInput" />
              </div>
              <div className="mt-2 mb-2">
                <Label htmlFor="basiInput" className="form-label">
                  Unit Price
                </Label>
                <Input type="text" className="form-control" id="basiInput" />
              </div>
              <div className="mt-2 mb-2">
                <Label htmlFor="basiInput" className="form-label">
                  Amount to Pay
                </Label>
                <Input type="text" className="form-control" id="basiInput" />
              </div>
              <div className="mt-2 mb-2">
                <Label htmlFor="basiInput" className="form-label">
                  Transaction fee
                </Label>
                <Input type="text" className="form-control" id="basiInput" />
              </div>
              <div className="mt-2 mb-2">
                <Label htmlFor="basiInput" className="form-label">
                  Total Payment
                </Label>
                <Input type="text" className="form-control" id="basiInput" />
              </div>

              <div>
                <Label
                  htmlFor="choices-single-default"
                  className="form-label fw-normal"
                >
                  Payment Type
                </Label>
              </div>
              <Select
                value={from}
                onChange={(obj) => setFrom(obj)}
                options={[
                  { value: "Nigerian Cards", label: "Nigerian Cards" },
                  { value: "Non-Nigerian Cards", label: "Non-Nigerian Cards" },
                ]}
              />

              <div className="col-lg-12">
                <div className="hstack gap-2 justify-content-end">
                  <Button
                    color="light"
                    onClick={() => setrechargemodal_grid(false)}
                  >
                    Close
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      // Your upload logic here
                      showToast("Contacts uploaded successfully!");
                    }}
                  >
                    Buy Now
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default CreateSMS;
