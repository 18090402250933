import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, FormFeedback, Input, Label, Row, UncontrolledDropdown } from 'reactstrap';
import { appSlugs } from '../../common/consts';

const Forms = ({ appSlug, validation }) => {
    if (appSlug === appSlugs.FLASH_CALL) {
        return (
            <div className="mb-3">
                <Label htmlFor="FriendlyName-field" className="form-label">FriendlyName</Label>
                <Input
                    name="FriendlyName"
                    id="FriendlyName-field"
                    className="form-control"
                    placeholder="Enter Name"
                    type="text"
                    validate={{
                        required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.FriendlyName || ""}
                    invalid={
                        validation.touched.FriendlyName && validation.errors.FriendlyName ? true : false
                    }
                />
                {validation.touched.FriendlyName && validation.errors.FriendlyName ? (
                    <FormFeedback type="invalid">{validation.errors.FriendlyName}</FormFeedback>
                ) : null}

            </div>
        );
    } else if (appSlug === appSlugs.USSD) {
        return (
            <div className="mb-3">
                <Label htmlFor="FriendlyName-field" className="form-label">FriendlyName</Label>
                <Input
                    name="FriendlyName"
                    id="FriendlyName-field"
                    className="form-control"
                    placeholder="Enter Name"
                    type="text"
                    validate={{
                        required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.customer || ""}
                    invalid={
                        validation.touched.customer && validation.errors.customer ? true : false
                    }
                />
                <Label htmlFor="FriendlyName-field" className="form-label">FriendlyName</Label>
                <Input
                    name="FriendlyName"
                    id="FriendlyName-field"
                    className="form-control"
                    placeholder="Enter Name"
                    type="text"
                    validate={{
                        required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.FriendlyName || ""}
                    invalid={
                        validation.touched.FriendlyName && validation.errors.FriendlyName ? true : false
                    }
                />
                {validation.touched.FriendlyName && validation.errors.FriendlyName ? (
                    <FormFeedback type="invalid">{validation.errors.customer}</FormFeedback>
                ) : null}

            </div>
        );
    } else return null;
};

export default Forms;