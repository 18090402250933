import React, { useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Button, Spinner } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { accountForgotPassword } from "../../store/actions";

import logoLight from "../../assets/images/logo.svg";

import ParticlesAuth from "./ParticlesAuth";

const ForgotPassword = () => {
    document.title = "BRIVAS | Forgot Password";

    const history = useNavigate();
    const dispatch = useDispatch();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please enter your email address")
        }),
        onSubmit: (values) => {
            const data = { email: values.email };
            dispatch(accountForgotPassword(data, history));
        }
    });

    const { loading, forgetError, forgetSuccessMsg } = useSelector(state => ({
        loading: state.Account.loading,
        forgetError: state.Account.forgetError,
        forgetSuccessMsg: state.Account.forgetSuccessMsg,
    }));

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-1 mb-0 text-black-55">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="20" />
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">

                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Forgot Password?</h5>

                                            <lord-icon
                                                src="https://cdn.lordicon.com/rhvddzym.json"
                                                trigger="loop"
                                                colors="primary:#0ab39c"
                                                className="avatar-xl"
                                                style={{ width: "120px", height: "120px" }}
                                            >
                                            </lord-icon>

                                        </div>

                                        <Alert className="alert-borderless alert-warning text-center mb-2 mx-2" role="alert">
                                            Enter your email and instructions will be sent to you!
                                        </Alert>
                                        <div className="p-2">
                                            {forgetError ? (
                                                <Alert color="danger" style={{ marginTop: "13px" }}>
                                                    {forgetError}
                                                </Alert>
                                            ) : null}
                                            {forgetSuccessMsg ? (
                                                <Alert color="success" style={{ marginTop: "13px" }}>
                                                    {forgetSuccessMsg}
                                                </Alert>
                                            ) : null}
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                            >
                                                <div className="mb-4">
                                                    <Label htmlFor="useremail" className="form-label fw-normal fs-12">Email <span className="text-danger">*</span></Label>
                                                    <Input
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter your email here"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mt-4">
                                                    <button
                                                        disabled={loading}
                                                        color="success"
                                                        className="btn w-100 rounded-pill btn-primary"
                                                        type="submit">
                                                        {loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                        Send Reset Link
                                                    </button>
                                                </div>
                                            </Form>
                                            <div className="mt-4 text-center">
                                                <p className="mb-0 fs-13">Wait, I remember my password... <Link to="/login" className="text-primary"> Click here </Link> </p>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default ForgotPassword;