import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import img1 from "../../assets/images/svg/Nonprofit-banner3-cardimg.svg";

const Thirdbanner = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section>
      <Row className="mx-md-5 px-3 px-md-5 my-5">
        <Col sm={12} md={6}>
          <div class="card">
            <div class="row g-0 card-body px-0 py-0">
              <div class="col-md-3 col-sm-12">
                <div
                  style={{
                    height: "290px",
                    width: "auto",
                    backgroundImage: `url(${img1})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    
                  }}
                ></div>
              </div>
              <div class="col-md-9 col-sm-12">
                <div class="d-flex justify-content-center flex-column py-3 px-4">
                  <h5 class="card-title fs-24">Online Fundraising Support</h5>
                  <p class="card-text fs-16 fw-400">
                    BRIVAS offers tools for setting up and managing online
                    fundraising campaigns. Nonprofits can leverage SMS
                    donations, voice broadcasts, and email communications to
                    solicit donations and engage donors.
                  </p>

                  <div>
                    <button
                      color="danger"
                      className="btn rounded-pill btn-primary"
                      onClick={() => history("/register")}
                    >
                      Start for free
                    </button>
                    <button
                      color="success"
                      className="btn rounded-pill btn-light border-danger mx-3"
                      onClick={() => history("/register")}
                    >
                      <span className="text-danger">Talk to an expert</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12} md={6} className="mx=md-5">
          <div className="text-bold fs-40 pt-5 py-5 w-100 " style={{}}>
            <h1 className="mb-3 text-center">
              Expanding Your Reach,{" "}
              <span className="text-primary">Amplifying</span> Your Impact
            </h1>
            <p className="mb-4 ff-secondary">
              Our technology and assistance link your organization to millions
              of people each year to expand its impact. We'll improve your
              organization's SMS, WhatsApp, voice, and chatbot outreach to help
              you make a bigger difference.
            </p>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Thirdbanner;
