import {
  SEND_VERIFICATION_CODE,
  SEND_VERIFICATION_CODE_SUCCESS,
  SEND_VERIFICATION_CODE_FAILED,
  RESET_ACCOUNT_PHONE_FLAG,
} from "./actionTypes";

export const sendVerificationCode = (data, history) => {

  return {
    type: SEND_VERIFICATION_CODE,
    payload: { data, history },
  };
};

export const sendVerificationCodeSuccess = data => {
  return {
    type: SEND_VERIFICATION_CODE_SUCCESS,
    payload: data,
  };
};

export const sendVerificationCodeError = error => {
  return {
    type: SEND_VERIFICATION_CODE_FAILED,
    payload: error,
  };
};

export const resetAccountPhoneFlag = () => {
  return {
    type: RESET_ACCOUNT_PHONE_FLAG,
  }
}
