import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { CREATE_ACCOUNT, CREATE_ACCOUNT_INIT } from "./actionTypes";
import { createSuccess, createAccountError, createAccountInitSuccess, createAccountInitError } from "./actions";

//Include Both Helper File with needed methods
import { getCreateAccount, postCreateAccount } from "../../../helpers/backend_helper";

function* createAccountInit() {
  try {
    const resp = yield call(getCreateAccount);
    if (resp.status === "success") {
      yield put(createAccountInitSuccess(resp));
    } else {
      yield put(createAccountInitError(resp));
    }
  } catch (error) {
    yield put(createAccountInitError(error));
  }
}

function* createAccount({ payload: { data, history } }) {
  try {
    const resp = yield call(postCreateAccount, data);
    if (resp.status === "success") {
      yield put(createSuccess(resp));
      history('/account/phone');
    } else {
      yield put(createAccountError(resp));
    }
  } catch (error) {
    yield put(createAccountError(error ?? 'An error occured!'));
  }
}

function* createSaga() {
  yield takeEvery(CREATE_ACCOUNT, createAccount);
  yield takeEvery(CREATE_ACCOUNT_INIT, createAccountInit);
}

export default createSaga;
