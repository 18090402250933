import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const Services = () => {
  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="mb-5">
                <div className="text-center my-5 py-3">
                  <h1 className="fw-semibold text-dark">
                    Tips to find the right SMS provider for you
                  </h1>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="g-3">
            {productGroups.map((item, k) => (
              <Col lg={3} key={k}>
                <div className="d-flex flex-column p-3">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm">
                      <div className="avatar-title bg-transparent text-success rounded-circle">
                        <img
                          src={require(`../../assets/images/svg/${item.icon}.svg`)}
                          alt=""
                          height="46"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1 d-flex flex-column">
                    <h5 className="fs-18 text-dark">{item.name}</h5>
                    {/* <div className="d-flex flex-column justify-content-space-between"> */}
                    <p className="my-3 text-dark">{item.description} ... </p>
                    <Link
                      to={`/products/${item.slug}`}
                      className="fs-13 fw-medium align-self-baseline"
                    >
                      Learn More{" "}
                      <i className="ri-arrow-right-s-line align-bottom"></i>
                    </Link>
                    {/* </div> */}
                  </div>
                </div>
              </Col>
            ))}
            <Col></Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Services;
