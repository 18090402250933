import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import img1 from "../../assets/images/svg/mrbiola-min.png";
import Hearticon from "../../assets/images/svg/Heart-icon-Healthcare.png";
import Healthticon from "../../assets/images/svg/Health-icon-Healthcare.png";
import Streaming from "../../assets/images/svg/streaming-icon-healthcare.png";

const Reachout = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <React.Fragment>
      <div className="mx-md-5 px-3 px-md-5">
        <div class="row">
          <div class="col-md-6 col-sm-12 voip order-1 d-flex align-items-center justify-content-center">
            <img src={img1} alt="" className="user-img w-75 my-5" />
          </div>
          <div class="col-md-6 col-sm-12 order-2 order-md-1 my-md-5">
            <h1 className="display-6 fw-bold fs-48 lh-base text-dark text-sm-center text-md-start">
              Convenient <span>Healthcare</span> Experience
            </h1>
            <h5 className="my-4">Revitalized Medical Care</h5>
            <p className="mb-5 text-justify text-dark">
              With a focus on providing a smooth healthcare journey, our
              platform offers a seamless experience where you can effortlessly
              schedule appointments and enjoy reliable streaming for virtual
              consultations and accessing medical content.
            </p>
            <div className="media d-flex mb-4">
              <img
                className="align-self-start mx-2"
                src={Healthticon}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">Schedule Appointment</h5>
                <p>
                  Stay informed with appointment reminders sent via SMS, calls,
                  and email.
                </p>
              </div>
            </div>
            <div className="media d-flex mb-4">
              <img
                className="align-self-start mx-2"
                src={Hearticon}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">Fast and reliable</h5>
                <p>
                  Advanced technology enables secure, timely consultations and
                  seamless information exchange.
                </p>
              </div>
            </div>
            <div className="media d-flex mb-4">
              <img
                className="align-self-start mx-2"
                src={Streaming}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">Streaming</h5>
                <p>
                  Virtual consultations made seamless with in-app video calling.
                </p>
              </div>
            </div>

            <div className="d-flex gap-2 my-5">
              <button
                color="primary "
                className="btn rounded-pill btn-primary"
                onClick={() => "/register"}
              >
                Start for free
                <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
              </button>
              <button
                color="success"
                className="btn rounded-pill btn-light border-primary"
                onClick={() => history("/register")}
              >
                <span className="text-danger">Talk to an expert</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Reachout;
