import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import img1 from "../../assets/images/svg/telehealthbackground.svg";

const Homenew = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <div
      className=" d-flex justify-content-around min-vh-100 mh-75 "
      style={{
        backgroundImage: `url(${img1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "#FFF5FF",
        width: "auto",
          
        }}
    >
      <div className="mx-5 my-5">
        <div className="text-bold my-5 py-5">
          <h1 className="mb-3 text-primary fs-48">
            Ensuring Reliable
            <br />
            Medical Care Worldwide
          </h1>
          <p className="mb-4 ff-secondary w-50 text-justify">
            Our groundbreaking healthcare solution completely transforms the way
            healthcare is delivered by enabling real-time, secure, and
            convenient interactions between medical professionals and patients.
          </p>

          <div className="d-flex gap-2 mt-5">
            <button
              color="primary "
              className="btn rounded-pill btn-primary"
              onClick={() => "/register"}
            >
              Start for free
            </button>
            <button
              color="success"
              className="btn rounded-pill btn-light border-danger"
              onClick={() => history("/register")}
            >
              <span className="text-danger">Talk to an expert</span>
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Homenew;
