import React from "react";
import {
  Card,
  Container,
} from "reactstrap";

const QueuedMessages = () => {

  document.title = "BRIVAS | Queued Messages";

  return (
    <Container fluid>
      <Card className="shadow-none">

      </Card>
    </Container>
  );
};

export default QueuedMessages;
