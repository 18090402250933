import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

// import JobProfile2 from "../../assets/images/job-profile2.png";
import Cardimage1 from "../../assets/images/svg/Enterprise-bottom-cardimg1.svg";
import Cardimage2 from "../../assets/images/svg//Enterprise-bottom-cardimg2.svg";

import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardText } from "@mui/joy";

const Bottom = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <div className="my-sm-2 mt-md-5 py-5">
      <Row>
        <Col sm={12} md={6}>
          <div class="card mx-4">
            <div class="row g-0 card-body px-0 py-0">
              <div class="col-md-3 col-sm-12">
                <div
                  style={{
                    height: "290px",
                    width: "auto",
                    backgroundImage: `url(${Cardimage1})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div class="col-md-9 col-sm-12">
                <div class="d-flex justify-content-center flex-column py-3 px-4">
                  <h5 class="card-title fs-24">Make every interaction count</h5>
                  <p class="card-text fs-16 fw-400">
                    Make connecting with your customers a breeze with a
                    toll-free number from Brivas. Break down communication
                    barriers, build trust, and provide a free and convenient way
                    for your audience to reach you.
                  </p>

                  <div>
                    <button
                      color="danger"
                      className="btn rounded-pill btn-primary"
                      onClick={() => history("/register")}
                    >
                      Start for free
                    </button>
                    <button
                      color="success"
                      className="btn rounded-pill btn-light border-danger mx-3"
                      onClick={() => history("/register")}
                    >
                      <span className="text-danger">Talk to an expert</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div class="card mx-4">
            <div class="row g-0 card-body px-0 py-0">
              <div class="col-md-3 col-sm-12">
                <div
                  style={{
                    height: "290px",
                    width: "auto",
                    backgroundImage: `url(${Cardimage2})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div class="col-md-9 col-sm-12">
                <div class="d-flex justify-content-center flex-column py-3 px-4">
                  <h5 class="card-title fs-24 fw-500">
                    Create engaging video content
                  </h5>
                  <p class="card-text fs-16 fw-400">
                    Make people feel safe. Use this trusted channel to have
                    two-way conversations with customers. Never miss a chance to
                    get straight feedback and make connections that matter.
                  </p>

                  <div>
                    <button
                      color="danger"
                      className="btn rounded-pill btn-primary"
                      onClick={() => history("/register")}
                    >
                      Start for free
                    </button>
                    <button
                      color="success"
                      className="btn rounded-pill btn-light border-danger mx-3"
                      onClick={() => history("/register")}
                    >
                      <span className="text-danger">Talk to an expert</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Bottom;
