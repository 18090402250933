import React from 'react';
import Section from './Section';
import Footer from '../../Components/Footer';
import Navbar from '../../Components/Navbar';


const Product = () => {
    document.title = "Project Overview | Velzon - React Admin & Dashboard Template";
    return (
        <React.Fragment>
            <div className="layout-wrapper landing">
                <Navbar />
                <Section />
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default Product;