import flagarnig from "../assets/images/flags/ng.svg";

const countries = [
    {
        name: "Nigeria",
        flag: flagarnig,
        code: 234,
    },
]

export default countries