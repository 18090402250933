import {
  BULK_SMS_BALANCE,
  BULK_SMS_ERROR,
  BULK_SMS_LIST_CONTACT,
  BULK_SMS_SCHEDULE,
  BULK_SMS_SUCCESS,
  SEND_BULK_SMS,
} from "./actonType";

const initialState = {
  loading: false,
  error: null,
  contacts: [],
  balance: 0,
  isSent: false,
};

const BulkSms = (state = initialState, action) => {
  switch (action.type) {
    case BULK_SMS_SUCCESS:
      switch (action.payload.actionType) {
        case BULK_SMS_BALANCE:
          return { ...state, isSent: false, balance: action.payload.data };
        case SEND_BULK_SMS:
          return { ...state, isSent: true, loading: false };
        case BULK_SMS_SCHEDULE:
          return { ...state, loading: false };
        case BULK_SMS_LIST_CONTACT:
          return { ...state, contacts: action.payload.data };
        default:
          return { ...state };
      }
    case BULK_SMS_ERROR:
      switch (action.payload.actionType) {
        case SEND_BULK_SMS:
          return { ...state, loading: false };
        case BULK_SMS_SCHEDULE:
          return { ...state, loading: false };
        // case BULK_SMS_LIST_CONTACT:
        //   return { ...state };
        default:
          return { ...state };
      }
    case SEND_BULK_SMS:
      return { ...state, loading: true };
    case BULK_SMS_SCHEDULE:
      return { ...state, loading: true };
    default:
      return state;
  }
};

export default BulkSms;
