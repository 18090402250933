import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import img1 from "../../assets/images/svg/Partners-Banner-img1.png";
import TowerIcon from "../../assets/images/svg/Purpletick-img.png";

const Banner = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <div className="mx-md-5 px-3 px-md-5 my-5">
      <div class="row">
        <div class="col-md-6 col-sm-12"></div>
        <div class="col-md-6 col-sm-12">
          <h1 className="display-6 fw-bold fs-48 lh-base text-sm-center ">
            Collaborate with Us for Unmatched Solutions.
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12 voip order-1">
          <img src={img1} alt="" className="user-img w-75 my-4" />
        </div>
        <div class="col-md-6 col-sm-12 order-2 order-md-1 my-3">
          <p className="mb-5">
            We believe in the power of partnerships to drive innovation and
            growth. Our partner program is designed to empower businesses like
            yours with cutting-edge technology and solutions. By collaborating
            with BRIVAS, you'll gain access to a world of opportunities that can
            help you thrive in the ever-evolving landscape of customer
            engagement.
          </p>
          <div className="media d-flex mb-4">
            <img
              className="align-self-start mx-2"
              src={TowerIcon}
              alt="Generic placeholder image"
            />
            <div className="media-body">
              <h5 className="mt-0">Unlock Innovation</h5>
            </div>
          </div>
          <div className="media d-flex mb-4">
            <img
              className="align-self-start mx-2"
              src={TowerIcon}
              alt="Generic placeholder image"
            />
            <div className="media-body">
              <h5 className="mt-0">Accelerate Growth</h5>
            </div>
          </div>
          <div className="media d-flex mb-4">
            <img
              className="align-self-start mx-2"
              src={TowerIcon}
              alt="Generic placeholder image"
            />
            <div className="media-body">
              <h5 className="mt-0">Access Opportunities</h5>
            </div>
          </div>
          <div className="media d-flex mb-4">
            <img
              className="align-self-start mx-2"
              src={TowerIcon}
              alt="Generic placeholder image"
            />
            <div className="media-body">
              <h5 className="mt-0">Navigate Customer Engagement</h5>
            </div>
          </div>

          <div className="d-flex gap-2 my-5">
            <button
              color="primary "
              className="btn rounded-pill btn-primary"
              onClick={() => "/register"}
            >
              Start for free
              <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
            </button>
            <button
              color="success"
              className="btn rounded-pill btn-light border-danger"
              onClick={() => history("/register")}
            >
              <span className="text-danger">Talk to an expert</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
