import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import img1 from "../../assets/images/svg/Ecommerce-banner-img.png";

const Banner = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <div className="mx-md-5 px-3 px-md-5">
      <div class="row">
        <div class="col-md-6 col-sm-12 order-2 order-md-1">
          <h1 className="display-6 fw-bold fs-48 lh-base mt-md-5 text-dark">
            Embrace the Power of <span className="text-primary">BRICOM:</span>{" "}
            Unleash Your Online Business Potential
          </h1>
          <h4>Build Trust, Increase Conversion</h4>
          <p className="mb-5">
            BRICOM is the user-friendly platform that simplifies your online
            business journey. Start, run, and expand your venture effortlessly
            with essential tools, features, and support. Thrive in the
            competitive e-commerce landscape with BRICOM's easy-to-use platform.
          </p>

          <div className="d-flex gap-2 my-5">
            <button
              color="primary "
              className="btn rounded-pill btn-primary"
              onClick={() => "/register"}
            >
              Start for free
              <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
            </button>
            <button
              color="success"
              className="btn rounded-pill btn-light border-danger"
              onClick={() => history("/register")}
            >
              <span className="text-danger">Talk to an expert</span>
            </button>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 my-5 d-flex align-items-center order-1">
          <img src={img1} alt="" className="user-img w-75" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
