import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import img1 from "../../assets/images/svg/Telecom-banner4.svg";
import icon1 from "../../assets/images/svg/Telecom-icon1.svg";
import icon2 from "../../assets/images/svg/Telecom-icon2.svg";
import icon3 from "../../assets/images/svg/Telecom-icon3.svg";
import icon4 from "../../assets/images/svg/Telecom-icon4.svg";
import icon5 from "../../assets/images/svg/Telecom-icon5.svg";
import background from "../../assets/images/svg/Verification-bg-img.png";

const Banner = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "auto",
      }}
    >
      <Row>
        <Col sm={12} lg={6} className="py-5 px-5">
          <h2 className="fw-bold text-dark lh-base">
            Integrating user{" "}
            <span className="text-primary">authentication</span>
            <br />
            throughout the customer lifecycle
          </h2>
        </Col>
      </Row>

      <Row className=" mx-md-5 px-3 px-md-5">
        <Col sm={12} lg={3}>
          <Card
            className=""
            style={{
              borderRadius: "20px",
              boxShadow: "10px 10px",
              width: "276px",
              height: "351px",
            }}
          >
            <img
              className="pt-2"
              src={icon1}
              alt="Card cap"
              width="40px"
              height="40px"
            />
            <CardBody>
              <h4 className="card-title ft-bold mb-3 mt-4 fw-bolder fs-24">
                {" "}
                SMS OTP
              </h4>
              <p className="card-text text-muted">
                Thanks to our automatic SMS verification, your users don’t need
                to enter codes manually. Keep your customers protected with
                Brivas SMS verification.
              </p>
              <div className="text-end mt-5">
                <Link to="#" className="soft-primary">
                  Sign up
                  <i class="ri-arrow-right-line"></i>
                </Link>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm={12} lg={3}>
          <Card
            style={{
              borderRadius: "20px",
              boxShadow: "10px 10px",
              width: "276px",
              height: "351px",
            }}
          >
            <img
              className="card-img-top pt-3"
              style={{ width: 40, height: 40 }}
              src={icon2}
              alt="Card cap"
            />
            <CardBody style={{ width: "276px" }}>
              <h4 className="card-title ft-bold mb-3 mt-4 fw-bolder fs-24">
                Flash Call
              </h4>
              <p className="card-text text-muted">
                Brivas Flash Call is not only quick but also affordable and
                automatic. Verify users during account creation and verify
                transactions easily.
              </p>
              <div className="text-end">
                <Link to="#" className="soft-primary w-50 mx-auto">
                  Sign up
                  <i class="ri-arrow-right-line"></i>
                </Link>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm={12} lg={3}>
          <Card
            style={{
              borderRadius: "20px",
              boxShadow: "10px 10px",
              width: "276px",
              height: "351px",
            }}
          >
            <img
              className="card-img-top pt-3"
              style={{ width: 40, height: 40 }}
              src={icon3}
              alt="Card cap"
            />
            <CardBody style={{ width: "276px" }}>
              <h4 className="card-title ft-bold mb-3 mt-4  fw-bolder fs-24">
                Verification API
              </h4>
              <p className="card-text text-muted">
                Do you want to enjoy swift transactions? Our instant one-time
                passwords and two-factor authentication are delivered for
                enhanced security.
              </p>
              <div className="text-end">
                <Link to="#" className="soft-primary w-50 mx-auto">
                  Sign up
                  <i class="ri-arrow-right-line"></i>
                </Link>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm={12} lg={3}>
          <Card
            style={{
              borderRadius: "20px",
              boxShadow: "10px 10px",
              width: "276px",
              height: "351px",
            }}
          >
            <img
              className="card-img-top pt-3"
              style={{ width: 40, height: 40 }}
              src={icon4}
              alt="Card cap"
            />
            <CardBody style={{ width: "276px" }}>
              <h4 className="card-title ft-bold mb-3 mt-4 fw-bolder fs-24">
                Voice Call
              </h4>
              <p className="card-text text-center text-muted">
                By leveraging advanced text-to-speech software, we provide you
                with a seamless and trustworthy way to authenticate individuals
                and prevent fraud.
              </p>
              <div className="text-end">
                <Link to="#" className="soft-primary mx-auto">
                  Sign up
                  <i class="ri-arrow-right-line"></i>
                </Link>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </section>
  );
};

export default Banner;
