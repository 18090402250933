import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Carousel,
  Container,
  Form,
  Input,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

// import JobProfile2 from "../../assets/images/job-profile2.png";
import Dolapo from "../../assets/images/svg/dolapofacemin.png";

import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const Homenew = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <section
        className="section  bg-secondary pb-0"
        id="hero"
        style={{
          backgroundImage: `url(${Dolapo})`,
          backgroundSize: "cover",
          backgroundPosition: "contain",
         
        }}
      >
     
        <div className="my-3 py-5">
          <Row className="">
            <Col md={12} sm={12}>
              <div className="mx-5 d-flex flex-column">
                <h1 className="display-5 fw-medium mb-3 ft-bold text-light fs-50  lh-base">
                  Reliable and Quick<br></br>
                  Messaging Solutions
                </h1>
                <p className="lead lh-base mb-5 mt-5 w-75 text-light ">
                  Want stronger customer relationships? Our communication APIs
                  are complete. <br></br>  Fast transactional and promotional messaging
                  API.
                </p>

                <div className=" d-flex gap-2 mt-2">
                  <button
                    color="danger "
                    className="btn rounded-pill btn-danger"
                    onClick={() => history("/register")}
                  >
                    Start for free
                   
                  </button>

                  <button
                    color="success"
                    className="btn rounded-pill btn-light margin-left-20px"
                    onClick={() => history("/register")}
                  >
                    Talk to an expert
                   
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Homenew;
