import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import Section from './Section';

const Profile = () => {
    document.title = "App Overview | BRIVAS";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid> 
                    <Section />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Profile;