import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Button,
  Alert,
  NavLink,
  ModalHeader,
  ModalBody,
  Modal,
  Accordion,
  AccordionItem,
  Collapse,
  Badge,
  Col,
  Container,
  Form,
  Label,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";

import SimpleBar from "simplebar-react";
import classnames from "classnames";
import {
  BasicRaterExample,
  BasicRaterExamplefornode,
  BasicRaterExampleforjava,
  BasicRaterExampleforpython,
  BasicRaterExampleforphp,
} from "../../Components/UiRatingsCode";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setAuthorization } from "../../helpers/api_helper";
import { sendBulkSMS } from "../../store/actions";
import Header from "./Header";

const GetStarted = () => {
  const [activeTab, setactiveTab] = useState(1);
  const [codeTab, setCodeTab] = useState("cURL"); // Define isTypeTab variable
  const [isLoader, setIsLoader] = useState(false);

  const dispatch = useDispatch();

  const { account, loading } = useSelector(state => ({
    account: state.Account.account,
    loading: state.BulkSMS.loading,
  }));

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      sender: "",
      recipients: "",
      messageBody: "",
    },
    validationSchema: Yup.object({
      // recipients: Yup.string().required("Please enter your email address"),
      // messageBody: Yup.string().required("Please enter a message"),
    }),
    onSubmit: (values) => {
      setAuthorization(account?.test_secret_key);
      dispatch(sendBulkSMS({ from: values.sender, to: values.recipients, message: values.messageBody }));
    },
  });

  document.title = "BRIVAS | Bulk SMS";

  return (
    <Container fluid>
      <Card className="shadow-none">
        <Header >
          <h1 className="mb-0 fs-120 fw-bold">
            Get started with Bulk SMS
          </h1>
          <p className="card-title mb-0 w-50 text-muted mt-2 fs-13">
            Try our API by sending an SMS to your phone. Sending SMS uses your
            account credit. Learn more
          </p>
        </Header>
        <CardBody>
          <Alert color="text-dark" style={{ backgroundColor: "#F0F0F0" }}>
            <strong>
              When sending SMS in Nigeria, we{" "}
              <span className="text-primary">
                may override the ‘From’ field
              </span>
            </strong>
            <br />
            This is to ensure delivery when restrictions apply. These
            restrictions can be avoided in some locations. Find out more
            <br />
          </Alert>
          <Row>
            <Col xl={6}>
              <Form
                action="#"
                className="form-steps"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={1}>
                    <div>
                      <div className="mb-4"></div>
                      <h5>
                        <p>
                          <strong>Run test </strong>
                        </p>
                      </h5>

                      <div className="mb-3 col-md-10">
                        <Label
                          className="form-label fw-bold fs-12"
                          htmlFor="gen-info-email-input"
                        >
                          From
                        </Label>
                        <Input
                          type="text"
                          name="sender"
                          className="form-control"
                          placeholder="Brivas"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.sender || ""}
                          invalid={
                            validation.touched.sender &&
                              validation.errors.sender
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="mb-3 col-md-10">
                        <Label
                          className="form-label fw-bold"
                          htmlFor="gen-info-password-input"
                        >
                          Registered phone number
                        </Label>
                        <Input
                          name="recipients"
                          type="text"
                          className="form-control"
                          placeholder="2348165661377"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.recipients || ""}
                          invalid={
                            validation.touched.recipients &&
                              validation.errors.recipients
                              ? true
                              : false
                          }
                        />

                        <Label
                          className="form-label text-muted fw-normal"
                          htmlFor="gen-info-password-input"
                        >
                          You can only use the registered number to receive
                          messages in this example.
                        </Label>
                      </div>
                      <div className="mt-4 mr-5 ml-3">
                        <div className="mb-5">
                          <Label
                            htmlFor="exampleFormControlTextarea5"
                            className="form-label "
                          >
                            Message
                          </Label>
                          <textarea
                            className="form-control rounded-4 "
                            name="messageBody"
                            rows="3"
                            placeholder="Testing SMS Api"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.messageBody || ""}
                            invalid={
                              validation.touched.messageBody &&
                                validation.errors.messageBody
                                ? true
                                : false
                            }
                            style={{ maxWidth: "85%" }}
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div className=" align-items-start gap-3 mt-0">
                      <Button type="submit" color="primary" className="btn-load rounded-pill"
                        // onClick={onSendSMS}
                        disabled={loading}>
                        <span className="d-flex align-items-center">
                          {loading && <Spinner size="sm" type="grow" className="flex-shrink-0" />}
                          <span className="flex-grow-1 ms-1">
                            Send SMS
                          </span>
                        </span>
                      </Button>
                    </div>
                  </TabPane>
                </TabContent>
              </Form>
            </Col>
            <Col xl={6}>
              <br />

              <h5>
                <strong>
                  <p>Run Test code</p>
                </strong>
              </h5>

              <p>Use these pre-built snippets to try it out with code.</p>

              <div className="email-content">
                <div className="p-4 pb-0">
                  <div className="border-bottom border-bottom-dashed">
                    <Row className="row align-items-end mt-3">
                      <Col>
                        <div id="mail-filter-navlist">
                          <Nav
                            className="nav nav-tabs nav-tabs-custom nav-success gap-1 text-center border-bottom-0"
                            role="tablist"
                          >
                            <NavItem>
                              <NavLink
                                className={classnames(
                                  { active: codeTab === "cURL" },
                                  "fw-semibold"
                                )}
                                onClick={() => setCodeTab("cURL")}
                                href="#"
                              >
                                <span className="ms-1 d-none d-sm-inline-block">
                                  cURL
                                </span>
                              </NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink
                                className={classnames(
                                  { active: codeTab === "Node" },
                                  "fw-semibold"
                                )}
                                onClick={() => setCodeTab("Node")}
                                href="#"
                              >
                                <span className="ms-1 d-none d-sm-inline-block">
                                  Node
                                </span>
                              </NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink
                                className={classnames(
                                  { active: codeTab === "Java" },
                                  "fw-semibold"
                                )}
                                onClick={() => setCodeTab("Java")}
                                href="#"
                              >
                                <span className="ms-1 d-none d-sm-inline-block">
                                  Java
                                </span>
                              </NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink
                                className={classnames(
                                  { active: codeTab === "PYTHON" },
                                  "fw-semibold"
                                )}
                                onClick={() => setCodeTab("PYTHON")}
                                href="#"
                              >
                                <span className="ms-1 d-none d-sm-inline-block">
                                  PYTHON
                                </span>
                              </NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink
                                className={classnames(
                                  { active: codeTab === "PHP" },
                                  "fw-semibold"
                                )}
                                onClick={() => setCodeTab("PHP")}
                                href="#"
                              >
                                <span className="ms-1 d-none d-sm-inline-block">
                                  PHP
                                </span>
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <SimpleBar className="message-list-content mx-n4 px-4 message-list-scroll">
                    {isLoader ? (
                      <div id="elmLoader">
                        <div
                          className="spinner-border text-primary avatar-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {codeTab === "cURL" && (
                          <div>
                            <h6 className=" mt-2">Install the Library</h6>

                            <pre>
                              <code className="language-markup">
                                <BasicRaterExample />
                              </code>
                            </pre>

                            {/* <h6 className=" mt-2">
                                                                    Initialize the library
                                                                </h6>

                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre>
                                                                <h6 className=" mt-2">Write the code</h6>
                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre> */}
                          </div>
                        )}

                        {codeTab === "Node" && (
                          <div>
                            <div>
                              <h6 className=" mt-2">Install the Library</h6>

                              <pre>
                                <code className="language-markup">
                                  <BasicRaterExamplefornode />
                                </code>
                              </pre>

                              {/* <h6 className=" mt-2">
                                                                    Initialize the library
                                                                </h6>

                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre>
                                                                <h6 className=" mt-2">Write the code</h6>
                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre> */}
                            </div>
                          </div>
                        )}

                        {codeTab === "Java" && (
                          <div>
                            <div>
                              <h6 className=" mt-2">Install the Library</h6>

                              <pre>
                                <code className="language-markup">
                                  <BasicRaterExampleforjava />
                                </code>
                              </pre>

                              {/* <h6 className=" mt-2">
                                                                    Initialize the library
                                                                </h6>

                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre>
                                                                <h6 className=" mt-2">Write the code</h6>
                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre> */}
                            </div>
                          </div>
                        )}

                        {codeTab === "PYTHON" && (
                          <div>
                            <div>
                              <h6 className=" mt-2">Install the Library</h6>

                              <pre>
                                <code className="language-markup">
                                  <BasicRaterExampleforpython />
                                </code>
                              </pre>

                              {/* <h6 className=" mt-2">
                                                                    Initialize the library
                                                                </h6>

                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre>
                                                                <h6 className=" mt-2">Write the code</h6>
                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre> */}
                            </div>
                          </div>
                        )}

                        {codeTab === "PHP" && (
                          <div>
                            <div>
                              <h6 className=" mt-2">Install the Library</h6>

                              <pre>
                                <code className="language-markup">
                                  <BasicRaterExampleforphp />
                                </code>
                              </pre>

                              {/* <h6 className=" mt-2">
                                                                    Initialize the library
                                                                </h6>

                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre>
                                                                <h6 className=" mt-2">Write the code</h6>
                                                                <pre>
                                                                    <code className="language-markup">
                                                                        <BasicRaterExample />
                                                                    </code>
                                                                </pre> */}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </SimpleBar>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default GetStarted;
