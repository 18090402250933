import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import img1 from "../../assets/images/svg/Telecom-banner4.svg";
import icon1 from "../../assets/images/enhanchedbrimail1.png";
import icon2 from "../../assets/images/enhanchedbrimail2.png";
import icon3 from "../../assets/images/enhanchedbrimail3.png";
import icon4 from "../../assets/images/enhanchedbrimail4.png";
import icon5 from "../../assets/images/enhanchedbrimail5.png";
import icon6 from "../../assets/images/enhanchedbrimail6.png";
import icon7 from "../../assets/images/enhanchedbrimail7.png";
import icon8 from "../../assets/images/enhanchedbrimail8.png";
import icon9 from "../../assets/images/enhanchedbrimail9.png";
import icon10 from "../../assets/images/enhanchedbrimail10.png";
import icon11 from "../../assets/images/enhanchedbrimail11.png";
import icon12 from "../../assets/images/enhanchedbrimail12.png";
import icon13 from "../../assets/images/enhanchedbrimail13.png";
import icon14 from "../../assets/images/enhanchedbrimail14.png";
import icon15 from "../../assets/images/enhanchedbrimail15.png";

import { icon } from "leaflet";

const Banner3 = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section>
      <div className="p-4" style={{ backgroundColor: "#FFF5FF" }}>
        <Row>
          <div className="text-center mt-3 mb-5">
            <h2 className="mb-1 fw-bold text-dark mt-4 mb-3 lh-base">
              Enhanced Brimail Features
            </h2>
          </div>
          <Container>
            <Row>
              <Col sm={3} xl={3}>
                <Card className="rounded-4 rounded col-9">
                  <img
                    className="card-img-top img-fluid mt-3 m-3 float-left"
                    style={{ width: 40, height: 40 }}
                    src={icon1}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title fw-bold mb-2">Custom Domain</h4>
                    <p className="card-text w-100 mb-5">
                      Give your emails professional and branded appearance.
                    </p>
                    <div className="text-end mt-3">
                      <Link
                        to="#"
                        className="soft-primary w-100 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              

              <Col sm={3} xl={3}>
                <Card className="rounded-4 rounded col-9">
                  <img
                    className="card-img-top img-fluid mt-3 m-3 float-left"
                    style={{ width: 40, height: 40 }}
                    src={icon2}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title fw-bold mb-2">Multiple Mailbox</h4>
                    <p className="card-text w-100 mb-5">
                    Create multiple email accounts, each with its own inbox, sent items, and settings.
                    </p>
                    <div className="text-end mt-3">
                      <Link
                        to="#"
                        className="soft-primary w-100 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col sm={3} xl={3}>
                <Card className="rounded-4 rounded col-9">
                  <img
                    className="card-img-top img-fluid mt-3 m-3 float-left"
                    style={{ width: 40, height: 40 }}
                    src={icon3}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title fw-bold mb-2">Webmail Access</h4>
                    <p className="card-text w-100 mb-5">
                    Check and send emails from any internet-connected device.
                    </p>
                    <div className="text-end mt-3">
                      <Link
                        to="#"
                        className="soft-primary w-100 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col sm={3} xl={3}>
                <Card className="rounded-4 rounded col-9">
                  <img
                    className="card-img-top img-fluid mt-3 m-3 float-left"
                    style={{ width: 40, height: 40 }}
                    src={icon4}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title fw-bold mb-2">Compatibility</h4>
                    <p className="card-text w-100 mb-5">
                    We support popular email clients like Microsoft Outlook, Apple Mail, etc.
                    </p>
                    <div className="text-end mt-3">
                      <Link
                        to="#"
                        className="soft-primary w-100 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col sm={3} xl={3}>
                <Card className="rounded-4 rounded col-9">
                  <img
                    className="card-img-top img-fluid mt-3 m-3 float-left"
                    style={{ width: 40, height: 40 }}
                    src={icon5}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title fw-bold mb-2">Virus Filtering</h4>
                    <p className="card-text w-100 mb-5">
                    Gain protection against malicious malware. We ensure a clean box.
                    </p>
                    <div className="text-end mt-3">
                      <Link
                        to="#"
                        className="soft-primary w-100 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col sm={3} xl={3}>
                <Card className="rounded-4 rounded col-9">
                  <img
                    className="card-img-top img-fluid mt-3 m-3 float-left"
                    style={{ width: 40, height: 40 }}
                    src={icon6}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title fw-bold mb-2">Storage Space</h4>
                    <p className="card-text w-100 mb-5">
                    Unlimited storage space available. Choose your preference.
                    </p>
                    <div className="text-end mt-3">
                      <Link
                        to="#"
                        className="soft-primary w-100 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col sm={3} xl={3}>
                <Card className="rounded-4 rounded col-9">
                  <img
                    className="card-img-top img-fluid mt-3 m-3 float-left"
                    style={{ width: 40, height: 40 }}
                    src={icon7}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title fw-bold mb-2">Mobile Devices</h4>
                    <p className="card-text w-100 mb-5">
                    Check your inbox from the palm of your hand with mobile email access.
                    </p>
                    <div className="text-end mt-3">
                      <Link
                        to="#"
                        className="soft-primary w-100 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col sm={3} xl={3}>
                <Card className="rounded-4 rounded col-9">
                  <img
                    className="card-img-top img-fluid mt-3 m-3 float-left"
                    style={{ width: 40, height: 40 }}
                    src={icon8}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title fw-bold mb-2">Calendar Sync</h4>
                    <p className="card-text w-100 mb-5">
                    Sync data across your calendar applications with Brimail.
                    </p>
                    <div className="text-end mt-3">
                      <Link
                        to="#"
                        className="soft-primary w-100 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col sm={3} xl={3}>
                <Card className="rounded-4 rounded col-9">
                  <img
                    className="card-img-top img-fluid mt-3 m-3 float-left"
                    style={{ width: 40, height: 40 }}
                    src={icon9}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title fw-bold mb-2">Integration</h4>
                    <p className="card-text w-100 mb-5">
                    Integrate with other productivity and collaborative tools smoothly.
                    </p>
                    <div className="text-end mt-3">
                      <Link
                        to="#"
                        className="soft-primary w-100 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col sm={6} xl={3}>
                <Card className="rounded-4 rounded col-9">
                  <img
                    className="card-img-top img-fluid mt-3 m-3 float-left"
                    style={{ width: 40, height: 40 }}
                    src={icon10}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title fw-bold mb-2">Pricing Plans</h4>
                    <p className="card-text w-100 mb-5">
                    Our pricing plans are set to cater to your budget and needs..
                    </p>
                    <div className="text-end mt-3">
                      <Link
                        to="#"
                        className="soft-primary w-100 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col sm={6} xl={3}>
                <Card className="rounded-4 rounded col-9">
                  <img
                    className="card-img-top img-fluid mt-3 m-3 float-left"
                    style={{ width: 40, height: 40 }}
                    src={icon11}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title fw-bold mb-2">Sharing Folders</h4>
                    <p className="card-text w-100 mb-5">
                    Share documents and resources seamlessly, facilitating collaboration like never before..
                    </p>
                    <div className="text-end mt-3">
                      <Link
                        to="#"
                        className="soft-primary w-100 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col sm={6} xl={3}>
                <Card className="rounded-4 rounded col-9">
                  <img
                    className="card-img-top img-fluid mt-3 m-3 float-left"
                    style={{ width: 40, height: 40 }}
                    src={icon12}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title fw-bold mb-2">Network Edition</h4>
                    <p className="card-text w-100 mb-5">
                    Check your inbox from the palm of your hand with mobile email access.
                    </p>
                    <div className="text-end mt-3">
                      <Link
                        to="#"
                        className="soft-primary w-100 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col sm={5} xl={3}>
                <Card className="rounded-4 rounded col-9">
                  <img
                    className="card-img-top img-fluid mt-3 m-3 float-left"
                    style={{ width: 40, height: 40 }}
                    src={icon13}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title fw-bold mb-2">Contact Sync</h4>
                    <p className="card-text w-100 mb-5">
                    Sync data across your calendar applications with Brimail.
                    </p>
                    <div className="text-end mt-3">
                      <Link
                        to="#"
                        className="soft-primary w-100 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col sm={5} xl={3}>
                <Card className="rounded-4 rounded col-9">
                  <img
                    className="card-img-top img-fluid mt-3 m-3 float-left"
                    style={{ width: 40, height: 40 }}
                    src={icon14}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title fw-bold mb-2">Server Admin</h4>
                    <p className="card-text w-100 mb-5">
                    Take control of your email ecosystem with our comprehensive Server Administration tools.
                    </p>
                    <div className="text-end mt-3">
                      <Link
                        to="#"
                        className="soft-primary w-100 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col sm={5} xl={3} className="mb-3">
                <Card className="rounded-4 rounded col-9">
                  <img
                    className="card-img-top img-fluid mt-3 m-3 float-left"
                    style={{ width: 40, height: 40 }}
                    src={icon15}
                    alt="Card cap"
                  />
                  <CardBody>
                    <h4 className="card-title fw-bold mb-2">User Interface</h4>
                    <p className="card-text w-100 mb-5">
                    Say goodbye to clunky setups and hello to a sleek, organized dashboard tailored for maximum efficiency.
                    </p>
                    <div className="text-end mt-3">
                      <Link
                        to="#"
                        className="soft-primary w-100 mx-auto d-flex justify-content-center"
                      >
                        Learn more
                        <i class="ri-arrow-right-line"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Row>
      </div>
    </section>
  );
};

export default Banner3;
