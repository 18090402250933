import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  ACCOUNT_FORGOT_PASSWORD,
  ACCOUNT_LOGIN,
  ACCOUNT_LOGOUT,
  ACCOUNT_PROFILE,
  ACCOUNT_REGISTER,
  ACCOUNT_RESET_PASSWORD,
} from "./actionTypes";
import { accountError, accountSuccess } from "./actions";
import {
  getProfile,
  logout,
  postForgetPwd,
  postLogin,
  postRegister,
  postResetPwd,
} from "../../helpers/backend_helper";

function* register({ payload: { data, history } }) {
  try {
    const resp = yield call(postRegister, data);
    // console.log('RESP STATUS', resp.status)
    if (resp.status === "success") {
      // console.log('RESP', resp)
      yield put(accountSuccess(ACCOUNT_REGISTER, resp));
      history("/pending");
    } else {
      toast.error(resp?.msg ?? 'An error occured', { autoClose: 3000 });
      yield put(accountError(ACCOUNT_REGISTER, resp?.msg ?? 'An error occured'));
    }
  } catch (error) {
    // console.log('ERROR', error)
    yield put(accountError(ACCOUNT_REGISTER, error?.message ?? 'Failed'));
    toast.error(error?.response?.data?.msg ?? 'Failed', { autoClose: 3000 });
  }
}

function* login({ payload: { data, history } }) {
  try {
    const resp = yield call(postLogin, data);
    console.log("resp", resp);
    if (resp.status === "success") {
      yield put(accountSuccess(ACCOUNT_LOGIN, resp));
      // toast.success("Announcement created", { autoClose: 3000 });
      history("/dashboard");
    } else if (resp?.next === "confirm") {
      history("/pending");
      yield put(accountError(ACCOUNT_LOGIN, resp.msg));
    } else {
      toast.error(resp?.msg ?? "An error occured", { autoClose: 3000 });
      yield put(accountError(ACCOUNT_LOGIN, resp?.msg ?? "An error occured"));
    }
  } catch (error) {
    yield put(accountError(ACCOUNT_LOGIN, error?.message ?? "Failed"));
    {
      error && <div>{error?.response?.data?.msg ?? "Failed"}</div>;
    }
    toast.error(error?.response?.data?.msg ?? "Failed", { autoClose: 3000 });
  }
}

function* forgotPassword({ payload: { data, history } }) {
  try {
    const resp = yield call(postForgetPwd, data);
    console.log("forgotPassword", resp);
    if (resp.status === "success") {
      yield put(accountSuccess(ACCOUNT_FORGOT_PASSWORD, resp));
      // toast.success("Announcement created", { autoClose: 3000 });
      // history('/dashboard');
    } else {
      toast.error(resp?.msg ?? "An error occured", { autoClose: 3000 });
      yield put(
        accountError(ACCOUNT_FORGOT_PASSWORD, resp?.msg ?? "An error occured")
      );
    }
  } catch (error) {
    yield put(
      accountError(ACCOUNT_FORGOT_PASSWORD, error?.message ?? "Failed")
    );
    toast.error(error?.response?.data?.msg ?? "Failed", { autoClose: 3000 });
  }
}
function* resetPassword({ payload: { data, history } }) {
  try {
    const resp = yield call(postResetPwd, data);
    console.log("resp", resp);
    if (resp.status === "success") {
      console.log("Successful");
      yield put(accountSuccess(ACCOUNT_RESET_PASSWORD, resp));
      toast.success("Password reset successful", { autoClose: 3000 });
      history("/login");
    } else {
      toast.error(resp?.msg ?? "An error occured", { autoClose: 3000 });
      yield put(
        accountError(ACCOUNT_RESET_PASSWORD, resp?.msg ?? "An error occured")
      );
    }
  } catch (error) {
    yield put(accountError(ACCOUNT_RESET_PASSWORD, error?.message ?? "Failed"));
    toast.error(error?.response?.data?.msg ?? "Failed", { autoClose: 3000 });
  }
}


function* profile({ payload: history }) {
  // yield put(accountSuccess(ACCOUNT_PROFILE, { type: "Service Provider" }));
  try {
    const response = yield call(getProfile);
    if (response.status === 'success') {
      yield put(accountSuccess(ACCOUNT_PROFILE, response.data));
    } else {
      yield put(accountError(ACCOUNT_PROFILE, response.msg));
    }
  } catch (error) {
    yield put(accountError(ACCOUNT_PROFILE, error));
  }
}


function* accountLogout({ payload: { history } }) {
  try {
    const response = yield call(logout);
    if (response.status === "success") {
      window.location.reload();
    }
  } catch (error) {
    //
  }
}

export function* watchRegister() {
  yield takeEvery(ACCOUNT_REGISTER, register);
}
export function* watchLogin() {
  yield takeEvery(ACCOUNT_LOGIN, login);
}
export function* watchForgotPwd() {
  yield takeEvery(ACCOUNT_FORGOT_PASSWORD, forgotPassword);
}
export function* watchResetPwd() {
  yield takeEvery(ACCOUNT_RESET_PASSWORD, resetPassword);
}
export function* watchProfile() {
  yield takeEvery(ACCOUNT_PROFILE, profile);
}
export function* watchLogout() {
  yield takeEvery(ACCOUNT_LOGOUT, accountLogout);
}

function* accountSaga() {
  yield all([fork(watchRegister)]);
  yield all([fork(watchLogin)]);
  yield all([fork(watchForgotPwd)]);
  yield all([fork(watchResetPwd)]);
  yield all([fork(watchProfile)]);
  yield all([fork(watchLogout)]);
}

export default accountSaga;
