import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Ronke from "../../assets/images/ronke.png";

const Banner3 = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section className="p-4" style={{ backgroundColor: "#FFF5FF" }}>
      <div className="p-4">
        <Row className=" justify-content-between align-items-center ">
          <div>
            <h1 className="text-center fs-40 pt-4 text-dark mb-4 fw-bold">
              Embark on a{" "}
              <span className="text-danger">Streaming Journey</span> Like Never
              Before
            </h1>
            <h5 className="text-center text-dark fs-10 mb-2">
              Enjoy your favourite content on the go. Whether it is Movies, TV
              shows, or music, Brivas empowers you to curate your own immersive
              entertainment journey. Make every moment a personalized delight.
              <br />
              customer service—on a single platform.
            </h5>
          </div>

          <Col lg={6}>
            <div className="pr-3">
              <img src={Ronke} alt="" className="img-fluid" />
            </div>
          </Col>
          <Col lg={6} className="order-2 order-lg-1">
            <div className="text-bold fs-40 pt-5 py-5">
              <h1 className="mb-3 fw-bold text-dark text-center">
                <span className="text-danger">Connect</span> With Your Audience
                <br />
                Amplify Your Message
              </h1>
              <p className="mb-4 d-flex w-100">
                Develop one-of-a-kind experiences that will leave a lasting
                impression while enhancing live event broadcasting. We take
                measures to ensure a seamless viewing experience, including
                smooth playback, adaptive streaming, and decreased buffering.
              </p>

              <div className="d-flex pt-2 gap-2">
                <button
                  color="danger "
                  className="btn rounded-pill btn-danger"
                  onClick={() => history("/register")}
                >
                  Start for free
                  <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};
export default Banner3;
