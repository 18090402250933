import { all, fork } from "redux-saga/effects";
//Landing
import LandingSaga from "./landing/saga";

//layout
import LayoutSaga from "./layouts/saga";

//Auth
// import AccountSaga from "./auth/register/saga";
import PendingVerificationSaga from "./auth/pending-verification/saga";
// import AccountSaga from "./auth/register/saga";
import AccountSaga from "./account/saga";
import VerifyAccountSaga from "./auth/verify-account/saga";
import CreateAccountSaga from "./auth/createaccount/saga";
import SendOTPSaga from "./auth/account-phone/saga";
import AccountVerify from "./auth/account-verify/saga";
import AuthSaga from "./auth/login/saga";

import AppsSaga from "./apps/saga";
import AppSaga from "./app/saga";

import BulkSMSSaga from "./bulksms/saga";
import VerificationSMSSaga from "./smsotp/saga"; 
import VerificationFlashCall from "./flashcall/saga";

import uploadContact from "./uploadcontacts/saga";


import ProfileSaga from "./auth/profile/saga";

import QRLogin from "./auth/qr-login/saga";

// //calendar
// import calendarSaga from "./calendar/saga";
// //chat
// import chatSaga from "./chat/saga";
// //ecommerce
// import ecommerceSaga from "./ecommerce/saga";

// //Project
// import projectSaga from "./projects/saga";
// // Task
// import taskSaga from "./tasks/saga";
// // Crypto
// import cryptoSaga from "./crypto/saga";
// //TicketsList
// import ticketsSaga from "./tickets/saga";

// //crm
// import crmSaga from "./crm/saga";
// //invoice
// import invoiceSaga from "./invoice/saga";
// //mailbox
// import mailboxSaga from "./mailbox/saga";

// // Dashboard Analytics
// import dashboardAnalyticsSaga from "./dashboardAnalytics/saga";

// // Dashboard CRM
// import dashboardCrmSaga from "./dashboardCRM/saga";

// // Dashboard Ecommerce
// import dashboardEcommerceSaga from "./dashboardEcommerce/saga";

// // Dashboard Crypto
// import dashboardCryptoSaga from "./dashboardCrypto/saga";

// // Dashboard Project
// import dashboardProjectSaga from "./dashboardProject/saga";

// // Dashboard NFT
// import dashboardNFTSaga from "./dashboardNFT/saga";

// // Pages > Team
// import teamSaga from "./team/saga";

// // File Manager
// import fileManager from "./fileManager/saga";

// // To do
// import todos from "./todos/saga"

// //Job
// import jobSaga from "./job/saga";

// //API Key
// import APIKeysaga from "./apikey/saga";

export default function* rootSaga() {
  yield all([
    fork(LandingSaga),
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(PendingVerificationSaga),
    fork(VerifyAccountSaga),
    fork(AuthSaga),
    fork(CreateAccountSaga),
    fork(SendOTPSaga),
    fork(AccountVerify),
    fork(ProfileSaga),
    fork(QRLogin),
    fork(AppsSaga),
    fork(AppSaga),
    fork(BulkSMSSaga),
    fork(uploadContact),

    fork(VerificationSMSSaga),
    fork(VerificationFlashCall),
    // fork(chatSaga),
    // fork(projectSaga),
    // fork(taskSaga),
    // fork(cryptoSaga),
    // fork(ticketsSaga),
    // fork(calendarSaga),
    // fork(ecommerceSaga),
    // fork(crmSaga),
    // fork(invoiceSaga),
    // fork(mailboxSaga),
    // fork(dashboardAnalyticsSaga),
    // fork(dashboardCrmSaga),
    // fork(dashboardEcommerceSaga),
    // fork(dashboardCryptoSaga),
    // fork(dashboardProjectSaga),
    // fork(dashboardNFTSaga),
    // fork(teamSaga),
    // fork(fileManager),
    // fork(todos),
    // fork(jobSaga),
    // fork(APIKeysaga),
  ]);
}
