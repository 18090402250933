import React, { useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Button, Spinner } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// action
import { initCreateAccount } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

//import images 
// import logoLight from "../../assets/images/logo-light.png";
import logoLight from "../../assets/images/logo.svg";

import ParticlesAuth from "./ParticlesAuth";

const Register = () => {
    document.title = "BRIVAS | Register";

    const history = useNavigate();
    const dispatch = useDispatch();

    const accountCategories = [
        {
            options: [
                { label: "Personal", value: "Personal" },
                { label: "Service Provider", value: "Service Provider" },
                { label: "Content Owner", value: "Content Owner" },
            ],
        },
    ];

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
            category: '',
            first_name: '',
            last_name: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please enter your email address"),
            // category: Yup.string().required("Please select account category"),
            first_name: Yup.string().required("Please enter your first name"),
            last_name: Yup.string().required("Please enter your last name"),
        }),
        onSubmit: (values) => {
            const data = { email: values.email, type: values.category, first_name: values.first_name, last_name: values.last_name };
            dispatch(initCreateAccount(data, history));
        }
    });

    const { loading, isPending } = useSelector(state => ({
        isPending: state.Account.isPending,
        loading: state.Account.loading,
    }));

    // useEffect(() => {
    //     dispatch(apiError(""));
    // }, [dispatch]);

    // useEffect(() => {
    //     if (isPending) {
    //         // setTimeout(() => history("/login"), 3000);
    //         history("/pending");
    //     }

    //     // setTimeout(() => {
    //     //     dispatch(resetRegisterFlag());
    //     // }, 3000);

    // }, [dispatch, history, isPending]);

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                {/* text-white-50 */}
                                <div className="text-center mt-sm-1 mb-0 text-black-55">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="20" />
                                        </Link>
                                    </div>
                                    {/* fw-medium */}
                                    <p className="mt-3 fs-13">Signup with Brivas</p>
                                </div>
                            </Col>``
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-0">

                                    <CardBody className="p-4 pt-1">
                                        {/* <div className="text-center mt-2">
                                            <h5 className="text-primary">Create New Account</h5>
                                            
                                        </div> */}
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                className="needs-validation" action="#">

                                                <div className="mb-3">
                                                    <Label htmlFor="useremail" className="form-label fw-normal fs-12">Email <span className="text-danger">*</span></Label>
                                                    <Input
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter your email here"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                                                    ) : null}

                                                </div>

                                                {/* <div className="mb-3">
                                                    <Label
                                                        htmlFor="account-categories-input"
                                                        className="form-label fw-normal fs-12"
                                                    >
                                                        Category
                                                    </Label>
                                                    <Input
                                                        name="category"
                                                        type="select"
                                                        className="form-select"
                                                        id="account-categories-input"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={
                                                            validation.values.category || ""
                                                        }
                                                        required
                                                    >
                                                        <option value="">Select account category</option>
                                                        {accountCategories.map((item, key) => (
                                                            <React.Fragment key={key}>
                                                                {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                                            </React.Fragment>
                                                        ))}
                                                    </Input>
                                                    {validation.touched.category &&
                                                        validation.errors.category ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.category}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div> */}

                                                <div className="mb-3">
                                                    <Label htmlFor="first_name" className="form-label fw-normal fs-12">First name <span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="first_name"
                                                        type="text"
                                                        placeholder="Enter your first name"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.first_name || ""}
                                                        invalid={
                                                            validation.touched.first_name && validation.errors.first_name ? true : false
                                                        }
                                                    />
                                                    {validation.touched.first_name && validation.errors.first_name ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.first_name}</div></FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="last_name" className="form-label fw-normal fs-12">Last name <span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="last_name"
                                                        type="text"
                                                        placeholder="Enter your last name"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.last_name || ""}
                                                        invalid={
                                                            validation.touched.last_name && validation.errors.last_name ? true : false
                                                        }
                                                    />
                                                    {validation.touched.last_name && validation.errors.last_name ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.last_name}</div></FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-4 form-check form-check-info mb-3">
                                                    {/* <div className="form-check form-check-info mb-3"> */}
                                                    <Input className="form-check-input" type="checkbox" id="formCheck11" defaultChecked />
                                                    <Label className="form-check-label fs-12 text-muted fw-normal" htmlFor="formCheck11">
                                                        I want to receive Brivas offers, news, trends or blogs by email
                                                    </Label>
                                                    {/* </div> */}

                                                    {/* <p className="mb-0 fs-12 text-muted fst-italic">By registering you agree
                                                        <Link to="#" className="text-primary text-decoration-underline fst-normal fw-medium">Terms of Use</Link>
                                                    </p> */}
                                                </div>

                                                <div className="mt-4">
                                                    {/* <button className="btn btn-success w-100 rounded-pill" type="submit">Sign Up</button> */}
                                                    <button
                                                        disabled={loading}
                                                        color="success"
                                                        className="btn w-100 rounded-pill btn-primary"
                                                        type="submit">
                                                        {loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                        Sign Up
                                                    </button>
                                                </div>

                                                <div className="mt-4">
                                                    <div className="signin-other-title text-center">
                                                        <h5 className="fs-13 mb-4 title text-muted">OR</h5> 
                                                    </div>

                                                    <button className="btn w-100 rounded-pill p-2 d-flex justify-content-center align-items-center btn-outline-secondary" type="submit">
                                                        <i className="ri-google-fill fs-13"></i> <span className="fs-11"> Sign up with Google</span>
                                                    </button>
                                                    <button className="btn w-100 rounded-pill mt-3 p-2 d-flex justify-content-center align-items-center btn-outline-secondary" type="submit">
                                                        <i className="ri-apple-fill fs-13"></i> <span className="fs-11"> Sign up with Apple</span>
                                                    </button>
                                                </div>
                                            </Form>
                                            <div className="mt-4 text-center">
                                                {/* fw-semibold  */}
                                                {/* text-decoration-underline */}
                                                <p className="mb-0 fs-12">Already have an account? <Link to="/login" className="text-primary"> Login </Link> </p>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default Register;