import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import img1 from "../../assets/images/svg/Telecom-banner4.svg";
import TowerIcon from "../../assets/images/svg/network-tower.png";

const Banner3 = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section>
      <div className="mx-md-5 px-3 px-md-5">
        <div class="row">
          <div class="col-md-6 col-sm-12 order-2 order-md-1">
            <h1
              className="display-6 fw-bold lh-base text-sm-center text-md-start mt-md-5 mb-5"
              style={{ fontSize: "40px" }}
            >
              Breakdown of Voice Services in Our Telecommunication Solution
            </h1>
            <div className="media d-flex mb-4">
              <img
                className="align-self-start mx-2"
                src={TowerIcon}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">Clear Communication</h5>
                <p>
                  Ensure reliable and crystal-clear voice calls with advanced
                  audio technology.
                </p>
              </div>
            </div>
            <div className="media d-flex mb-4">
              <img
                className="align-self-start mx-2"
                src={TowerIcon}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">Voice Biometrics</h5>
                <p>
                  Enhance security through voice recognition authentication
                  methods.
                </p>
              </div>
            </div>
            <div className="media d-flex mb-4">
              <img
                className="align-self-start mx-2"
                src={TowerIcon}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">Interactive Voice Response (IVR)</h5>
                <p>
                  Enable automated customer interactions for efficient query
                  resolution.
                </p>
              </div>
            </div>
            <div className="media d-flex mb-3">
              <img
                className="align-self-start mx-2"
                src={TowerIcon}
                alt="Generic placeholder image"
              />
              <div className="media-body">
                <h5 className="mt-0">Automated Payment Processing</h5>
                <p>
                  Streamline financial transactions with voice-enabled payment
                  options.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 my-5 d-flex align-items-center order-1">
            <img src={img1} alt="" className="user-img w-75" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner3;
