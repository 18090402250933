import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  CardText,
  Container,
  Form,
  Input,
  Label,
  Button,
  ButtonGroup,
  Alert,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";

import FBmsg from "../../assets/images/fbmessangericon.png";
import DLCfoods from "../../assets/images/dlcfoodsimage.png";

import SimpleBar from "simplebar-react";
import classnames from "classnames";
import { BasicRaterExample } from "../../Components/UiRatingsCode";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { setAuthorization } from "../../helpers/api_helper";
import { sendVerificationSMS } from "../../store/actions";

const Verifyauser = () => {
  const [activeTab, setactiveTab] = useState(1);
  const [progressbarvalue, setprogressbarvalue] = useState(0);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [displayTab, setDisplayTab] = useState("SMS OTP"); // Define isTypeTab variable
  const [codeTab, setCodeTab] = useState("cURL"); // Define isTypeTab variable
  const [isLoader, setIsLoader] = useState(false);

  const [selectedTab, setSelectedTab] = useState(0);

  const [justifyPillsTab, setjustifyPillsTab] = useState("1");
  const justifyPillsToggle = (tab) => {
    if (justifyPillsTab !== tab) {
      setjustifyPillsTab(tab);
    }
  };

  const { account, loading } = useSelector((state) => ({
    account: state.Account.account,
    loading: state.Smsotp.loading,
  }));
  // console.log(loading);

  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      sender: "",
      recipients: "",
      messageBody: "",
      digit: "",
    },
    validationSchema: Yup.object({
      // recipients: Yup.string().required("Please enter your email address"),
      messageBody: Yup.string().required("Please enter a message"),
    }),
    onSubmit: (values) => {
      // alert("Everything Submitted");
      setAuthorization(account?.test_secret_key);
      dispatch(
        // console.log(values.sender,values.recipients, values.messageBody, values.digit),
        // console.log(values),
        sendVerificationSMS({
          from: values.sender,
          to: values.recipients,
          message: values.messageBody,
          digit: values.digit,
        })
      );
    },
  });

  const jsonObject = {
    id: "018d3bff-f6d1-da98-a064-56031969a189",
    method: "sms",
    sms: {
      template: "Your verification code is {{CODE}}. Verified by Brivas",
      interceptionTimeout: 298,
    },
    _links: [
      {
        rel: "status",
        href: "https://dc-euc1-std.verification.api.Brivas.com/verification/v1/verifications/id/018d3bff-f6d1-da98-a064-56031969a189",
        method: "GET",
      },
      {
        rel: "report",
        href: "https://dc-euc1-std.verification.api.Brivas.com/verification/v1/verifications/id/018d3bff-f6d1-da98-a064-56031969a189",
        method: "PUT",
      },
    ],
  };

  function toggleTab(tab, ntype, value) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setprogressbarvalue(value);
  }

  document.title = "BRIVAS | Verification";
  return (
    <React.Fragment>
      <Card className="shadow-none">
        <div className="page-content">
          <CardHeader>
            <Row>
              <Col xl={6} sm={12}>
                <h1 className=" mb-0 fs-120 fw-bold">
                  Check out the Verify API{" "}
                </h1>
                <p className="card-title mb-0 text-muted mt-2 fs-13">
                  The Verify API lets you send a PIN to a user's phone and
                  validate that they received it.{" "}
                  <span className="text-primary">Learn more </span>
                </p>
              </Col>
            </Row>
            <Alert
              color=" text-dark"
              style={{ backgroundColor: "#F0F0F0" }}
              className="mt-3"
            >
              <strong>
                Verify <span className="text-primary"> V2 API </span> is now
                available
              </strong>
              <br />
              The developer documentation for the new API can be found here and
              includes some new features such as
              <br />
              - Sending verifications over new channels such as WhatsApp and
              Email
              <br />
              - Receiving callbacks with added insights into your request
              <br />
            </Alert>
          </CardHeader>

          <div>
            <Row>
              <Col xl={6}>
                <Form
                  action="#"
                  className="form-steps"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={1}>
                      <div>
                        <div className="mb-4"></div>
                        <h5>
                          <p>
                            <strong>Try it out with UI</strong>
                          </p>
                        </h5>

                        <br />
                        <br />

                        <div className="mb-3 col-md-10">
                          <Label
                            className="form-label  fw-normal fs-12"
                            htmlFor="gen-info-email-input"
                          >
                            Brand name
                          </Label>
                          <Input
                            type="text"
                            className="form-control rounded-3"
                            placeholder="BRIVAS"
                            name="sender"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.sender || ""}
                            invalid={
                              validation.touched.sender &&
                              validation.errors.sender
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="mb-5 mt-5 col-md-10">
                          <Label
                            className="form-label fw-normal"
                            htmlFor="gen-info-password-input"
                          >
                            Registered phone number
                          </Label>
                          <Input
                            className="form-control rounded-3"
                            id="gen-info-password-input"
                            placeholder="2348165661377"
                            name="recipients"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.recipients || ""}
                            invalid={
                              validation.touched.recipients &&
                              validation.errors.recipients
                                ? true
                                : false
                            }
                          />

                          <Label
                            className="form-label text-muted fw-normal"
                            htmlFor="gen-info-password-input"
                          >
                            You can only use the registered number to receive
                            messages in this example.
                          </Label>
                        </div>

                        <div className="mt-4 mr-5 ml-3">
                          <div className="mb-5">
                            <Label
                              htmlFor="exampleFormControlTextarea5"
                              className="form-label "
                            >
                              Message
                            </Label>
                            <textarea
                              className="form-control rounded-4 "
                              id="exampleFormControlTextarea5"
                              rows="3"
                              placeholder="Your verification code is “ code “ Verified by Brivas"
                              style={{ maxWidth: "80%" }}
                              name="messageBody"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.messageBody || ""}
                              invalid={
                                validation.touched.messageBody &&
                                validation.errors.messageBody
                                  ? true
                                  : false
                              }
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3 mt-5 col-md-10">
                        <div className="form-group w-25">
                          <div className=" border-4">
                            <label
                              className="form-label text-muted fw-normal"
                              htmlFor="gen-info-password-input"
                            >
                              PIN Length
                            </label>
                            <br></br>
                            <select
                              data-choices
                              data-choices-sorting="true"
                              id="autoSizingSelect1"
                              className="form-control rounded-4 "
                              rows="3"
                              placeholder="Your verification code is “ code “ Verified by Brivas"
                              style={{ maxWidth: "80%" }}
                              name="digit"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.digit || ""}
                              invalid={
                                validation.touched.digit &&
                                validation.errors.digit
                                  ? true
                                  : false
                              }
                            >
                              <option selected="selected">Select</option>
                              <option>4-digit</option>
                              <option>5-digit</option>
                              <option>6-digit</option>
                              <option>7-digit</option>
                              <option>8-digit</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className=" align-items-start gap-3 mt-0">
                        <Button
                          type="submit"
                          color="primary"
                          className="btn-load rounded-pill"
                          disabled={loading}
                        >
                          <span className="d-flex align-items-center">
                            {loading && (
                              <Spinner
                                size="sm"
                                type="grow"
                                className="flex-shrink-0"
                              />
                            )}
                            <span className="flex-grow-1 ms-1">Send OTP</span>
                          </span>
                        </Button>
                      </div>
                    </TabPane>
                  </TabContent>
                </Form>
              </Col>
              <Col xl={6}>
                <div className="email-content">
                  <div className="p-4 pb-0">
                    <div className="border-bottom border-bottom-dashed">
                      {/* <Row className="mt-n2 mb-3 mb-sm-0">
                              <Col className="col-sm-auto order-1 d-block d-lg-none">
                                <button
                                  type="button"
                                  className="btn btn-soft-success btn-icon btn-sm fs-16 email-menu-btn"
                                >
                                  <i className="ri-menu-2-fill align-bottom"></i>
                                </button>
                              </Col>
                              <div className="col-sm order-3 order-sm-2">
                                <div className="hstack gap-sm-1 align-items-center flex-wrap email-topbar-link">
                                  <div className="form-check fs-14 m-0">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="checkall"
                                      onChange={(e) => {
                                        // checkedAll(e.target.value);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="checkall"
                                    ></label>
                                  </div>
      
                                  <div
                                    id="email-topbar-actions"
                                    style={{ display: "none" }}
                                  >
                                    <div className="hstack gap-sm-1 align-items-center flex-wrap">
                                      <button
                                        type="button"
                                        className="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                                        id="Tooltip1"
                                      >
                                        <i className="ri-inbox-archive-fill align-bottom"></i>
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                                        id="Tooltip2"
                                      >
                                        <i className="ri-error-warning-fill align-bottom"></i>
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                                        id="Tooltip3"
                                        onClick={() => {
                                          // setDeleteModal(true);
                                        }}
                                      >
                                        <i className="ri-delete-bin-5-fill align-bottom"></i>
                                      </button>
                                    </div>
                                  </div>
      
                                  <div className="vr align-self-center mx-2"></div>
      
                                  <Alert
                                    color="warning"
                                    style={{ display: "none" }}
                                    className="unreadConversations-alert px-4 fade"
                                    id="unreadConversations"
                                  >
                                    No Unread Conversations
                                  </Alert>
      
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="Tooltip1"
                                  >
                                    {" "}
                                    Archive{" "}
                                  </UncontrolledTooltip>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="Tooltip2"
                                  >
                                    {" "}
                                    Report Spam{" "}
                                  </UncontrolledTooltip>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="Tooltip3"
                                  >
                                    {" "}
                                    Trash{" "}
                                  </UncontrolledTooltip>
                                </div>
                              </div>
                              <div className="col-auto order-2 order-sm-3">
                                <div className="d-flex gap-sm-1 email-topbar-link">
                                  <button
                                    type="button"
                                    className="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                                  >
                                    <i className="ri-refresh-line align-bottom"></i>
                                  </button>
                                </div>
                              </div>
                            </Row> */}

                      <Row className="row align-items-end mt-3">
                        <Col>
                          <div id="mail-filter-navlist">
                            <Nav
                              className="nav nav-tabs nav-tabs-custom nav-success gap-5 text-center border-bottom-0"
                              role="tablist"
                            >
                              <NavItem>
                                <NavLink
                                  className={classnames(
                                    { active: codeTab === "cURL" },
                                    "fw-semibold"
                                  )}
                                  onClick={() => setCodeTab("cURL")}
                                  href="#"
                                >
                                  <span className="ms-1 d-none d-sm-inline-block">
                                    cURL
                                  </span>
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames(
                                    { active: codeTab === "Node" },
                                    "fw-semibold"
                                  )}
                                  onClick={() => setCodeTab("Node")}
                                  href="#"
                                >
                                  <span className="ms-1 d-none d-sm-inline-block">
                                    Node
                                  </span>
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <SimpleBar className="message-list-content mx-n4 px-4 message-list-scroll">
                      {isLoader ? (
                        <div id="elmLoader">
                          <div
                            className="spinner-border text-primary avatar-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {codeTab === "cURL" && (
                            <Card className="shadow-none">
                              <h6 className=" mt-3 mb-5">
                                Install the Library
                              </h6>

                              <pre>
                                <code className="language-markup">
                                  <BasicRaterExample />
                                </code>
                              </pre>

                              <h6 className=" mt-2">
                                Check the request with a code
                              </h6>

                              <pre>
                                <code className="language-markup">
                                  <BasicRaterExample />
                                </code>
                              </pre>
                            </Card>
                          )}

                          {codeTab === "Node" && (
                            <Card className="shadow-none">
                              <h6 className=" mt-3 mb-5">
                                This is a place holder for content
                              </h6>

                              <pre>
                                <code className="language-markup">
                                  <BasicRaterExample />
                                </code>
                              </pre>

                              <h6 className=" mt-2">
                                Check the request with a code
                              </h6>

                              <pre>
                                <code className="language-markup">
                                  <BasicRaterExample />
                                </code>
                              </pre>
                            </Card>
                          )}
                          {codeTab === "Java" && <div>This is for Java</div>}
                        </div>
                      )}
                    </SimpleBar>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default Verifyauser;
