import {
  CREATE_ACCOUNT,
  CREATE_SUCCESS,
  CREATE_ACCOUNT_FAILED,
  CREATE_ACCOUNT_INIT,
  CREATE_ACCOUNT_INIT_SUCCESS,
  CREATE_ACCOUNT_INIT_FAILED,
  RESET_CREATE_ACCOUNT_FLAG,
} from "./actionTypes";

export const createAccountInit = () => {
  return {
    type: CREATE_ACCOUNT_INIT
  };
};

export const createAccountInitSuccess = data => {
  return {
    type: CREATE_ACCOUNT_INIT_SUCCESS,
    payload: data,
  };
};

export const createAccountInitError = error => { 
  return {
    type: CREATE_ACCOUNT_INIT_FAILED,
    payload: error,
  };
};

export const createAccount = (data, history) => {
  return {
    type: CREATE_ACCOUNT,
    payload: { data, history },
  };
};

export const createSuccess = data => {
  return {
    type: CREATE_SUCCESS,
    payload: data,
  };
};

export const createAccountError = error => { 
  return {
    type: CREATE_ACCOUNT_FAILED,
    payload: error,
  };
};

export const createResetFlag = () => { 
  return {
    type: RESET_CREATE_ACCOUNT_FLAG,
  }
}
