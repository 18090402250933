import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import numberandconnectivityhomepage from "../../assets/images/svg/numbers-connectivity-homepage.svg";
import numberandconnectivityhomepage2 from "../../assets/images/svg/numbers-connectivity-homepage2-min.png";
import { listProductGroup } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import ListIcon from "../../assets/images/svg/voiceservicelogo.svg";
import TowerIcon from "../../assets/images/brimailtransform1.png";
import img1 from "../../assets/images/svg/transferbrimailcartoon.svg";
import img2 from "../../assets/images/brimailtransform2.png";
import img3 from "../../assets/images/brimailtransform3.png";

const Home = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductGroup());
  }, [dispatch]);

  const { productGroups } = useSelector((state) => ({
    productGroups: state.Landing.productGroups,
  }));

  return (
    <React.Fragment>
      <section className="section pb-0" id="hero">
        <div className="mx-md-5 px-3 px-md-5">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <h1 className="display-6 fw-bold fs-43 lh-base text-dark text-sm-center text-md-start">
                Transform Your Emails with Brimail
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 order-2 order-md-1">
              <p className="mb-5">
                Unlock personalised engagement, effortless delivery, and
                interactive experiences with Brimail. Stay connected with
                mobile-friendly email hosting that allows easy access,
                management, and communication from any device, ensuring
                productivity and communication. Elevate your emails and connect
                with your audience in ways that truly matter.
              </p>
              <div className="media d-flex">
                <img
                  className="align-self-start mx-2"
                  src={img2}
                  alt="Generic placeholder image"
                />
                <div className="media-body">
                  <h5 className="mt-0 fw-bold">Fast Delivery</h5>
                  <p>
                    Quickly and easily send emails; only a few minutes are
                    required.
                  </p>
                </div>
              </div>

              <div className="media d-flex">
                <img
                  className="align-self-start mx-2"
                  src={img3}
                  alt="Generic placeholder image"
                />
                <div className="media-body">
                  <h5 className="mt-0 fw-bold">Professional Branding</h5>
                  <p>
                    With every email you send, stand out, create trust, and
                    strengthen your brand.
                  </p>
                </div>
              </div>

              <div className="media d-flex">
                <img
                  className="align-self-start mx-2"
                  src={img2}
                  alt="Generic placeholder image"
                />
                <div className="media-body">
                  <h5 className="mt-0 fw-bold">Secure Mailing Platform</h5>
                  <p>
                  Brimail protects your business emails with data encryption, spam filters, malware prevention, and dependable uptime.
                  </p>
                </div>
              </div>

              <div className="d-flex gap-2 my-5">
                <button
                  color="primary "
                  className="btn rounded-pill btn-primary"
                  onClick={() => "/register"}
                >
                  Start for free
                  <i className=" align-middle ms-1 padding-right-30px  rounded-pill"></i>
                </button>
                <button
                  color="success"
                  className="btn rounded-pill btn-light border-primary"
                  onClick={() => history("/register")}
                >
                  <span className="text-danger">Talk to an expert</span>
                </button>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 voip order-1">
              <img
                src={img1}
                alt=""
                className="user-img w-100"
                // style={{margin:'-10%'}}
                // width="599px"
                // width="380px"
              />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Home;
