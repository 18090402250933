import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import img1 from "../../assets/images/somtovoice.png";

const Home2 = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <section className="mb-5">
      <div className="row  mx-md-0 px-3 px-md-0">
        <div className="col-sm-12 col-md-6 order-sm-2  order-md-1">
          <div className=" p-md-5 mx-md-5  ">
            <p className=" mb-4">BRIVAS VOICE</p>
            <h1 className=" fw-bold fs-48  lh-base  text-dark">
              Crystal clear <br></br>
              <span className="text-primary">Communication</span> <br></br>
              for your business
            </h1>

            <p className="w-100">
              With Brivas voice services, you get access to superior call
              quality that leaves no room for miscommunication.
            </p>

            <div className="d-flex gap-2 mt-5">
              <button
                color="primary "
                className="btn rounded-pill btn-primary"
                onClick={() => "/register"}
              >
                Start for free
              </button>
              <button
                color="success"
                className="btn rounded-pill btn-light border-danger"
                onClick={() => history("/register")}
              >
                <span className="text-danger">Talk to an expert</span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 d-none d-md-inline order-sm-1 order-md-2">
          <img src={img1} alt="" className="user-img w-100 " />
        </div>
      </div>
    </section>
  );
};

export default Home2;
