import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, CardHeader } from "reactstrap";
import { getBulkSMSBal } from "../../store/actions";
import { setAuthorization } from "../../helpers/api_helper";

const Header = ({ children }) => {
    const dispatch = useDispatch();

    const { account, balance, isSent } = useSelector(state => ({
        account: state.Account.account,
        balance: state.BulkSMS.balance,
        isSent: state.BulkSMS.isSent,
    }));

    const getBal = () => {
        setAuthorization(account?.live_secret_key);
        dispatch(getBulkSMSBal());
    }

    useEffect(() => {
        getBal();
    }, []);

    useEffect(() => {
        if (!isSent) return;
        getBal();
    }, [isSent]);

    return <CardHeader className="d-flex flex-row">
        <div className="flex-grow-1 mx-4">
            {children}
        </div>
        <div className="mx-4">
            <div className="fw-normal fs-17 d-flex align-item-center mb-2">
                <i class="ri-wallet-line"></i><span className="mx-1">{balance} Units</span>
            </div>
            <Button color='primary' className="btn-sm fw-bold" onClick={(e) => { }}>
                Buy Unit
            </Button>
        </div>
    </CardHeader>
}

export default Header;