import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, ModalBody, Button, ModalHeader, Modal, Container, Input, Label, Row, Table } from 'reactstrap';
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
// import PreviewCardHeader from '../../../Components/Common/PreviewCardHeader';
// import UiContent from "../../../Components/Common/UiContent";

import Select from "react-select";
import { Link } from 'react-router-dom';
// import {
//   DefaultTables,
//   StrippedRow,
//   TablesColors,
//   HoverableRows,
//   CardTables,
//   ActiveTables,
//   BorderedTables,
//   TablesBorderColors,
//   TablesWithoutBorders,
//   SmallTables,
//   TableHead,
//   TableFoot,
//   Captions,
//   TableNesting,
//   Variants,
//   VerticalAlignment,
//   ResponsiveTables,
//   StripedColumnsTables
// } from './BasicTablesCode';

//Import images
import avtarImage1 from "../../assets/images/tok9jalogo.png";
import avtarImage3 from "../../assets/images/tok9jalogo.png";
import avtarImage4 from "../../assets/images/tok9jalogo.png";
import avtarImage5 from "../../assets/images/tok9jalogo.png";
import avtarImage6 from "../../assets/images/tok9jalogo.png";
import avtarImage7 from "../../assets/images/mtnlogo.png";
import avtarImage8 from "../../assets/images/airtellogo.png";











const CreateAConversation = () => {

  const [sortBy, setsortBy] = useState(null);
  const [modal_grid, setmodal_grid] = useState(false);
  const [modal_center, setmodal_center] = useState(false);


  const tog_grid = () => {
    setmodal_grid(!modal_grid);
  };


  function tog_center() {
    setmodal_center(!modal_center);
  }



  const sortByName = [
    {
      options: [
        { label: "Alabama", value: "AL" },
        { label: "Madrid", value: "MA" },
        { label: "Toronto", value: "TO" },
        { label: "Londan", value: "LO" },
        { label: "Wyoming", value: "WY" },
        { label: "Wyoming", value: "WY" },
        { label: "Wyoming", value: "WY" },
        { label: "Wyoming", value: "WY" },
        { label: "Wyoming", value: "WY" }
      ],
    },
  ];





  return (<React.Fragment>

    <Card>
      <CardBody>
        <CardHeader className="">
          <h1 className=" mb-0 fs-120 align-items-center fw-bold">Get started with Brivas SMS API</h1>
          <p className="card-title mb-0 w-50 align-items-center text-muted mt-2 fs-13">
            Buy and search numbers
          </p>
        </CardHeader>

        <div className="text-center mt-lg-5 pt-5">
          <h1 className="display-6 fw-semibold mb-3 lh-base">Buy and search numbers <span
            className="text-primary">BRIVAS </span></h1>
          <p className="lead w-sm-50 mb-5 text-muted lh-base">Until a new number is added, this space will remain empty. In the meantime, please remain calm and patient.</p>

          <Row className="g-4">
            <Col lg={4}>
              <p className="fw-bold text-start">Select Numbers to Buy</p>
              <Select
                value={sortBy}
                onChange={(sortBy) => {
                  setsortBy(sortBy);
                }}
                options={sortByName}
                id="choices-single-default"
                className="js-example-basic-single mb-0"
                name="state"
              />
            </Col>
          </Row>

          <div className=" align-items-start gap-3 mt-3">




            <Button color="primary rounded-pill" onClick={() => tog_center()}>Buy Number</Button>

            <Modal
              isOpen={modal_center}
              toggle={() => {
                tog_center();
              }}
              centered
            >

              <ModalHeader className="modal-title" />

              <ModalBody className="text-center p-5">
                <lord-icon src="https://cdn.lordicon.com/hrqwmuhr.json"
                  trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: "120px", height: "120px" }}>
                </lord-icon>
                <div className="mt-4">
                  <h4 className="mb-3">Amount Due</h4>
                  <p className="text-muted mb-4"> Monthly</p>
                  <div className="hstack gap-2 justify-content-center">
                    <Button color="light rounded-pill" onClick={() => setmodal_center(false)}>Close</Button>
                    <Link to="#" className="btn rounded-pill btn-danger">BUY</Link>
                  </div>
                </div>
              </ModalBody>

            </Modal>









            {/* <Button color="primary rounded-pill" onClick={() => setmodal_grid(true)}>
              Buy Number
            </Button> */}

            {/* <Modal
              isOpen={modal_grid}
              toggle={() => {
                tog_grid();
              }}
            >
              <ModalHeader>
                <h5 className="modal-title text-bold">
                  Order Summary
                </h5>
                <Button
                  type="button"
                  onClick={() => {
                    setmodal_grid(false);
                  }}
                  className="btn-close"
                  aria-label="Close"
                >
                </Button>
              </ModalHeader>
              <ModalBody>
                <form action="#">
                  <div className="row g-3">
                    <Col xxl={6}>
                      <div>
                        <label htmlFor="firstName" className="form-label">First Name</label>
                        <Input type="text" className="form-control" id="firstName" placeholder="Enter firstname" />
                      </div>
                    </Col>
                    <Col xxl={6}>
                      <div>
                        <label htmlFor="lastName" className="form-label">Last Name</label>
                        <Input type="text" className="form-control" id="lastName" placeholder="Enter lastname" />
                      </div>
                    </Col>
                    <div className="col-lg-12">
                      <label className="form-label">Gender</label>
                      <div>
                        <div className="form-check form-check-inline">
                          <Input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                          <label className="form-check-label" htmlFor="inlineRadio1">Male</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <Input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                          <label className="form-check-label" htmlFor="inlineRadio2">Female</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <Input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3" />
                          <label className="form-check-label" htmlFor="inlineRadio3">Others</label>
                        </div>
                      </div>
                    </div>
                    <Col xxl={6}>
                      <label htmlFor="emailInput" className="form-label">Email</label>
                      <Input type="email" className="form-control" id="emailInput" placeholder="Enter your email" />
                    </Col>
                    
                    <div className="col-lg-12">
                      <div className="hstack gap-2 justify-content-end">
                        <Button color=" btn-outline-danger rounded-pill text-danger" onClick={() => setmodal_grid(false)}>CLOSE</Button>
                        <Button color="primary rounded-pill">BUY</Button>
                      </div>
                    </div>
                  </div>
                </form>
              </ModalBody>
            </Modal> */}

          </div>
        </div>

        <p className="text-bold fs-100"> Buy and search numbers</p>
        <div className="live-preview">
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-secondary">
                <tr>
                  <th scope="col" style={{ "width": "42px" }}>
                    <div className="form-check">
                      <Input className="form-check-input" type="checkbox" defaultValue="" id="responsivetableCheck" />
                      <Label className="form-check-label" for="responsivetableCheck"></Label>
                    </div>
                  </th>
                  <th scope="col">Country</th>
                  <th scope="col">Info</th>
                  <th scope="col">Capability</th>
                  <th scope="col">Type</th>
                  <th scope="col">Setup cost</th>
                  <th scope="col">Monthly fee</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <div className="form-check">
                      <Input className="form-check-input" type="checkbox" defaultValue="" id="responsivetableCheck01" />
                      <Label className="form-check-label" htmlFor="responsivetableCheck01"></Label>
                    </div>
                  </th>
                  <td><div className="d-flex gap-2 align-items-center">
                    <div className="flex-shrink-0">
                      <img src={avtarImage3} alt="" className="avatar-xs rounded-circle" />
                    </div>
                    <div className="flex-grow-1">
                      2348065342345
                    </div>
                  </div></td>
                  <td>Tok9ja Nigeria</td>
                  <td className=""><i className="fs-17 align-middle"></i>Voice & SMS</td>
                  <td>
                    <div className="d-flex gap-2 align-items-center">
                      {/* <div className="flex-shrink-0">
                        <img src={avtarImage3} alt="" className="avatar-xs rounded-circle" />
                      </div> */}
                      <div className="flex-grow-1">
                      Dedicated long number 
                      </div>
                    </div>
                  </td>
                  <td>$00.00</td>
                  <td className="text-success">$20.03</td>
                </tr>
                <tr>
                  <th scope="row">
                    <div className="form-check">
                      <Input className="form-check-input" type="checkbox" defaultValue="" id="responsivetableCheck02" />
                      <Label className="form-check-label" htmlFor="responsivetableCheck02"></Label>
                    </div>
                  </th>
                  <td><div className="d-flex gap-2 align-items-center">
                    <div className="flex-shrink-0">
                      <img src={avtarImage1} alt="" className="avatar-xs rounded-circle" />
                    </div>
                    <div className="flex-grow-1">
                      2348065342345
                    </div>
                  </div></td>
                  <td>Tok9ja Nigeria</td>
                  <td className=""><i className=" fs-17 align-middle"></i> Voice & SMS</td>
                  <td>
                    <div className="d-flex gap-2 align-items-center">
                      {/* <div className="flex-shrink-0">
                        <img src={avtarImage4} alt="" className="avatar-xs rounded-circle" />
                      </div> */}
                      <div className="flex-grow-1">
                      Dedicated long number
                      </div>
                    </div>
                  </td>
                  <td>$00.00</td>
                  <td className="text-success" >$20.03</td>
                </tr>
                <tr>
                  <th scope="row">
                    <div className="form-check">
                      <Input className="form-check-input" type="checkbox" defaultValue="" id="responsivetableCheck03" />
                      <Label className="form-check-label" htmlFor="responsivetableCheck03"></Label>
                    </div>
                  </th>
                  <td><div className="d-flex gap-2 align-items-center">
                    <div className="flex-shrink-0">
                      <img src={avtarImage7} alt="" className="avatar-xs rounded-circle" />
                    </div>
                    <div className="flex-grow-1">
                      2348065342345
                    </div>
                  </div></td>
                  <td>MTN Nigeria</td>
                  <td className=""><i className="fs-17 align-middle"></i>Voice & SMS</td>
                  <td>
                    <div className="d-flex gap-2 align-items-center">
                      {/* <div className="flex-shrink-0">
                        <img src={avtarImage5} alt="" className="avatar-xs rounded-circle" />
                      </div> */}
                      <div className="flex-grow-1">
                      Dedicated long number
                      </div>
                    </div>
                  </td>
                  <td>$00.00</td>
                  <td className="text-success">$20.03</td>
                </tr>
                <tr>
                  <th scope="row">
                    <div className="form-check">
                      <Input className="form-check-input" type="checkbox" defaultValue="" id="responsivetableCheck04" />
                      <Label className="form-check-label" htmlFor="responsivetableCheck04"></Label>
                    </div>
                  </th>
                  <td><div className="d-flex gap-2 align-items-center">
                    <div className="flex-shrink-0">
                      <img src={avtarImage8} alt="" className="avatar-xs rounded-circle" />
                    </div>
                    <div className="flex-grow-1">
                      2348065342345
                    </div>
                  </div></td>
                  <td>Airtel Nigeria</td>
                  <td className=""><i className=" fs-17 align-middle"></i>Voice & SMS</td>
                  <td>
                    <div className="d-flex gap-2 align-items-center">
                      {/* <div className="flex-shrink-0">
                        <img src={avtarImage6} alt="" className="avatar-xs rounded-circle" />
                      </div> */}
                      <div className="flex-grow-1">
                      Dedicated long number 
                      </div>
                    </div>
                  </td>
                  <td>$00.00</td>
                  <td className="text-success">$20.03</td>
                </tr>
                <tr>
                  <th scope="row">
                    <div className="form-check">
                      <Input className="form-check-input" type="checkbox" defaultValue="" id="responsivetableCheck05" />
                      <Label className="form-check-label" htmlFor="responsivetableCheck05"></Label>
                    </div>
                  </th>
                  <td><div className="d-flex gap-2 align-items-center">
                    <div className="flex-shrink-0">
                      <img src={avtarImage3} alt="" className="avatar-xs rounded-circle" />
                    </div>
                    <div className="flex-grow-1">
                      2348065342345
                    </div>
                  </div></td>
                  <td>10 Nov, 07:20</td>
                  <td className=""><i className=" fs-17 align-middle"></i> Voice & SMS</td>
                  <td>
                    <div className="d-flex gap-2 align-items-center">
                      {/* <div className="flex-shrink-0">
                        <img src={avtarImage1} alt="" className="avatar-xs rounded-circle" />
                      </div> */}
                      <div className="flex-grow-1">
                      Dedicated long number
                      </div>
                    </div>
                  </td>
                  <td>$00.00</td>
                  <td className="text-success">$20.03</td>
                </tr>
              </tbody>
              <tfoot className="table-light">
                <tr>
                  <td colSpan="6">Total</td>
                  <td>$947.55</td>
                </tr>
              </tfoot>
            </Table>

          </div>

        </div>
        {/* <div className="d-none code-view">
          <pre className="language-markup" style={{ "height": "275px" }}>
            <code>
              <ResponsiveTables />
            </code>
          </pre>
        </div> */}
      </CardBody>
    </Card>
  </React.Fragment>);
};

export default CreateAConversation;
