import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  postTestFlashCall,
  postVerifiyFlashCall,
} from "../../helpers/backend_helper";
import { TEST_FLASH_CALL, VERIFY_FLASH_CALL } from "./actonType";
import { flashCallError, flashCallSuccess } from "./action";

function* testFlashCall({ payload: { data } }) {
  // alert('test flash call is firing')
  // console.log(data);
  try {
    const resp = yield call(postTestFlashCall, data);
    if (resp.status === "success") {
      yield put(flashCallSuccess(TEST_FLASH_CALL, resp));
    } else {
      toast.error(resp?.msg ?? "An error occured", { autoClose: 3000 });
      yield put(
        flashCallError(TEST_FLASH_CALL, resp?.msg ?? "An error occured")
      );
    }
  } catch (error) {
    console.log("error".error);
    yield put(flashCallError(TEST_FLASH_CALL, error?.message ?? "Failed"));
    toast.error(error?.response?.data?.msg ?? "Failed", { autoClose: 3000 });
  }
}

function* verifyFlashCall({ payload: { data } }) {
  // alert('verify flash call is firing')
  console.log(data);
  try {
    const resp = yield call(postVerifiyFlashCall, data);
    if (resp.status === "success") {
      yield put(flashCallSuccess(VERIFY_FLASH_CALL, resp));
    } else {
      toast.error(resp?.msg ?? "An error occured", { autoClose: 3000 });
      yield put(
        flashCallError(VERIFY_FLASH_CALL, resp?.msg ?? "An error occured")
      );
    }
  } catch (error) {
    yield put(flashCallError(VERIFY_FLASH_CALL, error?.message ?? "Failed"));
    toast.error(error?.response?.data?.msg ?? "Failed", { autoClose: 3000 });
  }
}

export function* watchTestFlashCall() {
  yield takeEvery(TEST_FLASH_CALL, testFlashCall);
}
export function* watchVerifyFlashCall() {
  yield takeEvery(VERIFY_FLASH_CALL, verifyFlashCall);
}

function* VerificationFlashCall() {
  yield all([fork(watchTestFlashCall)]);
  yield all([fork(watchVerifyFlashCall)]);
}

export default VerificationFlashCall;
