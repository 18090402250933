import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import img1 from "../../assets/images/svg/realtimeimage.svg";
import VoiceICON1 from "../../assets/images/voiceicon1.png";
import VoiceICON2 from "../../assets/images/voiceicon2.png";
import VoiceICON3 from "../../assets/images/voiceicon3.png";
import VOICEcard1 from "../../assets/images/voicecard1.png";
import VOICEcard2 from "../../assets/images/voicecard2.png";
import Voicelogo from "../../assets/images/svg/voiceimage.svg";

const Realtime = () => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);
  const history = useNavigate();

  return (
    <React.Fragment>
      <section
        className="section "
        id="plans"
        style={{ backgroundColor: "#FFF5FF" }}
      >
        <div className="mx-md-5 px-3 px-md-5">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <h1 className="display-6 fw-bold fs-48 lh-base text-dark">
                Real-time communication
                <br />
                <span className="text-primary">(WebRTC)</span>
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 order-2 order-md-1 my-3">
              <p className="mb-5">
                With WebRTC Voice, we provide seamless real-time communication
                capabilities directly within your web browser, enabling
                high-quality voice calls and enhancing the way you connect with
                others online.
              </p>
              <div className="media d-flex mb-4">
                <img
                  className="align-self-start mx-2"
                  src={VoiceICON1}
                  alt="Generic placeholder image"
                />
                <div className="media-body">
                  <h5 className="mt-0 text-dark">Media Stream</h5>
                </div>
              </div>
              <div className="media d-flex mb-4">
                <img
                  className="align-self-start mx-2"
                  src={VoiceICON2}
                  alt="Generic placeholder image"
                />
                <div className="media-body">
                  <h5 className="mt-0 text-dark">File Exchange</h5>
                </div>
              </div>
              <div className="media d-flex mb-4">
                <img
                  className="align-self-start mx-2"
                  src={VoiceICON3}
                  alt="Generic placeholder image"
                />
                <div className="media-body">
                  <h5 className="mt-0 text-dark">Real-Time Language </h5>
                </div>
              </div>

              <div className="d-flex gap-2 my-5">
                <button
                  color="success"
                  className="btn rounded-pill btn-light border-danger"
                  onClick={() => history("/register")}
                >
                  <span className="text-danger">Talk to an expert</span>
                </button>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 voip order-1 d-flex align-items-start justify-content-center">
              <img src={img1} alt="" className="user-img w-50 my-4 mx-md-5" />
            </div>
          </div>
        </div>

        <Row>
          <Col sm={12} md={6}>
            <div class="card mx-4">
              <div class="row g-0 card-body px-0 py-0">
                <div class="col-md-3 col-sm-12">
                  <div
                    style={{
                      height: "290px",
                      width: "auto",
                      backgroundImage: `url(${VOICEcard1})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  ></div>
                </div>
                <div class="col-md-9 col-sm-12">
                  <div class="d-flex justify-content-center flex-column py-3 px-4">
                    <h5 class="card-title fs-24 text-dark">Voice Broadcast</h5>
                    <p class="card-text fs-16 fw-400">
                      Introducing our revolutionary automated telephone call
                      service that delivers a meticulously crafted recorded
                      message right on schedule. We are right here to help you
                      seamlessly connect with your audience.
                    </p>

                    <div>
                      <button
                        color="danger"
                        className="btn rounded-pill btn-primary"
                        onClick={() => history("/register")}
                      >
                        Start for free
                      </button>
                      <button
                        color="success"
                        className="btn rounded-pill btn-light border-danger mx-3"
                        onClick={() => history("/register")}
                      >
                        <span className="text-danger">Talk to an expert</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div class="card mx-4">
              <div class="row g-0 card-body px-0 py-0">
                <div class="col-md-3 col-sm-12">
                  <div
                    style={{
                      height: "290px",
                      width: "auto",
                      backgroundImage: `url(${VOICEcard2})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  ></div>
                </div>
                <div class="col-md-9 col-sm-12">
                  <div class="d-flex justify-content-center flex-column py-3 px-4">
                    <h5 class="card-title fs-24 fw-500 text-dark">In-App Voice</h5>
                    <p class="card-text fs-16 fw-400">
                      A voice at the other end takes the frustration away.
                      People like knowing they can make crystal-clear calls when
                      they have questions or concerns. Take your app a notch
                      further by giving people what they want.
                    </p>

                    <div>
                      <button
                        color="danger"
                        className="btn rounded-pill btn-primary"
                        onClick={() => history("/register")}
                      >
                        Start for free
                      </button>
                      <button
                        color="success"
                        className="btn rounded-pill btn-light border-danger mx-3"
                        onClick={() => history("/register")}
                      >
                        <span className="text-danger">Talk to an expert</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </React.Fragment>
  );
};

export default Realtime;
